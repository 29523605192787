import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Formik, Field, Form } from 'formik';

import { FETCH_MEDICAL_LICENSES } from 'graphql/queries';
import { loadingVar } from 'graphql/cache';
import {
  Row,
  Column,
  Text,
  Container,
  FormPicker,
  ScrollView,
  Button,
} from 'common';
import { SimpleLayout } from 'layouts';
import assets from 'assets';
import AddModal from './AddModal';
import { STATES_LIST } from 'utils/constants';
import LicenseItem from './LicenseItem';
import { DELETE_MEDICAL_LICENSE } from 'graphql/mutations';

const STATE_FILTER_OPTIONS = [{ label: 'All', value: 'All' }, ...STATES_LIST];
const STATUS_FILTER_OPTIONS = [
  'All',
  'Valid',
  'Checking',
  'Expired',
  'Invalid',
];

const initialValues = {
  state: 'All',
  status: 'All',
};

export default ({}) => {
  const history = useHistory();
  const [licenses, updateLicenses] = useState([]);
  const [stateFilter, updateStateFilter] = useState('All');
  const [statusFilter, updateStatusFilter] = useState('All');
  const [showAddModal, setShowAddModal] = useState(false);
  const [fetchLicenses, { loading: fetchingLicenses }] = useLazyQuery(
    FETCH_MEDICAL_LICENSES,
    {
      onCompleted: ({ medicalLicenses }) => {
        updateLicenses(medicalLicenses || []);
      },
      fetchPolicy: 'no-cache',
      initialFetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    }
  );
  const [removeLicense, { loading: removingLicense }] = useMutation(
    DELETE_MEDICAL_LICENSE,
    {
      onCompleted: ({ deleteMedicalLicense }) => {
        updateLicenses(deleteMedicalLicense || []);
      },
      fetchPolicy: 'no-cache',
      initialFetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    }
  );
  // const [updateScheduleApi] = useMutation(UPDATE_OPERATION_SCHEDULE);
  // const [schedules, updateSchedules] = useState();

  const goBack = useCallback(() => {
    history.replace('/');
  }, []);

  const handleAdd = async () => {
    setShowAddModal(true);
  };

  const handleCloseModal = (updatedLicenses) => {
    if (updatedLicenses) {
      updateLicenses(updatedLicenses);
    }
    setShowAddModal(false);
  };

  const handleDeleteLicense = (license) => {
    removeLicense({
      variables: { id: license._id },
    });
  };

  const filteredLicenses = useMemo(() => {
    if (
      licenses?.length > 0 &&
      (stateFilter !== 'All' || statusFilter !== 'All')
    ) {
      const list = [];
      for (let license of licenses) {
        if (stateFilter !== 'All' && license.stateCode !== stateFilter) {
          continue;
        }
        if (statusFilter !== 'All' && license.status !== statusFilter) {
          continue;
        }
        list.push(license);
      }
      return list;
    }
    return licenses;
  }, [licenses, stateFilter, statusFilter]);

  useEffect(() => {
    fetchLicenses();
  }, []);

  loadingVar(fetchingLicenses || removingLicense);

  return (
    <SimpleLayout>
      <SimpleLayout.Header content="Medical Licenses" />
      <SimpleLayout.Content>
        <Container modifiers={['backgroundWhite', 'fullHeight', 'flexBox']}>
          <Formik initialValues={initialValues} key="stateFilterPickerForm">
            <Form>
              <Row>
                <Column modifiers={['col_6', 'flexBox']}>
                  <Text modifiers={['medium']}>State: </Text>
                  <Field
                    asInfo
                    bold
                    noPadding
                    noEmptyOption
                    noBorder
                    fitContent
                    options={STATE_FILTER_OPTIONS}
                    handleChange={updateStateFilter}
                    idField="value"
                    nameField="label"
                    component={FormPicker}
                    name="state"
                  />
                </Column>
                <Column modifiers={['col_6', 'flexBox']}>
                  <Text modifiers={['medium']}>Status: </Text>
                  <Field
                    asInfo
                    bold
                    noPadding
                    noEmptyOption
                    noBorder
                    fitContent
                    options={STATUS_FILTER_OPTIONS}
                    handleChange={updateStatusFilter}
                    component={FormPicker}
                    name="status"
                  />
                </Column>
              </Row>
            </Form>
          </Formik>
          <ScrollView>
            {filteredLicenses.length > 0 ? (
              filteredLicenses.map((license) => (
                <LicenseItem
                  license={license}
                  handleDelete={handleDeleteLicense}
                  key={license._id}
                />
              ))
            ) : (
              <Container>
                <Text modifiers={['medium', 'block']}>
                  You have no licenses.
                </Text>
              </Container>
            )}
          </ScrollView>
        </Container>
        {showAddModal && <AddModal handleClose={handleCloseModal} />}
      </SimpleLayout.Content>
      <SimpleLayout.Footer>
        <Row modifiers={['middle', 'spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={goBack}
          />
          <Button modifiers={['widthSmall', 'roundCorner']} onClick={handleAdd}>
            Add
          </Button>
        </Row>
      </SimpleLayout.Footer>
    </SimpleLayout>
  );
};
