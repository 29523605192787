import React from 'react';
import assets from 'assets';
import { noop } from 'lodash';
import { Text, Row, Column as Col, Button } from '@gaz/gaz-components.public';
import { displayTime } from 'utils/time';
import { getVitalTypeAttr, getVitalValueString } from 'utils/helper';
import { NoteItemWrapper } from './NoteItem';
import VitalHightlight from './VitalHighlight';

export default function VitalItem({
  measurement = {},
  vitalType,
  handleSelectVital = noop,
}) {
  const vitalTypeAttrs = getVitalTypeAttr(vitalType);
  const { value, value2, danger, success, muted, time } = measurement;
  let title;

  if (!value) {
    title = 'N/A';
  } else {
    title = getVitalValueString(vitalType, value, value2);
  }

  return (
    <NoteItemWrapper
      modifiers={['round']}
      onClick={() => handleSelectVital(vitalType)}
    >
      <Row>
        <Col modifiers={['col_9', 'fluid', 'noVerticalPadding']}>
          <Row>
            <Col
              modifiers={[
                'col_2',
                'flexBox',
                'center',
                'verticalContentCenter',
                'noVerticalPadding',
              ]}
            >
              <img src={vitalTypeAttrs.icon} alt="" width="25" height="25" />
            </Col>
            <Col modifiers={['col_8', 'noVerticalPadding', 'fluid']}>
              <Text modifiers={['small', 'xSmall', 'block', 'secondaryColor']}>
                {vitalTypeAttrs.title}
              </Text>
              <Text modifiers={['xSmall', 'block', 'muted']}>
                {time ? displayTime(time, 'MM/DD/YYYY • hh:mm a') : 'N/A'}
              </Text>
            </Col>
            <Col modifiers={['col_2', 'fluid', 'verticalContentCenter']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icGoNextBig}
                width={32}
                height={32}
              />
            </Col>
          </Row>
        </Col>
        <VitalHightlight
          measurementValue={title || 'N/A'}
          modifiers={[
            danger && 'danger',
            success && 'success',
            muted && 'muted',
          ]}
        />
      </Row>
    </NoteItemWrapper>
  );
}
