import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Row, Column, Text } from '@gaz/gaz-components.public';

import assets from 'assets';
import urls from 'routes/urls';
import Card from './Card';
import { ProviderContext } from 'contexts/provider';

const Coordinate = () => {
  const history = useHistory();
  const { coordinateCount } = useContext(ProviderContext);

  return (
    <Card modifiers={['fluid']} onClick={() => history.push(urls.COORDINATE)}>
      <Row modifiers={['fullHeight', 'middle']}>
        <Column modifiers={['col_3', 'flexBox', 'verticalContentCenter']}>
          <Card.Icon src={assets.icons.icCoordinatePink} />
        </Column>
        <Column modifiers={['col_9']}>
          <Text modifiers={['block', 'darkGrey', 'subtitle', 'semiBold']}>
            Coordinate
          </Text>
          {coordinateCount > 0 && (
            <Text modifiers={['block', 'darkGrey', 'small', 'body']}>
              {coordinateCount} New
            </Text>
          )}
        </Column>
      </Row>
    </Card>
  );
};

export default Coordinate;
