import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';

import { Container, Row, Column as Col, Button } from 'common';
import { FormInput, Text } from 'common';
import urls from 'routes/urls';
import schema from 'utils/schema';
import { REQUEST_RESET_PASSWORD } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { AuthContext } from 'contexts/auth';

const emailSchema = Yup.object().shape({
  email: schema.email,
});

export default function Login() {
  const { me } = useContext(AuthContext);
  const [didSubmit, setDidSubmit] = useState(false);

  const [requestResetPassword] = useMutation(REQUEST_RESET_PASSWORD);
  if (me) {
    return <Redirect to={urls.DASHBOARD} />;
  }

  const handleSubmit = async (values) => {
    loadingVar(true);

    await requestResetPassword({
      variables: {
        email: values.email,
      },
    });

    loadingVar(false);
    setDidSubmit(true);
  };

  return (
    <Container
      modifiers={['flexBox', 'fullHeight']}
      style={{ paddingTop: '40%' }}
    >
      {!didSubmit ? (
        <Formik
          initialValues={{}}
          validationSchema={emailSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, dirty }) => (
            <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Row>
                <Col modifiers={'col_12'}>
                  <Text modifiers={['block']}>
                    Please enter your email address and we will send you a link
                    to reset a password if it matches.
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col modifiers={['col_12']}>
                  <Field
                    name="email"
                    placeholder="Enter email address"
                    type="email"
                    modifiers={['squared']}
                    component={FormInput}
                  />
                </Col>
              </Row>
              <Row>
                <Col modifiers={['col_12', 'flexBox', 'center']}>
                  <Button
                    modifiers={[
                      'primary',
                      'roundCorner',
                      'widthMedium',
                      (!dirty || !isValid) && 'disabled',
                    ]}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      ) : (
        <Row>
          <Col modifiers={'col_12'}>
            <Text modifiers={['block']}>
              Password Reset has been requested. We will send you the link to
              your email inbox.
            </Text>
          </Col>
        </Row>
      )}
    </Container>
  );
}
