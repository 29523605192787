import { Container, Text, Column, Avatar } from '@gaz/gaz-components.public';
import styled from 'styled-components';

import { Row } from 'common';
import { buildStyledComponent } from 'style';
import { alertsTitle } from 'utils/helper';
import { formatTitleAndName } from 'utils/string';
import { displayTime } from 'utils/time';
import MeasureItem from './MeasureItem';

const CareTeam = buildStyledComponent(
  'CareplanCareTeam',
  styled(({ members, ...props }) => {
    const items = members.map((item) => {
      const detail = {};
      if (item.appointments?.length > 0) {
        detail.Appointments = '';
        item.appointments.forEach((appointment) => {
          detail.Appointments +=
            displayTime(appointment.time, 'MM/DD/YY @hh:mm A') + '\n';
        });
      }
      if (item.alerts && Object.keys(item.alerts).length > 0) {
        detail.Triggers = `>${item.alerts.triggerValue} missed`;
        detail.Allowed = alertsTitle(item.alerts);
        detail.Action = 'Book Appointments';
      }

      return {
        user: item.user,
        name: formatTitleAndName(item.user),
        goal: '',
        detail,
      };
    });
    return (
      <Container {...props}>
        <Text modifiers={['bold']}>CARE TEAM</Text>
        {items.map((item, index) => (
          <Container modifiers={['fluid']} key={index}>
            <Row modifiers={['middle', 'smallGutters']}>
              <Avatar
                user={item.user}
                modifiers={['squared']}
                width={30}
                height={30}
              />
              <Column modifiers={['col']}>
                <Text modifiers={['block']}>{item.name}</Text>
              </Column>
            </Row>
            <MeasureItem details={item.detail} />
          </Container>
        ))}
      </Container>
    );
  }),
  ({ theme }) => `
    >:not(:first-child) {
      margin-left: ${theme.dimensions.padding_2};
    }
  `
);

export default CareTeam;
