import { useState, useRef } from 'react';
import { cloneDeep } from 'lodash';

import {
  Row,
  Text,
  Button,
  Input,
  BackButton,
} from '@gaz/gaz-components.public';
import { Container, Tabs, Modal } from 'common';
import Alerts from './Alerts';
import Frequency from '../Frequency';
import { CustomRow } from '../Frequency/Items';
import assets from 'assets';

const ActivityInstruction = ({ activity, handleDone, handleClose }) => {
  const [durationValue, updateDurationValue] = useState(30);
  const [activeTab, setActiveTab] = useState(0);
  const [frequency, updateFrequency] = useState(
    cloneDeep(activity?.frequency || {})
  );
  const [alerts, updateAlerts] = useState(cloneDeep(activity?.alerts || {}));
  const frequencyRef = useRef();
  const alertsRef = useRef();

  const handleDoneClick = () => {
    let frequencyEdit, alertsEdit;
    if (activeTab === 0) {
      updateFrequency(frequencyRef.current());
      frequencyEdit = frequencyRef.current();
      alertsEdit = alerts;
    } else {
      updateAlerts(alertsRef.current.values);
      frequencyEdit = frequency;
      alertsEdit = alertsRef.current.values;
    }
    frequencyEdit.duration = durationValue;
    handleDone({ ...activity, frequency: frequencyEdit, alerts: alertsEdit });
    handleClose();
  };

  const changeTab = (tab) => {
    setActiveTab(tab);
    if (tab === 1) {
      updateFrequency(frequencyRef.current());
    } else {
      updateAlerts(alertsRef.current.values);
    }
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Activity Instructions</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container
          modifiers={['flexBox', 'fluid', 'fullHeight', 'backgroundGray']}
        >
          <Container modifiers={['flexFixed', 'padding_2']}>
            <Text modifiers={['medium']}>{activity.activity}</Text>
          </Container>
          <Container
            modifiers={['flexOne', 'fullHeight', 'flexBox', 'backgroundWhite']}
          >
            <Container modifiers={['flexFixed']}>
              <CustomRow title="Duration" hasThreeComponents>
                <Input
                  modifiers={[
                    'noPadding',
                    'borderBottomOnly',
                    'asInfo',
                    'valid',
                    'widthSmall',
                  ]}
                  type="number"
                  onChange={(event) => updateDurationValue(event.target.value)}
                  value={durationValue}
                />
                <Text>Mins</Text>
              </CustomRow>
            </Container>
            <Tabs current={activeTab} onChange={changeTab} noSeperator>
              <Tabs.Tab
                title="Frequency"
                modifiers={['xSmallPadding', 'noTab']}
              />
              <Tabs.Tab title="Alerts" modifiers={['xSmallPadding']} />
            </Tabs>
            <Container modifiers={['fluid', 'fullHeight', 'withGutters']}>
              {activeTab === 0 ? (
                <Frequency
                  frequency={frequency}
                  getRef={frequencyRef}
                  icon={assets.icons.icActivity}
                />
              ) : (
                <Alerts alerts={alerts} formRef={alertsRef} />
              )}
            </Container>
          </Container>
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleClose}
              />
              <Button
                modifiers={['primary', 'widthSmall', 'roundCorner']}
                onClick={handleDoneClick}
              >
                Done
              </Button>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ActivityInstruction;
