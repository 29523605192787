import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: ${theme.colors.black};

  .btn-back {
    margin: ${px2rem(10)} ${px2rem(20)};
  }
`;

export default buildStyledComponent('QRCodeReader', styled.div, styles);
