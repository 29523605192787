import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router';
import qs from 'query-string';
import * as Yup from 'yup';

import { Container, Row, Button, A } from 'common';
import { FormPasswordInput, FormPinInput, Text } from 'common';
import urls from 'routes/urls';
import schema from 'utils/schema';
import { client } from 'graphql/client';
import { RESET_PASSWORD } from 'graphql/mutations';
import { CHECK_RESET_PASSWORD_TOKEN } from 'graphql/queries';
import { loadingVar } from 'graphql/cache';
import { AuthContext } from 'contexts/auth';

const resetPasswordSchema = Yup.object().shape({
  password: schema.password,
  passwordConfirmation: schema.confirmPassword,
  pin: schema.pin,
  pinConfirmation: schema.confirmPin,
});

const initialValues = {
  password: '',
  passwordConfirmation: '',
  pin: '',
  pinConfirmation: '',
};

const ERROR_STATUS = {
  VALID_TOKEN: 'VALID_TOKEN',
  INVALID_TOKEN: 'INVALID_TOKEN',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export default function Login() {
  const { updateAuth } = useContext(AuthContext);
  const [status, updateStatus] = useState();

  const location = useLocation();
  const { token } = qs.parse(location.search);

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: async (data) => {
      updateStatus(data.resetPassword);
      await updateAuth();
      client.resetStore();
    },
    onError: () => {
      updateStatus(ERROR_STATUS.FAILED);
    },
  });
  const [checkResetPasswordToken] = useLazyQuery(CHECK_RESET_PASSWORD_TOKEN, {
    variables: {
      token,
    },
    onCompleted: (data) => {
      updateStatus(data.checkResetPasswordToken);
      loadingVar(false);
    },
    onError: () => {
      updateStatus(ERROR_STATUS.INVALID_TOKEN);
      loadingVar(false);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (!token || token.length === 0) {
      updateStatus(ERROR_STATUS.INVALID_TOKEN);
    } else {
      loadingVar(true);
      checkResetPasswordToken();
    }
  }, [token]);

  const handleSubmit = async (values) => {
    loadingVar(true);

    await resetPassword({
      variables: {
        token,
        security: {
          currentPassword: '',
          password: values.password,
          currentPin: '',
          pin: values.pin,
        },
      },
    });

    loadingVar(false);
  };

  return (
    <>
      {status === ERROR_STATUS.VALID_TOKEN ? (
        <Container modifiers={['flexBox', 'fullHeight']}>
          <Formik
            initialValues={initialValues}
            validationSchema={resetPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty }) => (
              <Form>
                <Container modifiers="padding_2">
                  <Text modifiers="bold">Reset Password</Text>
                  <Container>
                    <Field
                      name="password"
                      placeholder="New password"
                      label="New Password"
                      component={FormPasswordInput}
                    />
                  </Container>
                  <Container>
                    <Field
                      name="passwordConfirmation"
                      placeholder="Confirm New Password"
                      label="Confirm New Password"
                      component={FormPasswordInput}
                    />
                  </Container>
                </Container>
                <hr />
                <Container modifiers="padding_2">
                  <Text modifiers="bold">Reset 4-digit Code</Text>
                  <Container>
                    <Field
                      hasLabel
                      placeholder="New 4-digit Code"
                      name="pin"
                      component={FormPinInput}
                      fields={4}
                    />
                  </Container>
                  <Container>
                    <Field
                      hasLabel
                      placeholder="Confirm New 4-digit Code"
                      name="pinConfirmation"
                      component={FormPinInput}
                      fields={4}
                    />
                  </Container>
                </Container>
                <Container>
                  <Row modifiers={['center']}>
                    <Button
                      type="submit"
                      modifiers={(!isValid || !dirty) && 'disabled'}
                    >
                      Save
                    </Button>
                  </Row>
                </Container>
              </Form>
            )}
          </Formik>
        </Container>
      ) : (
        <Container
          modifiers={['flexBox', 'fullHeight']}
          style={{ paddingTop: '40%' }}
        >
          {status === ERROR_STATUS.INVALID_TOKEN && (
            <Text modifiers={['block', 'center']}>
              Your reset password url is invalid.
            </Text>
          )}
          {status === ERROR_STATUS.TOKEN_EXPIRED && (
            <Text modifiers={['block', 'center']}>
              Your reset password url is expired.
            </Text>
          )}
          {status === ERROR_STATUS.SUCCESS && (
            <Text modifiers={['block', 'center']}>
              You have successfully reset your password.
            </Text>
          )}
          {status === ERROR_STATUS.FAILED && (
            <Text modifiers={['block', 'center']}>
              There was an error resetting your password. Please try again later
              in a few minutes.
            </Text>
          )}
          <Row modifiers={['center', 'withGutters']}>
            <A to={urls.AUTH}>
              <Button>Log In</Button>
            </A>
          </Row>
        </Container>
      )}
    </>
  );
}
