import styled from 'styled-components';

import { buildStyledComponent } from 'style';
import Notifications from './Notifications';
import { DESKTOP_VIEWPORT_WIDTH } from 'utils/constants';

const styles = ({ theme }) => `
  color: ${theme.colors.text};
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.medium};
  height: 100%;
  position: relative;

  @media only screen and (min-width: 769px) {
    max-width: ${DESKTOP_VIEWPORT_WIDTH};
    margin: 0 auto;
  }

  button, input, * {
    font-family: ${theme.fonts.primary};
  }

  * {
    box-sizing: border-box;
  }
`;

const Wrapper = buildStyledComponent('Main', styled.div, styles);
Wrapper.Notifications = Notifications;

export default Wrapper;
