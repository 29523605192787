import { useState, useRef, useEffect } from 'react';
import { cloneDeep } from 'lodash';

import { Row, Text, Button, BackButton } from '@gaz/gaz-components.public';
import { Container, Modal, Tabs } from 'common';
import Alerts from './Alerts';
import Mods from './Mods';
import Frequency from '../Frequency';
import Quantity from './Quantity';
import GroupModal from './GroupModal';
import assets from 'assets';

const MedicationInstruction = ({
  medication,
  medications,
  handleDone,
  handleClose,
  handleGrouping,
  noAlerts,
  forOrder,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [frequency, updateFrequency] = useState(
    cloneDeep(medication?.frequency || {})
  );
  const [alerts, updateAlerts] = useState(cloneDeep(medication?.alerts || {}));
  const [mods, updateMods] = useState(cloneDeep(medication?.mods || {}));
  const [quantity, updateQuantity] = useState(medication?.quantity || 0);
  const [otherMeds, setOtherMeds] = useState([]);
  const [showGroupingModal, setShowGroupingModal] = useState(false);
  const frequencyRef = useRef();
  const alertsRef = useRef();
  const modsRef = useRef();

  useEffect(() => {
    const others = {};
    Object.values(medications).forEach((med) => {
      if (med.ndc !== medication.ndc) {
        others[med.ndc] = med;
      }
    });
    setOtherMeds({ ...others });
  }, []);

  const handleDoneClick = () => {
    if (forOrder && (isNaN(quantity) || parseFloat(quantity) <= 0)) {
      Modal.info({
        text: 'Please enter quantity for order.',
      });
      return;
    }
    let frequencyEdit, alertsEdit, modsEdit;
    if (activeTab === 0) {
      updateFrequency(frequencyRef.current());
      frequencyEdit = frequencyRef.current();
      alertsEdit = alerts;
      modsEdit = mods;
    } else if (activeTab === 1) {
      updateAlerts(alertsRef.current());
      frequencyEdit = frequency;
      alertsEdit = alertsRef.current();
      modsEdit = mods;
    } else if (activeTab === 2) {
      updateMods(modsRef.current());
      frequencyEdit = frequency;
      alertsEdit = alerts;
      modsEdit = modsRef.current();
    } else {
      frequencyEdit = frequency;
      alertsEdit = alerts;
      modsEdit = mods;
    }
    handleDone(
      medication,
      frequencyEdit,
      alertsEdit,
      modsEdit,
      forOrder ? parseFloat(quantity) : null
    );
    handleClose();
  };

  const handleGroupClick = () => {
    if (activeTab === 0) {
      updateFrequency(frequencyRef.current());
    } else if (activeTab === 1) {
      updateAlerts(alertsRef.current());
    } else {
      updateMods(modsRef.current());
    }
    setShowGroupingModal(true);
  };

  const handleFinishGrouping = (selectedMeds) => {
    switch (activeTab) {
      case 0:
        handleGrouping(medication, selectedMeds, frequency, 'frequency');
        break;
      case 1:
        handleGrouping(medication, selectedMeds, alerts, 'alerts');
        break;
      case 2:
        handleGrouping(medication, selectedMeds, mods, 'mods');
        break;
    }
  };

  const changeTab = (tab) => {
    switch (activeTab) {
      case 0:
        updateFrequency(frequencyRef.current());
        break;
      case 1:
        updateAlerts(alertsRef.current());
        break;
      case 2:
        updateMods(modsRef.current());
        break;
    }
    setActiveTab(tab);
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Medication Instructions</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container
          modifiers={['flexBox', 'fluid', 'fullHeight', 'backgroundGray']}
        >
          <Container modifiers={['flexFixed', 'padding_2']}>
            <Text modifiers={['medium']}>{medication.name}</Text>
          </Container>
          <Container
            modifiers={['flexOne', 'fullHeight', 'flexBox', 'backgroundWhite']}
          >
            <Tabs current={activeTab} onChange={changeTab} noSeperator>
              <Tabs.Tab
                title="Frequency"
                modifiers={['xSmallPadding', 'noTab']}
              />
              <Tabs.Tab
                title="Alerts"
                modifiers={['xSmallPadding', 'noTab', noAlerts && 'noDisplay']}
                hidden
              />
              <Tabs.Tab title="Mods" modifiers={['xSmallPadding', 'noTab']} />
              <Tabs.Tab
                title="Quantity"
                modifiers={['xSmallPadding', 'noTab']}
                hidden={!forOrder}
              />
            </Tabs>
            <Container modifiers={['fluid', 'fullHeight', 'withGutters']}>
              {activeTab === 0 && (
                <>
                  <Frequency
                    frequency={frequency}
                    getRef={frequencyRef}
                    icon={assets.icons.icMedication}
                  />
                  <GroupModal
                    isVisible={showGroupingModal}
                    type="frequency"
                    value={frequency}
                    medications={otherMeds}
                    onConfirm={handleFinishGrouping}
                    onClose={() => setShowGroupingModal(false)}
                  />
                </>
              )}
              {activeTab === 1 && (
                <>
                  <Alerts alerts={alerts} getRef={alertsRef} />
                  <GroupModal
                    isVisible={showGroupingModal}
                    type="alerts"
                    value={alerts}
                    medications={otherMeds}
                    onConfirm={handleFinishGrouping}
                    onClose={() => setShowGroupingModal(false)}
                  />
                </>
              )}
              {activeTab === 2 && (
                <>
                  <Mods mods={mods} getRef={modsRef} />
                  <GroupModal
                    isVisible={showGroupingModal}
                    type="mods"
                    value={mods}
                    medications={otherMeds}
                    onConfirm={handleFinishGrouping}
                    onClose={() => setShowGroupingModal(false)}
                  />
                </>
              )}
              {activeTab === 3 && (
                <Quantity
                  updateQuantity={updateQuantity}
                  medication={medication}
                  quantity={quantity}
                />
              )}
            </Container>
          </Container>
          <Container modifiers={['flexFixed', 'footer', 'backgroundWhite']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleClose}
              />
              {Object.keys(otherMeds).length > 0 && (
                <Button
                  modifiers={['roundCorner', 'success']}
                  onClick={handleGroupClick}
                >
                  Group{' '}
                  {activeTab === 0
                    ? 'Frequency'
                    : activeTab === 1
                    ? 'Alerts'
                    : 'Mods'}
                </Button>
              )}
              <Button
                modifiers={['primary', 'widthSmall', 'roundCorner']}
                onClick={handleDoneClick}
              >
                Done
              </Button>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default MedicationInstruction;
