import React, { useState, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import Picker from '../../Picker/Picker.js';

import 'rmc-picker/assets/index.css';

import FormInput from '../FormInput';
import Modal from './Modal';

export const FormPicker = (props) => {
  const {
    options,
    idField = 'id',
    nameField = 'name',
    shortNameField = 'shortName',
    noEmptyOption,
    handleChange: customChangeHandler,
    ...inputProps
  } = props;
  const items = options.map((item) => {
    if (typeof item === 'object' && item !== null) {
      return {
        id: item[idField],
        name: item[nameField],
        shortName: item[shortNameField],
      };
    } else {
      return {
        id: item,
        name: item,
      };
    }
  });
  if (!noEmptyOption) {
    items.unshift({ id: '', name: '' });
  }
  const {
    field: { value, name, onChange },
    placeholder,
    title,
  } = inputProps;
  const [showModal, setShowModal] = useState(false);
  const [selectedValue, updateSelectedValue] = useState(value);
  const originalItem = items.find((item) => item.id === value);
  const selectedItem = items.find((item) => item.id === selectedValue);
  const wheelDeltaData = useRef();

  const handleChange = (item) => {
    if (showModal) {
      updateSelectedValue(item.id);
    }
  };

  const handleToggle = () => {
    if (showModal === true) {
      updateSelectedValue(value);
    }
    setShowModal(!showModal);
  };

  const handleDone = () => {
    onChange(name)(selectedValue);
    if (customChangeHandler) {
      customChangeHandler(selectedValue);
    }
    setShowModal(false);
  };

  const clearWheelDelta = useCallback(
    debounce(() => {
      if (wheelDeltaData.current) {
        wheelDeltaData.current.delta = 0;
      }
    }, 300),
    []
  );

  const handleWheel = useCallback(
    (event) => {
      const delta = -event.nativeEvent?.wheelDeltaY || 0;
      if (delta === 0) {
        return;
      }
      if (!wheelDeltaData.current) {
        wheelDeltaData.current = { delta: 0 };
      }
      wheelDeltaData.current.delta = wheelDeltaData.current.delta + delta;
      let step = 0;
      if (wheelDeltaData.current.delta >= 120) {
        step = wheelDeltaData.current.delta / 120;
        wheelDeltaData.current.delta = 0;
      } else if (wheelDeltaData.current.delta <= -120) {
        step = -(-wheelDeltaData.current.delta / 120);
        wheelDeltaData.current.delta = 0;
      }

      const wheelStep = Math.max(-3, Math.min(3, Math.round(step)));
      const index = items.indexOf(selectedItem);
      const newIndex = Math.min(
        items.length - 1,
        Math.max(0, wheelStep + index)
      );
      if (index !== newIndex) {
        updateSelectedValue(items[newIndex].id);
      }
      clearWheelDelta();
    },
    [selectedValue]
  );

  return (
    <div>
      <FormInput
        {...inputProps}
        field={{
          ...inputProps.field,
          value: originalItem?.shortName || originalItem?.name || '',
        }}
        onClick={() => setShowModal(true)}
        readOnly
      ></FormInput>
      <Modal
        modifiers={['v2']}
        title={title || placeholder}
        show={showModal ? 'yes' : undefined}
        onToggle={handleToggle}
        handleDone={handleDone}
        onPointerDown={(evt) => {
          evt.target?.setPointerCapture(evt.pointerId);
        }}
        onPointerUp={(evt) => {
          evt.target?.releasePointerCapture(evt.pointerId);
        }}
        onWheel={handleWheel}
      >
        <Picker selectedValue={selectedItem} onValueChange={handleChange}>
          {items.map((item, index) => (
            <Picker.Item value={item} key={index}>
              {item.name}
            </Picker.Item>
          ))}
        </Picker>
      </Modal>
    </div>
  );
};

export default FormPicker;
