import { useContext } from 'react';

import { Column, IconButton, Ribbon, Badge } from 'common';
import { ProviderContext } from 'contexts/provider';
import NavData from './NavData';

const Invitations = ({ selected, handleClick }) => {
  const nav = NavData.invitations;
  const blueTextColor = '#039BE5';
  const { inviteCount } = useContext(ProviderContext);
  return (
    <Column>
      {inviteCount > 0 && (
        <Ribbon top="5px" right="5px">
          <Badge modifiers={['small']}>{inviteCount}</Badge>
        </Ribbon>
      )}
      <IconButton
        title={nav.title}
        textColor={selected === nav.title && blueTextColor}
        icon={selected ? nav.selectedIcon : nav.icon}
        badgeCount={inviteCount}
        size={40}
        onClick={handleClick}
      />
    </Column>
  );
};

export default Invitations;
