import styled from 'styled-components';

import { Container, Row, Column, Checkbox, Text } from 'common';
import { buildStyledComponent, px2rem } from 'style';

const DiagnosisItem = ({
  value,
  selectable,
  selected,
  handleChange,
  date,
  withMargin,
  ...props
}) => {
  return (
    <Container
      onClick={() => {
        selectable && handleChange();
      }}
      modifiers={['fluid', 'withGutters']}
      {...props}
    >
      {date && (
        <Row>
          <Text modifiers={['muted', 'block']}>{date}</Text>
        </Row>
      )}
      <Row modifiers={[withMargin && 'withGutters']}>
        <Text modifiers={[selected && 'bold']}>{value.code}</Text>
        <Column modifiers={['col', 'noPadding']}>
          <Text modifiers={['block', 'noMargin', selected && 'bold']}>
            {value.description}
          </Text>
        </Column>
        {selectable && (
          <Checkbox
            dark={true}
            modifiers={['small']}
            checked={!!selected}
            onChange={() => {}}
          />
        )}
      </Row>
    </Container>
  );
};

const styles = () => `
  > :last-child {
    > :first-child {
      width: ${px2rem(70)};
      margin-right: ${px2rem(5)};
    }
    > :nth-child(2) {
      margin-right: ${px2rem(5)};
    }
  }
`;

export default buildStyledComponent(
  'DiagnosisItem',
  styled(DiagnosisItem),
  styles
);
