import React from 'react';
import assets from 'assets';
import moment from 'moment';
import { noop } from 'lodash';
import {
  Text,
  Row,
  Column as Col,
  Button,
  Ribbon,
} from '@gaz/gaz-components.public';
import { getVitalTypeAttr } from 'utils/helper';
import { NoteItemWrapper } from '../NoteItem';
import VitalHightlight from './VitalHighlight';

export default function VitalItem({
  measurement = {},
  vitalType,
  hasMeasurement = true,
  handleSelectVital = noop,
  hideGoToDetails,
  handleRemove = noop,
  isAdd,
}) {
  const vitalTypeAttrs = getVitalTypeAttr(vitalType);

  return (
    <NoteItemWrapper
      modifiers={['fluid', 'round', hasMeasurement && isAdd && 'newVital']}
      style={{ background: 'white' }}
    >
      <Row>
        <Col
          onClick={handleSelectVital(vitalType)}
          modifiers={['col_9', 'fluid', 'noVerticalPadding']}
        >
          <Row>
            <Col
              modifiers={[
                'col_2',
                'flexBox',
                'center',
                'verticalContentCenter',
                'noVerticalPadding',
              ]}
            >
              <img src={vitalTypeAttrs.icon} alt="" width="25" height="25" />
            </Col>
            <Col
              modifiers={[
                'col_8',
                hasMeasurement && !isAdd && 'noVerticalPadding',
                'fluid',
              ]}
            >
              <Text
                modifiers={[
                  'small',
                  hasMeasurement && !isAdd && 'xSmall',
                  'block',
                  'secondaryColor',
                  hasMeasurement && isAdd && 'primary',
                ]}
              >
                {vitalTypeAttrs.title}
              </Text>
              <Text modifiers={['xSmall', 'block', 'muted']}>
                {hasMeasurement &&
                  !isAdd &&
                  moment(measurement.date).format('MM/DD/YYYY • hh:mm a')}
              </Text>
            </Col>
            {!hideGoToDetails && (
              <Col modifiers={['col_2', 'fluid', 'noVerticalPadding']}>
                <Button
                  modifiers={['icon', 'roundCorner', 'transparent', 'small']}
                  image={assets.icons.chevronR}
                />
              </Col>
            )}
          </Row>
        </Col>
        <VitalHightlight
          measurementValue={measurement.value || 0}
          modifiers={[
            measurement.danger && 'danger',
            measurement.success && 'success',
            hasMeasurement && isAdd && 'newVital',
          ]}
        />
      </Row>
      {hasMeasurement && isAdd && (
        <Ribbon top="-15px" right="-15px">
          <Button
            onClick={handleRemove(vitalType)}
            image={assets.icons.close}
            modifiers={['icon', 'noPadding', 'transparent']}
          />
        </Ribbon>
      )}
    </NoteItemWrapper>
  );
}
