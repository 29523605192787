import GothamBookBoldTtf from './Gotham-Book-Bold.ttf';
import GothamBookBoldWoff from './Gotham-Book-Bold.woff';
import GothamBookBoldWoff2 from './Gotham-Book-Bold.woff2';
import GothamBookEot from './Gotham-Book.eot?#iefix';
import GothamBookSvg from './Gotham-Book.svg?#Gotham-Book';
import GothamBookTtf from './Gotham-Book.ttf';
import GothamBookWoff from './Gotham-Book.woff';
import GothamBookWoff2 from './Gotham-Book.woff2';
import InterBold from './Inter-Bold.ttf';
import Inter from './Inter.ttf';

const fonts = {
  GothamBookBoldTtf,
  GothamBookBoldWoff,
  GothamBookBoldWoff2,
  GothamBookEot,
  GothamBookSvg,
  GothamBookTtf,
  GothamBookWoff,
  GothamBookWoff2,
  InterBold,
  Inter,
};

export default fonts;
