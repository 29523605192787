import { FormIconInput } from 'common';
import React from 'react';

import assets from 'assets';
import FormInput from './FormInput';

const normalizeInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 3) return currentValue;
  if (cvLength < 5)
    return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
  return `${currentValue.slice(0, 2)}/${currentValue.slice(
    2,
    4
  )}/${currentValue.slice(4, 8)}`;
};

export const FormDateInput = (props) => {
  const {
    field: { name, onChange },
    variant = 'plain' || 'contained',
    selectedDropdownIcon,
  } = props;

  const handleChange = (e) => {
    onChange(name)(normalizeInput(e.target.value));
  };

  const renderInput = () => {
    switch (variant) {
      case 'contained':
        return (
          <FormIconInput
            iconWidth={20}
            iconHeight={20}
            onChange={handleChange}
            icon={assets.icons.icDayCalendar}
            {...props}
          />
        );
      case 'plain':
      default:
        return (
          <FormInput
            {...props}
            onChange={handleChange}
            selectedDropdownIcon={selectedDropdownIcon}
          />
        );
    }
  };

  return renderInput();
};

export default FormDateInput;
