import React from 'react';
import { useHistory } from 'react-router';

import { Column, Avatar, HR } from '@gaz/gaz-components.public';
import assets from 'assets';
import { formatTitleAndName } from 'utils/string';

import Wrapper from './Wrapper';

export default function Contact({ contact, isLast }) {
  const history = useHistory();
  const isPerson = contact.__typename === 'User';
  const isPracticeGroup = contact.__typename === 'Practice';

  const handleClick = () => {
    let route;
    if (contact.role === 'provider') {
      route = `/contacts/${contact._id}`;
    } else if (contact.role === 'patient') {
      route = `/patients/${contact._id}`;
    } else {
      const type = isPracticeGroup ? 'practice' : 'group';
      route = `/groups/${contact._id}?type=${type}`;
    }
    history.push(route);
  };

  return (
    <Wrapper
      modifiers={[isPracticeGroup && 'practice', 'middle']}
      onClick={handleClick}
    >
      <Column>
        <Avatar
          width={50}
          height={50}
          modifiers={['round']}
          image={!isPerson && (contact.image?.url || assets.images.emptyAvatar)}
          user={isPerson && contact}
        />
      </Column>
      <Column modifiers="col">
        {isPerson ? formatTitleAndName(contact) : contact.name}
      </Column>
      {!isLast && (
        <Column modifiers={['col_12']}>
          <HR modifiers={['colored']} />
        </Column>
      )}
    </Wrapper>
  );
}
