import React from 'react';

import assets from 'assets';

import Wrapper from './Wrapper';
import Icon from './Icon';

const LoaderIcon = () => <Icon src={assets.icons.loader} alt="Loading..." />;

export const Loader = () => {
  return (
    <Wrapper>
      <LoaderIcon />
    </Wrapper>
  );
};

Loader.Icon = LoaderIcon;

export default Loader;
