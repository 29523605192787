import styled from 'styled-components';

import { buildStyledComponent } from 'style';
import assets from 'assets';

const modifierConfig = {
  drop_down_icon_1: ({ theme }) => `
    display: inline-block;
    align-items: center;
    position: relative;

    &::after {
      content: url(${assets.icons.arrowDropDown});
      top: 15%;
      right: 15%;
      position: absolute;

      @media only screen and (min-width: 769px) {
        right: 22%;
      }
    }
  `,
  drop_down_icon_2: ({ theme }) => `
    display: inline-block;
    align-items: center;
    position: relative;

    &::after {
      content: url(${assets.icons.arrowDropDown});
      top: 15%;
      right: 10%;
      position: absolute;

      @media only screen and (min-width: 769px) {
        right: 20%;
      }
    }
  `,
  flex: ({ theme }) => `
    display: flex;
  `,
};

const styles = ({ theme }) => ``;

const InputContainer = buildStyledComponent(
  'FormInput_Container',
  styled.div,
  styles,
  {
    modifierConfig,
  }
);

export default InputContainer;
