import { useContext, useState, useMemo } from 'react';

import {
  Row,
  Column,
  ScrollView,
  Text,
  Button,
  BackButton,
} from '@gaz/gaz-components.public';
import { Container, Modal, PeriodPicker } from 'common';
import { CreateCareplanContext } from '../Context';
import Item from './Item';
import { endDateOfCareplan } from 'utils/careplan';
import { toLocalTime, getStartDate } from 'utils/time';
import assets from 'assets';

export default ({ handleAdd, handleClose }) => {
  const { careplanHistorySigned: cpHistory } = useContext(
    CreateCareplanContext
  );
  const [selectedItems, updateSelectedItems] = useState({});

  const [selectedPeriod, updateSelectedPeriod] = useState('3m');

  const pastItems = useMemo(() => {
    if (!cpHistory) {
      return {};
    }
    const items = {};
    const filterStartDateTimestamp = getStartDate(selectedPeriod).getTime();
    for (let cp of cpHistory) {
      const endTimestamp = toLocalTime(endDateOfCareplan(cp))
        .toDate()
        .getTime();
      if (filterStartDateTimestamp > endTimestamp) {
        break;
      }
      if (!cp.content?.careTeam) {
        continue;
      }
      cp.content.careTeam.forEach((item) => {
        if (items[item.user._id]) {
          return;
        }
        items[item.user._id] = item;
      });
    }
    return items;
  }, [cpHistory, selectedPeriod]);

  const toggleSelect = (user) => {
    if (selectedItems[user._id]) {
      delete selectedItems[user._id];
    } else {
      selectedItems[user._id] = user;
    }
    updateSelectedItems({ ...selectedItems });
  };

  const handleDone = () => {
    handleAdd(selectedItems);
    handleClose();
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Past Care Team Members</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
          <Container modifiers={['flexFixed', 'backgroundGray', 'padding_2']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Column>
                <Text modifiers={['primary']}>Select from below list</Text>
              </Column>
              <Column>
                <PeriodPicker
                  value={selectedPeriod}
                  handleSelect={updateSelectedPeriod}
                  icon={assets.icons.iconFilter}
                  actionTitle="Filter by time period"
                />
              </Column>
            </Row>
          </Container>
          <ScrollView>
            <Container>
              {Object.keys(pastItems).length === 0 ? (
                <Text>No past care team for selected period found.</Text>
              ) : (
                Object.values(pastItems).map((item) => (
                  <Item
                    item={item}
                    key={item.user._id}
                    selected={selectedItems[item.user._id]}
                    onSelect={() => toggleSelect(item.user)}
                  />
                ))
              )}
            </Container>
          </ScrollView>
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleClose}
              />
              <Button
                modifiers={[
                  'widthMedium',
                  'roundCorner',
                  Object.keys(selectedItems).length === 0 && 'disabled',
                ]}
                onClick={handleDone}
              >
                Add Selected
              </Button>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
