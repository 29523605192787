import { useContext, useState, useEffect } from 'react';

import { Container } from '@gaz/gaz-components.public';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import Main from '../Careplans/CreateCareplan/Medications/Main';
import PreviousMeds from '../Careplans/CreateCareplan/Medications/PreviousMeds';
import AddMeds from '../Careplans/CreateCareplan/Medications/AddMeds';
import Cart from './Cart';

const OrderMedications = ({}) => {
  const { goBack, setBackHandler } = useContext(PatientHomeContext);
  const [medications, updateMedications] = useState({});
  const [activeAction, updateActiveAction] = useState();

  const handleAddMedications = (newMeds) => {
    updateMedications({ ...medications, ...newMeds });
  };

  const handleAdd = () => {
    updateActiveAction('add');
  };
  const handleShowPrevious = () => {
    updateActiveAction('previous');
  };

  useEffect(() => {
    setBackHandler('');
  }, []);

  return (
    <Container modifiers={['fluid', 'fullHeight']}>
      <Main
        medications={medications}
        updateMedications={updateMedications}
        handleAdd={handleAdd}
        handleShowPrevious={handleShowPrevious}
        handleSave={() => {
          updateActiveAction('cart');
        }}
        handleClose={() => goBack()}
        forOrder
      />
      {activeAction === 'add' && (
        <AddMeds
          handleAdd={handleAddMedications}
          noAlerts
          handleClose={() => {
            updateActiveAction(null);
          }}
          forOrder
        />
      )}
      {activeAction === 'previous' && (
        <PreviousMeds
          handleAdd={handleAddMedications}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
      {activeAction === 'cart' && (
        <Cart
          medications={medications}
          updateMedications={updateMedications}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
    </Container>
  );
};

export default OrderMedications;
