import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = () => `
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
`;

const Video = buildStyledComponent('MediaViewer_Video', styled.video, styles);

export default Video;
