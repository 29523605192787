import { useContext, useState, useMemo } from 'react';
import { useHistory } from 'react-router';

import {
  Container,
  Row,
  Column,
  Text,
  ScrollView,
  SearchBar,
} from '@gaz/gaz-components.public';

import { PeriodPicker } from 'common';
import NoteItem from './Item';
import { CoordinateContext } from '../Context';
import { formatTitleAndName } from 'utils/string';

export default () => {
  const {
    notePeriod: period,
    updateNotePeriod: setPeriod,
    notes,
  } = useContext(CoordinateContext);
  const { push, location } = useHistory();
  const [query, updateQuery] = useState();

  const handleSelectPeriod = (value) => {
    setPeriod(value);
  };

  const filteredNotes = useMemo(() => {
    if (!notes) {
      return [];
    }
    if (!query) {
      return notes;
    }
    const trimmedQuery = query.trim().toLowerCase();
    if (!trimmedQuery || trimmedQuery.length === 0) {
      return notes || [];
    }
    return notes.filter(
      (note) =>
        formatTitleAndName(note.user).toLowerCase().includes(trimmedQuery) ||
        formatTitleAndName(note.creator?.user)
          .toLowerCase()
          .includes(trimmedQuery)
    );
  }, [notes, query]);

  const handleSelectNote = (note) => push(`${location.pathname}/${note._id}`);

  return (
    <Container modifiers={['fullHeight', 'flexBox']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <SearchBar
            value={query}
            onChange={updateQuery}
            placeholder="Search"
          />
          <Column>
            <PeriodPicker value={period} handleSelect={handleSelectPeriod} />
          </Column>
        </Row>
      </Container>
      <ScrollView>
        <Container>
          {filteredNotes?.length > 0 ? (
            filteredNotes?.map((note, i) => (
              <NoteItem
                handleClick={() => handleSelectNote(note)}
                note={note}
                key={i}
              />
            ))
          ) : (
            <Text modifiers={['muted']}>You don't have any shared notes</Text>
          )}
        </Container>
      </ScrollView>
    </Container>
  );
};
