import assets from 'assets';
import EntryItem from '../Entry';

export default ({ activity, ...props }) => {
  if (!activity) {
    return null;
  }
  const postInfos = [
    {
      title: 'Duration',
      value: activity.frequency ? `${activity.frequency.duration} mins` : null,
    },
  ];
  return (
    <EntryItem
      icon={assets.icons.icActivity}
      text={activity.activity}
      frequency={activity.frequency}
      alerts={activity.alerts}
      postInfos={postInfos}
      {...props}
    />
  );
};
