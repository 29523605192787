import assets from 'assets';

export const STATES_LIST_BY_CODE = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const STATES = Object.keys(STATES_LIST_BY_CODE).map((stateCode) => ({
  abbreviation: stateCode,
  name: STATES_LIST_BY_CODE[stateCode],
}));

export const STATES_LIST = STATES.map((item) => ({
  label: item.name,
  value: item.abbreviation,
}));

export const GENDERS = ['Male', 'Female', 'Unknown'];

export const STATUSES = ['Single', 'Married'];

export const SEARCH_DEBOUNCE_TIME = 500; // ms

export const VITALS = {
  heartRate: 'Heart Rate',
  bloodPressure: 'Blood Pressure',
  weight: 'Weight',
  glucose: 'Glucose',
  respiratory: 'Respiratory',
  bloodOxygen: 'Blood Oxygen',
  temperature: 'Temperature',
};

export const WELLNESS_ITEMS = {
  sleep: {
    title: 'Sleep',
    type: 'badGood',
    icon: assets.icons.icWellnessSleep,
  },
  breathing: {
    title: 'Breathing',
    type: 'badGood',
    icon: assets.icons.icWellnessBreathing,
  },
  energy: {
    title: 'Energy',
    type: 'badGood',
    icon: assets.icons.icWellnessEnergy,
  },
  memory: {
    title: 'Memory',
    type: 'badGood',
    icon: assets.icons.icWellnessMemory,
  },
  dizziness: {
    title: 'Dizziness',
    type: 'badGood',
    icon: assets.icons.icWellnessDizziness,
  },
  chestPain: {
    title: 'Chest Pain',
    type: 'badGood',
    icon: assets.icons.icWellnessChestPain,
  },
  woundHealing: {
    title: 'Wound Healing',
    type: 'badGood',
    icon: assets.icons.icWellnessWoundHealing,
  },
  swelling: {
    title: 'Swelling',
    type: 'badGood',
    icon: assets.icons.icWellnessSwelling,
  },
};

export const DIET_ITEMS = {
  salt: {
    title: 'Salt in Diet',
    type: 'highLow',
    icon: assets.icons.icDietSalt,
  },
  grains: {
    title: 'Grains in Diet',
    type: 'lowHigh',
    icon: assets.icons.icDietGrains,
  },
  grocery: {
    title: 'Grocery Shopping',
    type: 'lowHigh',
    icon: assets.icons.icDietGrocery,
  },
  cooking: {
    title: 'Home Cooking',
    type: 'lowHigh',
    icon: assets.icons.icDietCooking,
  },
  fruitsVeg: {
    title: 'Fruits and Vegetables',
    type: 'lowHigh',
    icon: assets.icons.icDietVegs,
  },
  fats: {
    title: 'Fats in meals',
    type: 'highLow',
    icon: assets.icons.icDietFat,
  },
  mealPortions: {
    title: 'Meal Portions',
    type: 'highLow',
    icon: assets.icons.icDietMeal,
  },
};

export const SCALE_TYPES = {
  badGood: 'Very bad to Very good',
  lowHigh: 'Very low to Very high',
  highLow: 'Very high to Very low',
};

export const BAD_GOOD_SCALES = [
  'very bad',
  'bad',
  'medium',
  'good',
  'very good',
];
export const LOW_HIGH_SCALES = [
  'very low',
  'low',
  'medium',
  'high',
  'very high',
];
export const HIGH_LOW_SCALES = [
  'very high',
  'high',
  'medium',
  'low',
  'very low',
];

export const INVITE_ROLE_TYPES = {
  PROVIDER: 'providers',
  GROUPS: 'groups',
  PATIENT: 'patients',
  PRACTICE: 'practices',
};

export const PROGRESS_ITEMS = {
  medication: {
    icon: assets.icons.icMedication,
    url: 'medication',
    title: 'Medication',
  },
  vital: { icon: assets.icons.icVital, url: 'vital', title: 'Vitals' },
  activity: {
    icon: assets.icons.icActivity,
    url: 'activity',
    title: 'Activity',
  },
  wellness: {
    icon: assets.icons.icWellness,
    url: 'wellness',
    title: 'Wellness',
  },
  diet: { icon: assets.icons.icDiet, url: 'diet', title: 'Diet' },
  appointment: {
    icon: assets.icons.icCareTeam,
    url: 'appointment',
    title: 'Appointments',
  },
};

export const GROUP_TYPES = {
  CARE_TEAM: 'care_team',
  CONTACTS: 'contacts',
};

export const MODAL_PRIORITIES = {
  NORMAL: 'normal',
  HIGH: 'high',
};

export const MEMBER_DESIGNATIONS = {
  doctor: 'MD',
  do: 'DO',
  nursePractioner: 'NP/CNM/DNP',
  pa: 'PA',
  crna: 'CRNA',
  rn: 'RN',
  other: 'Other Healthcare Professional',
};

export const MEMBER_DESIGNATIONS_LIST = Object.entries(MEMBER_DESIGNATIONS).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
);

export const DESKTOP_VIEWPORT_RATIO = 375 / 667;
export const DESKTOP_VIEWPORT_WIDTH = 100 * DESKTOP_VIEWPORT_RATIO + 'vh';
