import { useContext, useMemo } from 'react';

import { ScrollView } from 'common';
import { TIMER_TYPE } from 'contexts/timer';
import { formatTitleAndName, getFullName } from 'utils/string';
import { displayTime, secondsToDuration } from 'utils/time';
import { AuthContext } from 'contexts/auth';
import Table from './Table';

const extractData = (provider, record, item) => {
  const renderType = () => {
    switch (item.type) {
      case TIMER_TYPE.CHAT:
        return 'Message';
      case TIMER_TYPE.CALL:
        const prefix = item.isPSTN ? 'Audio ' : item.scheduled ? 'Appt ' : '';
        const type = item.isPSTN ? 'PSTN' : 'Video';
        return `${prefix}${type}`;
      case TIMER_TYPE.MANUAL_PSTN:
        return 'Audio PSTN';
      case TIMER_TYPE.MANUAL_VIDEO:
        return 'Video Call';
      case TIMER_TYPE.REVIEW:
        return 'Chart Review';
      case TIMER_TYPE.NOTE:
        return 'Create Note';
      case TIMER_TYPE.CAREPLAN:
        return 'Create Careplan';
      case TIMER_TYPE.ORDER_MEDS:
        return 'Order Meds';
      default:
        return '';
    }
  };
  const start = new Date(item.startedAt).getTime();
  const end = new Date(item.endedAt).getTime();
  const duration = parseInt((end - start) / 1000);

  let refString;
  let byString;

  if (['chat', 'call', TIMER_TYPE.MANUAL_VIDEO].includes(item.type)) {
    const participantIds = item.participants?.map((p) => p._id);
    byString = item.participants?.map((p) => formatTitleAndName(p)).join(' / ');

    if (
      item.referredPatient &&
      !participantIds.includes(item.referredPatient._id)
    ) {
      refString = getFullName(item.referredPatient);
    }
  } else {
    byString = formatTitleAndName(record.provider || provider);
  }

  return [
    displayTime(record.startedAt, 'MM/DD/YY'),
    displayTime(record.startedAt, 'hh:mm A'),
    renderType(),
    refString ? [byString, refString] : byString,
    secondsToDuration(duration),
  ];
};

const TimeTable = ({ reports }) => {
  const { me: provider } = useContext(AuthContext);
  const columns = ['Date', 'Time', 'Task', 'By', 'Dur.'];
  const datas = useMemo(() => {
    const datas = [];
    reports?.records?.forEach((record) => {
      record.items.forEach((item) => {
        datas.push(extractData(provider, record, item));
      });
    });
    return datas;
  }, [reports]);

  return (
    <ScrollView>
      <Table columns={columns} datas={datas} />
    </ScrollView>
  );
};

export default TimeTable;
