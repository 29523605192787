import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { MainLayout } from 'layouts';
import { BackButton, Text, Row, Column } from 'common';
import { Container, Avatar, Tabs } from 'common';

import CoordinateProvider from './Context';
import Notes from './Notes';
import NoteDetails from './Notes/NoteDetails';
import CarePlans from './CarePlans';
import CarePlan from './CarePlans/CareplanDetail';
import DirectMessages from './DirectMessages';
import DirectMessage from './DirectMessages/DirectMessageDetail';
import EFaxMessages from './EFax';
import { AuthContext } from 'contexts/auth';
import { getFullName } from 'utils/string';

const { Header, Content } = MainLayout;

export default () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { me } = useContext(AuthContext);

  const PATHS = useMemo(() => {
    if (!me || !me.activeProviderPractice.practice.isGazuntitePractice) {
      return ['/note', '/care-plans'];
    }
    return ['/note', '/care-plans', '/direct-messages', '/efax'];
  }, [me]);

  const [tab, setTab] = useState(() => {
    for (let i = 0; i < PATHS.length; i++) {
      if (history.location.pathname.startsWith(`/coordinate${PATHS[i]}`)) {
        return i;
      }
    }
    return null;
  });

  const updateTab = (tab) => {
    setTab(tab);
    const route = getPathByTab(tab);
    history.push(route);
  };

  useEffect(() => {
    if (tab === null) {
      updateTab(0);
    }
  }, []);

  const getPathByTab = useCallback(
    (tab) => {
      return `${path}${PATHS[tab]}`;
    },
    [tab, path]
  );

  const goBack = () => {
    const pathName = history.location.pathname;
    const urlParts = pathName.split('/');
    urlParts.pop();
    const newUrl = urlParts.join('/');
    if (newUrl === '/coordinate' || newUrl === '/coordinate/') {
      history.replace('/');
    } else {
      history.replace(urlParts.join('/'));
    }
  };

  const isShowingItem = () => {
    const pathName = history.location.pathname;
    const urlParts = pathName.split('/');
    urlParts.pop();
    const newUrl = urlParts.join('/');
    return !(newUrl === '/coordinate' || newUrl === '/coordinate/');
  };

  if (!me) {
    return null;
  }

  return (
    <CoordinateProvider>
      <MainLayout>
        <Header>
          <Header.Left>
            <BackButton onClick={goBack} />
          </Header.Left>
          <Header.Center>
            <Row modifiers={['middle']}>
              <Avatar
                user={me}
                width={40}
                height={40}
                modifiers={['squared']}
              />
              <Column modifiers={['col_8', 'flexBox']}>
                <Text modifiers={['semiBold', 'start']}>{getFullName(me)}</Text>
              </Column>
            </Row>
          </Header.Center>
        </Header>
        <Content>
          <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
            <Container
              modifiers={[
                isShowingItem() && 'noDisplay',
                'topGutters_1',
                'fluid',
              ]}
            >
              {me?.activeProviderPractice.practice.isGazuntitePractice ? (
                <Tabs current={tab} onChange={updateTab} flatTabs>
                  <Tabs.Tab title="Notes" />
                  <Tabs.Tab title="Care Plans" />

                  <Tabs.Tab title="Direct Messages" />

                  <Tabs.Tab title="EFax" />
                </Tabs>
              ) : (
                <Tabs current={tab} onChange={updateTab} flatTabs>
                  <Tabs.Tab title="Notes" />
                  <Tabs.Tab title="Care Plans" />
                </Tabs>
              )}
            </Container>
            <Container
              modifiers={['backgroundWhite', 'fullHeight', 'fluid', 'flexBox']}
            >
              <Switch>
                <Route exact path={`${path}/note`} component={Notes} />
                <Route
                  exact
                  path={`${path}/note/:id`}
                  component={NoteDetails}
                />
                <Route
                  exact
                  path={`${path}/care-plans`}
                  component={CarePlans}
                />
                <Route
                  exact
                  path={`${path}/care-plans/:id`}
                  component={CarePlan}
                />
                {me?.activeProviderPractice.practice.isGazuntitePractice && (
                  <Route
                    exact
                    path={`${path}/direct-messages`}
                    component={DirectMessages}
                  />
                )}
                {me?.activeProviderPractice.practice.isGazuntitePractice && (
                  <Route
                    exact
                    path={`${path}/direct-messages/:id`}
                    component={DirectMessage}
                  />
                )}
                {me?.activeProviderPractice.practice.isGazuntitePractice && (
                  <Route exact path={`${path}/efax`} component={EFaxMessages} />
                )}
              </Switch>
            </Container>
          </Container>
        </Content>
      </MainLayout>
    </CoordinateProvider>
  );
};
