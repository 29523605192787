import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  active: ({ theme }) => `
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    font-weight: 600;
  `,
};

const styles = ({ theme }) => `
  background: ${theme.colors.white};
  box-shadow: 0px 1px 2px rgba(46, 41, 78, 0.02), 0px 4px 8px rgba(46, 41, 78, 0.08);
  border-radius: 0px 0px 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${px2rem(8)};
  flex: 0 0 auto;
  flex-basis: 25%;
  color: ${theme.colors.darkGrey};
  font-weight: 500;
  font-size: ${px2rem(12)};
  width: fit-content;
`;

export default buildStyledComponent('Tabs_Tab', styled.div, styles, {
  modifierConfig,
});
