import { useHistory } from 'react-router';
import { Row, Button } from '@gaz/gaz-components.public';

import { Container } from 'common';
import assets from 'assets';
import urls from 'routes/urls';

export default ({ handleBook }) => {
  const history = useHistory();

  return (
    <Container modifiers={['footer']}>
      <Row modifiers={['spaceBetween']}>
        <Button
          modifiers={['icon', 'transparent']}
          image={assets.icons.icCloseGray}
          onClick={() => history.push(urls.DASHBOARD)}
        />
        <Button modifiers={['roundCorner']} onClick={handleBook}>
          Add New
        </Button>
      </Row>
    </Container>
  );
};
