import React, { useContext, useEffect } from 'react';

import { AuthContext } from 'contexts/auth';
import { Button, Column, Text, BackButton } from '@gaz/gaz-components.public';
import { client } from 'graphql/client';
import { Row } from 'common';
import urls from 'routes/urls';
import { MainLayout } from 'layouts';
import AddContact from 'compositions/AddContact';
import { INVITE_ROLE_TYPES } from 'utils/constants';

const MyPractice = ({ history }) => {
  const { me, fetchMe, updateAuth } = useContext(AuthContext);

  useEffect(() => {
    fetchMe();
  }, [fetchMe]);

  useEffect(() => {
    const practice = me.activeProviderPractice?.practice;
    if (practice) {
      history.replace(`/groups/${practice._id}?type=practice`);
    }
  }, [history, me.activeProviderPractice]);

  const logOut = async () => {
    await updateAuth();
    history.replace(urls.AUTH);
    client.resetStore();
  };

  return (
    <MainLayout hasFooter={false}>
      <MainLayout.Header>
        <MainLayout.Header.Left>
          <BackButton onClick={!me.activeProviderPractice && logOut} />
        </MainLayout.Header.Left>
        <MainLayout.Header.Center>
          <Text modifiers="pageTitle">My Practice</Text>
        </MainLayout.Header.Center>
        <MainLayout.Header.Right />
      </MainLayout.Header>
      <MainLayout.Content>
        <Row modifiers={['flexColumn', 'fullHeight', 'mediumPadding']}>
          <Column>
            <Text modifiers={['secondaryColor', 'noPadding']}>
              You currently are not a member of a practice.
            </Text>
          </Column>

          <Column>
            <Text modifiers={['secondaryColor', 'noPadding']}>
              Please <span style={{ fontWeight: 'bold' }}>Search</span> to find
              an existing practice on the Gazuntite network or
            </Text>
          </Column>

          <Column>
            <Text modifiers={['secondaryColor', 'noPadding']}>
              press <span style={{ fontWeight: 'bold' }}>Create</span> to form a
              new practice.
            </Text>
          </Column>

          <Column modifiers="col">
            <AddContact
              showManualAdd={false}
              contactType={INVITE_ROLE_TYPES.PRACTICE}
              isModalView={false}
              placeholder="Search by Practice Name"
              isFullWidth={true}
            />
          </Column>

          <Column>
            <Row modifiers={['spaceBetween']}>
              <Column>
                <Button
                  modifiers="outlineDanger"
                  onClick={
                    me.activeProviderPractice ? () => history.goBack() : logOut
                  }
                >
                  Cancel
                </Button>
              </Column>
              <Column>
                <Button
                  modifiers="success"
                  onClick={() => history.push(urls.REGISTER_PRACTICE)}
                >
                  Create
                </Button>
              </Column>
            </Row>
          </Column>
        </Row>
      </MainLayout.Content>
    </MainLayout>
  );
};

export default MyPractice;
