import React, { useContext } from 'react';

import assets from 'assets';
import { Button, Ribbon, Badge } from 'common';
import { ProviderContext } from 'contexts/provider';

export const MenuButton = ({ openSideMenu }) => {
  const { callMasking, schedulerSet } = useContext(ProviderContext);
  const showRibbon = !(callMasking && schedulerSet);
  return (
    <Button
      onClick={openSideMenu}
      modifiers={['icon', 'transparent']}
      image={assets.icons.icHamburger}
      imageWidth={16}
      imageHeight={16}
    >
      {showRibbon && (
        <Ribbon top="5px" right="3px">
          <Badge modifiers={['tiny']} />
        </Ribbon>
      )}
    </Button>
  );
};

export default MenuButton;
