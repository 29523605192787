import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = () => `
  padding: ${px2rem(10)};
  width: 100%;
`;

export default buildStyledComponent('Modal_Footer', styled.div, styles);
