import { useContext } from 'react';

import { Container } from 'common';
import { PatientHomeContext } from '../Context';
import List from 'pages/CallHistory/List';

const History = ({}) => {
  const { patient } = useContext(PatientHomeContext);

  return (
    <Container modifiers={['fullHeight', 'fluid']}>
      <List user={patient} />
    </Container>
  );
};

export default History;
