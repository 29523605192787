import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  dark: ({ theme }) => ({
    styles: `
      color: ${theme.colors.white_1};
    `,
  }),
  info: ({ theme }) => `
    color: ${theme.colors.blue};
  `,
  large: ({ theme }) => ({
    styles: `
      font-size: ${theme.dimensions.fontSizeMedium};
    `,
  }),
  small: ({ theme }) => ({
    styles: `
      font-size: ${theme.dimensions.fontSizeXSmall};
    `,
  }),
  center: () => ({
    styles: `
      width: 100%;
      text-align: center;
    `,
  }),
  labelLightWeight: ({ theme }) => `
    font-weight: ${theme.fontWeights.normal};
  `,
  inline: ({ theme }) => `
    display: inline-block;
  `,
  bottomSpacing: () => `
    margin-bottom: ${px2rem(8)};
  `,
};

const styles = ({ theme }) => `
  color: ${theme.colors.text};
  font-size: ${theme.dimensions.fontSizeSmall};
  font-weight: ${theme.fontWeights.bold};
  display: block;
  > span {
    color: ${theme.colors.danger};
    font-size: 1.3em;
  }
`;

export const Label = buildStyledComponent('Form_Label', styled.span, styles, {
  modifierConfig,
});

export default Label;
