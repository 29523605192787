const colors = {
  role: '#1565C0',
  primary: '#1565C0',
  secondary: '#062854',
  danger: '#FF5964',
  danger_2: '#EB3D2C',
  danger_3: 'rgba(235, 61, 44, 0.1)',
  info: '#25B2C2',
  success: '#00a651',
  success_2: '#35BA83',
  success_3: 'rgba(53, 186, 131, 0.1)',
  warning: '#f2c144',
  warning_2: '#F7B538',
  white: '#ffffff',
  white_1: '#F3F3F4',
  black: '#000000',
  cyan: '#00c2da',
  blue: '#039be5',
  darkBlue: '#00225e',
  grey: '#efefef',
  babu: '#00a699',
  darkGrey: '#3B4C6A',
  transparent: 'transparent',
  link: '#0054E6',
  text: '#1F1514',
  gray1: '#3B4C6A',
  gray2: '#575D67',
  gray3: '#636873',
  gray4: '#6E737E',
  gray5: '#797E89',
  gray6: '#B8C3DE',
  gray7: '#D2D2D2',
  gray8: 'rgba(210, 210, 210, 0.1)',
  purple: '#AC5DD9',
  background: '#f9f9ff',
  textSecondary: '#3B4C6A',
  textPrimary: '#1565C0',
  blueBrand: '#0A215B',
  lightBlueBrand: '#2eb1f1',
  graphGray: '#D2D2D24D',
  graphBlue: '#42A5F5',
  orange: '#F7B538',
};

export default colors;
