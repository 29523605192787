import React, { useEffect, useState, useCallback, useRef } from 'react';
import Picker from '../../Picker/Picker';
import MultiPicker from 'rmc-picker/lib/MultiPicker';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { debounce } from 'lodash';

import 'rmc-picker/assets/index.css';

import FormDateInput from '../FormDateInput';
import Modal from '../FormPicker/Modal';

const moment = extendMoment(Moment);
const locale = 'en';

export const FormDatePicker = (props) => {
  const {
    dateFormat,
    minDate,
    maxDate,
    valueAsObject,
    hideDates,
    submitOnClose,
    selectedDropdownIcon,
    subModal,
    ...inputProps
  } = props;
  const {
    field: { name },
    form: { touched, setFieldValue, submitForm },
    placeholder,
  } = inputProps;

  const [showModal, setShowModal] = useState(false);
  const defaultDate = props.field.value
    ? [
        parseInt(moment(props.field.value).format('DD')),
        moment(props.field.value).format('MMM'),
        parseInt(moment(props.field.value).format('Y')),
      ]
    : [];

  const [value, setValue] = useState(defaultDate);
  const wheelDeltaData = useRef();

  const createYears = () => {
    const range = moment.range(
      minDate ? moment(minDate) : moment().subtract(99, 'years'),
      maxDate ? moment(maxDate).add(1, 'years') : moment().add(50, 'years')
    );

    return [...range.by('year')]
      .map((momentObject) => {
        return parseInt(momentObject.format('Y'));
      })
      .reverse();
  };
  const createMonths = () => {
    let months = moment.months(moment.locale(locale));
    if (value?.length === 3) {
      if (maxDate && parseInt(value[2]) === moment(maxDate).year()) {
        months = months.slice(0, moment(maxDate).month() + 1);
      }
      if (minDate && parseInt(value[2]) === moment(minDate).year()) {
        months = months.slice(moment(minDate).month(), 12);
      }
    }

    return months.map((value) => {
      return {
        value: moment(value, 'MMM').format('MMM'),
        label: value,
      };
    });
  };
  const createDays = () => {
    let daysInMonth = moment(value[1] || 'January', 'MMM').daysInMonth();

    if (
      value[2] &&
      moment([value[2]]).isLeapYear() &&
      value[1] === moment.months(moment.locale(locale))[1]
    ) {
      daysInMonth += 1;
    }

    let daysArray = Array(daysInMonth)
      .fill()
      .map((values, index) => index + 1);

    if (value?.length === 3) {
      const monthValue = moment(value[1] || 'January', 'MMM').format('MMM');
      if (
        maxDate &&
        parseInt(value[2]) === moment(maxDate).year() &&
        monthValue === moment(maxDate).format('MMM')
      ) {
        daysArray = daysArray.slice(0, moment(maxDate).date());
      }
      if (
        minDate &&
        parseInt(value[2]) === moment(minDate).year() &&
        monthValue === moment(minDate).format('MMM')
      ) {
        daysArray = daysArray.slice(
          moment(minDate).date() - 1,
          daysInMonth + 1
        );
      }
    }

    return daysArray;
  };

  const convertDate = (date, asObject) => {
    let d = moment();

    if (date.length === 3 && date[0] && date[1] && date[2]) {
      d = moment(`${date[2]}-${date[1]}-${date[0]}`, 'Y-MMM-DD');
    }

    if (asObject) {
      return d.toDate();
    }

    return d.format(dateFormat || 'MM/DD/Y');
  };

  const handleChange = (date) => {
    if (showModal) {
      setValue(date);
    }
  };

  const handleToggle = () => {
    if (showModal) {
      setValue(defaultDate);
    }
    setShowModal(!showModal);
  };

  const handleDone = () => {
    if (showModal) {
      setFieldValue(name, convertDate(value, valueAsObject));
      if (submitOnClose) {
        submitForm();
      }
    }
    setShowModal(false);
  };

  const clearWheelDelta = useCallback(
    debounce(() => {
      if (wheelDeltaData.current) {
        wheelDeltaData.current.delta = 0;
      }
    }, 300),
    []
  );

  const handleWheel = useCallback(
    (event) => {
      const delta = -event.nativeEvent?.wheelDeltaY || 0;
      if (delta === 0) {
        return;
      }

      const picker = event.target.parentElement;
      const multiPicker = picker.parentElement;
      let section;
      let values;
      if (hideDates) {
        if (picker === multiPicker.children[0]) {
          section = 1;
          values = createMonths();
        } else if (picker === multiPicker.children[1]) {
          section = 2;
          values = createYears();
        } else {
          return;
        }
      } else {
        if (picker === multiPicker.children[0]) {
          section = 0;
          values = createDays();
        } else if (picker === multiPicker.children[1]) {
          section = 1;
          values = createMonths();
        } else if (picker === multiPicker.children[2]) {
          section = 2;
          values = createYears();
        } else {
          return;
        }
      }

      if (
        !wheelDeltaData.current ||
        wheelDeltaData.current.section !== section
      ) {
        wheelDeltaData.current = { delta: 0, section };
      }
      wheelDeltaData.current.delta = wheelDeltaData.current.delta + delta;
      let step = 0;
      if (wheelDeltaData.current.delta >= 120) {
        step = wheelDeltaData.current.delta / 120;
        wheelDeltaData.current.delta = 0;
      } else if (wheelDeltaData.current.delta <= -120) {
        step = -(-wheelDeltaData.current.delta / 120);
        wheelDeltaData.current.delta = 0;
      }

      const wheelStep = Math.max(-3, Math.min(3, Math.round(step)));
      const index =
        section === 1
          ? values.findIndex((a) => a.value === value[section])
          : values.indexOf(value[section]);
      const newIndex = Math.min(
        values.length - 1,
        Math.max(0, wheelStep + index)
      );
      if (hideDates) {
        value[0] = 1;
      }
      if (index !== newIndex) {
        value[section] =
          section === 1 ? values[newIndex].value : values[newIndex];
        setValue([...value]);
      }
      clearWheelDelta();
    },
    [value]
  );

  useEffect(() => {
    if (!touched[name] && value.length === 0 && showModal) {
      handleChange([1, 'January', createYears()[0]]);
    }
  }, [touched, name, showModal, value, handleChange]);

  return (
    <div>
      <FormDateInput
        {...inputProps}
        field={{
          ...inputProps.field,
          value: convertDate(defaultDate),
        }}
        onClick={(event) => {
          setShowModal(true);
        }}
        selectedDropdownIcon={selectedDropdownIcon}
        readOnly
        htmlFor="input-id"
      />

      <Modal
        subModal={subModal}
        title={placeholder}
        show={showModal ? 'yes' : undefined}
        onToggle={handleToggle}
        handleDone={handleDone}
        onPointerDown={(evt) => {
          evt.target?.setPointerCapture(evt.pointerId);
        }}
        onPointerUp={(evt) => {
          evt.target?.releasePointerCapture(evt.pointerId);
        }}
        onWheel={handleWheel}
      >
        <MultiPicker selectedValue={value} onValueChange={handleChange}>
          {hideDates ? (
            <></>
          ) : (
            <Picker>
              {createDays().map((value) => {
                return (
                  <Picker.Item value={value} key={value}>
                    {value}
                  </Picker.Item>
                );
              })}
            </Picker>
          )}
          <Picker>
            {createMonths().map((month) => {
              return (
                <Picker.Item value={month.value} key={month.value}>
                  {month.label}
                </Picker.Item>
              );
            })}
          </Picker>
          <Picker>
            {createYears().map((value) => {
              return (
                <Picker.Item value={value} key={value}>
                  {value}
                </Picker.Item>
              );
            })}
          </Picker>
        </MultiPicker>
      </Modal>
    </div>
  );
};

export default FormDatePicker;
