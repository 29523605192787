import styled from 'styled-components';
import { Column as Col } from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';

const styles = () => `
  border: 1px solid #F3F3F4;
  border-radius: 6px 0px 0px 6px;
`;

const Item = buildStyledComponent('Vitals_VitalsItem', styled(Col), styles);

const valueStyles = ({ theme }) => `
  background: #F3F3F4;
  border-radius: 0px 6px 6px 0px;

  > input {
    width: calc(50% - ${px2rem(10)});
  }

  input {
    border-radius: 0;
    color: ${theme.colors.primary};
    font-size: ${theme.dimensions.fontSizeMedium};
  }

  > * span {
    padding: 0;
  }
`;

const Value = buildStyledComponent(
  'Vitals_VitalsValue',
  styled(Col),
  valueStyles
);

const Vital = {
  Item,
  Value,
};

export default Vital;
