import { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';

import { Button, Column, Text, ScrollView, HR, Modal, A } from 'common';
import { Container, Row } from 'common';
import {
  FETCH_DIRECT_MESSAGE,
  REQUEST_DIRECT_MESSAGE_ATTACHMENT_URL,
} from 'graphql/queries';
import {
  UPDATE_PATIENT_ATHENA_ID,
  CREATE_NOTE_FROM_DIRECT_MESSAGE,
} from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { displayTime } from 'utils/time';
import assets from 'assets';

import PatientSelect from './PatientSelect';
import { AuthContext } from 'contexts/auth';

export default () => {
  const history = useHistory();
  const { id } = useParams();
  const { me } = useContext(AuthContext);

  const [showSearchModal, setShowSearchModal] = useState(false);
  // const [readMessage] = useMutation(READ_PATIENT_NOTE, {
  //   variables: { note: id },
  // });
  const [fetchMessage, { data: { directMessage: message } = {}, loading }] =
    useLazyQuery(FETCH_DIRECT_MESSAGE, {
      variables: { id },
    });
  const [getAttachmentUrl, { loading: loadingAttachment }] = useLazyQuery(
    REQUEST_DIRECT_MESSAGE_ATTACHMENT_URL,
    {
      variables: { id },
      onCompleted: ({ requestDirectMessageAttachmentUrl: attachmentUrl }) => {
        if (attachmentUrl) {
          window.open(attachmentUrl, '_blank').focus();
        }
      },
      fetchPolicy: 'no-cache',
      initialFetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    }
  );
  const [updatePatientAthenaId, { loading: saving }] = useMutation(
    UPDATE_PATIENT_ATHENA_ID,
    {
      onCompleted: (data) => {
        setShowSearchModal(false);
        fetchMessage();
      },
      onError: (error) => {
        console.error(error);
        setShowSearchModal(false);
      },
    }
  );
  const [createNoteFromDM, { loading: creating }] = useMutation(
    CREATE_NOTE_FROM_DIRECT_MESSAGE
  );

  useEffect(() => {
    fetchMessage();
  }, [fetchMessage]);

  const goBack = useCallback(() => {
    history.replace('/coordinate/direct-messages');
  }, [history]);

  const handleOpenAttachment = useCallback(() => {
    getAttachmentUrl();
  }, [message]);

  const handleSelectPatient = useCallback(
    (patient) => {
      updatePatientAthenaId({
        variables: {
          patient: patient._id,
          athenaId: message.patientInfo.id,
        },
      });
    },
    [updatePatientAthenaId, message]
  );

  const handleCreateNote = async () => {
    await createNoteFromDM({
      variables: { message: message._id },
      onCompleted: ({ createNoteFromDirectMessage: newNoteId }) => {
        if (newNoteId) {
          const url = `/notes/${newNoteId}`;
          history.replace(url);
        }
      },
    });
  };

  const canCreateNote = useMemo(() => {
    if (
      !message ||
      !me ||
      !me.activeProviderPractice.practice.isGazuntitePractice ||
      me.activeProviderPractice.disableTracking
    ) {
      return false;
    }
    if (
      message.note &&
      message.note.creator !== me.activeProviderPractice._id
    ) {
      return false;
    }
    return true;
  }, [message, me]);

  loadingVar(loading || loadingAttachment || saving || creating);

  if (loading || !message) {
    return null;
  }

  return (
    <Container
      modifiers={['fluid', 'flexBox', 'fullHeight', 'backgroundWhite']}
    >
      <ScrollView>
        <Container modifiers={['padding_2']}>
          <Row>
            <Column modifiers={['noPadding']}>
              <Text modifiers={['block', 'medium']}>From:</Text>
              <Text modifiers={['block', 'primary', 'medium']}>
                {message.from}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['noPadding']}>
              <Text modifiers={['block', 'medium']}>Subject:</Text>
              <Text modifiers={['block', 'primary', 'medium']}>
                {message.subject}
              </Text>
            </Column>
          </Row>
        </Container>
        <HR />
        <Container modifiers={['padding_2']}>
          <Row>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['block', 'medium']}>Patient Name:</Text>
              <Text
                modifiers={[
                  'block',
                  message.patient ? 'primary' : 'danger',
                  'bold',
                  'medium',
                ]}
              >
                {message.patientInfo.name}
              </Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['block', 'medium']}>Dob:</Text>
              <Text modifiers={['primary', 'medium']}>
                {displayTime(message.patientInfo.dob, 'MM/DD/YYYY')}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['block', 'medium']}>Patient Id:</Text>
              <Text modifiers={['primary', 'medium']}>
                {message.patientInfo.id}
              </Text>
            </Column>
            <Column
              modifiers={['col_6', 'noPadding']}
              onClick={handleOpenAttachment}
            >
              <Text modifiers={['block', 'medium']}>Attachment:</Text>
              <Text modifiers={['primary', 'medium']}>
                {message.attachment?.fileName}
              </Text>
            </Column>
          </Row>
        </Container>
        <HR />
        <Container modifiers={['padding_2']}>
          <Row modifiers={'smallGutters'}>
            <Text modifiers={['block', 'preserveLineBreak']}>
              {message.body}
            </Text>
          </Row>
        </Container>
        {/* <Container modifiers={['padding_2']}>
          <Text modifiers={['block', 'medium', 'muted']}>
            Visit date/time{' '}
            <Text modifiers={['medium', 'primary']}>
              {note.signDate &&
                displayTime(note.signDate, 'MM/DD/yyyy @ hh:mma')}
            </Text>
          </Text>
          <NoteDetail note={fullyLoadedNote} />
        </Container> */}
      </ScrollView>
      <Container modifiers={['footer', 'flexFixed']}>
        <Row modifiers={['spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={goBack}
          />
          <Button
            modifiers={['roundCorner', 'widthSmall']}
            onClick={() => setShowSearchModal(true)}
          >
            Find Patient
          </Button>
          {canCreateNote && (
            <Button
              modifiers={[
                'roundCorner',
                'widthSmall',
                !message.patient && 'disabled',
              ]}
              onClick={handleCreateNote}
            >
              Create Note
            </Button>
          )}
        </Row>
      </Container>
      {showSearchModal && (
        <Container modifiers={[!showSearchModal && 'noDisplay']}>
          <PatientSelect
            handleClose={() => setShowSearchModal(false)}
            handleSelect={handleSelectPatient}
          />
        </Container>
      )}
    </Container>
  );
};
