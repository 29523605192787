import styled from 'styled-components';

import { IconButton } from '@gaz/gaz-components.public';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  right: () => `
    right: ${px2rem(15)};
  `,
  left: () => `
    left: ${px2rem(15)};
  `,
  top: () => `
    top: ${px2rem(15)};
  `,
  bottom: () => `
    bottom: ${px2rem(15)};
  `,
  verticalCenter: () => `
    top: 0;
    bottom: 0;
  `,
  horizontalCenter: () => `
    left: 0;
    right: 0;
  `,
};

const styles = () => `
  position: absolute;
  z-index: 100;

  img {
    height: auto;
  }
`;

const ViewerControl = buildStyledComponent(
  'MediaViewer_ViewerControl',
  styled(IconButton),
  styles,
  { modifierConfig }
);

export default ViewerControl;
