import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  wide: () => `
    width: ${px2rem(20)};
    height: ${px2rem(15.69)};
  `,
};

const styles = ({ ...props }) => `
  margin-right: ${px2rem(10)};
  width: ${px2rem(props.iconWidth || 15.05)};
  height: ${px2rem(props.iconHeight || 20.97)};
  position: absolute;
  right: 0;
`;

export default buildStyledComponent('FormIconInput_Image', styled.img, styles, {
  modifierConfig,
});
