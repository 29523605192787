import styled from 'styled-components';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { buildStyledComponent, px2rem } from 'style';
import { DESKTOP_VIEWPORT_WIDTH } from 'utils/constants';

const modifierConfig = {
  smallPadding: () => `
    div:nth-child(2) {
      padding: ${px2rem(10)} ${px2rem(10)};
    }
  `,
  noPadding: () => `
    .slide-pane__content {
      padding: ${px2rem(10)} 0px;

      @media only screen and (min-width: 769px) {
        width: ${DESKTOP_VIEWPORT_WIDTH};
        margin: 0 auto;
      }
    }
  `,
  noHeader: () => `
    .slide-pane__header {
      display: none;
    }
  `,
};

const styles = ({ theme }) => `
  box-shadow: ${theme.dimensions.boxShadow_2};
  border-radius: ${theme.dimensions.borderRadius_2} ${
  theme.dimensions.borderRadius_2
} 0px 0px;
  z-index: 999;
  flex: 1;
  & > * {
    background-color: ${theme.colors.white};
    border-bottom: none;
    border-radius: ${theme.dimensions.borderRadius_2} ${
  theme.dimensions.borderRadius_2
} 0px 0px;
  }
  &::nth-child(1) {
    border-radius: ${theme.dimensions.borderRadius_2} ${
  theme.dimensions.borderRadius_2
} 0px 0px;
  }
  &:nth-child(2) {
    padding: ${px2rem(10)} ${px2rem(10)};
  }

  @media only screen and (min-width: 769px) {
    background: transparent;
  }
`;

export const CustomSlidingPane = buildStyledComponent(
  'CustomSlidingPane',
  styled(SlidingPane),
  styles,
  { modifierConfig }
);
export * from './SlidingPaneContent';
