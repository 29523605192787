import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  round: ({ theme }) => `
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  `,
  dark: ({ theme }) => `
    background-color: ${theme.colors.transparent};
    border-color: ${theme.colors.white};
    color: ${theme.colors.white};
  `,
  bordered: ({ theme }) => `
    border: ${px2rem(1)} solid ${theme.colors.grey};
  `,
  error: ({ theme }) => `
    border-color: ${theme.colors.danger} !important;
  `,
  valid: ({ theme }) => `
    border-color: ${theme.colors.primary};
  `,
  flexColumn: () => `
    flex-direction: column;
  `,
  noMargin: () => `
    margin-top: 0;
  `,
};

const styles = ({ theme, width }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${width ? px2rem(width) : '100%'};
  color: ${theme.colors.text};
  font-size: ${theme.dimensions.fontSize};
  position: relative;
  margin: 4px 0;
  min-height: ${px2rem(42)};
`;

const Wrapper = buildStyledComponent('FormInput_Wrapper', styled.div, styles, {
  modifierConfig,
});

export default Wrapper;
