import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { loadData, saveData, removeData } from 'utils/storage';

import { PatientHomeContext } from '../../Context';

export const CreateNoteContext = createContext();

const CreateNoteProvider = ({ children }) => {
  const { provider, practice, patient, goBack, setBackHandler } =
    useContext(PatientHomeContext);
  const [newNote, setNote] = useState({
    content: {
      subjective: null,
      objective: null,
      plan: null,
      assessment: null,
      attachments: null,
      diagnosis: null,
    },
  });
  const [footerVisible, setFooterVisible] = useState(true);
  const [itemsPickedPeriods, setItemsPickedPeriods] = useState({
    subjective: '6m',
    objective: '6m',
    plan: '6m',
    diagnosis: '6m',
    assessment: '6m',
    attachments: '6m',
  });

  useEffect(() => {
    if (patient) {
      const savedNoteString = loadData(`noteDraft-${patient._id}`);
      let savedNote;
      if (savedNoteString?.length > 0) {
        savedNote = JSON.parse(savedNoteString);
      } else {
        savedNote = newNote;
      }
      setNote({ ...savedNote, user: patient._id });
    }
  }, [patient]);

  const createNoteBackHandler = (backHandler, force) => {
    if (!backHandler || backHandler === '') {
      setBackHandler('', force);
    } else {
      setBackHandler(async () => {
        await backHandler();
        return false;
      }, force);
    }
  };

  const checkEmptyNote = (note) => {
    if (!note.content) {
      return true;
    }
    if (note.content.subjective && note.content.subjective.trim().length > 0) {
      return false;
    }
    if (note.content.plan && note.content.plan.trim().length > 0) {
      return false;
    }
    if (note.content.assessment && note.content.assessment.trim().length > 0) {
      return false;
    }
    if (
      note.content.diagnosis &&
      Object.values(note.content.diagnosis).length > 0
    ) {
      return false;
    }
    if (note.content.attachments?.length > 0) {
      return false;
    }
    if (
      !!note.content.objective &&
      Object.keys(note.content.objective).length > 0
    ) {
      return false;
    }

    return true;
  };

  const saveLocalDraft = useCallback(
    (note) => {
      if (patient && note && JSON.stringify(note).length > 0) {
        if (checkEmptyNote(note)) {
          return;
        }
        saveData(`noteDraft-${patient._id}`, JSON.stringify(note));
      }
    },
    [patient]
  );

  const removeLocalDraft = useCallback(() => {
    if (patient?._id) {
      removeData(`noteDraft-${patient._id}`);
    }
  }, [patient]);

  const updateNote = useCallback(
    (note, saveLocal = true) => {
      if (patient && !note.user) {
        note.user = patient._id;
      }
      setNote(note);
      if (saveLocal) {
        saveLocalDraft(note);
      }
    },
    [saveLocalDraft]
  );

  const isEmptyNote = useMemo(() => checkEmptyNote(newNote), [newNote]);

  return (
    <CreateNoteContext.Provider
      value={{
        setBackHandler: createNoteBackHandler,
        note: newNote,
        updateNote,
        patient,
        provider,
        practice,
        goBack,
        footerVisible,
        setFooterVisible,
        isEmptyNote,
        itemsPickedPeriods,
        setItemsPickedPeriods,
        removeLocalDraft,
      }}
    >
      {children}
    </CreateNoteContext.Provider>
  );
};

export default CreateNoteProvider;
