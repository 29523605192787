import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  bottom: () => ({
    styles: `
      align-items: flex-end;
    `,
  }),
  center: () => ({
    styles: `
      justify-content: center;
      text-align: center;
    `,
  }),
  end: () => ({
    styles: `
      justify-content: flex-end;
      text-align: end;
    `,
  }),
  fluid: ({ theme }) => ({
    styles: `
      margin-left: calc(-${theme.dimensions.gridPad} * 2);
      margin-right: calc(-${theme.dimensions.gridPad} * 2);
    `,
  }),
  flexColumn: () => ({
    styles: `
      flex-direction: column;
    `,
  }),
  fullHeight: () => ({
    styles: `
      height: 100%;
    `,
  }),
  middle: () => ({
    styles: `
      align-items: center;
    `,
  }),
  noDisplay: () => ({
    styles: `
      display: none;
    `,
  }),
  reverse: () => ({
    styles: `
      -webkit-box-direction: reverse;
      -webkit-box-orient: horizontal;
      flex-direction: row-reverse;
    `,
  }),
  start: () => ({
    styles: `
      justify-content: flex-start;
      text-align: start;
    `,
  }),
  top: () => ({
    styles: `
      align-items: flex-start;
    `,
  }),
  spaceBetween: () => ({
    styles: `
      justify-content: space-between;
    `,
  }),
  spaceAround: () => ({
    styles: `
      justify-content: space-around;
    `,
  }),
  alignCenter: () => ({
    styles: `
      align-items: center;
    `,
  }),
  withGutters: () => `
    margin-top: ${px2rem(15)};
    margin-bottom: ${px2rem(15)};
  `,
  smallGutters: () => `
    margin-top: ${px2rem(5)};
    margin-bottom: ${px2rem(5)};
  `,
  topGutters_1: ({ theme }) => `
    margin-top: ${theme.dimensions.padding_1};
  `,
  topGutters_2: ({ theme }) => `
    margin-top: ${theme.dimensions.padding_2};
  `,
  bottomGutters_1: ({ theme }) => `
    margin-bottom: ${theme.dimensions.padding_1};
  `,
  bottomGutters_2: ({ theme }) => `
    margin-bottom: ${theme.dimensions.padding_2};
  `,
  noWrap: () => `
    flex-wrap: nowrap;
  `,
  borderGray: ({ theme }) => `
    border: solid ${px2rem(1)} ${theme.colors.grey};
  `,
  roundCorner: ({ theme }) => `
    border-radius: ${theme.dimensions.padding_1};
  `,
  horizontalSmallPadding: ({ theme }) => `
    padding: 10px;
  `,
  mediumPadding: ({ theme }) => `
    padding: 3% 3% 0 3%;
  `,
  borderBlack: ({ theme }) => `
    border: solid ${px2rem(1)} ${theme.colors.darkGrey};
  `,
  alignTextTop: () => `
    align-items: baseline;
  `,
};

const styles = () => `
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 auto;
  position: relative;
`;

export default buildStyledComponent('Row', styled.div, styles, {
  modifierConfig,
});
