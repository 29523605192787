import React from 'react';
import QrReader from 'react-qr-reader';

import { Button, Row, Column } from '@gaz/gaz-components.public';

import Wrapper from './Wrapper';

export const QRCodeReader = ({ visible, onScan, onError, onClose }) => {
  const handleScan = (data) => {
    if (data) {
      onScan(data);
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <Wrapper>
      <Row modifiers={['fullHeight', 'flexColumn']}>
        <Column>
          <Button className="btn-back" onClick={onClose}>
            Back
          </Button>
        </Column>

        <Column modifiers="col">
          <Row modifiers={['fullHeight', 'center', 'middle']}>
            <QrReader
              onError={onError}
              onScan={handleScan}
              style={{ width: '100%' }}
            />
          </Row>
        </Column>
      </Row>
    </Wrapper>
  );
};

export default QRCodeReader;
