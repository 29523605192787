import React, { useState, useContext, useRef, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';

import { MainLayout } from 'layouts';
import {
  BackButton,
  Avatar,
  Container,
  Row,
  Column,
  Text,
  Button,
  FormInput,
  FormPhoneInput,
  FormSelect,
} from 'common';

import assets from 'assets';
import { AuthContext } from 'contexts/auth';
import {
  formatAddress,
  formatMemberDesignation,
  formatTitleAndName,
} from 'utils/string';
import { FormFileInput } from 'common';
import { loadingVar } from 'graphql/cache';
import { UPDATE_ME } from 'graphql/mutations';
import schema from 'utils/schema';
import { MEMBER_DESIGNATIONS_LIST } from 'utils/constants';

import CardContainer from './CardContainer';

const { Header, Content } = MainLayout;

const profileSchema = Yup.object().shape({
  firstName: schema.firstName,
  lastName: schema.lastName,
  middleName: schema.middleName,
  email: schema.email,
  phones: schema.phones,
  photo: schema.file,
  specialty: schema.specialty,
  memberDesignation: Yup.string()
    .nullable()
    .required('Member Designation is required.'),
  npi: Yup.string().length(10, 'NPI number should be 10 digits.'),
});

export default function MyProfile() {
  const [isEditing, setIsEditing] = useState(false);
  const { me, updateAuth, specialties } = useContext(AuthContext);
  const formRef = useRef();
  const [updateMe, { loading }] = useMutation(UPDATE_ME, {
    onCompleted: async (data) => {
      const {
        updateMe: { user: newUser },
      } = data;

      await updateAuth(newUser);
    },
    onError: () => {},
  });

  loadingVar(loading);

  const specialtyList = useMemo(() => {
    if (!specialties) {
      return [];
    }
    return specialties.map((specialty) => ({
      value: specialty,
      label: specialty,
    }));
  }, [specialties]);

  const practice = me.activeProviderPractice?.practice || {};

  const handleToggleEdit = () => {
    if (isEditing) {
      formRef.current.submitForm();
    }
    setIsEditing(!isEditing);
  };

  const handleSave = async (values) => {
    const { memberDesignation, npi, ...profileValues } = values;

    updateMe({
      variables: {
        provider: {
          memberDesignation: memberDesignation,
          npi,
          user: profileValues,
        },
      },
    });
  };

  const initialValues = {
    firstName: me.firstName,
    lastName: me.lastName,
    middleName: me.middleName || '',
    email: me.email,
    phones: me.phones,
    photo: me.photo,
    specialty: me.specialty,
    memberDesignation: MEMBER_DESIGNATIONS_LIST.find(
      (m) => m.value === me.memberDesignation
    )?.value,
    npi: me.npi,
  };

  return (
    <MainLayout>
      <Header style={{ backgroundColor: 'white' }}>
        <Header.Left>
          <Row modifiers={['middle']}>
            <BackButton />
            <Text modifiers={['pageTitle']}>Profile</Text>
          </Row>
        </Header.Left>
        <Header.Right>
          <Button onClick={handleToggleEdit}>
            {isEditing ? 'Done' : 'Edit'}
          </Button>
        </Header.Right>
      </Header>
      <Content style={{ backgroundColor: '#f9f9ff' }}>
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={profileSchema}
          onSubmit={handleSave}
        >
          <Form>
            <Row modifiers="middle">
              <Column>
                <Field
                  disabled={!isEditing}
                  name="photo"
                  component={FormFileInput}
                  s3={{ folder: 'providers' }}
                  modifiers={['round', 'flexColumn']}
                  accept="image/*"
                  width={70}
                  height={70}
                >
                  <Avatar
                    image={assets.images.emptyAvatar}
                    width={70}
                    height={70}
                    modifiers={['round']}
                  />
                </Field>
              </Column>
              <Column>
                {isEditing ? (
                  <>
                    <Field
                      name="firstName"
                      component={FormInput}
                      placeholder="First Name"
                    />
                    <Field
                      name="middleName"
                      component={FormInput}
                      placeholder="Middle Name"
                      style={{ marginTop: '5px' }}
                    />
                    <Field
                      name="lastName"
                      component={FormInput}
                      placeholder="Last Name"
                      style={{ marginTop: '5px' }}
                    />
                  </>
                ) : (
                  <Text>{formatTitleAndName(me)}</Text>
                )}
              </Column>
            </Row>
            <Container>
              <CardContainer modifiers={['bottomGutters_1']}>
                <Row>
                  <Text modifiers={['bold', 'small']}>Practice</Text>
                </Row>
                <Row>
                  <Text modifiers={['topPadding', 'body', 'primary']}>
                    {practice.name}
                    <br />
                    {formatAddress(practice.address)}
                  </Text>
                </Row>
              </CardContainer>
              <CardContainer modifiers={['bottomGutters_1']}>
                <div>
                  <Text modifiers={['bold', 'small']}>Designation</Text>
                </div>
                <div>
                  {isEditing ? (
                    <Field
                      name="memberDesignation"
                      required
                      options={MEMBER_DESIGNATIONS_LIST}
                      modifiers={['squared']}
                      component={FormSelect}
                    />
                  ) : (
                    <Text modifiers={['body', 'primary', 'topPadding']}>
                      {formatMemberDesignation(me.memberDesignation)}
                    </Text>
                  )}
                </div>
              </CardContainer>
              <CardContainer modifiers={['bottomGutters_1']}>
                <Row>
                  <Text modifiers={['bold', 'small']}>NPI</Text>
                </Row>
                <Row>
                  {isEditing ? (
                    <Field
                      name="npi"
                      component={FormInput}
                      placeholder="NPI"
                      maxLength={10}
                    />
                  ) : (
                    <Text modifiers={['body', 'primary', 'topPadding']}>
                      {me.npi}
                    </Text>
                  )}
                </Row>
              </CardContainer>
              <CardContainer modifiers={['bottomGutters_1']}>
                <div>
                  <Text modifiers={['bold', 'small']}>Specialty</Text>
                </div>
                <div>
                  {isEditing ? (
                    <Field
                      name="specialty"
                      options={specialtyList}
                      modifiers={['squared']}
                      component={FormSelect}
                    />
                  ) : (
                    <Text modifiers={['body', 'primary', 'topPadding']}>
                      {me.specialty}
                    </Text>
                  )}
                </div>
              </CardContainer>
              <CardContainer modifiers={['bottomGutters_1']}>
                <Row>
                  <Text modifiers={['bold', 'small']}>
                    Phones (PSTN - Charges may apply)
                  </Text>
                </Row>
                <Row modifiers={['middle']}>
                  <Column modifiers={['col_4']}>
                    <Text modifiers={['body', 'inline']}>Work</Text>
                  </Column>
                  <Column modifiers={['col']}>
                    {isEditing ? (
                      <Field name="phones.work" component={FormPhoneInput} />
                    ) : (
                      <Text modifiers={['body', 'primary', 'inline']}>
                        {me.phones.work}
                      </Text>
                    )}
                  </Column>
                </Row>
                <Row modifiers={['middle']}>
                  <Column modifiers={['col_4']}>
                    <Text modifiers={['body', 'inline']}>Mobile</Text>
                  </Column>
                  <Column modifiers={['col']}>
                    {isEditing ? (
                      <Field name="phones.mobile" component={FormPhoneInput} />
                    ) : (
                      <Text modifiers={['body', 'primary', 'inline']}>
                        {me.phones.mobile}
                      </Text>
                    )}
                  </Column>
                </Row>
                <Row modifiers={['middle']}>
                  <Column modifiers={['col_4']}>
                    <Text modifiers={['body', 'inline']}>Home</Text>
                  </Column>
                  <Column modifiers={['col']}>
                    {isEditing ? (
                      <Field name="phones.home" component={FormPhoneInput} />
                    ) : (
                      <Text modifiers={['body', 'primary', 'inline']}>
                        {me.phones.home}
                      </Text>
                    )}
                  </Column>
                </Row>
              </CardContainer>
              <CardContainer modifiers={['bottomGutters_1']}>
                <Row>
                  <Text modifiers={['bold', 'small']}>E-mail</Text>
                </Row>
                <Row>
                  <Text modifiers={['body', 'topPadding']}>Work</Text>
                </Row>
                <div>
                  {isEditing ? (
                    <Field name="email" component={FormInput} />
                  ) : (
                    <Text modifiers={['topPadding', 'body', 'primary']}>
                      {me.email}
                    </Text>
                  )}
                </div>
              </CardContainer>
            </Container>
          </Form>
        </Formik>
      </Content>
    </MainLayout>
  );
}
