import React, { useState } from 'react';
import moment from 'moment';
import TimePicker from 'react-time-picker';

import { FormInput, Row } from 'common';
import Modal from './Modal';

export const FormDirectTimeInput = (props) => {
  const {
    field: { name, onChange },
    form: { touched },
    placeholder = 'Select time',
    handleChange: customChangeHandler,
  } = props;
  const defaultTime = props.field.value || moment(new Date()).format('hh:mm a');
  const [showModal, setShowModal] = useState(false);

  const convertTime = (selectedTime, isFullFormat = false) => {
    if (isFullFormat) {
      return moment(selectedTime, 'hh:mm').format('hh:mm a');
    }

    return moment(selectedTime, 'hh:mm a').format('hh:mm');
  };

  const [time, onTime] = useState(convertTime(defaultTime));

  const handleToggle = () => {
    setShowModal(!showModal);
  };

  const handleDone = () => {
    if (showModal) {
      onChange(name)(convertTime(time, true));

      if (customChangeHandler) {
        customChangeHandler(convertTime(time, true));
      }
    }
    setShowModal(false);
  };

  return (
    <div>
      <FormInput
        {...props}
        field={{ ...props.field, value: defaultTime }}
        onClick={() => setShowModal(true)}
        readOnly
      ></FormInput>

      <Modal
        title={placeholder}
        show={showModal ? 'yes' : undefined}
        onToggle={handleToggle}
        handleDone={handleDone}
      >
        <Row modifiers={['horizontalSmallPadding', 'center']}>
          <TimePicker
            onChange={(t) => onTime(t)}
            value={time}
            disableClock
            autoFocus
          />
        </Row>
      </Modal>
    </div>
  );
};

export default FormDirectTimeInput;
