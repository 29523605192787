import styled from 'styled-components';
import {
  Container,
  Row,
  Column as Col,
  Text,
  Button,
} from '@gaz/gaz-components.public';

import assets from 'assets';
import { buildStyledComponent } from 'style';

export const ItemWrapper = buildStyledComponent(
  'ItemWrapper',
  styled(Container),
  ({ theme }) => `
    border-radius: ${theme.dimensions.borderRadius_1};
    margin-bottom: ${theme.dimensions.padding_2};
    background: ${theme.colors.white};
  `,
  {
    modifierConfig: {
      round: ({ theme }) => `
        border-radius: 6px;
        border: 1px solid #E7E7E9;
      `,
      disabled: () => `
        opacity: 0.5;
        pointer-events: none;
      `,
      detail: ({ theme }) => `
        > :first-child {
          background-color: #F3F3F4;
          > :last-child {
            background-color: white;
          }
        }
      `,
    },
  }
);

const ListItem = ({
  icon,
  title,
  hideGoToDetails,
  detail,
  disabled,
  ...props
}) => (
  <ItemWrapper
    modifiers={['fluid', 'round', disabled && 'disabled', detail && 'detail']}
    {...props}
  >
    <Row>
      <Col
        modifiers={[detail ? 'col_10' : 'col', 'fluid', 'noVerticalPadding']}
      >
        <Row>
          <Col
            modifiers={[
              'flexBox',
              'center',
              'verticalContentCenter',
              'noVerticalPadding',
            ]}
          >
            <img src={icon} alt="" width="25" height="25" />
          </Col>
          <Col modifiers={['col', 'fluid']}>
            <Text modifiers={['small', 'block', 'secondaryColor', 'start']}>
              {title}
            </Text>
          </Col>
          {!hideGoToDetails && (
            <Col modifiers={['fluid', 'noVerticalPadding']}>
              <Button
                modifiers={[
                  'icon',
                  'roundCorner',
                  'transparent',
                  'small',
                  disabled && 'disabled',
                ]}
                image={assets.icons.chevronR}
              />
            </Col>
          )}
        </Row>
      </Col>
      {detail && <Col modifiers={['col_2']}>{detail}</Col>}
    </Row>
  </ItemWrapper>
);

export default ListItem;
