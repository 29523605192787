import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  grid: ({ theme }) => `
    width: calc(50% - ${theme.dimensions.padding_1} / 2);
    margin: 0 0 ${theme.dimensions.padding_1} 0;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    &:nth-child(even) {
      margin-right: ${theme.dimensions.padding_1};
    }

    span {
      margin: ${theme.dimensions.padding_1} 0 0 0;
    }

    >*:last-child {
      margin: ${theme.dimensions.padding_1} 0 0 0;
    }
  `,
  list: ({ theme }) => `
  `,
  smallPadding: ({ theme }) => `
    padding: ${theme.dimensions.padding_1};
  `,
  mediumBorderRadius: ({ theme }) => `
    border-radius: ${theme.dimensions.borderRadius_2};
  `,
};

const styles = ({ theme }) => `
  position: relative;
  width: 100%;
  padding: ${theme.dimensions.padding_2};
  margin: 0 0 ${theme.dimensions.padding_1} 0;
  background-color: ${theme.colors.white};
  border-radius: ${theme.dimensions.borderRadius_1};
  box-shadow: ${theme.dimensions.boxShadow};
  display: flex;
  align-items: center;

  p {
    font-size: ${theme.dimensions.fontSize};
    color: ${theme.colors.text};
    margin: 0 0 0 ${theme.dimensions.padding_1};
    flex: 1;
    margin-bottom: 0px;
  }
`;

const Card = buildStyledComponent('Search_List_Card', styled.div, styles, {
  modifierConfig,
});

export default Card;
