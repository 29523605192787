import { useHistory } from 'react-router';
import { Container, Row, Text, BackButton } from '@gaz/gaz-components.public';

import urls from 'routes/urls';

export default () => {
  const history = useHistory();
  return (
    <Container>
      <Row modifiers={['spaceBetween', 'middle']}>
        <BackButton onClick={() => history.push(urls.DASHBOARD)} />
        <Text modifiers={['large']}>Appointments</Text>
        <div></div>
      </Row>
    </Container>
  );
};
