import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = () => `
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
`;

const Wrapper = buildStyledComponent('Tabs', styled.div, styles);

export default Wrapper;
