import { gql } from '@apollo/client';

const addressFragment = gql`
  fragment AddressFragment on Address {
    addressLine1
    addressLine2
    city
    state
    stateCode
    country
    countryCode
    zipcode
  }
`;

const scheduleFragment = gql`
  fragment ScheduleFragment on Schedule {
    days
    from
    to
    duration
    breakOn
    breaks {
      from
      to
    }
  }
`;

const practiceFragment = gql`
  fragment PracticeFragment on Practice {
    _id
    name
    npi
    fax
    email
    phone
    address {
      ...AddressFragment
    }
    image {
      originalName
      type
      url
    }
  }
  ${addressFragment}
`;

const groupFragment = gql`
  fragment GroupFragment on Group {
    _id
    name
    description
    image {
      originalName
      type
      url
    }
  }
`;

const userProfileFragment = gql`
  fragment UserProfileFragment on User {
    _id
    firstName
    lastName
    middleName
    email
    photo {
      originalName
      type
      url
    }
    phones {
      mobile
      home
      work
      preference
      masking
    }
    role
    status
    dob
    athenaId
    ssn
    gender
    maritalStatus
    memberDesignation
    npi
    bpmIMEI
    scaleIMEI
    address {
      ...AddressFragment
    }
    insurances {
      company
      id
      groupNumber
      card {
        originalName
        type
        url
      }
    }
    notifications
  }
  ${addressFragment}
`;

const authUserProfileFragment = gql`
  fragment AuthUserProfileFragment on AuthUser {
    _id
    firstName
    lastName
    middleName
    email
    photo {
      originalName
      type
      url
    }
    phones {
      mobile
      home
      work
      preference
      masking
    }
    role
    status
    dob
    ssn
    gender
    maritalStatus
    memberDesignation
    npi
    bpmIMEI
    scaleIMEI
    address {
      ...AddressFragment
    }
    insurances {
      company
      id
      groupNumber
      card {
        originalName
        type
        url
      }
    }
    notifications
  }
  ${addressFragment}
`;

const authUserFragment = gql`
  fragment AuthUserFragment on AuthUser {
    ...AuthUserProfileFragment
    activeProviderPractice {
      practice {
        _id
        name
        npi
        fax
        email
        phone
        address {
          ...AddressFragment
        }
        image {
          originalName
          type
          url
        }
      }
      isAdmin
      title
      specialty
      operationSchedule {
        ...ScheduleFragment
      }
      billing {
        time
        therapeutic
        physiologic
      }
      disableTracking
    }
  }
  ${authUserProfileFragment}
  ${scheduleFragment}
`;

const userFragment = gql`
  fragment UserFragment on User {
    ...UserProfileFragment
    activeProviderPractice {
      practice {
        ...PracticeFragment
      }
      isAdmin
      title
      specialty
    }
  }
  ${practiceFragment}
  ${userProfileFragment}
`;

const leadFragment = gql`
  fragment LeadFragment on Lead {
    contact {
      ... on User {
        ...UserFragment
      }
      ... on Practice {
        ...PracticeFragment
      }
      ... on Group {
        ...GroupFragment
      }
    }
    inviteId
    outgoing
  }
  ${userFragment}
  ${practiceFragment}
  ${groupFragment}
`;

const inviteFragment = gql`
  fragment InviteFragment on Invite {
    _id
    practice {
      ...PracticeFragment
    }
    group {
      ...GroupFragment
    }
    inviter {
      ...UserFragment
    }
    invitee {
      ...UserFragment
    }
  }
  ${userFragment}
  ${practiceFragment}
  ${groupFragment}
`;

const providerFragment = gql`
  fragment ProviderFragment on ProviderPractice {
    user {
      ...UserProfileFragment
    }
    practice {
      ...PracticeFragment
    }
    isAdmin
  }
  ${userProfileFragment}
  ${practiceFragment}
`;

const authFragment = gql`
  fragment AuthFragment on Auth {
    error
    token
    user {
      ...AuthUserFragment
    }
  }
  ${authUserFragment}
`;

const diagnosisFragment = gql`
  fragment DiagnosisFragment on Diagnosis {
    code
    description
    date
  }
`;

const noteContentFragment = gql`
  fragment NoteContentFragment on NoteContent {
    subjective
    objective {
      heartRate
      glucose
      weight
      bloodPressure
      bloodPressure2
      bloodOxygen
      respiratory
      temperature
    }
    assessment
    plan
    diagnosis {
      ...DiagnosisFragment
    }
    attachments {
      category
      url
      originalName
      type
      chatId
      messageIds
      note
      createdAt
    }
  }
  ${diagnosisFragment}
`;

const noteShareFragment = gql`
  fragment NoteShareFragment on NoteShare {
    by {
      ...ProviderFragment
    }
    at
  }
  ${providerFragment}
`;

const noteFragment = gql`
  fragment NoteFragment on Note {
    _id
    signDate
    createdAt
    updatedAt
    isDraft
    user {
      ...UserProfileFragment
    }
    creator {
      ...ProviderFragment
    }
    content {
      ...NoteContentFragment
    }
    shares {
      ...NoteShareFragment
    }
    isSeen
  }
  ${userProfileFragment}
  ${providerFragment}
  ${noteContentFragment}
  ${noteShareFragment}
`;

const careplanShareFragment = gql`
  fragment CareplanShareFragment on CareplanShare {
    by {
      ...ProviderFragment
    }
    at
  }
  ${providerFragment}
`;

const careTeamFragment = gql`
  fragment CareTeamFragment on CareTeam {
    user {
      ...UserProfileFragment
    }
    appointments
    alerts
  }
`;

const careplanContentFragment = gql`
  fragment CareplanContentFragment on CareplanContent {
    medication
    vital
    activity
    wellness
    diet
    careTeam {
      ...CareTeamFragment
    }
  }
  ${careTeamFragment}
`;

const careplanFragment = gql`
  fragment CareplanFragment on Careplan {
    _id
    startDate
    signDate
    duration
    isDraft
    isActive
    createdAt
    updatedAt
    user {
      ...UserProfileFragment
    }
    creator {
      ...ProviderFragment
    }
    shares {
      ...CareplanShareFragment
    }
    content {
      ...CareplanContentFragment
    }
    isSeen
  }
  ${userProfileFragment}
  ${providerFragment}
  ${careplanShareFragment}
  ${careplanContentFragment}
`;

const chatFragment = gql`
  fragment ChatFragment on Chat {
    _id
    members {
      ...UserProfileFragment
    }
    referredPatient {
      ...UserProfileFragment
    }
    group
    messages {
      _id
      sender {
        ...UserProfileFragment
      }
      text
      createdAt
      attachment {
        url
        originalName
        type
      }
      note {
        ...NoteFragment
      }
      careplan {
        ...CareplanFragment
      }
    }
    createdAt
  }
  ${userProfileFragment}
  ${noteFragment}
  ${careplanFragment}
`;

const frequencyFragment = gql`
  fragment FrequencyFragment on Frequency {
    type
  }
`;

const basicAlertsFragment = gql`
  fragment BasicAlertsFragment on Alerts {
    triggerValue
    triggerType
  }
`;

const appointmentFragment = gql`
  fragment AppointmentFragment on Appointment {
    _id
    creator {
      ...UserProfileFragment
    }
    patient {
      ...UserProfileFragment
    }
    provider {
      ...UserProfileFragment
    }
    time
    reason
    status
    joined
  }
  ${userProfileFragment}
`;

const practiceDetailsFragment = gql`
  fragment PracticeDetailsFragment on PracticeDetails {
    group {
      ...PracticeFragment
    }
    members {
      user {
        ...UserProfileFragment
      }
      isAdmin
    }
    invites {
      ...InviteFragment
    }
    requests {
      ...InviteFragment
    }
    isAdmin
  }
  ${practiceFragment}
  ${userProfileFragment}
  ${inviteFragment}
`;

const groupDetailsFragment = gql`
  fragment GroupDetailsFragment on GroupDetails {
    group {
      ...GroupFragment
    }
    members {
      user {
        ...UserProfileFragment
      }
      isAdmin
    }
    invites {
      ...InviteFragment
    }
    requests {
      ...InviteFragment
    }
    isAdmin
  }
  ${groupFragment}
  ${userProfileFragment}
  ${inviteFragment}
`;

const medicationOrderFragment = gql`
  fragment MedicationOrderFragment on MedicationOrder {
    _id
    orderNumber
    status
    patient {
      ...UserProfileFragment
    }
    provider {
      ...UserFragment
    }
    medications
    createdAt
    updatedAt
  }
  ${userFragment}
`;

const careplanResponseFragment = gql`
  fragment CareplanResponseFragment on CareplanResponse {
    date
    careplan
    responses
  }
`;

const progressFragment = gql`
  fragment ProgressFragment on Progress {
    vital
    medication
    wellness
    diet
    activity
    appointment
  }
`;

const recentPatientFragment = gql`
  fragment RecentPatientFragment on User {
    _id
    firstName
    lastName
    email
    photo {
      originalName
      type
      url
    }
  }
`;

const callHistoryFragment = gql`
  fragment CallHistoryFragment on CallHistory {
    _id
    callType
    attendees {
      user {
        ...UserProfileFragment
      }
    }
    status
    referredPatient {
      ...UserProfileFragment
    }
    isPSTN
    pstnInfo {
      phoneType
      number
    }
    startTime
    endTime
    createdAt
  }
  ${userProfileFragment}
`;

const directMessageFragment = gql`
  fragment DirectMessageFragment on DirectMessage {
    _id
    messageId
    from
    to
    body
    attachment {
      fileName
      contentType
      fileUrl
    }
    patientInfo {
      id
      name
      dob
    }
    patient {
      ...UserProfileFragment
    }
    subject
    createTime
  }
  ${userProfileFragment}
`;

const eFaxMessageFragment = gql`
  fragment EFaxMessageFragment on EFaxMessage {
    _id
    messageId
    from
    to
    attachment {
      fileName
      contentType
      fileUrl
    }
    createTime
  }
`;

const fragments = {
  addressFragment,
  practiceFragment,
  groupFragment,
  userProfileFragment,
  userFragment,
  authUserFragment,
  leadFragment,
  inviteFragment,
  providerFragment,
  authFragment,
  diagnosisFragment,
  noteContentFragment,
  noteShareFragment,
  noteFragment,
  careplanShareFragment,
  careplanContentFragment,
  careplanFragment,
  chatFragment,
  frequencyFragment,
  basicAlertsFragment,
  appointmentFragment,
  practiceDetailsFragment,
  groupDetailsFragment,
  medicationOrderFragment,
  scheduleFragment,
  careplanResponseFragment,
  progressFragment,
  recentPatientFragment,
  callHistoryFragment,
  directMessageFragment,
  eFaxMessageFragment,
};

export default fragments;
