import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';

import { MainLayout } from 'layouts';
import {
  Tabs,
  Button,
  Row,
  Column,
  BackButton,
  Text,
  Container,
  Popover,
} from '@gaz/gaz-components.public';
import { CREATE_PATIENT } from 'graphql/mutations';
import urls from 'routes/urls';

import Profile from './Profile';
import Devices from './Devices';
import Contact from './Contact';

// todo: abstract logic and create FormWizard component
const AddPatientManual = ({ history }) => {
  const [createPatient] = useMutation(CREATE_PATIENT, {
    onCompleted: () => {
      history.push(urls.PATIENTS);
    },
    onError: () => {},
  });
  const [step, setStep] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false);
  const formDataRef = useRef({});
  const profileRef = useRef();
  const devicesRef = useRef();
  const contactRef = useRef();
  const { firstName, lastName, email, phones } = formDataRef.current;

  const handleAdd = async () => {
    const refs = [profileRef, devicesRef, contactRef];

    for (let i = 0; i < 3; i++) {
      await refs[i].current.submitForm();
      if (!refs[i].current.isValid) {
        setStep(i);
        return;
      }

      formDataRef.current = {
        ...formDataRef.current,
        ...refs[i].current.values,
      };
    }

    const userKeys = [
      'firstName',
      'lastName',
      'middleName',
      'email',
      'phones',
      'photo',
      'address',
    ];
    createPatient({
      variables: {
        patient: {
          ..._.omit(formDataRef.current, userKeys),
          user: _.pick(formDataRef.current, userKeys),
        },
      },
    });
  };

  const handleStepChange = async (newStep) => {
    setStep(newStep);
  };

  const handleClose = () => {
    setPopupVisible(false);
  };

  return (
    <MainLayout>
      <MainLayout.Header>
        <MainLayout.Header.Left>
          <BackButton />
        </MainLayout.Header.Left>
        <MainLayout.Header.Center>
          <MainLayout.Header.Title>New Patient</MainLayout.Header.Title>
        </MainLayout.Header.Center>
        <MainLayout.Header.Right />
      </MainLayout.Header>
      <MainLayout.Content>
        <Tabs current={step} onChange={handleStepChange}>
          <Tabs.Tab title="Profile" />
          <Tabs.Tab title="Devices" />
          <Tabs.Tab title="Contact" />
        </Tabs>
        <Container modifiers="flexOne" style={{ overflowY: 'auto' }}>
          <Profile ref={profileRef} show={step === 0} />
          <Devices ref={devicesRef} show={step === 1} />
          <Contact ref={contactRef} show={step === 2} />
        </Container>
        <Row modifiers={['center']}>
          <Column modifiers="col_6">
            <Button modifiers={['fullWidth']} onClick={handleAdd}>
              Add Patient
            </Button>
          </Column>
        </Row>
      </MainLayout.Content>
      <Popover
        open={popupVisible}
        title={`Patient ${firstName} ${lastName} Added`}
        footer
        onClose={handleClose}
      >
        <Container>
          <Text modifiers={['center', 'info']}>{email}</Text>
        </Container>
        <Container>
          <Text modifiers={['center', 'info']}>{phones?.mobile}</Text>
        </Container>
        <Container>
          <Text modifiers={['center']}>Invitation has been sent.</Text>
        </Container>
      </Popover>
    </MainLayout>
  );
};

export default AddPatientManual;
