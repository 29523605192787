import styled from 'styled-components';
import { Column, Text } from '@gaz/gaz-components.public';

import { Container, Row, HR } from 'common';
import { displayTime } from 'utils/time';
import assets from 'assets';
import { buildStyledComponent, px2rem } from 'style';
import { formatTitleAndName } from 'utils/string';

const Item = buildStyledComponent(
  'CareplanItem',
  styled(({ handleClick, careplan, ...props }) => (
    <Container modifiers={['card']} onClick={handleClick} {...props}>
      <Row modifiers={['middle', 'spaceBetween']}>
        <img src={assets.icons.icCareplanItem} />
        <Column modifiers={['col']}>
          <Text
            modifiers={[
              careplan?._id &&
                !careplan?.isDraft &&
                !careplan?.isSeen &&
                'bold',
            ]}
          >
            {formatTitleAndName(careplan.creator.user)}
          </Text>
        </Column>
        <Text>{careplan._id.substr(-7)}</Text>
      </Row>
      <HR modifiers={['gray']} />
      <Row modifiers={['smallGutters']}>
        <Text
          modifiers={[
            careplan?._id && !careplan?.isDraft && !careplan?.isSeen && 'bold',
          ]}
        >
          Patient Name:{' '}
          <Text modifiers={['primary']}>
            {formatTitleAndName(careplan.user)}
          </Text>
        </Text>
      </Row>
      <Row modifiers={['middle', 'spaceBetween']}>
        <Text
          modifiers={[
            careplan?._id && !careplan?.isDraft && !careplan?.isSeen && 'bold',
          ]}
        >
          {displayTime(
            careplan.isDraft ? careplan.updatedAt : careplan.signDate,
            'hh:mm a, MM/DD/yy'
          )}
        </Text>
        <img src={assets.icons.icMore} />
      </Row>
    </Container>
  )),
  ({ theme }) => `
    margin: ${theme.dimensions.padding_2} 0;
    padding: ${theme.dimensions.padding_2} ${theme.dimensions.padding_1};
    > :last-child {
      img {
        width: ${px2rem(18)};
        height: ${px2rem(18)};
      }
    }
  `
);

export default Item;
