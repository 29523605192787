import React, { useCallback, useState, useEffect, useContext } from 'react';
import debounce from 'lodash/debounce';
import { useLazyQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  BackButton,
  SearchBar,
  Text,
  Container,
} from '@gaz/gaz-components.public';
import { MainLayout } from 'layouts';
import { FETCH_RECENT_CHATS } from 'graphql/queries';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import { useFab } from 'contexts/fab';
import { EVENTS, SocketContext } from 'contexts/socket';
import assets from 'assets';
import urls from 'routes/urls';
import { getChat } from 'graphql/utils';
import { client } from 'graphql/client';

import ChatHistoryItem from './ChatHistoryItem';

export default function ChatHistory({ history }) {
  const { subscribe } = useContext(SocketContext);
  const [hasMore, setHasMore] = useState(true);
  const [query, setQuery] = useState('');
  const [fetchRecentChats, { data: { recentChats } = {}, fetchMore }] =
    useLazyQuery(FETCH_RECENT_CHATS, {
      onCompleted: (data) => {
        if (data.recentChats.length === 0) {
          setHasMore(false);
        }
      },
      fetchPolicy: 'cache-and-network',
    });
  const fab = useFab();

  const goToContacts = () => {
    history.push(urls.CONTACTS);
  };

  const handleSearch = useCallback(
    debounce((query) => {
      fetchRecentChats({
        variables: {
          query,
          offset: 0,
          limit: 10,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchRecentChats]
  );

  const handleQueryChange = (value) => {
    handleSearch(value);
    setQuery(value);
  };

  const handleFetchMore = async () => {
    const newPage = await fetchMore({
      variables: {
        offset: recentChats.length,
      },
    });
    if (newPage.data.recentChats.length === 0) {
      setHasMore(false);
    }
  };

  const handleMessageReceive = useCallback(
    async (payload) => {
      const { chat, text } = payload;
      let newList;

      if (!query) {
        const index = recentChats.findIndex((c) => c.chat._id === chat);
        if (index >= 0) {
          const message = recentChats[index];
          newList = [
            {
              ...payload,
              chat: {
                ...message.chat,
                unreadCount: message.chat.unreadCount + 1,
              },
              __typename: 'ChatMessage',
            },
            ...recentChats.slice(0, index),
            ...recentChats.slice(index + 1),
          ];
        } else {
          const chatData = await getChat(chat);
          const newChat = { ...chatData };
          newChat.unreadCount = 1;
          newList = [
            {
              ...payload,
              chat: newChat,
              __typename: 'ChatMessage',
            },
            ...recentChats,
          ];
        }
      } else if (text.includes(query)) {
        const chatData = await getChat(chat);
        const newChat = { ...chatData };
        newChat.unreadCount = 1;
        newList = [
          {
            ...payload,
            chat: newChat,
            __typename: 'ChatMessage',
          },
          ...recentChats,
        ];
      }

      client.writeQuery({
        query: FETCH_RECENT_CHATS,
        variables: {
          query,
        },
        data: {
          recentChats: newList,
        },
      });
    },
    [query, recentChats]
  );

  useEffect(() => {
    fab.showFab();
    fab.setIcon(assets.icons.icMessageNew);
    fab.setAction(goToContacts);

    return () => {
      fab.hideFab();
    };
  }, []);

  useEffect(() => {
    const subscription = subscribe(EVENTS.CHAT, handleMessageReceive);

    return () => {
      subscription.unsubscribe();
    };
  }, [subscribe, handleMessageReceive]);

  return (
    <MainLayout>
      <MainLayout.Header>
        <MainLayout.Header.Left>
          <BackButton />
        </MainLayout.Header.Left>
        <MainLayout.Header.Center>
          <Text>Messaging</Text>
        </MainLayout.Header.Center>
        <MainLayout.Header.Right />
      </MainLayout.Header>
      <Container modifiers={['withGutters']}>
        <SearchBar
          value={query}
          onChange={handleQueryChange}
          placeholder="Search keywords"
        />
      </Container>
      <MainLayout.Content id="scrollableChatHistory">
        <Container>
          <InfiniteScroll
            dataLength={recentChats?.length || 0}
            next={handleFetchMore}
            hasMore={hasMore}
            loader={<Text modifiers={['block', 'center']}>Loading...</Text>}
            endMessage={
              <Text modifiers={['block', 'center']}>You've seen it all!</Text>
            }
            scrollableTarget="scrollableChatHistory"
          >
            {recentChats?.map((message) => (
              <ChatHistoryItem key={message._id} message={message} />
            ))}
          </InfiniteScroll>
        </Container>
      </MainLayout.Content>
    </MainLayout>
  );
}
