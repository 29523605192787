import { createRef, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { Container } from '../Grid';

import { FormPicker } from '../Form/FormPicker';

export const PickerWrapper = ({
  handleChange,
  children,
  noFormWrap,
  name = 'picker',
  value,
  options,
  subModal,
  inline,
}) => {
  const fieldRef = createRef();

  const handleComponentClick = useCallback(
    (event) => {
      if (fieldRef.current) {
        fieldRef.current.click();
      }
    },
    [fieldRef]
  );

  const fieldComponent = (
    <Field
      type="hidden"
      name={name}
      valueAsObject
      submitOnClose
      title="Select"
      options={options}
      component={FormPicker}
      innerRef={fieldRef}
      subModal={subModal}
      handleChange={handleChange}
    />
  );

  let wrapper;

  if (noFormWrap) {
    wrapper = fieldComponent;
  } else {
    wrapper = (
      <Formik initialValues={{ picker: value }}>
        {() => <Form>{fieldComponent}</Form>}
      </Formik>
    );
  }

  return (
    <Container modifiers={['fluid', inline && 'inline']}>
      <Container
        modifiers={['fluid', 'fullHeight', inline && 'inline']}
        onClick={handleComponentClick}
      >
        {children}
      </Container>
      {wrapper}
    </Container>
  );
};
