import { getChat } from 'graphql/utils';
import qs from 'query-string';

import urls from 'routes/urls';

export const getChatLocationDescriptor = async (message, me) => {
  const { _id, chat } = message;
  let chatData = chat;
  if (typeof chat === 'string') {
    chatData = await getChat(chat);
  }
  const otherMembers = chatData.members.filter((m) => m._id !== me._id);
  const otherMemberIds = otherMembers.map((m) => m._id);

  return {
    pathname: urls.CHAT,
    search: qs.stringify({
      memberIds: otherMemberIds,
      referredPatientId: chatData.referredPatient?._id,
    }),
    state: {
      messageId: _id,
    },
  };
};
