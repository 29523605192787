import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  minimized: () => `
    display: none;
  `,
};

const styles = () => `
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 5000;
  background: white;
  display: flex;
  flex-direction: column;

  * {
    box-sizing: border-box;
  }

  > :first-child {
    flex: 1 1 auto;
  }

  > :last-child {
    bottom: ${px2rem(40)};
    left: 10%;
    position: absolute;
    width: 80%;
    z-index: 50;
    background: rgba(0, 0, 0, 0.26);
    backdrop-filter: blur(${px2rem(16)});
    border-radius: ${px2rem(12)};
  }
`;

export default buildStyledComponent('CallWrapper', styled.div, styles, {
  modifierConfig,
});
