import assets from 'assets';
import { displayTime } from 'utils/time';
import { DIET_ITEMS, WELLNESS_ITEMS } from './constants';

export function elementHasClass(element, classNames, maxParents = 0) {
  let findIndex = 0;
  let didFind = false;
  let currentNode = element;

  // Search element
  didFind = classNames.some((className) =>
    currentNode.className.includes(className)
  );

  // Search parents
  while (!didFind && findIndex < maxParents) {
    findIndex += 1;

    if (currentNode.parentElement) {
      currentNode = currentNode.parentElement;

      didFind = classNames.some((className) =>
        currentNode.className.includes(className)
      );
    }
  }

  return didFind;
}

export function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1 ||
    window.innerWidth < 768
  );
}

export const frequencyTitle = (frequency, asObject) => {
  if (!frequency) {
    return null;
  }
  const weekdays2Strings = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  const { type, value } = frequency;
  if (!type || !value) {
    return null;
  }
  let frequencyString, onString, atString, directionString;
  if (type === 'preset') {
    const hours = value.hours;
    frequencyString = `${hours.length} x per day`;
    onString = 'Everyday';
    if (value.direction && value.direction !== 'none') {
      directionString = `${value.direction}`;
    }
    atString = hours.join(', ').toLowerCase();
  } else if (value.frequency === 'Weekly') {
    const hours = Object.values(value.weeklyTimes);
    frequencyString = `${hours?.length} x every `;
    if (value.everyWeeks === 1) {
      frequencyString += 'week';
    } else {
      frequencyString += `${value.everyWeeks} week(s)`;
    }
    onString = value.weekDays.map((day) => weekdays2Strings[day]).join(', ');
    if (value.weeklyDirection && value.weeklyDirection !== 'none') {
      directionString = `${value.weeklyDirection}`;
    }
    atString = hours?.join(', ').toLocaleLowerCase();
  } else if (value.frequency === 'Daily') {
    const hours = Object.values(value.dailyTimes);
    frequencyString = `${hours?.length} x every`;
    if (value.everyDays === 1) {
      frequencyString += 'day';
    } else {
      frequencyString += ` ${value.everyDays} day(s)`;
    }
    onString = `after ${displayTime(value.startDate, 'MM/DD/yyyy')}`;
    if (value.dailyDirection && value.dailyDirection !== 'none') {
      directionString = `${value.dailyDirection}`;
    }
    atString = hours?.join(', ').toLocaleLowerCase();
  }
  if (asObject) {
    return {
      frequency: frequencyString,
      on: onString,
      at: atString,
      direction: directionString,
    };
  } else {
    return `${frequencyString}${onString ? '\n' + onString : ''}${
      atString ? '\n' + atString : ''
    }`;
  }
};

export const alertsTitle = (alerts, type, shortDescription = false) => {
  if (!alerts || Object.keys(alerts).length === 0) {
    return null;
  }
  switch (type) {
    case 'heartRate':
      return shortDescription
        ? `>${alerts.value} bpm / <${alerts.value2} bpm`
        : `>${alerts.value} bpm, <${alerts.value2} bpm, ${alerts.triggerValue} ${alerts.triggerType}`;
    case 'bloodPressure':
      return shortDescription
        ? `>${alerts.value} sys,${alerts.value2} dia / <${alerts.value3} sys,${alerts.value4} dia`
        : `>${alerts.value} sys,${alerts.value2} dia / <${alerts.value3} sys,${alerts.value4} dia, ${alerts.triggerValue} ${alerts.triggerType}`;
    case 'weight':
      return shortDescription
        ? `${alerts.value} lbs, >${alerts.value2} lbs / ${alerts.periodValue} days`
        : `current: ${alerts.value} lbs, >${alerts.value2} lbs / ${alerts.periodValue} days`;
    case 'glucose':
      return shortDescription
        ? // ? `<${alerts.value} mg/dl / <${alerts.value2} mg/dl`
          // : `<${alerts.value} mg/dl bf meal, <${alerts.value2} mg/dl af meal, ${alerts.triggerValue} ${alerts.triggerType}`;
          `<${alerts.value} mg/dl`
        : `<${alerts.value} mg/dl , ${alerts.triggerValue} ${alerts.triggerType}`;
    case 'respiratory':
      return shortDescription
        ? `<${alerts.value} / >${alerts.value2} BPM`
        : `<${alerts.value} / >${alerts.value2} BPM, ${alerts.triggerValue} ${alerts.triggerType}`;
    case 'bloodOxygen':
      return shortDescription
        ? `>${alerts.value}%`
        : `>${alerts.value}%, ${alerts.triggerValue} ${alerts.triggerType}`;
    case 'temperature':
      return shortDescription
        ? `>${alerts.value} \u2109`
        : `>${alerts.value} \u2109, ${alerts.triggerValue} ${alerts.triggerType}`;
  }

  return `${alerts.triggerValue || ''} ${
    alerts.triggerType || ''
  }`.toLocaleLowerCase();
};

export const medicationModsTitle = (mods) => {
  if (!mods || !mods.mode) {
    return null;
  }
  switch (mods.mode) {
    case 'full':
      return '1 (full) pill';
    case 'threeQuarter':
      return '3/4 (three quarter) pill';
    case 'half':
      return '1/2 (half) pill';
    case 'oneQuarter':
      return '1/4 (one quarter) pill';
  }
  return null;
};

export const getVitalTypeAttr = (vitalType) => {
  switch (vitalType) {
    case 'heartRate':
      return {
        icon: assets.icons.icHeartRate,
        title: 'Heart Rate (bpm)',
        name: 'Heart Rate',
        unit: 'BPM\n(Beats per Min)',
        unitShort: 'bpm',
      };
    case 'glucose':
      return {
        icon: assets.icons.icGlucose,
        title: 'Glucose (mg/dL)',
        name: 'Glucose',
        unit: 'mg/dL',
      };
    case 'weight':
      return {
        icon: assets.icons.icWeight,
        title: 'Weight (lbs)',
        name: 'Weight',
        unit: 'lbs',
      };
    case 'bloodPressure':
      return {
        icon: assets.icons.icBloodPressure,
        title: 'Blood Pressure (mmHg)',
        name: 'Blood Pressure',
        unit: 'mmHg',
      };
    case 'oxygen':
    case 'bloodOxygen':
      return {
        icon: assets.icons.icOxygen,
        title: 'Oxygen (%)',
        name: 'Oxygen',
        unit: '%',
      };
    case 'breathRate':
    case 'respiratory':
      return {
        icon: assets.icons.icBreathRate,
        title: 'Breath Rate (bpm)',
        name: 'Breath Rate',
        unit: 'BPM\n(Breaths per Min)',
      };
    case 'temperature':
      return {
        icon: assets.icons.icTemperature,
        title: 'Temperature (\u2109)',
        name: 'Temperature',
        unit: '\u2109',
      };
    default:
      return {};
  }
};

export const dailyCareplan = (careplan, date = new Date()) => {};

export const validateVital = (vitalType, value, value2) => {
  if (vitalType === 'heartRate') {
    if (parseInt(value, 10) > 224 || parseInt(value, 10) < 40) {
      return `You entered ${value} BPM.`;
    }
    return true;
  }
  if (vitalType === 'bloodPressure') {
    if (parseInt(value, 10) >= 190 || parseInt(value2, 10) <= 100) {
      return `You entered ${value} mmHg and ${value2} mmHg.`;
    }
    return true;
  }
  if (vitalType === 'weight') {
    if (parseInt(value, 10) >= 450) {
      return `You entered ${value} lbs.`;
    }
    return true;
  }
  if (vitalType === 'glucose') {
    if (parseInt(value, 10) > 300 || parseInt(value, 10) < 50) {
      return `You entered ${value} mg/dL.`;
    }
    return true;
  }
  if (vitalType === 'rapiratory') {
    if (parseInt(value, 10) >= 27 || parseInt(value, 10) <= 5) {
      return `You entered ${value} breaths/min.`;
    }
    return true;
  }
  if (vitalType === 'bloodOxygen' || vitalType === 'oxygen') {
    if (parseInt(value, 10) < 90) {
      return `You entered ${value}%.`;
    }
    return true;
  }
  if (vitalType === 'temperature') {
    if (parseInt(value, 10) >= 104) {
      return `You entered ${value}\u2109.`;
    }
    return true;
  }
  return true;
};

export const getVitalValueString = (type, value, value2 = null) => {
  let title;
  switch (type) {
    case 'heartRate':
      title = `${value} bpm`;
      break;
    case 'glucose':
      title = `${value} mg/dl`;
      break;
    case 'weight':
      title = `${value} lbs`;
      break;
    case 'bloodPressure':
      title = `${value} sys / ${value2} dia`;
      break;
    case 'bloodOxygen':
      title = `${value}%`;
      break;
    case 'respiratory':
      title = `${value} BPM`;
      break;
    case 'temperature':
      title = `${value} \u2109`;
      break;
  }
  return title;
};

export const getQuestionValueString = (type, value) => {
  const { type: scaleType } = WELLNESS_ITEMS[type] || DIET_ITEMS[type];
  if (!scaleType) {
    return 'N/A';
  }
  switch (scaleType) {
    case 'lowHigh':
      switch (value) {
        case 1:
          return 'Very Low';
        case 2:
          return 'Low';
        case 3:
          return 'Medium';
        case 4:
          return 'High';
        case 5:
          return 'Very High';
      }
      break;
    case 'highLow':
      switch (value) {
        case 5:
          return 'Very Low';
        case 4:
          return 'Low';
        case 3:
          return 'Medium';
        case 2:
          return 'High';
        case 1:
          return 'Very High';
      }
      break;
    case 'badGood':
      switch (value) {
        case 1:
          return 'Very Bad';
        case 2:
          return 'Bad';
        case 3:
          return 'Medium';
        case 4:
          return 'Good';
        case 5:
          return 'Very Good';
      }
      break;
  }
  return 'N/A';
};

export const questionTypeValues = (type) => {
  const { type: scaleType } = WELLNESS_ITEMS[type] || DIET_ITEMS[type];
  if (!scaleType) {
    return ['Very Bad', 'Bad', 'Medium', 'Good', 'Very Good'];
  }
  switch (scaleType) {
    case 'lowHigh':
      return ['Very Low', 'Low', 'Medium', 'High', 'Very High'];
    case 'highLow':
      return ['Very High', 'High', 'Medium', 'Low', 'Very Low'];
    case 'badGood':
      return ['Very Bad', 'Bad', 'Medium', 'Good', 'Very Good'];
  }
  return ['Very Bad', 'Bad', 'Medium', 'Good', 'Very Good'];
};

export const uriFromPractice = (practiceName) => {
  return practiceName?.replace(/[^A-Za-z0-9]/g, '').toLowerCase() || '';
};
