import EntryItem from '../Entry';
import { WELLNESS_ITEMS, SCALE_TYPES } from 'utils/constants';
import assets from 'assets';

const Item = ({ item, ...props }) => {
  if (!item) {
    return null;
  }
  const preInfos = [
    {
      title: 'Scale',
      value: `1 to 5 - ${SCALE_TYPES[WELLNESS_ITEMS[item.id].type]}`,
    },
  ];
  return (
    <EntryItem
      icon={WELLNESS_ITEMS[item.id].icon}
      preInfos={preInfos}
      text={WELLNESS_ITEMS[item.id].title}
      frequency={item.frequency}
      alerts={item.alerts}
      alertsType={item.id}
      {...props}
    />
  );
};

export default Item;
