import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  squared: () => `
    
  `,
  round: () => `
    border-radius: ${px2rem(10)};
  `,
  'px-2': () => `
    padding-left: ${px2rem(15)};
    padding-right: ${px2rem(15)};
  `,
};

const styles = ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${px2rem(45)};
  position: relative;
  border: 1px solid #3B4C6A;
  border-radius: ${px2rem(10)};
`;

export default buildStyledComponent(
  'FormIconInput_Wrapper',
  styled.div,
  styles,
  { modifierConfig }
);
