export class Observable {
  listeners = {};

  subscribe(eventName, listener) {
    this.listeners[eventName] = this.listeners[eventName] || [];
    this.listeners[eventName].push(listener);

    return {
      unsubscribe: () => {
        const index = this.listeners[eventName].indexOf(listener);
        this.listeners[eventName].splice(index, 1);
      },
    };
  }

  publish(eventName, data) {
    this.listeners[eventName]?.forEach((cb) => {
      cb(data);
    });
  }
}

export default Observable;
