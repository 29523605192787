import { useState, useContext, useEffect } from 'react';
import moment from 'moment';

import assets from 'assets';
import {
  ScrollView,
  Row,
  Column,
  Text,
  Button,
} from '@gaz/gaz-components.public';

import { Container, TimeLabel } from 'common';
import Tabs from '../Tabs';
import IconWrapper from '../IconWrapper';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { checkTaskDue, hourToDate, toLocalTime, displayTime } from 'utils/time';
import { loadingVar } from 'graphql/cache';

export default () => {
  const [tab, setTab] = useState(null);
  const { tasks, addResponse, responses, goBack } =
    useContext(PatientHomeContext);
  const hours = tasks ? Object.keys(tasks.activity) : [];
  const isTaskDue =
    tab !== null && hours.length > 0 ? checkTaskDue(hours[tab]) : null;
  const activity =
    tab !== null && tasks?.activity && hours.length > 0
      ? tasks.activity[hours[tab]]
      : [];
  const [pastResponse, updatePastResponse] = useState(null);
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    if (!tasks || tab !== null) {
      return;
    }
    setTab(0);
  }, [tasks]);

  const addAnswer = async (answer) => {
    if (!tasks) {
      return;
    }
    loadingVar(true);
    await addResponse('activity', hourToDate(hours[tab]), {
      didTake: answer,
    });
    loadingVar(false);
    goBack();
  };

  useEffect(() => {
    if (!tasks || !responses) {
      updatePastResponse(null);
      setCanEdit(false);
      return;
    }
    const hour = new Date(hourToDate(hours[tab])).getTime() / 1000;
    const medResponse = responses.find(
      (response) =>
        response.measure === 'activity' &&
        toLocalTime(response.time).unix() === hour
    );
    if (!medResponse?.response) {
      updatePastResponse(null);
      setCanEdit(true);
      return;
    }
    updatePastResponse(medResponse.response.didTake);
    if (isTaskDue && !medResponse.response) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [responses, tab, tasks]);

  const handleEdit = () => {
    setCanEdit(true);
  };

  return (
    <Container modifiers={['flexBox', 'fullHeight', 'fluid']}>
      <Container modifiers={['flexOne', 'fluid', 'fullHeight', 'flexBox']}>
        <Container modifiers={['flexFixed']}>
          <Tabs>
            <Tabs.Header>
              <Text modifiers={['white', 'subtitle']}>Activity</Text>
              <Text modifiers={['primary', 'subtitle', 'semiBold']}>
                {isTaskDue === false ? (
                  <Text modifiers={['danger', 'subtitle', 'semiBold']}>
                    Not Due - {hours[tab]}, {moment().format('MM/DD/YYYY')}
                  </Text>
                ) : (
                  <TimeLabel />
                )}
              </Text>
            </Tabs.Header>
            <Row>
              {hours.map((hour, index) => (
                <Tabs.Tab
                  key={index}
                  onClick={() => setTab(index)}
                  modifiers={[tab === index && 'active']}
                >
                  {hour}
                </Tabs.Tab>
              ))}
            </Row>
          </Tabs>
        </Container>
        <Container modifiers={['flexFixed']}>
          <Row modifiers={['center']}>
            <Text modifiers={['darkGrey', 'subtitle']}>
              Please let us know if you have completed the following activity
            </Text>
          </Row>
        </Container>
        <ScrollView>
          {activity.map((act, i) => (
            <Row modifiers={['middle']} key={i}>
              <Column
                modifiers={[
                  'col_2',
                  'flexBox',
                  'verticalContentCenter',
                  'center',
                ]}
              >
                <IconWrapper>
                  <img
                    src={assets.icons.icWalk}
                    width="18"
                    height="18"
                    alt=""
                  />
                </IconWrapper>
              </Column>
              <Column
                modifiers={[
                  'col_10',
                  'fluid',
                  'flexBox',
                  'verticalContentCenter',
                ]}
              >
                <Text modifiers={['subtitle', 'center']}>
                  {act.activity}
                  {act.frequency?.duration
                    ? ` (${act.frequency.duration} mins)`
                    : ''}
                </Text>
              </Column>
            </Row>
          ))}
        </ScrollView>
      </Container>
      {!canEdit && (
        <Container modifiers={['flexFixed', 'fluid']}>
          <Row modifiers={['withGutters']}>
            <Container
              modifiers={[
                pastResponse === true
                  ? 'backgroundSuccess'
                  : 'backgroundDanger',
              ]}
            >
              <Text modifiers={['block', 'center', 'medium', 'white']}>
                {pastResponse === true
                  ? 'YES - Above Activity was completed'
                  : 'NO - Above Activity was not completed'}
              </Text>
            </Container>
          </Row>
        </Container>
      )}
      <Container modifiers={['footer', 'flexFixed', 'fluid']}>
        <Row modifiers={['spaceBetween']}>
          <Column modifiers={['col_2', 'flexBox', 'verticalContentCenter']}>
            <Button
              image={assets.icons.icCloseGrey}
              width={22}
              height={22}
              modifiers={['icon', 'transparent']}
              onClick={() => goBack()}
            />
          </Column>
          {!isTaskDue || canEdit ? (
            <>
              <Column modifiers={['col_5', 'flexBox', 'center']}>
                <Button
                  modifiers={[
                    isTaskDue ? 'danger' : 'disabled',
                    'widthSmall',
                    'roundCorner',
                  ]}
                  onClick={() => addAnswer(false)}
                >
                  <Text modifiers={['subtitle', 'white', 'semiBold']}>No</Text>
                </Button>
              </Column>
              <Column modifiers={['col_5', 'flexBox', 'center']}>
                <Button
                  modifiers={[
                    isTaskDue ? 'success' : 'disabled',
                    'widthSmall',
                    'roundCorner',
                  ]}
                  onClick={() => addAnswer(true)}
                >
                  <Text modifiers={['subtitle', 'white', 'semiBold']}>Yes</Text>
                </Button>
              </Column>
            </>
          ) : (
            <Column modifiers={['col_5', 'col_offset_5', 'flexBox', 'center']}>
              <Button
                modifiers={['widthSmall', 'roundCorner']}
                onClick={handleEdit}
              >
                <Text modifiers={['subtitle', 'white', 'semiBold']}>Edit</Text>
              </Button>
            </Column>
          )}
        </Row>
      </Container>
    </Container>
  );
};
