import React from 'react';

import Wrapper from './Wrapper';

export const Avatar = ({ image, user, ...otherProps }) => {
  let initial = '';
  let color = 'transparent';
  const photo = image || user?.photo?.url;

  if (!photo && user) {
    const { firstName = '', lastName = '', role, memberDesignation } = user;
    initial = ((firstName[0] || '') + (lastName[0] || '')).toUpperCase();
    switch (role) {
      case 'provider':
        if (memberDesignation === 'pharmacist') {
          color = '#00b1f1';
        } else {
          color = '#1565C0';
        }
        break;
      case 'patient':
        color = '#7030a1';
        break;
      default:
    }
  }

  return (
    <Wrapper {...otherProps} image={photo} color={color}>
      {initial}
    </Wrapper>
  );
};

export default Avatar;
