import React from 'react';

import {
  Row,
  Column,
  Text,
  Checkbox,
  Avatar,
} from '@gaz/gaz-components.public';

import { formatTitleAndName } from 'utils/string';

export default function Contact({ contact, selected, handleSelect }) {
  const contactName = formatTitleAndName(contact);
  const contactId = contact._id;

  return (
    <Row onClick={() => handleSelect(contact)}>
      <Column modifiers={['col_2', 'flexBox', 'verticalContentCenter']}>
        <Checkbox checked={contactId === selected._id} onChange={() => {}} />
      </Column>
      <Column modifiers={['col_2']}>
        <Avatar modifiers={['round']} width={48} height={48} user={contact} />
      </Column>
      <Column
        modifiers={['col_6', 'fluid', 'flexBox', 'verticalContentCenter']}
      >
        <Text modifiers={['primary']}>{contactName}</Text>
      </Column>
    </Row>
  );
}
