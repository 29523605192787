import styled from 'styled-components';

import { Container } from '@gaz/gaz-components.public';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  padding: ${px2rem(10)} ${px2rem(10)};
  background: ${theme.colors.white};
  box-shadow: ${theme.dimensions.footerBoxShadow};
`;

export default buildStyledComponent(
  'CreateNote_Footer',
  styled(Container),
  styles
);
