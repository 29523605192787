import styled from 'styled-components';
import { buildStyledComponent } from 'style';

const styles = () => `
  width: 100%;
  height: auto;
`;

export default buildStyledComponent(
  'AddAttachment_MediaPreview',
  styled(({ isVideo, ...props }) =>
    isVideo ? <video {...props} /> : <img {...props} alt="" />
  ),
  styles
);
