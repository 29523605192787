import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  font-size: ${theme.dimensions.fontSizeSmall};
  color: ${theme.colors.text};
`;

const Label = buildStyledComponent('BirthdayPicker_Label', styled.div, styles);

export default Label;
