import React, { cloneElement } from 'react';

import Wrapper from './Wrapper';
import Header from './Header';
import Tab from './Tab';

export default function Tabs({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

Tabs.Header = Header;
Tabs.Tab = Tab;
