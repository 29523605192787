import styled from 'styled-components';
import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

export default buildStyledComponent(
  'Header_Wrapper',
  styled(Container),
  () => `
    background-color: white;
    border-bottom: 1px solid #2E294E14;
  `,
);
