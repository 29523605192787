import React from 'react';
import moment from 'moment';
import { Container, Row, Text } from 'common';
import VitalItem from './VitalItem';

export default function Vitals({ handleSelectVital }) {
  return (
    <Container>
      <Row modifiers={['bottomGutters_2']}>
        <Text modifiers={['bold', 'secondaryColor']}>Past Vitals</Text>
      </Row>
      <VitalItem
        handleSelectVital={handleSelectVital}
        measurement={{ date: moment(), value: '45 bpm', danger: true }}
        vitalType="heartRate"
      />
      <VitalItem
        handleSelectVital={handleSelectVital}
        measurement={{ date: moment(), value: '140 unsp', success: true }}
        vitalType="glucose"
      />
      <VitalItem
        handleSelectVital={handleSelectVital}
        measurement={{ date: moment(), value: '198', danger: true }}
        vitalType="weight"
      />
      <VitalItem
        handleSelectVital={handleSelectVital}
        measurement={{
          date: moment(),
          value: '140 sys / 100 dia',
          danger: true,
        }}
        vitalType="bloodPressure"
      />
      <VitalItem
        handleSelectVital={handleSelectVital}
        measurement={{ date: moment(), value: '94%', success: true }}
        vitalType="oxygen"
      />
      <VitalItem
        handleSelectVital={handleSelectVital}
        measurement={{ date: moment(), value: '14', success: true }}
        vitalType="breathRate"
      />
      <VitalItem
        handleSelectVital={handleSelectVital}
        measurement={{ date: moment(), value: '96', success: true }}
        vitalType="temperature"
      />
    </Container>
  );
}
