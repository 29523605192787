import { useContext, useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useParams,
  useLocation,
} from 'react-router-dom';

import { MainLayout } from 'layouts';
import { TIMER_TYPE, TimerContext } from 'contexts/timer';
import Timer from 'compositions/Timer';

import BackButton from './BackButton';
import Careplans from './Careplans';
import CreateCareplan from './Careplans/CreateCareplan';
import Profile from './Profile';
import Dashboard from './Dashboard';
import CreateNote from './Note/CreateNote';
import Records from './Records';
import OrderMeds from './OrderMeds';
import Schedule from './Schedule';
import Alerts from './Alerts';
import Calls from './Calls';
import { ProfileHeader /* TimerHeader */ } from './Header';
import PatientHomeProvider from './Context';

const PatientHome = () => {
  const { id } = useParams();
  const { addTimer, completeTimer, setPatientId } = useContext(TimerContext);
  const location = useLocation();
  let { path, url } = useRouteMatch();
  url = url.replace(/\/$/, '');

  useEffect(() => {
    const { pathname } = location;
    if (pathname.startsWith(`${url}/careplans`)) {
      addTimer(TIMER_TYPE.CAREPLAN);
    } else if (pathname.startsWith(`${url}/notes`)) {
      addTimer(TIMER_TYPE.NOTE);
    } else if (pathname.startsWith(`${url}/order-medications`)) {
      addTimer(TIMER_TYPE.ORDER_MEDS);
    } else {
      addTimer(TIMER_TYPE.REVIEW);
    }
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      completeTimer(TIMER_TYPE.CAREPLAN);
      completeTimer(TIMER_TYPE.NOTE);
      completeTimer(TIMER_TYPE.ORDER_MEDS);
      completeTimer(TIMER_TYPE.REVIEW);
    };
  }, []);

  useEffect(() => {
    setPatientId(id);
  }, [id, setPatientId]);

  return (
    <PatientHomeProvider id={id} url={url}>
      <MainLayout hasFooter={false}>
        <MainLayout.Header>
          <MainLayout.Header.Left>
            <BackButton />
          </MainLayout.Header.Left>
          <MainLayout.Header.Center>
            <ProfileHeader />
          </MainLayout.Header.Center>
          <MainLayout.Header.Right>
            <Timer />
          </MainLayout.Header.Right>
        </MainLayout.Header>
        <MainLayout.Content>
          <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
            <Route exact path={`${path}/profile`} component={Profile} />
            <Route
              exact
              path={`${path}/careplans/create`}
              component={CreateCareplan}
            />
            <Route
              exact
              path={`${path}/careplans/create/:careplanId`}
              component={CreateCareplan}
            />
            <Route exact path={`${path}/careplans`} component={Careplans} />
            <Route exact path={`${path}/records`} component={Records} />
            <Route exact path={`${path}/notes/create`} component={CreateNote} />
            <Route
              exact
              path={`${path}/notes/create/:noteId`}
              component={CreateNote}
            />
            <Route
              exact
              path={`${path}/notes/createFromNote/:fromNote`}
              component={CreateNote}
            />
            <Route
              exact
              path={`${path}/order-medications`}
              component={OrderMeds}
            />
            <Route exact path={`${path}/alerts`} component={Alerts} />
            <Route exact path={`${path}/calls`} component={Calls} />
            <Route exact path={`${path}/schedule`} component={Schedule} />
            <Redirect to={path} />
          </Switch>
        </MainLayout.Content>
      </MainLayout>
    </PatientHomeProvider>
  );
};

export default PatientHome;
