import { Container, Text, Row } from 'common';

const SimpleItem = ({ title, value }) => (
  <Container modifiers={['fluid', 'withGutters']}>
    <Row modifiers={['smallGutters']}>
      <Text modifiers={['block', 'semiBold', 'secondary']}>{title}</Text>
    </Row>
    <Text modifiers={['secondary', 'preserveLineBreak', 'block', 'medium']}>
      {value}
    </Text>
  </Container>
);

export default SimpleItem;
