import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  shown: () => `
    transition: all 0.5s;
    transform: translateX(0);

    @media only screen and (min-width: 769px) {
      display: block;
    }
  `,
};

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  width: 65vw;
  height: 100%;
  z-index: 99;
  transform: translateX(-100%);
  transition: all 0.5s;
  position: absolute;
  overflow-y: auto;

  @media only screen and (min-width: 769px) {
    display: none;
    width: 60%;
    transform: translateX(0);
  }
`;

export const Wrapper = buildStyledComponent(
  'SideMenu_Wrapper',
  styled.div,
  styles,
  { modifierConfig }
);
