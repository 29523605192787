import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';

import { FETCH_NOTE } from 'graphql/queries';
import { READ_PATIENT_NOTE } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import ViewNote from 'pages/Notes/ViewNote';

export default () => {
  const history = useHistory();
  const { id } = useParams();
  const [readNote] = useMutation(READ_PATIENT_NOTE, {
    variables: { note: id },
  });
  const { data: { note } = {}, loading } = useQuery(FETCH_NOTE, {
    variables: { id },
  });

  useEffect(() => {
    if (note && !note.isDraft && !note.isSeen) {
      readNote();
    }
  }, [note]);

  loadingVar(loading);

  if (loading) {
    return null;
  }

  return (
    <ViewNote note={note} goBack={() => history.replace('/coordinate/note')} />
  );
};
