import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  round: () => `
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  `,
  squared: () => `
    border-radius: 8px;
  `,
};

const styles = ({ image, color, width, height }) => `
  width: ${width ? px2rem(width) : '100%'};
  height: ${height ? px2rem(height) : '100%'};
  position: relative;
  overflow: hidden;
  background: url(${image}) center no-repeat;
  background-size: cover;
  background-color: ${color};
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Avatar = buildStyledComponent('Avatar', styled.div, styles, {
  modifierConfig,
});

export default Avatar;
