import { gql } from '@apollo/client';

import fragments from './fragments';

export const FETCH_ME = gql`
  query Me {
    me {
      ...AuthUserFragment
    }
  }
  ${fragments.authUserFragment}
`;

export const GET_LOADING = gql`
  query GetLoading {
    loading @client
  }
`;

export const GET_SPLASH_LOADING = gql`
  query GetSplashLoading {
    splashLoading @client
  }
`;

export const GET_ERROR = gql`
  query GetError {
    error @client
  }
`;

export const FETCH_USER = gql`
  query fetchUser($id: String!) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${fragments.userFragment}
`;

export const CHECK_RESET_PASSWORD_TOKEN = gql`
  query checkResetPasswordToken($token: String!) {
    checkResetPasswordToken(token: $token)
  }
`;

export const FETCH_CHAT = gql`
  query fetchChat($id: String!) {
    chat(id: $id) {
      ...ChatFragment
    }
  }
  ${fragments.chatFragment}
`;

export const FETCH_PATIENTS = gql`
  query fetchPatients(
    $query: String
    $birthday: String
    $inPractice: String
    $page: Int
    $pageSize: Int
  ) {
    patients(
      query: $query
      birthday: $birthday
      inPractice: $inPractice
      page: $page
      pageSize: $pageSize
    ) {
      ...UserFragment
    }
  }
  ${fragments.userFragment}
`;

export const FETCH_DIAGNOSES = gql`
  query fetchDiagnoses($query: String, $page: Int, $pageSize: Int) {
    diagnoses(query: $query, page: $page, pageSize: $pageSize) {
      ...DiagnosisFragment
    }
  }
  ${fragments.diagnosisFragment}
`;

export const FETCH_PATIENT_DIAGNOSES = gql`
  query fetchPatientDiagnoses($patient: ID, $period: String) {
    patientDiagnoses(patient: $patient, period: $period) {
      ...DiagnosisFragment
    }
  }
  ${fragments.diagnosisFragment}
`;

export const FETCH_NOTE_ITEM_PAST_RECORDS = gql`
  query fetchNoteItemPastRecords(
    $type: String!
    $period: String!
    $patient: String!
  ) {
    noteItemPastRecords(type: $type, period: $period, patient: $patient) {
      time
      content {
        ...NoteContentFragment
      }
    }
  }
  ${fragments.noteContentFragment}
`;

export const FETCH_NOTE = gql`
  query fetchNote($id: ID!) {
    note(id: $id) {
      ...NoteFragment
    }
  }
  ${fragments.noteFragment}
`;

export const FETCH_NOTES = gql`
  query fetchNotes($patient: String, $period: String!, $status: String) {
    notes(patient: $patient, period: $period, status: $status) {
      ...NoteFragment
    }
  }
  ${fragments.noteFragment}
`;

export const FETCH_CONTACTS = gql`
  query fetchContacts(
    $query: String
    $category: String
    $page: Int
    $pageSize: Int
  ) {
    contacts(
      query: $query
      category: $category
      page: $page
      pageSize: $pageSize
    ) {
      ... on User {
        ...UserFragment
      }
      ... on Practice {
        ...PracticeFragment
      }
      ... on Group {
        ...GroupFragment
      }
    }
  }
  ${fragments.userFragment}
  ${fragments.practiceFragment}
  ${fragments.groupFragment}
`;

export const FETCH_LEADS = gql`
  query fetchLeads(
    $query: String
    $category: String
    $page: Int
    $pageSize: Int
  ) {
    leads(
      query: $query
      category: $category
      page: $page
      pageSize: $pageSize
    ) {
      ...LeadFragment
    }
  }
  ${fragments.leadFragment}
`;

export const FETCH_INDIVIDUAL_INVITES = gql`
  query fetchIndividualInvites(
    $received: Boolean!
    $category: String!
    $page: Int
    $pageSize: Int
  ) {
    individualInvites(
      received: $received
      category: $category
      page: $page
      pageSize: $pageSize
    ) {
      received
      category
      invites {
        ...InviteFragment
      }
      total
    }
  }
  ${fragments.inviteFragment}
`;

export const FETCH_ALL_INVITES = gql`
  query fetchAllInvites($page: Int, $pageSize: Int) {
    allInvites(page: $page, pageSize: $pageSize) {
      received
      category
      invites {
        ...InviteFragment
      }
      total
    }
  }
  ${fragments.inviteFragment}
`;

export const FETCH_ADMIN_PRACTICE_INVITES = gql`
  query fetchAdminPracticeInvites {
    adminPracticeInvites {
      practice {
        ...PracticeFragment
      }
      totalReceived
      totalSent
    }
  }
  ${fragments.practiceFragment}
`;

export const FETCH_ADMIN_GROUP_INVITES = gql`
  query fetchAdminGroupInvites {
    adminGroupInvites {
      group {
        ...GroupFragment
      }
      totalReceived
      totalSent
    }
  }
  ${fragments.groupFragment}
`;

export const FETCH_PRACTICE_DETAILS = gql`
  query fetchPracticeDetails($id: ID!) {
    practiceDetails(id: $id) {
      ...PracticeDetailsFragment
    }
  }
  ${fragments.practiceDetailsFragment}
`;

export const FETCH_GROUP_DETAILS = gql`
  query fetchGroupDetails($id: ID!) {
    groupDetails(id: $id) {
      ...GroupDetailsFragment
    }
  }
  ${fragments.groupDetailsFragment}
`;

export const FETCH_GROUP_MEMBER_LEADS = gql`
  query groupMemberLeads($id: ID!, $isPractice: Boolean!, $query: String) {
    groupMemberLeads(id: $id, isPractice: $isPractice, query: $query) {
      ...UserProfileFragment
    }
  }
  ${fragments.userProfileFragment}
`;

export const SEARCH_MEDICATIONS = gql`
  query searchMedications($query: String!) {
    searchMedications(query: $query) {
      name
      strength {
        unit
        value
      }
      dosageForm
      ndc
      route
    }
  }
`;

export const FETCH_PATIENT_PAST_MEDICATIONS = gql`
  query fetchPatientPastMedications($patient: ID!, $period: String!) {
    patientPastMedications(patient: $patient, period: $period) {
      name
      strength {
        unit
        value
      }
      dosageForm
      ndc
      route
      frequency
      mods
    }
  }
`;

export const PATIENT_CP_ITEMS_HISTORY = gql`
  query patientCPItemsHistory {
    patientCPItemsHistory {
      activities {
        name
        frequency {
          ...FrequencyFragment
        }
        alerts {
          ...BasicAlertsFragment
        }
      }
      medications
    }
  }
  ${fragments.frequencyFragment}
  ${fragments.basicAlertsFragment}
`;

export const INSTANT_CALL = gql`
  query instantCall($user: String!, $referredPatient: String) {
    instantCall(user: $user, referredPatient: $referredPatient) {
      _id
      status
      callType
      isPSTN
      token
      referredPatient {
        ...UserProfileFragment
      }
    }
  }
  ${fragments.userProfileFragment}
`;

export const APPOINTMENT_CALL = gql`
  query appointmentCall($appointment: ID!) {
    appointmentCall(appointment: $appointment) {
      _id
      status
      callType
      isPSTN
      token
      appointment
      referredPatient {
        ...UserProfileFragment
      }
    }
  }
  ${fragments.userProfileFragment}
`;

export const PSTN_CALL = gql`
  query pstnCall($user: ID!, $number: String!, $type: String!) {
    pstnCall(user: $user, number: $number, type: $type) {
      _id
      status
      callType
      isPSTN
      token
      referredPatient {
        ...UserProfileFragment
      }
    }
  }
  ${fragments.userProfileFragment}
`;

export const FETCH_MONTHLY_PATIENT_STATUS = gql`
  query monthlyPatientStatus($year: Int!, $month: Int!) {
    monthlyPatientStatus(year: $year, month: $month) {
      totalPatients
      totalSeconds
      averageSeconds
    }
  }
`;

export const FETCH_PROVIDER_REPORT = gql`
  query providerReport($year: Int!, $month: Int!) {
    providerReport(year: $year, month: $month) {
      total
      items {
        subTotal
        lastSeen
        patient {
          ...UserFragment
        }
      }
    }
  }
  ${fragments.userFragment}
`;

export const FETCH_BILLABLE_READING_PATIENTS = gql`
  query billableReadingPatients($year: Int!, $month: Int!) {
    billableReadingPatients(year: $year, month: $month) {
      therapeutic
      physiologic
      physBillable
      theraBillable
      patient {
        ...UserFragment
      }
    }
  }
  ${fragments.userFragment}
`;

export const FETCH_PATIENT_REPORT = gql`
  query patientReport($year: Int!, $month: Int!, $patient: ID!) {
    patientReport(year: $year, month: $month, patient: $patient) {
      total
      records {
        _id
        totalTime
        isManual
        dscription
        startedAt
        endedAt
        provider {
          ...UserFragment
        }
        patient
        items {
          _id
          startedAt
          endedAt
          duration
          entityId
          type
          isPSTN
          scheduled
          participants {
            ...UserFragment
          }
          referredPatient {
            ...UserFragment
          }
        }
      }
    }
  }
  ${fragments.userFragment}
`;

export const FETCH_PATIENT_LAST_SEEN = gql`
  query patientLastSeen($patient: ID!) {
    patientLastSeen(patient: $patient)
  }
`;

export const FETCH_PATIENT_ACTIVE_CAREPLAN = gql`
  query fetchPatientActiveCareplan($patient: ID) {
    activeCareplan(patient: $patient) {
      ...CareplanFragment
    }
  }
  ${fragments.careplanFragment}
`;

export const FETCH_PATIENT_CAREPLANS = gql`
  query fetchPatientCareplans($patient: ID, $period: String, $status: String) {
    patientCareplans(patient: $patient, period: $period, status: $status) {
      ...CareplanFragment
    }
  }
  ${fragments.careplanFragment}
`;

export const FETCH_APPOINTMENTS = gql`
  query fetchAppointments($from: Date!, $to: Date!, $patient: ID) {
    appointments(from: $from, to: $to, patient: $patient) {
      ...AppointmentFragment
    }
  }
  ${fragments.appointmentFragment}
`;

export const FETCH_RECENT_CHATS = gql`
  query fetchRecentChats($query: String!, $offset: Int, $limit: Int) {
    recentChats(query: $query, offset: $offset, limit: $limit) {
      _id
      chat {
        _id
        members {
          _id
          firstName
          lastName
          role
          memberDesignation
          photo {
            url
          }
        }
        referredPatient {
          _id
          firstName
          lastName
        }
        unreadCount
      }
      text
      updatedAt
    }
  }
`;

export const FETCH_UNREAD_MESSAGE_COUNT = gql`
  query fetchUnreadMessageCount {
    unreadMessageCount
  }
`;

export const FETCH_PHARMACY_SUMMARY = gql`
  query fetchPharmacySummary {
    pharmacySummary {
      orders
      processing
      status
    }
  }
`;

export const FETCH_PHARMACY_ORDERS = gql`
  query fetchPharmacyOrders($period: String) {
    pharmacyOrders(period: $period) {
      ...MedicationOrderFragment
    }
  }
  ${fragments.medicationOrderFragment}
`;

export const FETCH_PATIENT_ORDERS = gql`
  query fetchPatientMedicationOrders($patient: ID, $period: String) {
    patientMedicationOrders(patient: $patient, period: $period) {
      ...MedicationOrderFragment
    }
  }
  ${fragments.medicationOrderFragment}
`;

export const FETCH_PROVIDER_ORDERS = gql`
  query fetchProviderMedicationOrders($period: String) {
    providerMedicationOrders(period: $period) {
      ...MedicationOrderFragment
    }
  }
  ${fragments.medicationOrderFragment}
`;

export const FETCH_OPERATION_SCHEDULE = gql`
  query fetchOperationSchedule {
    operationSchedule {
      ...ScheduleFragment
    }
  }
  ${fragments.scheduleFragment}
`;

export const FETCH_AVAILABLE_TIMES = gql`
  query fetchProviderAvailableTimes(
    $provider: ID!
    $from: Date!
    $to: Date!
    $offset: Int
  ) {
    availableTimes(provider: $provider, from: $from, to: $to, offset: $offset) {
      start
      end
    }
  }
`;

export const FETCH_CAREPLAN_RESPONSE = gql`
  query fetchPatientCareplanResponse($patient: ID!, $date: Date) {
    todayResponse(patient: $patient, date: $date) {
      ...CareplanResponseFragment
    }
  }
  ${fragments.careplanResponseFragment}
`;

export const FETCH_PATIENT_PROGRESS = gql`
  query fetchPatientProgress($patient: ID!) {
    patientProgress(patient: $patient) {
      ...ProgressFragment
    }
  }
  ${fragments.progressFragment}
`;

export const FETCH_PATIENT_RESPONSES = gql`
  query fetchPatientResponses($patient: ID!, $from: Date, $to: Date) {
    patientResponses(patient: $patient, from: $from, to: $to) {
      responses {
        ...CareplanResponseFragment
      }
      careplans {
        ...CareplanFragment
      }
    }
  }
  ${fragments.careplanResponseFragment}
  ${fragments.careplanFragment}
`;

export const GET_PHONE_VERIFICATION_STATUS = gql`
  query getPhoneVerificationStatus {
    phoneVerifyStatus {
      mobile
      work
      home
    }
  }
`;

export const FETCH_CAREPLAN = gql`
  query fetchPatientCareplan($id: ID!) {
    careplan(id: $id) {
      ...CareplanFragment
    }
  }
  ${fragments.careplanFragment}
`;

export const FETCH_ALERTS = gql`
  query fetchProviderAlerts($period: String) {
    providerAlerts(period: $period) {
      user {
        ...UserProfileFragment
      }
      triggerTime
      unseen
    }
  }
  ${fragments.userProfileFragment}
`;

export const FETCH_PATIENT_ALERTS = gql`
  query fetchPatientAlerts($patient: ID!, $period: String) {
    patientAlerts(patient: $patient, period: $period) {
      _id
      user {
        ...UserProfileFragment
      }
      alerts
      measure
      subType
      triggerTime
      isSeen
    }
  }
  ${fragments.userProfileFragment}
`;

export const FETCH_RECENT_PATIENTS = gql`
  query fetchRecentPatients {
    recentPatients {
      ...RecentPatientFragment
    }
  }
  ${fragments.recentPatientFragment}
`;

export const FETCH_CALL_HISTORY = gql`
  query fetchCallHistory($user: ID, $period: String) {
    callHistory(user: $user, period: $period) {
      ...CallHistoryFragment
    }
  }
  ${fragments.callHistoryFragment}
`;

export const FETCH_ALL_NEW_ITEMS_COUNT = gql`
  query fetchAllNewItemsCount {
    allNewItemsCount {
      note
      careplan
      coordinate
      prescribe
      alert
      call
      invite
      report
    }
  }
`;

export const FETCH_NEW_INVITE_COUNT = gql`
  query fetchNewInviteCount {
    inviteCount
  }
`;

export const FETCH_NEW_REPORT_COUNT = gql`
  query fetchNewReportCount {
    reportCount
  }
`;

export const FETCH_NEW_CAREPLAN_COUNT = gql`
  query fetchNewCareplanCount {
    careplanCount
  }
`;

export const FETCH_NEW_COORDINATE_COUNT = gql`
  query fetchNewCoordinateCount {
    coordinateCount
  }
`;

export const FETCH_NEW_NOTE_COUNT = gql`
  query fetchNewNoteCount {
    noteCount
  }
`;

export const FETCH_NEW_ALERT_COUNT = gql`
  query fetchNewAlertCount {
    alertCount
  }
`;

export const FETCH_NEW_CALL_COUNT = gql`
  query fetchNewCallCount {
    callCount
  }
`;

export const FETCH_NEW_PRESCRIBE_COUNT = gql`
  query fetchNewPrescribeCount {
    prescribeCount
  }
`;

export const FETCH_MONTHLY_PATIENT_READINGS = gql`
  query fetchMonthlyPatientReadings($year: Int!, $month: Int!, $patient: ID!) {
    monthlyPatientReadings(year: $year, month: $month, patient: $patient) {
      time
      physiologic
      therapeutic
    }
  }
`;

export const FETCH_MONTHLY_BILLABLE_SUMMARY = gql`
  query fetchMonthlyBillableSummary($year: Int!, $month: Int!) {
    monthlyProviderBillableSummary(year: $year, month: $month) {
      time
      physiologic
      therapeutic
    }
  }
`;

export const FETCH_PAYMENT_METHODS = gql`
  query fetchPaymentMethods($patient: ID!) {
    paymentMethods(patient: $patient) {
      isDefault
      cardType
      last4Digits
    }
  }
`;

export const FETCH_DIRECT_MESSAGES = gql`
  query directMessages($period: String!) {
    directMessages(period: $period) {
      ...DirectMessageFragment
    }
  }
  ${fragments.directMessageFragment}
`;

export const FETCH_DIRECT_MESSAGE = gql`
  query directMessage($id: ID!) {
    directMessage(id: $id) {
      ...DirectMessageFragment
    }
  }
  ${fragments.directMessageFragment}
`;

export const REQUEST_DIRECT_MESSAGE_ATTACHMENT_URL = gql`
  query requestDirectMessageAttachmentUrl($id: ID!) {
    requestDirectMessageAttachmentUrl(id: $id)
  }
`;

export const FETCH_EFAX_MESSAGES = gql`
  query eFaxMessages($period: String!) {
    eFaxMessages(period: $period) {
      ...EFaxMessageFragment
    }
  }
  ${fragments.eFaxMessageFragment}
`;

export const FETCH_MEDICAL_LICENSES = gql`
  query fetchMedicalLicenses {
    medicalLicenses {
      ...MedicalLicenseFragment
    }
  }
  ${fragments.medicalLicenseFragment}
`;

export const FETCH_SPECIALTIES = gql`
  query fetchSpecialties {
    specialties
  }
`;

export const FETCH_DIRECT_MESSAGE_REPLY_ADDRESSES = gql`
  query FetchDirectMessageReplyAddresses {
    directMessageReplyAddresses {
      firstName
      lastName
      email
    }
  }
`;
