import React from 'react';
import { useHistory } from 'react-router-dom';

export const ErrorPage = () => {
  let history = useHistory();

  return (
    <div>
      <p>Something went wrong! Please try again later.</p>

      <button type="button" onClick={history.goBack}>
        Go back
      </button>
    </div>
  );
};

export default ErrorPage;
