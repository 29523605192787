import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  bgWhiteSmoke: ({ theme }) => `
    background-color: ${theme.colors.white_1};
  `,
};

const styles = ({ theme }) => `
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

const Content = buildStyledComponent(
  'SimpleLayout_Content',
  styled.div,
  styles,
  {
    modifierConfig,
  }
);

export default Content;
