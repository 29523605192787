import styled, { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => css`
  width: ${px2rem(60)};
  height: ${px2rem(60)};

  animation: ${theme.animations.spin} 1.5s linear infinite;
`;

export default buildStyledComponent('Loader_Icon', styled.img, styles);
