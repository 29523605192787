import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = () => `
  flex: 1;
  margin-left: 10px;
`;

export default buildStyledComponent('Checkbox__Text', styled.div, styles);
