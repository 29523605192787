import { useMemo } from 'react';
import Wrapper from './Wrapper';

const ALL_DAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const ALL_DAYS_2C = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

export const WeekdaysPicker = ({
  handleSelect,
  selectedDays,
  availableDays,
  twoDayStyle,
}) => {
  const days = useMemo(
    () => (twoDayStyle ? ALL_DAYS_2C : ALL_DAYS),
    [twoDayStyle]
  );
  return (
    <Wrapper>
      {days.map((day, index) => {
        const isSelected = selectedDays.includes(index);
        const isAvailable =
          !availableDays || (!isSelected && availableDays.includes(index));
        const status = isSelected
          ? 'selected'
          : isAvailable
          ? null
          : 'disabled';
        return (
          <Wrapper.Day
            key={index}
            modifiers={[twoDayStyle && 'roundRect', status]}
            onClick={() => handleSelect(index, !isSelected)}
          >
            {day}
          </Wrapper.Day>
        );
      })}
    </Wrapper>
  );
};
