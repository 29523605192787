import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { loadingVar } from 'graphql/cache';
import { FETCH_USER } from 'graphql/queries';
import { AuthContext } from 'contexts/auth';
import useProgress from './progress';
import useResponse from './response';
import useCareplan from './careplan';

export const PatientHomeContext = createContext();

const PatientHomeProvider = ({ id, url, children }) => {
  const history = useHistory();
  let backHandlers = useRef([]);
  const [patient, setPatient] = useState(null);
  const { me: provider } = useContext(AuthContext);
  const [practice, setPractice] = useState({});
  const [rightHeaderComponent, setRightHeaderComponent] = useState(null);
  const [records, setRecords] = useState({
    notes: { period: '3m' },
    careplans: { period: '3m' },
    medications: { period: '3m' },
    diagnosis: { period: '3m' },
  });
  const {
    tasks,
    activeCareplan,
    isCPExpired,
    careplans,
    fetchActiveCareplan,
    fetchCareplans,
  } = useCareplan({ patient });
  const { progress, fetchProgress } = useProgress({ patient });
  const { responses, addResponse, fetchResponses } = useResponse({ patient });

  const setBackHandler = (handler, force) => {
    if (force) {
      backHandlers.current = [handler];
    } else {
      if (typeof handler === 'string' && backHandlers.current.length > 0) {
        const lastBackUrl =
          backHandlers.current[backHandlers.current.length - 1];
        if (lastBackUrl === handler) {
          return;
        }
      }
      backHandlers.current.push(handler);
    }
  };

  const goBack = async () => {
    if (backHandlers.current.length === 0) {
      history.goBack();
    } else {
      const backHandler = backHandlers.current.pop();
      const backUrl =
        typeof backHandler === 'function' ? await backHandler() : backHandler;
      if (backUrl !== false) {
        history.replace(`${url}/${backUrl}`);
      }
    }
  };

  const goPage = (page, isPushing) => {
    const link = `${url}/${page}`;
    if (isPushing) {
      history.push(link);
    } else {
      history.replace(link);
    }
  };

  useQuery(FETCH_USER, {
    variables: { id },
    onCompleted: (data) => {
      setPatient(data.user);
      loadingVar(false);
    },
    onError: () => {
      loadingVar(false);
    },
  });

  useEffect(() => {
    if (patient) {
      fetchActiveCareplan();
      fetchResponses();
      fetchProgress();
    }
  }, [patient]);

  useEffect(() => {
    setPractice(provider.activeProviderPractice?.practice);
  }, [provider]);

  useEffect(() => {
    if (!patient) {
      loadingVar(true);
    } else {
      loadingVar(false);
    }
  }, [patient]);

  return (
    <PatientHomeContext.Provider
      value={{
        patient,
        provider,
        practice,
        activeCareplan,
        isCPExpired,
        careplans,
        tasks,
        fetchActiveCareplan,
        fetchCareplans,
        goBack,
        setBackHandler,
        goPage,
        rightHeaderComponent,
        setRightHeaderComponent,
        records,
        setRecords,
        progress,
        fetchProgress,
        responses,
        addResponse,
        fetchResponses,
      }}
    >
      {children}
    </PatientHomeContext.Provider>
  );
};

export default PatientHomeProvider;
