import {
  PieChart as Chart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
} from 'recharts';

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    color,
    payload,
  } = props;
  const fontSize = innerRadius * 0.5;
  const startGradColor = `${color}33`;
  const endGradColor = `${color}ff`;

  return (
    <g>
      <defs>
        <linearGradient id="grad" gradientTransform="rotate(90)">
          <stop offset="5%" stopColor={startGradColor} />
          <stop offset="95%" stopColor={endGradColor} />
        </linearGradient>
      </defs>
      <text
        x={cx}
        y={cy}
        dy={fontSize * 0.4}
        textAnchor="middle"
        fill={color}
        fontSize={fontSize}
        fontWeight="bold"
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill="url('#grad')"
      />
      <circle cx={'30%'} cy={'85%'} r={8} fill={color} stroke="none" />
      <text
        x={'30%'}
        y={'85%'}
        dx={30}
        dy={6}
        textAnchor="middle"
        fill={'#3B4C6A'}
        fontSize={16}
        fontWeight="bold"
      >
        Yes
      </text>
      <circle cx={'55%'} cy={'85%'} r={8} fill={'#D2D2D24D'} stroke="none" />
      <text
        x={'55%'}
        y={'85%'}
        dx={30}
        dy={6}
        textAnchor="middle"
        fill={'#3B4C6A'}
        fontSize={16}
        fontWeight="bold"
      >
        No
      </text>
    </g>
  );
};

export const PieChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <Chart width={'100%'} height={'100%'}>
        <Pie
          activeIndex={0}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="40%"
          startAngle={90}
          endAngle={-270}
          innerRadius={'60%'}
          outerRadius={'80%'}
          dataKey="value"
          fill="#D2D2D24D"
        />
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} color={entry.color} />
        ))}
      </Chart>
    </ResponsiveContainer>
  );
};
