import { useState } from 'react';
import styled from 'styled-components';
import { Container, HR, Row, Text, Button } from '@gaz/gaz-components.public';

import { DESKTOP_VIEWPORT_WIDTH } from 'utils/constants';
import { buildStyledComponent, px2rem } from 'style';

const PERIODS = {
  all: 'All',
  today: 'Today',
  '1w': '1 Week',
  '2w': '2 Weeks',
  '1m': '1 Month',
  '3m': '3 Months',
  '6m': '6 Months',
  '1y': '1 Year',
};

const PeriodOption = buildStyledComponent(
  'PeriodOption',
  styled(({ value, title, handleClick, selected, ...props }) => (
    <Button
      onClick={() => {
        handleClick(value);
      }}
      modifiers={[
        'noCaps',
        'noShadow',
        'small',
        selected ? 'outlinePrimary' : 'outlineLight',
      ]}
      {...props}
    >
      {title}
    </Button>
  )),
  ({ theme }) => `
    margin: ${theme.dimensions.padding_1};
  `
);

const modifierConfig = {
  active: ({ theme }) => `
    background-color: ${theme.colors.transparent};
    border-color: ${theme.colors.white};
    color: ${theme.colors.white};
  `,
};

const styles = ({ theme }) => `
  min-width: ${px2rem(85)};
  text-align: center;


  >:first-child {
    width: 100%;
  }

  > :last-child {
    position: fixed;
    width: 100vw;
    left: 0;
    margin-top: ${theme.dimensions.padding_1};
    z-index: 10;
    > :first-child {
      background-color: ${theme.colors.white};
      box-shadow: ${theme.dimensions.boxShadow_2};
      border-radius: ${theme.dimensions.borderRadius_1};
    }

    @media only screen and (min-width: 769px) {
      max-width: ${DESKTOP_VIEWPORT_WIDTH};
      margin: auto;
      left: calc((100vw - ${DESKTOP_VIEWPORT_WIDTH}) / 2);
    }
  }
`;

export const PeriodPicker = buildStyledComponent(
  'PeriodPicker',
  styled(({ value, handleSelect, icon, actionTitle, ...props }) => {
    const [selectedOption, setSelectedOption] = useState(
      PERIODS[value] ? value : 'all'
    );
    const [showOptions, setShowOptions] = useState(false);

    const options = Object.keys(PERIODS).map((key) => (
      <PeriodOption
        key={key}
        value={key}
        title={PERIODS[key]}
        handleClick={() => {
          setSelectedOption(key);
          handleSelect(key);
          setShowOptions(false);
        }}
        selected={key === selectedOption}
      />
    ));

    return (
      <Container modifiers={['fluid']} {...props}>
        <Button
          modifiers={[
            'outlineLight',
            'small',
            'noCaps',
            'roundCorner',
            icon && 'textIcon',
          ]}
          onClick={() => setShowOptions(!showOptions)}
          image={icon}
        >
          {PERIODS[selectedOption]}
        </Button>
        <Container modifiers={[!showOptions && 'noDisplay']}>
          <Container>
            <Text style={{ margin: 10 }} modifiers={['block', 'bold', 'small']}>
              {actionTitle ? actionTitle : 'TIME PERIOD'}
            </Text>
            <HR />
            <Row modifiers={['spaceAround']}>{options}</Row>
          </Container>
        </Container>
      </Container>
    );
  }),
  styles,
  {
    modifierConfig,
  }
);

export default PeriodPicker;
