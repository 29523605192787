import { buildStyledComponent } from 'style';
import styled from 'styled-components';

const styles = ({ backgroundImage }) => `
  width: 100vw;
  height: 100vh;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;

const Image = buildStyledComponent('MediaViewer_Image', styled.div, styles);

export default Image;
