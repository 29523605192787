import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  'height_95%': ({ theme }) => `
    height: 95%;
    top: 2.5%;
  `,
  padding_1: ({ theme }) => `
    height: calc(100% - ${theme.dimensions.padding_1} * 2);
    top: ${theme.dimensions.padding_1};
  `,
  padding_2: ({ theme }) => `
    height: calc(100% - ${theme.dimensions.padding_2} * 2);
    top: ${theme.dimensions.padding_2};
  `,
};

const styles = ({ theme }) => `
  position: absolute;
  right: 0;
  top: 0;
  width: ${px2rem(2)};
  height: 100%;
  border-right: solid 1px ${theme.colors.gray7};
`;

export const VR = buildStyledComponent('VR', styled.div, styles, {
  modifierConfig,
});

export default VR;
