import { useState } from 'react';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'style';

import { Button, Container, Popover, Row } from 'common';

const Wrapper = buildStyledComponent(
  'Wrapper',
  styled(Container),
  ({}) => `
    font-size: ${px2rem(16)};

    > * > * > :first-child {
      margin-top: ${px2rem(20)};
      margin-bottom: ${px2rem(15)};

      input:nth-child(2) {
        flex-grow: 1;
      }
    }
  `
);

export const CreditCardInput = ({ handleAdd, handleClose }) => {
  const [number, updateNumber] = useState();
  const [exp, updateExp] = useState();
  const [cvc, updateCVC] = useState();

  const handleChange = ({ target }, updateValue) => {
    updateValue(target.value);
  };

  const {
    wrapperProps,
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const handleDone = () => {
    handleAdd({
      type: meta.cardType.type,
      number,
      exp,
      cvc,
    });
  };

  return (
    <Wrapper>
      <Popover open overlayClose onClose={handleClose}>
        <PaymentInputsWrapper {...wrapperProps}>
          <svg {...getCardImageProps({ images })} />
          <input
            {...getCardNumberProps({
              onChange: (e) => handleChange(e, updateNumber),
            })}
          />
          <input
            {...getExpiryDateProps({
              onChange: (e) => handleChange(e, updateExp),
            })}
          />
          <input
            {...getCVCProps({ onChange: (e) => handleChange(e, updateCVC) })}
          />
        </PaymentInputsWrapper>
        <Container>
          <Row modifiers={['center']}>
            <Button
              modifiers={['primary', meta.error && 'disabled']}
              onClick={handleDone}
            >
              Add
            </Button>
          </Row>
        </Container>
      </Popover>
    </Wrapper>
  );
};
