import { useState, useCallback, useEffect } from 'react';
import {
  Row,
  Column,
  ScrollView,
  Text,
  Button,
} from '@gaz/gaz-components.public';

import { Container, Tabs, Popover } from 'common';
import Item from './Item';
import Instruction from './Instruction';
import assets from 'assets';

const Main = ({
  items,
  updateItems,
  handleAdd,
  handleShowPrevious,
  handleSave,
  handleClose,
}) => {
  const [deletingItem, setDeletingItem] = useState();
  const [editingItem, setEditingItem] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [activeItems, updateActiveItems] = useState({});
  const [hasIncompleteItem, updateHasIncompleteItem] = useState(false);
  useEffect(() => {
    const newItems = {};
    let hasIncomplete = false;
    Object.values(items).forEach((item) => {
      if (!item.active) {
        return;
      }
      if (!item.frequency) {
        hasIncomplete = true;
      }
      newItems[item.id] = item;
    });
    updateActiveItems(newItems);
    updateHasIncompleteItem(hasIncomplete);
  }, [items]);

  const onRemove = useCallback((item) => {
    setShowDeleteModal(true);
    setDeletingItem(item);
  }, []);

  const onConfirmDelete = () => {
    deletingItem.active = false;
    setShowDeleteModal(false);
    updateItems({ ...items });
  };

  const onEditItem = useCallback((item) => {
    setEditingItem(item);
  }, []);

  const handleSaveInstruction = (item, frequency, alerts) => {
    item.frequency = frequency;
    item.alerts = alerts;
    updateItems({ ...items });
  };

  const handleGroupFrequency = (item, groupedItems, frequency) => {
    item.frequency = frequency;
    groupedItems.forEach((itemId) => {
      items[itemId].frequency = frequency;
      items[itemId].active = true;
    });
    updateItems({ ...items });
  };

  const selectTab = (tab) => {
    if (tab === 1) {
      handleShowPrevious();
    }
  };

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Column modifiers={['col']}>
            <Text modifiers={['bold', 'block']}>Diet</Text>
            <Text modifiers={['muted', 'medium', 'block']}>
              Create diet care plan (CP)
            </Text>
          </Column>
          <Button
            modifiers={['success', 'roundCorner', 'widthXSmall', 'noShadow']}
            onClick={handleAdd}
          >
            + ADD
          </Button>
        </Row>
      </Container>
      <Container
        modifiers={[
          'flexBox',
          'flexOne',
          'fullHeight',
          'backgroundWhite',
          'fluid',
        ]}
      >
        <Container modifiers={['flexFixed', 'topGutters_1']}>
          <Tabs current={activeTab} onChange={selectTab} noSeperator>
            <Tabs.Tab
              title="Recently added"
              modifiers={['xSmallPadding', 'noTab']}
            />
            <Tabs.Tab title="Previous CP Diet" modifiers={['xSmallPadding']} />
          </Tabs>
        </Container>
        <ScrollView>
          {Object.values(activeItems)?.length > 0 ? (
            Object.values(activeItems).map((item) => (
              <Item
                item={item}
                key={item.id}
                onRemove={() => {
                  onRemove(item);
                }}
                onEdit={() => {
                  onEditItem(item);
                }}
              />
            ))
          ) : (
            <Row modifiers={['fullHeight', 'middle', 'center']}>
              <Container>
                <img src={assets.icons.icNoDiet} />
                <Row modifiers={['withGutters']}>
                  <Text>
                    There are no care plan diet tasks. Please select from
                    previous diet plans or press add to create a new diet plan.
                  </Text>
                </Row>
              </Container>
            </Row>
          )}
        </ScrollView>
      </Container>
      <Container modifiers={['flexFixed', 'footer']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Button
            image={assets.icons.icCloseGray}
            modifiers={['icon', 'transparent']}
            onClick={handleClose}
          />
          <Button
            modifiers={[
              hasIncompleteItem && 'disabled',
              'widthMedium',
              'roundCorner',
            ]}
            disabled={hasIncompleteItem}
            onClick={handleSave}
          >
            Save
          </Button>
        </Row>
      </Container>
      <Popover
        open={showDeleteModal}
        onConfirm={onConfirmDelete}
        onClose={() => setShowDeleteModal(false)}
        footer
      >
        <Text modifiers={['block', 'center']}>
          Are you sure want to delete this diet?
        </Text>
      </Popover>
      {editingItem && (
        <Instruction
          item={editingItem}
          items={activeItems}
          handleClose={() => setEditingItem(null)}
          handleDone={handleSaveInstruction}
          handleGrouping={handleGroupFrequency}
        />
      )}
    </Container>
  );
};

export default Main;
