import { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';

import {
  Row,
  Column,
  Checkbox,
  Text,
  Button,
  BackButton,
  SearchBar,
  Avatar,
} from '@gaz/gaz-components.public';
import { ScrollView, Container, Modal } from 'common';
import assets from 'assets';
import { FETCH_CONTACTS } from 'graphql/queries';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import { formatTitleAndName } from 'utils/string';
import { AddItem } from './Item';
import { AuthContext } from 'contexts/auth';

const Add = ({ members, handleAdd, handleClose }) => {
  const [selectedMembers, updateSelectedMembers] = useState({});
  const [fetchContacts, { data: { contacts: searchResults } = {} }] =
    useLazyQuery(FETCH_CONTACTS);
  const searchQuery = useRef('');
  const { me } = useContext(AuthContext);

  const handleSearch = useCallback(
    debounce((query) => {
      fetchContacts({
        variables: {
          query,
          category: 'providers',
          page: 0,
          pageSize: 100,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchContacts]
  );

  const handleQueryChange = (value) => {
    searchQuery.current = value;
    handleSearch(value);
  };

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const handleDone = () => {
    handleAdd(selectedMembers);
    handleClose();
  };

  const handleRemoveMember = (member) => {
    delete selectedMembers[member._id];
    updateSelectedMembers({ ...selectedMembers });
  };

  const toggleSelection = (member) => {
    selectedMembers[member._id]
      ? delete selectedMembers[member._id]
      : (selectedMembers[member._id] = member);
    updateSelectedMembers({ ...selectedMembers });
  };

  useEffect(() => {
    if (!members) {
      updateSelectedMembers({});
      return;
    }
    const newMembers = {};
    members.forEach((member) => (newMembers[member.user._id] = member.user));
    updateSelectedMembers(newMembers);
  }, [members]);

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Search and Add Care Team</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
          <Container modifiers={['padding_2', 'flexFixed']}>
            <SearchBar
              onChange={handleQueryChange}
              autoFocus
              placeholder={'Search Providers'}
            />
          </Container>
          <Container modifiers={['flexFixed']}>
            <Text modifiers={['block', 'bold', 'medium']}>Search Result</Text>
          </Container>
          <ScrollView>
            <Row
              onClick={() => toggleSelection(me)}
              modifiers={['spaceBetween']}
            >
              <Column modifiers={'col'}>
                <Row modifiers={['middle']}>
                  <Avatar
                    modifiers={['squared']}
                    user={me}
                    width={40}
                    height={40}
                  />
                  <Text modifiers={['block']}>
                    {formatTitleAndName(me)} (me)
                  </Text>
                </Row>
              </Column>
              <Checkbox
                dark={true}
                modifiers={['small', 'margin_1']}
                checked={selectedMembers[me._id]}
                onChange={() => {}}
              />
            </Row>
            {!!searchResults &&
              searchResults.map((provider) => (
                <Row
                  onClick={() => toggleSelection(provider)}
                  modifiers={['spaceBetween']}
                  key={provider._id}
                >
                  <Column modifiers={'col'}>
                    <Row modifiers={['middle']}>
                      <Avatar
                        modifiers={['squared']}
                        user={provider}
                        width={40}
                        height={40}
                      />
                      <Text modifiers={['block']}>
                        {formatTitleAndName(provider)}
                      </Text>
                    </Row>
                  </Column>
                  <Checkbox
                    dark={true}
                    modifiers={['small', 'margin_1']}
                    checked={selectedMembers[provider._id]}
                    onChange={() => {}}
                  />
                </Row>
              ))}
          </ScrollView>
          {Object.keys(selectedMembers).length > 0 && (
            <Container modifiers={['flexFixed']}>
              <Text modifiers={['block', 'bold', 'medium']}>
                Selected Members
              </Text>
              <ScrollView modifiers={['horizontal']}>
                {Object.values(selectedMembers).map((member) => (
                  <AddItem
                    member={member}
                    handleRemove={() => handleRemoveMember(member)}
                    key={member._id}
                  />
                ))}
              </ScrollView>
            </Container>
          )}
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleClose}
              />
              <Button
                modifiers={[
                  'primary',
                  'widthSmall',
                  'roundCorner',
                  Object.keys(selectedMembers).length === 0 && 'disabled',
                ]}
                onClick={handleDone}
              >
                Done
              </Button>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default Add;
