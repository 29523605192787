import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  secondaryFont: ({ theme }) => `
    font-family: ${theme.fonts.secondary};
  `,
  xSmall: () => `
    font-size: ${px2rem(12)};
  `,
};

const styles = ({ theme }) => `
  color: ${theme.colors.primary};
  text-decoration: none;
`;

export const StyledA = buildStyledComponent('A', styled.a, styles, { modifierConfig });
export const StyledLink = buildStyledComponent('A', styled(Link), styles, { modifierConfig });
