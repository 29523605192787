import styled from 'styled-components';

import {
  Container,
  Row,
  Column,
  Text,
  Checkbox,
} from '@gaz/gaz-components.public';
import assets from 'assets';
import { buildStyledComponent, px2rem } from 'style';

const Option = buildStyledComponent(
  'NotificationOption',
  styled(({ icon, text, onClick, selected, disabled, ...props }) => (
    <Row onClick={onClick} modifiers={['spaceBetween']} {...props}>
      <Column modifiers={'col'}>
        <Row>
          <img src={icon} alt="" />
          <Column modifiers={['col', 'noPadding']}>
            <Text modifiers={'block'}>{text}</Text>
          </Column>
        </Row>
      </Column>
      <Checkbox
        dark
        modifiers={['small', 'margin_1']}
        checked={selected}
        disabled={disabled}
        onChange={() => {}}
      />
    </Row>
  )),
  ({ theme }) => `
    padding: 0 ${px2rem(10)};
  `
);

const Notification = ({ isEditing, patient, value, onChange }) => {
  const toggleOption = (key, option) => {
    if (isEditing) {
      const newNotification = { ...value };
      newNotification[key][option] = !newNotification[key][option];

      onChange(newNotification);
    }
  };

  return (
    <div>
      <Text modifiers={['semiBold']}>Notification</Text>

      <Container>
        <Text modifiers={['block', 'bold']}>SMS(text)</Text>
        <Option
          icon={assets.icons.icSMS}
          text={`mobile: ${patient.phones.mobile}`}
          onClick={() => toggleOption('text', 'mobile')}
          selected={!!value.text.mobile}
          disabled={!isEditing}
        />

        <Container modifiers={['fluid', 'withGutters']}>
          <Text modifiers={['block', 'bold']}>Email</Text>
          <Option
            icon={assets.icons.icMail}
            text={`email: ${patient.email}`}
            onClick={() => toggleOption('email', 'email')}
            selected={!!value.email.email}
            disabled={!isEditing}
          />
        </Container>
        <Container modifiers={['fluid', 'withGutters']}>
          <Text modifiers={['block', 'bold']}>Voice(Automated)</Text>
          {patient.phones.home && (
            <Option
              icon={assets.icons.icPhone}
              text={`home: ${patient.phones.home}`}
              onClick={() => toggleOption('voice', 'home')}
              selected={!!value.voice.home}
              disabled={!isEditing}
            />
          )}
          {patient.phones.work && (
            <Option
              icon={assets.icons.icPhone}
              text={`office: ${patient.phones.work}`}
              onClick={() => toggleOption('voice', 'office')}
              selected={!!value.voice.office}
              disabled={!isEditing}
            />
          )}
          <Option
            icon={assets.icons.icPhone}
            text={`mobile: ${patient.phones.mobile}`}
            onClick={() => toggleOption('voice', 'mobile')}
            selected={!!value.voice.mobile}
            disabled={!isEditing}
          />
        </Container>
      </Container>
    </div>
  );
};

export default Notification;
