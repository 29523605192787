import { Container, Row, Column, Text } from 'common';

const Diagnosis = ({ diagnosis }) => (
  <Container modifiers={['fluid', 'withGutters']}>
    <Row modifiers={['smallGutters']}>
      <Text modifiers={['block', 'semiBold', 'secondary']}>Diagnosis</Text>
    </Row>
    {diagnosis.map((item, index) => (
      <Row key={index} modifiers={['smallGutters']}>
        <Column modifiers={['col_3', 'noPadding']}>
          <Text modifiers={['secondary', 'medium']}>{item.code}</Text>
        </Column>
        <Column modifiers={['col', 'noPadding']}>
          <Text modifiers={['secondary', 'medium', 'block']}>
            {item.description}
          </Text>
        </Column>
      </Row>
    ))}
  </Container>
);

export default Diagnosis;
