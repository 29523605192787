import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import { Text } from 'common';

const Table = ({ columns, datas, ...props }) => (
  <table {...props}>
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={column}>{column}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {datas.map((row, index) => (
        <tr key={`row-${index}`}>
          {row.map((data, index) => (
            <td key={`cell-${index}`}>
              {typeof data === 'object' ? (
                data.type === 'list' ? (
                  <ul>
                    {data.items.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : data.style ? (
                  <Text modifiers={[data.style]}>{data.title}</Text>
                ) : (
                  <>
                    {data[0]}
                    <br />
                    (ref: {data[1]})
                  </>
                )
              ) : (
                <>{data}</>
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

const styles = ({ theme }) => `
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0;


  & > thead {
    position: sticky;
    top: 0px;
    background: ${theme.colors.background};
    th {
      border-top: solid ${px2rem(1)};
      border-bottom: solid ${px2rem(1)};
      font-size: ${px2rem(14)};
    }
  }
  & > tbody {
    tr {
      td {
        border-top: none;
        border-bottom: solid ${px2rem(1)};
        font-size: ${px2rem(12)} !important;

        & > * {
          font-size: ${px2rem(12)} !important;
        }
      }
    }
  }

  th, td {
    border-left: solid ${px2rem(1)};
  }

  th:last-child, td:last-child {
    border-right: solid ${px2rem(1)};
  }

  th, td {
    padding: ${px2rem(5)};
  }

  ul {
    text-align: left;
    padding-inline-start: ${px2rem(25)};
    margin: 0;
  }
`;

export default buildStyledComponent('ReportTable', styled(Table), styles);
