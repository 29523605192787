import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import { DESKTOP_VIEWPORT_WIDTH } from 'utils/constants';

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  border-radius: ${px2rem(12)};
  color: ${theme.colors.text};
  width: 80vw;
  height: fit-content;
  padding: ${px2rem(15)};
  display: flex;
  flex-direction: column;
  margin: auto;

  @media only screen and (min-width: 769px) {
    width: calc(${DESKTOP_VIEWPORT_WIDTH} * 0.8);
  }
`;

const modifierConfig = {
  maxModal: ({ theme }) => `
    max-height: 80vh;
    height: 80vh;
    padding: ${theme.dimensions.padding_2} ${theme.dimensions.padding_1};
  `,
};

export default buildStyledComponent('Popover_Content', styled.div, styles, {
  modifierConfig,
});
