import { useState, useEffect, useRef } from 'react';
import assets from 'assets';
import styled from 'styled-components';
import { noop } from 'lodash';
import { Row, Column as Col, Button, Input } from '@gaz/gaz-components.public';
import { Container, Text, SlidingPane, Modal } from 'common';
import { getVitalTypeAttr, validateVital } from 'utils/helper';
import { buildStyledComponent, px2rem } from 'style';

export const InputRow = buildStyledComponent(
  'VitalInputWrapper',
  styled(({ children, ...props }) => (
    <Container {...props}>{children}</Container>
  )),
  ({ theme }) => `
    margin-top: ${theme.dimensions.padding_2};
    padding: 0;

    >:first-child {
      box-shadow: ${theme.dimensions.widgetBoxShadow};
      background: ${theme.colors.white};
      border-radius: ${theme.dimensions.borderRadius_1};
      border: 1px solid #E7E7E9;
      overflow: hidden;

      >:not(:last-child) span {
        color: ${theme.colors.textSecondary};
      }

      >:last-child {
        background: ${theme.colors.white_1};
        padding: 0 ${px2rem(6)};
        > * {
          width: 100%;
        }
      }
    }
  `,
  {
    modifierConfig: {
      highlight: ({ theme }) => `
        >:first-child {
          border: solid ${px2rem(1)} ${theme.colors.primary};
          span {
            color: ${theme.colors.primary};
          }
        }
      `,
      isInput: ({ theme }) => `
        >:first-child {
          > * {
            padding-top: ${px2rem(15)};
            padding-bottom: ${px2rem(15)};
          }
          input {
            color: ${theme.colors.primary};
          }

          > :not(:last-child) {
            border-right: solid ${px2rem(1)} ${theme.colors.primary};
          }

          > :last-child {
            background: ${theme.colors.white};
          }
        }
      `,
      twoInputs: ({ theme }) => `
        >:first-child {
          align-items: stretch;
          > * {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          > :not(:first-child) {
            padding: ${theme.dimensions.padding_1} 0;
            > * {
              padding-left: ${px2rem(10)};
              padding-right: ${px2rem(10)};

            }
            > :first-child {
              padding-bottom: ${theme.dimensions.padding_1};
              border-bottom: solid ${px2rem(1)} ${theme.colors.primary};
            }
            > :last-child {
              padding-top: ${theme.dimensions.padding_1};
            }
          }
        }
      `,
      bottomMargin: ({ theme }) => `
        margin-bottom: ${theme.dimensions.padding_2};
        margin-top: 0;
      `,
    },
  }
);

const InputPane = ({
  vitalType,
  isVisible,
  value,
  value2,
  handleSave,
  handleCancel,
}) => {
  const [input, updateInput] = useState(value > 0 ? value : '');
  const [input2, updateInput2] = useState(value2 > 0 ? value2 : '');
  const inputRef = useRef();
  const vitalAttrs = getVitalTypeAttr(vitalType);

  const handleClose = (event) => {
    event.stopPropagation();
    handleCancel();
  };
  const handleDone = (event) => {
    event.stopPropagation();
    const alert = validateVital(vitalType, input, input2);
    if (alert === true) {
      handleSave(vitalType, input, input2);
      handleCancel();
    } else {
      Modal.confirm({
        title: `Confirm ${vitalAttrs.name}`,
        text: alert,
        onOk: () => {
          handleSave(vitalType, input, input2);
          handleCancel();
        },
      });
    }
  };

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 300);
      updateInput(value > 0 ? value : '');
      updateInput2(value2 > 0 ? value2 : '');
    }
  }, [isVisible]);

  return (
    <SlidingPane
      isOpen={isVisible}
      modifiers={['unsetHeight']}
      handleClose={handleClose}
    >
      <SlidingPane.Header modifiers={['noJustify', 'noPadding']}>
        <Col modifiers={['col_12']}>
          <Row modifiers={['middle', 'topGutters_1']}>
            <Col modifiers={['col_2']}>
              <Button
                modifiers={['icon', 'transparent']}
                onClick={handleClose}
                image={assets.icons.icClosePlain}
                width={12}
                height={12}
                imageWidth={12}
                imageHeight={12}
              />
            </Col>
            <Col modifiers={['col_8', 'noPadding']}>
              <Text modifiers={['h2', 'bold', 'darkGrey', 'center', 'block']}>
                {vitalAttrs.name}
              </Text>
            </Col>
          </Row>
        </Col>
      </SlidingPane.Header>
      <SlidingPane.Content>
        <Container modifiers={['flexBox', 'fluid', 'withGutters']}>
          <InputRow
            modifiers={[
              'highlight',
              'isInput',
              'bottomMargin',
              vitalType === 'bloodPressure' && 'twoInputs',
            ]}
          >
            <Row modifiers={['middle']}>
              <Col modifiers={['col_5']}>
                <Text modifiers={['medium', 'semiBold']}>
                  {vitalAttrs.name}
                </Text>
              </Col>
              <Col modifiers={['col_3']}>
                <Row>
                  <Input
                    value={input}
                    ref={inputRef}
                    modifiers={['noPadding', 'noBorder']}
                    type="number"
                    inputmode="decimal"
                    onChange={(e) => {
                      updateInput(e.target.value);
                    }}
                  />
                </Row>
                {vitalType === 'bloodPressure' && (
                  <Row>
                    <Input
                      value={input2}
                      modifiers={['noPadding', 'noBorder']}
                      type="number"
                      inputmode="decimal"
                      onChange={(e) => {
                        updateInput2(e.target.value);
                      }}
                    />
                  </Row>
                )}
              </Col>
              <Col modifiers={['col_4', 'noPadding']}>
                {vitalType === 'bloodPressure' ? (
                  <>
                    <Row>
                      <Text
                        modifiers={[
                          'small',
                          'primary',
                          'preserveLineBreak',
                          'block',
                        ]}
                      >
                        sys
                      </Text>
                    </Row>
                    <Row>
                      <Text
                        modifiers={[
                          'small',
                          'primary',
                          'preserveLineBreak',
                          'block',
                        ]}
                      >
                        dia
                      </Text>
                    </Row>
                  </>
                ) : (
                  <Text
                    modifiers={[
                      'small',
                      'primary',
                      'preserveLineBreak',
                      'block',
                    ]}
                  >
                    {vitalAttrs.unit}
                  </Text>
                )}
              </Col>
            </Row>
          </InputRow>
        </Container>
      </SlidingPane.Content>
      <Container modifiers={['flexBox', 'footer']}>
        <Row modifiers={['spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={handleClose}
          />
          <Button
            modifiers={['widthXSmall', 'primary', 'roundCorner']}
            onClick={handleDone}
          >
            <Text modifiers={['subtitle', 'semiBold', 'white']}>Save</Text>
          </Button>
        </Row>
      </Container>
    </SlidingPane>
  );
};

export default ({
  vitalType,
  editable,
  handleSave,
  handleRemove = noop,
  value,
  value2,
}) => {
  const vitalTypeAttrs = getVitalTypeAttr(vitalType);
  const highlight = value > 0 || value2 > 0;
  const [showInput, setShowInput] = useState(false);

  const handleClick = () => {
    setShowInput(true);
  };

  const onClickRemove = (e) => {
    e.stopPropagation();
    Modal.confirm({
      title: `Delete ${vitalTypeAttrs.name}`,
      text: `Are you sure want to delete ${vitalTypeAttrs.name}?`,
      onOk: () => {
        handleRemove(vitalType);
      },
    });
  };

  return (
    <InputRow
      modifiers={[highlight && 'highlight']}
      onClick={editable && handleClick}
    >
      <Row>
        <Col modifiers={['col_8', 'noPadding', 'noVerticalPadding']}>
          <Row>
            <Col
              modifiers={[
                'col_2',
                'flexBox',
                'center',
                'verticalContentCenter',
                'noVerticalPadding',
              ]}
            >
              <img src={vitalTypeAttrs.icon} alt="" width="25" height="25" />
            </Col>
            <Col modifiers={['col_10', 'fluid']}>
              <Text modifiers={['block', 'medium']}>
                {vitalTypeAttrs.title}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col modifiers={['col_4', 'flexBox']}>
          <Row modifiers={['middle', 'spaceBetween']}>
            <Col modifiers={['noPadding', 'col']}>
              <Text modifiers={['block', 'medium']}>
                {vitalType === 'bloodPressure' ? `${value}s/${value2}d` : value}
              </Text>
            </Col>
            {highlight && (
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                width={20}
                height={20}
                onClick={onClickRemove}
              />
            )}
          </Row>
        </Col>
      </Row>
      <InputPane
        isVisible={showInput}
        vitalType={vitalType}
        value={value}
        value2={value2}
        handleCancel={() => setShowInput(false)}
        handleSave={handleSave}
      />
    </InputRow>
  );
};
