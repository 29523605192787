import React from 'react';
// import { useHistory } from 'react-router';

import Wrapper from './Wrapper';

const Footer = ({ children }) => {
  // const history = useHistory();

  return <Wrapper modifiers={['middle']}>{children}</Wrapper>;
};

export default Footer;
