import { useState, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';

import {
  Row,
  Button,
  ScrollView,
  SearchBar,
  BackButton,
  Text,
} from '@gaz/gaz-components.public';
import { Container, Modal, Loader } from 'common';
import { FETCH_DIAGNOSES } from 'graphql/queries';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import DiagnosisItem from './Item';
import assets from 'assets';

const DiagnosisInputForm = ({ handleSave, handleCancel }) => {
  const [fetchDiagnoses, { loading }] = useLazyQuery(FETCH_DIAGNOSES, {
    onCompleted: (data) => {
      setDiagnoses(data.diagnoses);
    },
  });
  const [diagnoses, setDiagnoses] = useState([]);
  const [selectedDiagnoses, setSelectedDiagnoses] = useState({});
  const [searchQuery, updateSearchQuery] = useState();

  const handleSearch = useCallback(
    debounce((query) => {
      updateSearchQuery(query);
      if (query.length < 3) {
        setDiagnoses([]);
        return;
      }
      setDiagnoses(null);
      fetchDiagnoses({
        variables: {
          query,
          page: 0,
          pageSize: 20,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    []
  );

  const handleQueryChange = (value) => {
    handleSearch(value);
  };

  const handleAdd = () => {
    handleSave(selectedDiagnoses);
  };

  const handleSelect = (diagnosis) => {
    if (selectedDiagnoses[diagnosis.code]) {
      delete selectedDiagnoses[diagnosis.code];
    } else {
      selectedDiagnoses[diagnosis.code] = diagnosis;
    }
    setSelectedDiagnoses({ ...selectedDiagnoses });
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle', 'smallGutters']}>
          <BackButton onClick={handleCancel} />
          <Text>Search and Add Diagnosis</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
          <Container modifiers={['padding_2']}>
            <SearchBar
              onChange={handleQueryChange}
              autoFocus={true}
              placeholder={'Search by ICD Code or Keyword'}
            />
          </Container>
          <ScrollView>
            {loading ? (
              <Row modifiers={['center']}>
                <Loader.Icon />
              </Row>
            ) : (
              <>
                {!!diagnoses &&
                  diagnoses.map((diagnosis) => (
                    <DiagnosisItem
                      value={diagnosis}
                      key={diagnosis.code}
                      selectable
                      selected={!!selectedDiagnoses[diagnosis.code]}
                      handleChange={() => {
                        handleSelect(diagnosis);
                      }}
                      withMargin
                    />
                  ))}
                {diagnoses?.length === 0 && searchQuery?.length > 0 && (
                  <Row>
                    <Text modifiers={['primary']}>0 Results</Text>
                    <Text>found "{searchQuery}"</Text>
                  </Row>
                )}
              </>
            )}
          </ScrollView>
          <Container modifiers={['footer']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleCancel}
              />
              <Button
                modifiers={[
                  'widthSmall',
                  Object.keys(selectedDiagnoses).length === 0 && 'disabled',
                ]}
                onClick={handleAdd}
                disabled={Object.keys(selectedDiagnoses).length === 0}
              >
                Add Selected
              </Button>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default DiagnosisInputForm;
