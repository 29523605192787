import styled from 'styled-components';

import { Column, Container, Row, Text } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

import assets from 'assets';
import { Badge, Button } from 'common';

export const NOTE_ITEMS = {
  subjective: 'Subjective',
  objective: 'Objective',
  assessment: 'Assessment',
  plan: 'Plan',
  diagnosis: 'Qualifying Diagnosis',
  attachments: 'Attachments',
};

export const NoteItemWrapper = buildStyledComponent(
  'NoteItemWrapper',
  styled(Container),
  ({ theme }) => `
    border-radius: ${theme.dimensions.borderRadius_1};
    margin-bottom: ${theme.dimensions.padding_2};
    background: ${theme.colors.white};
    box-shadow: ${theme.dimensions.widgetBoxShadow};
    span {
      color: ${theme.colors.textSecondary};
    }
  `,
  {
    modifierConfig: {
      newVital: () => `
        border: 1px solid #D8D8F6;
        >:first-child {
          border-radius: 6px;
          background: #F9FBFF 0% 0% no-repeat padding-box;
        }
        >:nth-child(1) {
          background-color: #F9FBFF;
        }
      `,
      round: ({ theme }) => `
        border-radius: 6px;
        border: 1px solid #E7E7E9;
      `,
      active: ({ theme }) => `
        background: ${theme.colors.secondary};
        >:first-child {
          >:first-child {
            > * {
              color: ${theme.colors.white};
            }
          }
        }
      `,
    },
  }
);

const NoteItem = ({ itemKey, note, active, ...props }) => {
  return (
    <NoteItemWrapper {...props} modifiers={[active && 'active']}>
      <Row modifiers="middle">
        <Column modifiers={['col', 'flexBox', 'verticalContentCenter']}>
          <Text modifiers={['subtitle', 'semiBold', 'large']}>
            {NOTE_ITEMS[itemKey]}
          </Text>
          {itemKey === 'attachments' && note.content[itemKey]?.length > 0 && (
            <Badge modifiers={['success']}>
              {note.content[itemKey]?.length}
            </Badge>
          )}
        </Column>
        <Column>
          <Button
            image={
              active
                ? assets.icons.icRightChevronWhite
                : assets.icons.icRightChevron
            }
            modifiers={['round', 'light', 'icon', 'transparent']}
            width={26}
            height={26}
          />
        </Column>
      </Row>
    </NoteItemWrapper>
  );
};

export default NoteItem;
