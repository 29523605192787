import { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { Button, Column, Container, FormInput, Row, Text } from 'common';
import { UPDATE_BILLING } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { SimpleLayout } from 'layouts';
import { AuthContext } from 'contexts/auth';
import { ProviderContext } from 'contexts/provider';
import assets from 'assets';

const billingSchema = Yup.object().shape({
  time: Yup.number()
    .required('Please enter valid number.')
    .test(
      'Is positive?',
      'Please enter valid number',
      (value) => !isNaN(value) && value > 0 && value === parseInt(value, 10)
    ),
  therapeutic: Yup.number()
    .required('Please enter valid number.')
    .test(
      'Is positive?',
      'Please enter valid number',
      (value) => !isNaN(value) && value > 0 && value === parseInt(value, 10)
    ),
  physiologic: Yup.number()
    .required('Please enter valid number.')
    .test(
      'Is positive?',
      'Please enter valid number',
      (value) => !isNaN(value) && value > 0 && value === parseInt(value, 10)
    ),
});

export default ({}) => {
  const history = useHistory();
  const { me } = useContext(AuthContext);
  const { updateBilling, billing } = useContext(ProviderContext);
  const [updateBillingApi] = useMutation(UPDATE_BILLING);
  const [isEditing, updateIsEditing] = useState(false);
  const formRef = useRef();

  const goBack = useCallback(() => {
    history.replace('/');
  }, []);

  const handleDone = async () => {
    if (formRef.current.isValid) {
      loadingVar(true);
      await updateBillingApi({
        variables: formRef.current.values,
      });
      updateBilling(formRef.current.values);
      loadingVar(false);
      goBack();
    }
  };

  if (!me) {
    return null;
  }

  return (
    <SimpleLayout>
      <SimpleLayout.Header
        content="Monitoring"
        rightComponent={
          !isEditing && (
            <Button onClick={() => updateIsEditing(true)}>Edit</Button>
          )
        }
      />
      <SimpleLayout.Content>
        <Container
          modifiers={['backgroundWhite', 'fullHeight', 'flexBox', 'padding_2']}
        >
          {isEditing ? (
            <Formik
              initialValues={billing}
              validationSchema={billingSchema}
              innerRef={formRef}
            >
              <Form>
                <Row>
                  <Column modifiers={['col_12']}>
                    <Field
                      name="time"
                      type="number"
                      hasLabel
                      label="Time Goals (minutes)"
                      required
                      largeLabel
                      modifiers={['squared']}
                      component={FormInput}
                      disabled={!isEditing}
                    />
                  </Column>
                  <Column modifiers={['col_12']}>
                    <Field
                      name="therapeutic"
                      type="number"
                      hasLabel
                      label="Therapeutic Readings Goals"
                      required
                      largeLabel
                      modifiers={['squared']}
                      component={FormInput}
                      disabled={!isEditing}
                    />
                  </Column>
                  <Column modifiers={['col_12']}>
                    <Field
                      name="physiologic"
                      type="number"
                      hasLabel
                      label="Physiologic Readings Goals"
                      required
                      largeLabel
                      modifiers={['squared']}
                      component={FormInput}
                      disabled={!isEditing}
                    />
                  </Column>
                </Row>
              </Form>
            </Formik>
          ) : (
            <Container modifiers={['padding_2']}>
              <Row modifiers={['spaceBetween', 'withGutters']}>
                <Text modifiers={['large']}>Time Goals (minutes)</Text>
                <Text modifiers={['large', 'primary']}>{billing.time}</Text>
              </Row>
              <Row modifiers={['spaceBetween', 'withGutters']}>
                <Text modifiers={['large']}>Therapeutic Readings Goals</Text>
                <Text modifiers={['large', 'primary']}>
                  {billing.therapeutic}
                </Text>
              </Row>
              <Row modifiers={['spaceBetween', 'withGutters']}>
                <Text modifiers={['large']}>Physiologic Readings Goals</Text>
                <Text modifiers={['large', 'primary']}>
                  {billing.physiologic}
                </Text>
              </Row>
            </Container>
          )}
        </Container>
      </SimpleLayout.Content>
      {isEditing && (
        <SimpleLayout.Footer>
          <Row modifiers={['middle', 'spaceBetween']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            <Button
              modifiers={['widthSmall', 'roundCorner']}
              onClick={handleDone}
            >
              Done
            </Button>
          </Row>
        </SimpleLayout.Footer>
      )}
    </SimpleLayout>
  );
};
