import styled, { css } from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = ({ theme }) => css`
  width: 100%;
`;

const modifierConfig = {
  mediumHeight: ({ theme }) => `
    height: 30px;
  `,
  hasError: ({ theme }) => ({
    styles: `
      border: 1px solid ${theme.colors.danger};
    `,
  }),
};

const Input = buildStyledComponent(
  'FormDirectDate__Input',
  styled.input,
  styles,
  {
    modifierConfig,
  }
);

export default Input;
