import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import urls from 'routes/urls';

const ROUTES_WITH_MENU = [
  urls.DASHBOARD,
  urls.INVITATIONS,
  urls.REPORTS,
  urls.ADD_PATIENT,
];

const NavMenuContext = createContext();

export function NavMenuProvider({ children }) {
  const location = useLocation();
  const [hidden, setHidden] = useState(false);

  const showMenu = () => setHidden(false);

  const hideMenu = () => setHidden(true);

  useEffect(() => {
    if (!ROUTES_WITH_MENU.includes(location.pathname)) {
      hideMenu();
    } else {
      showMenu();
    }
  }, [location.pathname]);

  return (
    <NavMenuContext.Provider
      value={{
        hidden,
        showMenu,
        hideMenu,
      }}
    >
      {children}
    </NavMenuContext.Provider>
  );
}

export function useNavMenu() {
  return useContext(NavMenuContext);
}
