import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  color: ${theme.colors.text};
  font-size: ${theme.dimensions.fontSize};
`;

export default buildStyledComponent(
  'Dashboard_Card_Subtitle',
  styled.div,
  styles
);
