import React, { useEffect, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { Container, Row } from '../Grid';
import { Text } from '../Text';

import styled from 'styled-components';

import { buildStyledComponent } from 'style';

function gcd(a, b) {
  let c;
  do {
    c = a % b;
    a = b;
    b = c;
  } while (b !== 0);
  return a;
}

function lcm(a, b) {
  return (a * b) / gcd(a, b);
}

const CustomTooltip = ({ active, payload, fixedValues }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const keys = Object.keys(data).filter(
      (key) => key !== 'weight' && key !== 'label'
    );
    return (
      <Container modifiers={['card', 'padding_2']}>
        <Row modifiers={['bottomGutters_1']}>
          <Text modifiers={['medium', 'bold']}>{data.label}</Text>
        </Row>
        {fixedValues ? (
          <Row>
            <Text modifiers={['primary', 'block', 'medium']}>
              {keys[0]}: {fixedValues[data[keys[0]] - 1]}
            </Text>
          </Row>
        ) : (
          <>
            <Row>
              <Text modifiers={['primary', 'block', 'medium']}>
                {keys[0]}: {data[keys[0]]}
              </Text>
            </Row>
            {keys.length > 1 && (
              <Row>
                <Text modifiers={['secondary', 'block', 'medium']}>
                  {keys[1]}: {data[keys[1]]}
                </Text>
              </Row>
            )}
          </>
        )}
      </Container>
    );
  }

  return null;
};

const CustomAxisTick = ({ x, y, payload, fixedValues }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={5} textAnchor="start" fill="#9E9EA7" fontSize="12">
        {fixedValues[payload.value - 1]}
      </text>
    </g>
  );
};

const LineChartComponent = ({
  lineColors = ['#1565C0', '#3B4C6A'],
  dotColors = ['#D0E6FF', '#D2D4D7'],
  data = {},
  scale = 'auto',
  ...props
}) => {
  const [axisData, updateAxisData] = useState();
  const [graphData, updateGraphData] = useState();
  useEffect(() => {
    let commonWeight = 1;
    const newAxisData = [];
    const newGraphData = [];
    if (!data?.data || data.data.length === 0) {
      updateAxisData(null);
      updateGraphData(null);
      return;
    }
    let minValue;
    data.data.forEach((groupData) => {
      groupData.data.forEach((elem) => {
        if (elem.value) {
          const value = parseFloat(elem.value, 10);
          if (!minValue || value < minValue) {
            minValue = value;
          }
        }
        if (elem.value2) {
          const value2 = parseFloat(elem.value2, 10);
          if (!minValue || value2 < minValue) {
            minValue = value2;
          }
        }
      });
    });

    data.data.forEach((groupData) => {
      let maxValue;
      groupData.data.forEach((elem) => {
        if (elem.value) {
          const value = parseFloat(elem.value, 10);
          if (!maxValue || value > maxValue) {
            maxValue = value;
          }
        }
        if (elem.value2) {
          const value2 = parseFloat(elem.value2, 10);
          if (!maxValue || value2 > maxValue) {
            maxValue = value2;
          }
        }
      });
      newAxisData.push({
        label: groupData.label,
        value: data.fixedValues ? 1 : maxValue || minValue || 0,
      });
      if (groupData.data.length > 1) {
        commonWeight = lcm(commonWeight, groupData.data.length);
      }
    });
    for (let i = 0; i < data.data.length; i++) {
      const groupData = data.data[i];
      const groupDataLength = groupData.data.length;
      for (let j = 0; j < groupData.data.length; j++) {
        const payload = {
          weight: i * commonWeight + (j * commonWeight) / groupDataLength,
          label: groupData.data[j].label,
        };
        payload[data.labels[0]] = groupData.data[j].value;
        if (data.labels.length === 2) {
          payload[data.labels[1]] = groupData.data[j].value2;
        }
        newGraphData.push(payload);
      }
    }
    newAxisData.push({ label: '', value: data.fixedValues ? 5 : minValue });
    newGraphData.push({
      weight: commonWeight * data.data.length,
    });
    updateAxisData(newAxisData);
    updateGraphData(newGraphData);
  }, [data]);

  if (!graphData || graphData.length === 0) {
    return (
      <Container modifiers={['padding_2']}>
        <Text modifiers={['center', 'large', 'block', 'danger', 'bold']}>
          No Data available
        </Text>
      </Container>
    );
  }

  return (
    <div {...props}>
      <ResponsiveContainer aspect={1.2} width="100%" height="100%">
        <AreaChart
          data={axisData}
          margin={{ top: 30, right: 0, left: 20, bottom: 0 }}
        >
          <XAxis
            tick={{ fontSize: 12, fill: '#9E9EA7' }}
            dataKey="label"
            dy={10}
            tickLine={false}
          />
          {data.fixedValues ? (
            <YAxis
              tick={<CustomAxisTick fixedValues={data.fixedValues} />}
              padding={{ bottom: 30 }}
              orientation="right"
              axisLine={false}
              dx={0}
              tickLine={false}
              domain={[1, 5]}
              width={80}
            />
          ) : (
            <YAxis
              tick={{ fontSize: 12, fill: '#9E9EA7' }}
              padding={{ bottom: 30 }}
              orientation="right"
              domain={['auto', (dataMax) => dataMax * 1.25]}
              axisLine={false}
              scale={scale}
              dx={5}
              tickLine={false}
            />
          )}
          <CartesianGrid vertical={true} strokeDasharray="2" />
          <Area
            connectNulls={false}
            dataKey="value"
            stroke="transparent"
            fillOpacity={0}
          />
        </AreaChart>
      </ResponsiveContainer>
      <ResponsiveContainer aspect={1.2} width="100%" height="100%">
        <AreaChart
          data={graphData}
          margin={{ top: 30, right: 0, left: 20, bottom: 0 }}
        >
          <defs>
            <linearGradient id="lineColor1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="3%" stopColor={lineColors[0]} stopOpacity={0.2} />
              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="lineColor2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={lineColors[1]} stopOpacity={0.8} />
              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            tick={{ fontSize: 12, fill: '#9E9EA7' }}
            dataKey="weight"
            dy={10}
            tickLine={false}
            type="number"
            scale="linear"
          />
          {data.fixedValues ? (
            <YAxis
              tick={<CustomAxisTick fixedValues={data.fixedValues} />}
              padding={{ bottom: 30 }}
              orientation="right"
              axisLine={false}
              dx={0}
              tickLine={false}
              domain={[1, 5]}
              width={80}
            />
          ) : (
            <YAxis
              tick={{ fontSize: 12, fill: '#9E9EA7' }}
              padding={{ bottom: 30 }}
              orientation="right"
              domain={['auto', (dataMax) => dataMax * 1.25]}
              axisLine={false}
              scale={scale}
              dx={5}
              tickLine={false}
            />
          )}
          <Area
            dataKey={data.labels[0]}
            stroke={lineColors[0]}
            fillOpacity={1}
            fill="url(#lineColor1)"
            dot={
              graphData?.length > 5
                ? false
                : {
                    stroke: dotColors[0],
                    strokeWidth: 4,
                    fill: lineColors[0],
                    r: 8,
                    strokeDasharray: '',
                  }
            }
            activeDot={{
              stroke: dotColors[0],
              strokeWidth: 6,
              fill: lineColors[0],
              r: 12,
              strokeDasharray: '',
            }}
          />
          <Tooltip
            content={<CustomTooltip fixedValues={data.fixedValues} />}
            position={{ y: 100 }}
            offset={20}
          />
          {data.labels.length === 2 && (
            <Area
              dataKey={data.labels[1]}
              stroke={lineColors[1]}
              fillOpacity={1}
              fill="url(#lineColor2)"
              dot={
                graphData?.length > 5
                  ? false
                  : {
                      stroke: dotColors[1],
                      strokeWidth: 4,
                      fill: lineColors[1],
                      r: 8,
                      strokeDasharray: '',
                    }
              }
              activeDot={{
                stroke: dotColors[1],
                strokeWidth: 6,
                fill: lineColors[1],
                r: 12,
                strokeDasharray: '',
              }}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export const LineChart = buildStyledComponent(
  'GazGraph',
  styled(LineChartComponent),
  ({}) => `
    > :last-child {
      position: absolute;
      top: 0;

      svg {
        > g:nth-child(3), > g:nth-child(4) {
          visibility: hidden;
        }
      }
    }
  `
);
