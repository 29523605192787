import React from 'react';

import Wrapper from './Wrapper';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';

export const SimpleLayout = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

SimpleLayout.Header = Header;
SimpleLayout.Content = Content;
SimpleLayout.Footer = Footer;
