import { useContext, useEffect, useMemo, useState } from 'react';

import { PatientHomeContext } from 'pages/PatientHome/Context';
import { ProgressContext } from '../Context';
import { getMeasureProgress } from 'utils/careplan';
import Detail from './Detail';
import List from './List';
import { DIET_ITEMS, WELLNESS_ITEMS } from 'utils/constants';

export default ({ type }) => {
  const typeItems = useMemo(
    () => (type === 'wellness' ? WELLNESS_ITEMS : DIET_ITEMS),
    [type]
  );
  const { progress, fetchProgress, setBackHandler } =
    useContext(PatientHomeContext);
  const { responses } = useContext(ProgressContext);
  const measureProgress = useMemo(() => {
    if (!!progress) {
      return getMeasureProgress(progress, 'diet');
    }
    return null;
  }, [progress]);

  const itemResponses = useMemo(() => {
    const values = {};
    Object.keys(typeItems).forEach((key) => (values[key] = []));
    responses?.forEach((dailyResponse) => {
      dailyResponse.responses
        .filter((response) => response.measure === type)
        .sort((a, b) => {
          if (new Date(a.time) > new Date(b.time)) {
            return -1;
          }
          return 1;
        })
        .forEach((response) => {
          values[response.response.type].push(response);
        });
    });
    return values;
  }, [responses]);

  const finalResponses = useMemo(() => {
    const values = {};
    Object.keys(typeItems).forEach((key) => {
      if (itemResponses[key].length === 0) {
        values[key] = null;
      }
      for (let i = 0; i < itemResponses[key].length; i++) {
        if (
          itemResponses[key][i].response.value !== undefined &&
          itemResponses[key][i].response.value !== null
        ) {
          values[key] = itemResponses[key][i];
          return;
        }
      }
      values[key] = null;
    });
    return values;
  }, [itemResponses]);

  useEffect(() => {
    if (progress === null || progress === undefined) {
      fetchProgress();
    }
  }, []);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelectItem = (vital) => {
    setSelectedItem(vital);
  };

  useEffect(() => {
    if (selectedItem) {
      setBackHandler(() => {
        setSelectedItem(null);
        return false;
      });
    }
  }, [selectedItem]);

  return (
    <>
      {selectedItem ? (
        <Detail
          type={type}
          itemType={selectedItem}
          finalResponse={finalResponses[selectedItem]}
          responses={itemResponses[selectedItem]}
        />
      ) : (
        <List
          type={type}
          progress={measureProgress}
          finalResponses={finalResponses}
          handleSelectItem={handleSelectItem}
        />
      )}
    </>
  );
};
