import styled from 'styled-components';

import { Container, Row, Column, Text } from 'common';
import { buildStyledComponent, px2rem } from 'style';

const DiagnosisItem = ({ date, diagnoses, ...props }) => (
  <Container modifiers={['fluid', 'withGutters']} {...props}>
    <Row>
      <Text modifiers={['muted', 'block']}>{date}</Text>
    </Row>
    {diagnoses.map((diagnosis) => (
      <Row modifiers={['withGutters']}>
        <Text modifiers={['bold']}>{diagnosis.value.code}</Text>
        <Column modifiers={['col', 'noPadding']}>
          <Text modifiers={['block', 'noMargin']}>
            {diagnosis.value.description}
          </Text>
        </Column>
      </Row>
    ))}
  </Container>
);

const styles = () => `
  > :not(:first-child) {
    margin-left: ${px2rem(15)};

    > :first-child {
      width: ${px2rem(70)};
      margin-right: ${px2rem(5)};
    }
    > :nth-child(2) {
      margin-right: ${px2rem(5)};
    }
  }
`;

export default buildStyledComponent(
  'DiagnosisItem',
  styled(DiagnosisItem),
  styles
);
