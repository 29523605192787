export class TimeLog {
  type = '';
  entityId = null; // Associated note/chat/careplan/review id
  startedAt = 0;
  endedAt = 0;
  duration = 0; // Pause functionality exists. Duration isn't always same as endedAt - startedAt
  deleted = false;

  constructor(type, id) {
    this.type = type;
    this.entityId = id;
    this.startedAt = Math.round(new Date().valueOf() / 1000);
    this.duration = 0;
    this.deleted = false;
  }

  serialize() {
    const obj = {
      type: this.type,
      entityId: this.entityId,
      startedAt: this.startedAt,
      endedAt: this.endedAt || Math.round(new Date().valueOf() / 1000),
      duration: this.duration,
      deleted: this.deleted,
    };

    return obj;
  }

  refresh() {
    this.startedAt = Math.round(new Date().valueOf() / 1000);
    this.endedAt = undefined;
    this.duration = 0;
  }

  setEntityId(id) {
    this.entityId = id;
  }
}
