import React, { useState } from 'react';
import { startCase } from 'lodash';

import { Container, Row, Column, Text } from '@gaz/gaz-components.public';

import Wrapper from './Wrapper';
import Circle from './Circle';

const circleOptions = ['veryBad', 'bad', 'medium', 'good', 'veryGood'];

export default function Rating({
  options = [],
  value,
  handleSelect,
  disabled,
}) {
  return (
    <Container>
      <Row>
        {options.map((option, i) => (
          <Wrapper
            modifiers={[disabled && (value === i + 1 ? 'selected' : 'muted')]}
            onClick={() => !disabled && handleSelect(i + 1)}
            key={`rating-option-${i + 1}`}
          >
            <Circle
              modifiers={[circleOptions[i], value === i + 1 && 'selected']}
            >
              <Text modifiers={['subtitle', 'mediumWeight', 'white']}>
                {i + 1}
              </Text>
            </Circle>
            <Text
              modifiers={[
                'subtitle',
                'mediumWeight',
                'noPadding',
                'topPadding',
                value === i + 1 && 'primary',
              ]}
            >
              {startCase(option)}
            </Text>
          </Wrapper>
        ))}
      </Row>
    </Container>
  );
}
