import Wrapper from './Wrapper';
import Item from './Item';

export function DetailedList({ data = [] }) {
  return (
    <Wrapper>
      {data.map((item, index) => (
        <Item item={item} key={index} />
      ))}
    </Wrapper>
  );
}
