import React, { useContext, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';
import {
  Row,
  Button,
  Container,
  ListView,
  SearchBar,
  Avatar,
  Text,
} from '@gaz/gaz-components.public';

import { AuthContext } from 'contexts/auth';
import { MainLayout } from 'layouts';
import urls from 'routes/urls';
import { INVITE_EXISTING_USER } from 'graphql/mutations';
import { FETCH_PATIENTS } from 'graphql/queries';
import { loadingVar } from 'graphql/cache';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import assets from 'assets';

const AddPatient = ({ history }) => {
  const [query, setQuery] = useState('');
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const { me } = useContext(AuthContext);
  const [patients, setPatients] = useState([]);
  const [fetchPatients, { loading }] = useLazyQuery(FETCH_PATIENTS, {
    onCompleted: (data) => {
      setPatients(data.patients);
    },
  });
  const [inviteExistingUser] = useMutation(INVITE_EXISTING_USER);

  loadingVar(loading);

  const handleSearch = useCallback(
    debounce((query) => {
      fetchPatients({
        variables: {
          query,
          inPractice: 'no',
          page: 0,
          pageSize: 100,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchPatients]
  );

  const handleInvite = useCallback(
    async (record) => {
      loadingVar(true);
      await inviteExistingUser({
        variables: {
          inviteeId: record._id,
        },
      });
      loadingVar(false);
      setPatients(patients.filter((patient) => patient._id !== record._id));
    },
    [inviteExistingUser]
  );

  const handleQueryChange = (value) => {
    setQuery(value);
    if (value) {
      handleSearch(value);
    }
  };

  return (
    <MainLayout>
      <MainLayout.SideMenu
        closeSideMenu={() => setSideMenuOpen(false)}
        isOpen={sideMenuOpen}
      />
      <MainLayout.Header>
        <MainLayout.Header.Left>
          <MainLayout.Header.MenuButton
            openSideMenu={() => setSideMenuOpen(true)}
          />
        </MainLayout.Header.Left>
        <MainLayout.Header.Center>
          <Text modifiers={['subtitle', 'semiBold']}>Add Patient</Text>
        </MainLayout.Header.Center>
        <MainLayout.Header.Right>
          <Avatar width={36} height={36} modifiers="round" user={me} />
        </MainLayout.Header.Right>
      </MainLayout.Header>
      <MainLayout.Content>
        <Container>
          <SearchBar
            onChange={handleQueryChange}
            placeholder="Search patient by Name/SS#"
          />
        </Container>
        {query ? (
          <ListView
            records={patients}
            hasShowType
            hasInvite
            onClickItem={handleInvite}
          />
        ) : (
          <Container modifiers={['flexOne', 'flexBox']}>
            <Row
              modifiers={['center']}
              style={{ marginTop: 'auto', marginBottom: '30px' }}
            >
              <Button
                modifiers={['primary', 'widthMedium', 'roundCorner']}
                onClick={() => history.push(urls.ADD_PATIENT_MANUAL)}
              >
                Add Patient Manually
              </Button>
            </Row>
          </Container>
        )}
      </MainLayout.Content>
    </MainLayout>
  );
};

export default AddPatient;
