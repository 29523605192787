import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  border: 1px solid ${theme.colors.primary};
  border-radius: ${px2rem(5)};
  color: ${theme.colors.primary};
  padding: ${px2rem(5)} ${px2rem(10)};
  margin: 0 ${px2rem(5)};
  font-size: ${px2rem(16)};
  width: ${px2rem(80)};
  text-align: center;
`;

export default buildStyledComponent('Timer', styled.div, styles);
