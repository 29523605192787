import loader from './home_loader.svg';

import addAttachment from './add_new_attachment.svg';
import addParticipant from './add_new_participants_icon.svg';
import addImage from './add_photo_camera.svg';
import addNote from './add_to_note_new_icon.svg';
import addAppointment from './calendar_appointment_blue.svg';
import addFromPhotoLibrary from './photo_library.svg';

import iconBack from './icon_back.png';
import iconMenu from './icon_menu.svg';
import iconSearch from './icon_search.svg';
import iconPlusMain from './icon_plus_main.svg';
import iconFilter from './IcFilter.svg';
import patientPlus from './patient_plus.svg';
import logoIcon from './logo_icon.png';

import passwordInput from './password_input.svg';
import emailIcon from './email_icon.svg';

import searchBox from './search_box.svg';
import searchBoxDisabled from './search_box_disabled.svg';

import close from './close.svg';
import close2 from './close2.png';
import decline from './decline.svg';
import blueIks from './blue_iks.svg';
import iks from './iks.svg';
import iks2 from './iks2.svg';

import footerHome from './footer_home.png';
import footerNotification from './footer_notifications.png';
import footerReports from './footer_reports.png';

import user from './user.svg';
import userGroup3 from './user_group_3.png';
import userGroup5 from './user_group_5.png';
import patient from './patient.png';
import personIcon from './person_icon.svg';
import phoneIcon from './phone_icon.svg';

import grid from './grid.svg';
import list from './list.svg';
import list2 from './list2.svg';

import photoCamera from './photo_camera.svg';
import arrowDown from './arrow_down.png';
import arrowDropDown from './arrow_drop_down.svg';
import arrowLeft from './arrow_left.svg';
import arrowLeftView from './arrow-left.svg';
import arrowRightView from './arrow-right.svg';
import circleBlackMinus from './circle-black-minus.svg';
import circleBlackPlus from './circle-black-plus.svg';
import clockNormal from './clock_normal.svg';
import clockDanger from './clock_danger.svg';

import timerPlay from './timer_play.png';
import timerPause from './IcTimerPause.svg';
import timerClose from './IcTimerClose.svg';

import trash from './trash.svg';

import borderedPlus from './plus_bordered.png';
import circlePlus from './circle_plus.svg';
import iconNote from './IcRoundEditNote.svg';
import iconAlert from './IcAlertsLight.svg';
import iconCall from './IcCallLight.svg';
import iconHeart from './PhHeartbeatLight.svg';
import iconMeds from './MedOrderLight.svg';
import iconMessage from './IcMessageLight.svg';
import iconRecord from './IcRecords.svg';
import iconReport from './CarbonReportData.svg';
import iconSchedule from './IcScheduleLight.svg';
import videoPlayer from './video-player.svg';
import shapeChatBubbleR from './ShapeChatBubbleRight.svg';
import shapeChatBubbleL from './ShapeChatBubbleLeft.svg';
import icSendMessage from './IcSendMessage.svg';
import icChatPlus from './IcChatPlus.svg';

import icPerson from './IcPerson.svg';
import icStethoscope from './IcStethoscope.svg';
import icCalendar from './IcCalendar.svg';
import icCall from './IcCall.svg';
import icCallDialer from './icCallDialer.png';
import icDoc from './IcDoc.svg';
import icShield from './IcShield.svg';
import icPrivacy from './IcPrivacy.svg';
import icPaper from './IcPaper.svg';

import icBack from './IcArrowLeft.svg';
import icSearch from './IcSearch.svg';
import icEdit from './IcEdit.svg';
import icOxygen from './oxygen.svg';
import icGlucose from './glucose.svg';
import icHeartRate from './heart_rate.svg';
import icBreathRate from './breath_rate.svg';
import icBloodPressure from './blood_pressure.svg';
import icWeight from './weight.svg';
import icTemperature from './temperature.svg';
import icAddUser from './IcAddUser.svg';
import icPlus from './IcPlus.svg';
import icCloseGray from './IcCloseGray.svg';
import icMedication from './icon_cp_medication.png';
import icVital from './icon_cp_vital.png';
import icWellness from './icon_cp_wellness.png';
import icDiet from './icon_cp_diet.png';
import icNotification from './icon_cp_notification.png';
import icActivity from './icon_cp_activity.png';
import icHistory from './icon_cp_history.png';
import icCareTeam from './icon_cp_careteam.png';
import icInbox from './IcInbox.svg';
import icCloseRed from './IcCloseRed.svg';
import icAcceptGreen from './IcAcceptGreen.svg';
import icCallGreen from './IcCallGreen.svg';
import icCallVideo from './icCallVideo.svg';
import icMessagingOrange from './IcMessagingOrange.svg';
import icContactRed from './IcContactRed.svg';
import icEducationPurple from './IcEducationPurple.svg';
import icPrescribeBlue from './IcPrescribeBlue.svg';
import icCarePlanGreen from './IcCarePlanGreen.svg';
import icNoteBlue from './IcNoteBlue.svg';
import icCoordinatePink from './IcCoordinatePink.svg';
import icAlertGold from './IcAlertGold.svg';
import icAppointmentPurple from './IcAppointmentPurple.svg';
import icHome from './IcHome.svg';
import icHomeBlue from './IcHome_blue.svg';
import icInvitation from './IcInvitation.svg';
import icInvitationBlue from './IcInvitation_blue.svg';
import icReports from './IcReports.svg';
import icReportsBlue from './IcReports_blue.svg';
import icAdd from './IcAdd.svg';
import icAddBlue from './IcAdd_blue.svg';
import icHamburger from './IcHamburger.svg';
import icEye from './IcEye.svg';
import icCallBlue from './IcCallBlue.svg';
import icMessageBlue from './IcMessageBlue.svg';
import icMessageNew from './message_new.svg';
import icVideoBlue from './IcVideoBlue.svg';
import icCheckMark from './IcCheckMark.svg';
import icDayCalendar from './IcDayCalendar.svg';
import icClosePlain from './IcClosePlain.svg';
import icAttachmentRed from './IcAttachRed.svg';
import icScheduleGold from './IcScheduleGold.svg';
import icGroupPlus from './IcGroupPlus.svg';
import icAddToNote from './IcAddToNote.svg';
import icRefPatient from './IcRefPatient.svg';
import icRecordsGrey from './IcRecordsGrey.svg';
import icReportsGrey from './IcReportsGrey.svg';
import icAlertsGrey from './IcAlertsGrey.svg';
import icVideoGrey from './IcVideoGrey.svg';
import icCameraGrey from './IcCameraGrey.svg';
import icAttachmentGrey from './IcAttachmentGrey.svg';
import icPictureGrey from './IcPictureGrey.svg';
import icCloseGrey from './IcCloseGrey.svg';
import icCloseWhite from './IcCloseWhite.svg';
import icCloseWhite_1 from './IcCloseWhite_1.svg';
import icRightChevron from './IcRightChevron.svg';
import icRightChevronWhite from './IcRightChevronWhite.svg';
import icPdf from './IcPdf.svg';
import icPng from './IcPng.svg';
import icChevronRight from './IcChevronRight.svg';
import icChevronDown from './icChevronDown.svg';
import icChevronDownGray from './icChevronDownGray.svg';

import icNoWellness from './icn_no_wellness.png';
import icNoVitals from './icn_no_vitals.png';
import icNoActivity from './icn_no_activity.png';
import icNoDiet from './icn_no_diet.png';
import icNoMedications from './icn_no_medications.png';
import icNoCareteam from './icn_no_careteam.png';

import icMore from './icn_more.svg';
import icNoteItem from './icon_note_item.png';
import icCareplanItem from './icon_careplan_item.png';
import icMedicationItem from './icon_medication_item.png';

import icPhone from './icon_phone.svg';
import icMail from './icon_mail.svg';
import icSMS from './icon_sms.svg';
import icPhoneWhite from './icPhoneWhite.svg';
import icPadBackspace from './icPadBackspace.png';

import icCallCamera from './icCallCamera.png';
import icCallCameraMuted from './icCallCameraMuted.png';
import icCallMic from './icCallMic.png';
import icCallMicMuted from './icCallMicMuted.png';
import icCallMinimize from './icCallMinimize.png';
import icCallMaximize from './icCallMaximize.png';

import icFullPill from './icFullPill.svg';
import icFullPillSelected from './icFullPillSelected.svg';
import icThreeQuarterPill from './icThreeQuarterPill.svg';
import icThreeQuarterPillSelected from './icThreeQuarterPillSelected.svg';
import icHalfPill from './icHalfPill.svg';
import icHalfPillSelected from './icHalfPillSelected.svg';
import icOneQuarterPill from './icOneQuarterPill.svg';
import icOneQuarterPillSelected from './icOneQuarterPillSelected.svg';

import icWellnessSleep from './icWellnessSleep.svg';
import icWellnessBreathing from './icWellnessBreathing.svg';
import icWellnessEnergy from './icWellnessEnergy.svg';
import icWellnessMemory from './icWellnessMemory.svg';
import icWellnessDizziness from './icWellnessDizziness.svg';
import icWellnessChestPain from './icWellnessChestPain.svg';
import icWellnessWoundHealing from './icWellnessWoundHealing.svg';
import icWellnessSwelling from './icWellnessSwelling.svg';

import icDietSalt from './icDietSalt.svg';
import icDietGrains from './icDietGrains.svg';
import icDietGrocery from './icDietGrocery.svg';
import icDietCooking from './icDietCooking.svg';
import icDietVegs from './icDietVegs.svg';
import icDietFat from './icDietFat.svg';
import icDietMeal from './icDietMeal.svg';
import icDietNounGrain from './icDietNounGrain.svg';

import icSort from './ic_sort.png';

import qrCode from './qr_code.svg';

import infoIcon from './info.png';

// arrows
import chevronR from './ChevronR.svg';
import iHidePassword from './password-hide.png';
import iShowPassword from './password-show.png';

import icMenuBilling from './menu_billing.png';
import icTrash from './icTrash.svg';

const icons = {
  loader,
  iconBack,
  iconMenu,
  iconSearch,
  iconPlusMain,
  searchBox,
  searchBoxDisabled,
  footerHome,
  footerNotification,
  footerReports,
  close,
  close2,
  decline,
  blueIks,
  iks,
  iks2,
  user,
  userGroup3,
  userGroup5,
  patient,
  list,
  list2,
  grid,
  photoCamera,
  trash,
  arrowDown,
  arrowDropDown,
  timerPlay,
  timerPause,
  timerClose,
  borderedPlus,
  circlePlus,
  iconNote,
  iconAlert,
  iconCall,
  icCallDialer,
  iconHeart,
  iconMeds,
  iconMessage,
  iconRecord,
  iconReport,
  iconSchedule,
  qrCode,
  passwordInput,
  emailIcon,
  arrowLeft,
  arrowLeftView,
  arrowRightView,
  circleBlackMinus,
  circleBlackPlus,
  clockNormal,
  clockDanger,
  logoIcon,
  personIcon,
  phoneIcon,
  videoPlayer,
  infoIcon,
  patientPlus,
  addAttachment,
  addParticipant,
  addImage,
  addNote,
  addAppointment,
  addFromPhotoLibrary,
  chevronR,
  icEdit,
  iconFilter,
  icOxygen,
  icGlucose,
  icHeartRate,
  icBreathRate,
  icBloodPressure,
  icWeight,
  icTemperature,
  icSearch,
  icBack,
  icAddUser,
  icPlus,
  icCloseGray,
  icMedication,
  icVital,
  icActivity,
  icWellness,
  icDiet,
  icCareTeam,
  icNotification,
  icHistory,
  icInbox,
  icAcceptGreen,
  icCloseRed,
  icCallGreen,
  icCallVideo,
  icMessagingOrange,
  icContactRed,
  icEducationPurple,
  icCarePlanGreen,
  icPrescribeBlue,
  icNoteBlue,
  icCoordinatePink,
  icAlertGold,
  icAppointmentPurple,
  icHome,
  icHomeBlue,
  icInvitation,
  icInvitationBlue,
  icReports,
  icReportsBlue,
  icAdd,
  icAddBlue,
  icHamburger,
  icEye,
  icCallBlue,
  icMessageBlue,
  icMessageNew,
  icVideoBlue,
  icPerson,
  icStethoscope,
  icCalendar,
  icCall,
  icDoc,
  icShield,
  icPrivacy,
  icPaper,
  icNoActivity,
  icNoCareteam,
  icNoDiet,
  icNoMedications,
  icNoVitals,
  icNoWellness,
  icNoteItem,
  icCareplanItem,
  icMedicationItem,
  icMore,
  icPhone,
  icPhoneWhite,
  icPadBackspace,
  icSMS,
  icMail,
  icCallCamera,
  icCallCameraMuted,
  icCallMic,
  icCallMicMuted,
  icCallMinimize,
  icCallMaximize,
  icFullPill,
  icFullPillSelected,
  icThreeQuarterPill,
  icThreeQuarterPillSelected,
  icHalfPill,
  icHalfPillSelected,
  icOneQuarterPill,
  icOneQuarterPillSelected,
  icCheckMark,
  icDayCalendar,
  icSort,
  shapeChatBubbleR,
  shapeChatBubbleL,
  icSendMessage,
  icChatPlus,
  icClosePlain,
  icAttachmentRed,
  icScheduleGold,
  icGroupPlus,
  icAddToNote,
  icRefPatient,
  icRecordsGrey,
  icReportsGrey,
  icAlertsGrey,
  icVideoGrey,
  icCameraGrey,
  icAttachmentGrey,
  icPictureGrey,
  icCloseGrey,
  icCloseWhite,
  icCloseWhite_1,
  icRightChevron,
  icRightChevronWhite,
  icPdf,
  icPng,
  icChevronRight,
  icChevronDown,
  icChevronDownGray,
  icWellnessSleep,
  icWellnessBreathing,
  icWellnessEnergy,
  icWellnessMemory,
  icWellnessDizziness,
  icWellnessChestPain,
  icWellnessWoundHealing,
  icWellnessSwelling,
  icDietSalt,
  icDietGrains,
  icDietGrocery,
  icDietCooking,
  icDietVegs,
  icDietFat,
  icDietMeal,
  icDietNounGrain,
  iHidePassword,
  iShowPassword,
  icMenuBilling,
  icTrash,
};

export default icons;
