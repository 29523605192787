import styled from 'styled-components';

import EntryItem from '../Entry';
import { Container, Row, Text, Avatar } from 'common';
import {
  formatTitleAndName,
  getFullName,
  formatMemberDesignation,
} from 'utils/string';
import assets from 'assets';
import { buildStyledComponent, px2rem } from 'style';

export const AddItem = buildStyledComponent(
  'CareTeam_AddItem',
  styled(({ member, handleRemove, ...props }) => (
    <Container modifiers={['card']} {...props}>
      <Row modifiers={['spaceBetween', 'top']}>
        <Avatar user={member} modifiers={['squared']} width={40} height={40} />
        <img src={assets.icons.icCloseWhite} onClick={handleRemove} />
      </Row>
      <Text modifiers={['block', 'medium']}>{getFullName(member)}</Text>
      <Text modifiers={['blue', 'block', 'medium']}>
        {formatMemberDesignation(member.memberDesignation)}&nbsp;
      </Text>
    </Container>
  )),
  ({ theme }) => `
    display: inline-block;
    width: ${px2rem(120)};
    :not(:last-child) {
      margin-right: ${theme.dimensions.padding_2};
    }
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `
);

const Item = ({ item, ...props }) => {
  if (!item) {
    return null;
  }
  return (
    <EntryItem
      user={item.user}
      text={formatTitleAndName(item.user)}
      appointments={item.appointments}
      alerts={item.alerts}
      forCareTeam
      {...props}
    />
  );
};

export default Item;
