import { useState, useEffect, createContext, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';

import { AuthContext } from 'contexts/auth';
import { FETCH_PATIENT_RESPONSES } from 'graphql/queries';
import { PatientHomeContext } from 'pages/PatientHome/Context';

export const ProgressContext = createContext();

export const ProgressContextProvider = ({ children }) => {
  const { me } = useContext(AuthContext);
  const { patient } = useContext(PatientHomeContext);
  const [loadResponsesApi] = useLazyQuery(FETCH_PATIENT_RESPONSES, {
    onCompleted: (data) => {
      if (!data.patientResponses) {
        return;
      }
      const responses = data.patientResponses.responses;
      updateResponses(responses);
      const cpDict = {};
      data.patientResponses?.careplans?.forEach(
        (careplan) => (cpDict[careplan._id] = careplan)
      );
      updatePastCPs(cpDict);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const [responses, updateResponses] = useState([]);
  const [pastCPs, updatePastCPs] = useState({});

  const fetchResponses = async () => {
    await loadResponsesApi({ variables: { patient: patient._id } });
  };

  useEffect(() => {
    if (!me?._id) {
      updateResponses(null);
    } else {
      fetchResponses();
    }
  }, [me?._id]);

  return (
    <ProgressContext.Provider
      value={{
        responses,
        pastCPs,
        fetchResponses,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export default ProgressContextProvider;
