import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  dark: ({ theme }) => ({
    styles: `
      background-color: ${theme.colors.white};
      border: ${theme.dimensions.highlightBorder};
      color: ${theme.colors.secondary};
    `,
  }),
  error: ({ theme }) => ({
    styles: `
      border-color: ${theme.colors.danger};
    `,
  }),
  borderBottomOnly: () => ({
    styles: `
      border-width: 0;
      border-bottom-width: 1px;
    `,
  }),
  large: () => ({
    styles: ``,
  }),
  fullWidth: () => `
    width: 100%;
    min-height: ${px2rem(44)};
    border: 1px solid #3B4C6A;
  `,
};

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  border: 1px solid #7D8EB5;
  color: ${theme.colors.text};
  font-size: ${theme.dimensions.fontSizeLarge};
  border-radius: ${px2rem(6)};
  outline: none;
  padding: 0.3em;
  width: ${px2rem(40)};
  height: ${px2rem(40)};
  text-align: center;
`;

export default buildStyledComponent(
  'VerificationCodeInput_Wrapper',
  styled.input,
  styles,
  {
    modifierConfig,
  }
);
