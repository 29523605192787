import React from 'react';

import Content from './Content';
import Wrapper from './Wrapper';
import Title from './Title';

export function AuthLayoutV2({ children }) {
  return <Wrapper modifiers="fluid">{children}</Wrapper>;
}

AuthLayoutV2.Content = Content;
AuthLayoutV2.Title = Title;
