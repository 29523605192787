import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  center: () => ({
    styles: `
      justify-content: center;
      text-align: center;
    `,
  }),
  verticalContentCenter: () => `
    align-items: center;
  `,
  col: () => ({
    styles: `
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      -ms-flex-positive: 1;
    `,
  }),
  col_1: () => ({
    styles: `
      flex-basis: 8.33333333%;
      max-width: 8.33333333%;
      -ms-flex-preferred-size: 8.33333333%;
    `,
  }),
  col_2: () => ({
    styles: `
      flex-basis: 16.66666667%;
      max-width: 16.66666667%;
      -ms-flex-preferred-size: 16.66666667%;
    `,
  }),
  col_3: () => ({
    styles: `
      flex-basis: 25%;
      max-width: 25%;
      -ms-flex-preferred-size: 25%;
    `,
  }),
  col_4: () => ({
    styles: `
      flex-basis: 33.33333333%;
      max-width: 33.33333333%;
      -ms-flex-preferred-size: 33.33333333%;
    `,
  }),
  col_5: () => ({
    styles: `
      flex-basis: 41.66666667%;
      max-width: 41.66666667%;
      -ms-flex-preferred-size: 41.66666667%;
    `,
  }),
  col_6: () => ({
    styles: `
      flex-basis: 50%;
      max-width: 50%;
      -ms-flex-preferred-size: 50%;
    `,
  }),
  col_7: () => ({
    styles: `
      flex-basis: 58.33333333%;
      max-width: 58.33333333%;
      -ms-flex-preferred-size: 58.33333333%;
    `,
  }),

  col_8: () => ({
    styles: `
      flex-basis: 66.66666667%;
      max-width: 66.66666667%;
      -ms-flex-preferred-size: 66.66666667%;
    `,
  }),

  col_9: () => ({
    styles: `
      flex-basis: 75%;
      max-width: 75%;
      -ms-flex-preferred-size: 75%;
    `,
  }),
  col_10: () => ({
    styles: `
      flex-basis: 83.33333333%;
      max-width: 83.33333333%;
      -ms-flex-preferred-size: 83.33333333%;
    `,
  }),
  col_11: () => ({
    styles: `
      flex-basis: 91.66666667%;
      max-width: 91.66666667%;
      -ms-flex-preferred-size: 91.66666667%;
    `,
  }),
  col_12: () => ({
    styles: `
      flex-basis: 100%;
      max-width: 100%;
      -ms-flex-preferred-size: 100%;
    `,
  }),
  col_offset_1: () => ({
    styles: `
      margin-left: 8.33333333%;
    `,
  }),
  col_offset_2: () => ({
    styles: `
      margin-left: 16.66666667%;
    `,
  }),
  col_offset_3: () => ({
    styles: `
      margin-left: 25%;
    `,
  }),
  col_offset_4: () => ({
    styles: `
      margin-left: 33.33333333%;
    `,
  }),
  col_offset_5: () => ({
    styles: `
      margin-left: 41.66666667%;
    `,
  }),
  col_offset_6: () => ({
    styles: `
      margin-left: 50%;
    `,
  }),
  col_offset_7: () => ({
    styles: `
      margin-left: 58.33333333%;
    `,
  }),
  col_offset_8: () => ({
    styles: `
      margin-left: 66.66666667%;
    `,
  }),
  col_offset_9: () => ({
    styles: `
      margin-left: 75%;
    `,
  }),
  col_offset_10: () => ({
    styles: `
      margin-left: 83.33333333%;
    `,
  }),
  col_offset_11: () => ({
    styles: `
      margin-left: 91.66666667%;
    `,
  }),
  end: () => ({
    styles: `
      justify-content: flex-end;
      text-align: end;
    `,
  }),
  fluid: () => ({
    styles: `
      padding-left: 0;
      padding-right: 0;
    `,
  }),
  noDisplay: () => ({
    styles: `
      display: none;
    `,
  }),
  reverse: () => ({
    styles: `
      flex-direction: column-reverse;
    `,
  }),
  start: () => ({
    styles: `
      justify-content: flex-start;
      text-align: start;
    `,
  }),
  noPadding: () => ({
    styles: `
      padding: 0 !important;
    `,
  }),
  padding_2: () => `
    padding: ${px2rem(5)};
  `,
  padding_3: () => `
    padding: ${px2rem(2)};
  `,
  noVerticalPadding: () => `
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  `,
  flexBox: () => ({
    styles: `
      display: flex;
    `,
  }),
  verticalCenter: () => ({
    styles: `
      align-self: center;
    `,
  }),
  scroll: () => ({
    styles: `
      overflow: auto;
    `,
  }),
  flexColumn: () => `
    flex-direction: column;
  `,
};

const styles = ({ theme }) => `
  box-sizing: border-box;
  flex: 0 0 auto;
  padding: ${theme.dimensions.gridPad};
  position: relative;
`;

export default buildStyledComponent('Column', styled.div, styles, {
  modifierConfig,
});
