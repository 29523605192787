import { useCallback, useRef, useEffect } from 'react';

export const OutsideClicker = ({ handleClickOutside, children }) => {
  const ref = useRef(null);

  const checkOutsideClick = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClickOutside();
      }
    },
    [handleClickOutside]
  );

  useEffect(() => {
    document.addEventListener('click', checkOutsideClick, true);
    return () => {
      document.removeEventListener('click', checkOutsideClick, true);
    };
  }, [checkOutsideClick]);

  return <div ref={ref}>{children}</div>;
};

export default OutsideClicker;
