import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  > :first-child {
    text-align: center;
    margin: ${px2rem(50)} ${px2rem(15)} ${px2rem(20)} ${px2rem(15)};
    font-size: ${px2rem(30)};
    color: ${theme.colors.secondary};
    font-weight: ${theme.fontWeights.semiBold};
  }
  > :nth-child(2) {
    > * > * {
      display: flex;
      width: ${px2rem(60)};
      height: ${px2rem(60)};
      border-radius: ${px2rem(30)};
      font-size: ${px2rem(30)};
      margin: ${px2rem(5)} ${px2rem(10)};
    }

    >:last-child :first-child {
      :span {
        visibility: hidden;
      }
    }
    >:last-child :last-child {
      background: none;
      box-shadow: none;
    }
  }
  > :last-child {
    margin-bottom: ${px2rem(50)};
  }
`;

export default buildStyledComponent('CallPadWrapper', styled.div, styles);
