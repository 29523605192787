import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = () => `
  position: absolute;
  top: 0;
  left: ${px2rem(55)};
  right: ${px2rem(55)};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Center = buildStyledComponent(
  'MainLayout_Header_Center',
  styled.div,
  styles
);

export default Center;
