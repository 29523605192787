import styled from 'styled-components';
import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  background-color: ${theme.colors.primary};
  border-radius: ${px2rem(6)};
`;

export default buildStyledComponent('Summary_Card', styled(Container), styles);
