import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { FETCH_PATIENT_PROGRESS } from 'graphql/queries';

const useProgress = ({ patient }) => {
  const [progress, updateProgress] = useState(null);
  const [fetchProgressApi] = useLazyQuery(FETCH_PATIENT_PROGRESS, {
    onCompleted: (data) => {
      updateProgress(data.patientProgress || {});
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const fetchProgress = async () => {
    await fetchProgressApi({ variables: { patient: patient._id } });
  };

  return { progress, fetchProgress };
};

export default useProgress;
