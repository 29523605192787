import { useState } from 'react';

import {
  Row,
  ScrollView,
  Text,
  Button,
  BackButton,
} from '@gaz/gaz-components.public';
import { Container, Modal, Popover } from 'common';
import MedicationItem from './Item';
import Instruction from './Instruction';
import SearchMedication from './SearchMedication';
import assets from 'assets';

const AddMeds = ({ handleAdd, handleClose, noAlerts, forOrder }) => {
  const [editingMedication, setEditingMedication] = useState();
  const [deletingMedication, setDeletingMedication] = useState();
  const [addedMeds, updateAddedMeds] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let hasIncompleteMedication = false;

  Object.values(addedMeds).forEach((medication) => {
    if (!medication.frequency) {
      hasIncompleteMedication = true;
    }
  });

  const handleAddSearch = (medication) => {
    if (addedMeds[medication.ndc]) {
      return;
    }
    addedMeds[medication.ndc] = medication;
    updateAddedMeds({ ...addedMeds });
  };

  const handleBack = () => {
    if (editingMedication) {
      setEditingMedication(null);
    } else {
      handleClose();
    }
  };

  const handleDone = () => {
    handleAdd(addedMeds);
    handleClose();
  };

  const onRemove = (medication) => {
    setShowDeleteModal(true);
    setDeletingMedication(medication);
  };

  const onConfirmDelete = () => {
    delete addedMeds[deletingMedication.ndc];
    setShowDeleteModal(false);
    updateAddedMeds({ ...addedMeds });
  };

  const onEditMedication = (medication) => {
    setEditingMedication(medication);
  };

  const handleSaveInstruction = (
    medication,
    frequency,
    alerts,
    mods,
    quantity
  ) => {
    addedMeds[medication.ndc] = {
      ...medication,
      frequency,
      alerts,
      mods,
      quantity,
    };
    updateAddedMeds({ ...addedMeds });
  };

  const handleGrouping = (medication, medications, value, type) => {
    if (type === 'frequency') {
      addedMeds[medication.ndc] = {
        ...medication,
        frequency: value,
      };
      Object.keys(medications).forEach(
        (ndc) => (addedMeds[ndc] = { ...addedMeds[ndc], frequency: value })
      );
    } else if (type === 'alerts') {
      addedMeds[medication.ndc] = {
        ...medication,
        alerts: value,
      };
      Object.keys(medications).forEach(
        (ndc) => (addedMeds[ndc] = { ...addedMeds[ndc], alerts: value })
      );
    } else {
      addedMeds[medication.ndc] = {
        ...medication,
        mods: value,
      };
      Object.keys(medications).forEach(
        (ndc) => (addedMeds[ndc] = { ...addedMeds[ndc], mods: value })
      );
    }
    updateAddedMeds({ ...addedMeds });
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleBack} />
          <Text>Search and Add Medications</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        {editingMedication && (
          <Instruction
            medication={editingMedication}
            medications={addedMeds}
            handleClose={handleBack}
            handleDone={handleSaveInstruction}
            handleGrouping={handleGrouping}
            noAlerts={noAlerts}
            forOrder={forOrder}
          />
        )}
        {
          <Container
            modifiers={[
              'fluid',
              'fullHeight',
              'flexBox',
              editingMedication && 'noDisplay',
            ]}
          >
            <SearchMedication handleAdd={handleAddSearch} />
            <ScrollView>
              <Container>
                {Object.entries(addedMeds).map(([ndc, medication]) => (
                  <MedicationItem
                    medication={medication}
                    noAlerts={noAlerts}
                    key={ndc}
                    onRemove={() => {
                      onRemove(medication);
                    }}
                    onEdit={() => {
                      onEditMedication(medication);
                    }}
                  />
                ))}
              </Container>
            </ScrollView>
            <Container modifiers={['flexFixed', 'footer']}>
              <Row modifiers={['spaceBetween', 'middle']}>
                <Button
                  modifiers={['icon', 'transparent']}
                  image={assets.icons.icCloseGray}
                  onClick={handleClose}
                />
                <Button
                  modifiers={[
                    'primary',
                    'widthSmall',
                    'roundCorner',
                    (hasIncompleteMedication || addedMeds.length === 0) &&
                      'disabled',
                  ]}
                  onClick={handleDone}
                >
                  Done
                </Button>
              </Row>
            </Container>
          </Container>
        }
        <Popover
          open={showDeleteModal}
          onConfirm={onConfirmDelete}
          onClose={() => setShowDeleteModal(false)}
          footer
        >
          <Text modifiers={['block', 'center']}>
            Are you sure want to delete this medication?
          </Text>
        </Popover>
      </Modal.Body>
    </Modal>
  );
};

export default AddMeds;
