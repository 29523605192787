import { useState, useContext, useEffect, useMemo } from 'react';

import { Container } from 'common';
import Medication from './Medication';
import Vital from './Vital';
import Activity from './Activity';
import Appointment from './Appointment';
import List from './List';
import Question from './Question';
import { AuthContext } from 'contexts/auth';
import ProgressProvider, { ProgressContext } from './Context';
import { PatientHomeContext } from 'pages/PatientHome/Context';

const Progress = () => {
  const { me } = useContext(AuthContext);
  const { fetchResponses, setBackHandler } = useContext(PatientHomeContext);
  const [selectedSection, updateSelectedSection] = useState();
  const ContentComponent = () => {
    switch (selectedSection) {
      case 'medication':
        return <Medication />;
      case 'vital':
        return <Vital />;
      case 'wellness':
        return <Question type="wellness" />;
      case 'diet':
        return <Question type="diet" />;
      case 'activity':
        return <Activity />;
    }
    return <List handleItemSelect={updateSelectedSection} />;
  };

  useEffect(() => {
    if (!!me) {
      fetchResponses();
    }
  }, [me]);

  useEffect(() => {
    if (selectedSection) {
      setBackHandler(() => {
        updateSelectedSection(null);
        return false;
      });
    }
  }, [selectedSection]);

  if (!me) {
    return null;
  }

  return (
    <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
      <ContentComponent />
    </Container>
  );
};

const ProgressWithProvider = () => {
  return (
    <ProgressProvider>
      <Progress />
    </ProgressProvider>
  );
};

export default ProgressWithProvider;
