import EntryItem from '../Entry';
import { getVitalTypeAttr } from 'utils/helper';
import { VITALS } from 'utils/constants';

const Item = ({ vital, ...props }) => {
  if (!vital) {
    return null;
  }
  return (
    <EntryItem
      icon={getVitalTypeAttr(vital.id).icon}
      text={VITALS[vital.id]}
      frequency={vital.frequency}
      alerts={vital.alerts}
      alertsType={vital.id}
      {...props}
    />
  );
};

export default Item;
