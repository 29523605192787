import { useHistory } from 'react-router';

import { Container } from 'common';
import PatientCard from './PatientCard';

const TimeList = ({ hidden, data }) => {
  const history = useHistory();
  return (
    <Container modifiers={[hidden && 'noDisplay']}>
      {data?.map((record) => (
        <PatientCard
          key={record.patient._id}
          onClick={({ _id }) => history.push(`/reports/${_id}`)}
          record={record}
        />
      ))}
    </Container>
  );
};

export default TimeList;
