import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  primary: ({ theme }) => `
    color: ${theme.colors.primary};
    font-weight: ${theme.fontWeights.bold};
  `,
  blue: ({ theme }) => `
    color: ${theme.colors.primary};
  `,
  secondaryColor: ({ theme }) => `
    color: ${theme.colors.secondary};
  `,
  secondary: ({ theme }) => `
    color: ${theme.colors.textSecondary};
  `,
  dark: ({ theme }) => `
    color: ${theme.colors.gray1};
  `,
  info: ({ theme }) => `
    color: ${theme.colors.info};
  `,
  success: ({ theme }) => `
    color: ${theme.colors.success};
  `,
  danger: ({ theme }) => `
    color: ${theme.colors.danger};
  `,
  bold: ({ theme }) => `
    font-weight: ${theme.fontWeights.bold};
  `,
  inline: ({ theme }) => `
    display: inline-block;
  `,
  noPadding: ({ theme }) => `
    padding: 0;
  `,
  noMargin: () => `
    margin: 0;
  `,
  block: ({ theme }) => `
    display: block;
    margin: ${px2rem(5)} 0;
    padding: 0;
  `,
  white: ({ theme }) => `
    color: ${theme.colors.white};
  `,
  orange: ({ theme }) => `
    color: ${theme.colors.orange};
  `,
  xxSmall: () => `
    font-size: ${px2rem(10)};
  `,
  xSmall: ({ theme }) => `
    font-size: ${px2rem(12)};
  `,
  small: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeSmall} !important;
  `,
  medium: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeMedium} !important;
  `,
  large: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeLarge} !important;
  `,
  xLarge: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeXLarge} !important;
  `,
  center: () => `
    text-align: center;
  `,
  start: () => `
    text-align: left;
  `,
  end: () => `
    text-align: right;
  `,
  capitalize: () => `
    text-transform: capitalize;
  `,
  preserveLineBreak: () => `
    white-space: pre-line;
  `,
  breakAll: () => `
    word-break: break-all;
  `,
  light: ({ theme }) => `
    font-weight: ${theme.fontWeights.light};
  `,
  mediumWeight: ({ theme }) => `
    font-weight: ${theme.fontWeights.medium};
  `,
  normalWeight: ({ theme }) => `
    font-weight: ${theme.fontWeights.normal};
  `,
  secondaryFont: ({ theme }) => `
    font-family: ${theme.fonts.secondary} !important;
  `,
  topPadding: ({ theme }) => `
    padding-top: ${theme.dimensions.paragraphPadding};
  `,
  muted: ({ theme }) => `
    color: ${theme.colors.gray5};
  `,
  pageTitle: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeLarge};
  `,
  heavy: ({ theme }) => `
    font-weight: ${theme.fontWeights.bold};
  `,
  h2: ({ theme }) => `
    font-weight: ${theme.fontWeights.semiBold};
    font-size: ${theme.dimensions.fontSizeMedium};
  `,
  h3: ({ theme }) => `
    font-weight: ${theme.fontWeights.medium};
    font-size: ${theme.dimensions.fontSizeMedium};
    color: ${theme.colors.text};
  `,
  body: ({ theme }) => `
    font-weight: ${theme.fontWeights.normal};
    color: ${theme.colors.text};
    font-size: ${theme.dimensions.fontSizeNormal};
  `,
  subtitle: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeNormal};
    font-weight: ${theme.fontWeights.medium};
  `,
  semiBold: ({ theme }) => `
    font-weight: ${theme.fontWeights.semiBold} !important;
  `,
  darkGrey: ({ theme }) => `
    color: ${theme.colors.darkGrey};
  `,
  oneLiner: () => `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `,
};

const styles = ({ theme }) => `
  padding: 0 ${px2rem(5)};
  color: ${theme.colors.text};
  word-break: break-word;
  font-size: ${theme.dimensions.fontSize};
  font-weight: ${theme.fontWeights.normal};
`;

export const Text = buildStyledComponent('Text', styled.span, styles, {
  modifierConfig,
});

export default Text;
