import { useState } from 'react';
import { Container, Row, Column, Button, Text, HR, Popover } from 'common';
import assets from 'assets';
import { displayTime } from 'utils/time';
import { STATES_LIST_BY_CODE } from 'utils/constants';

const LICENSE_STATUSES = {
  Valid: { text: 'Valid', style: 'success' },
  Checking: { text: 'Checking', style: 'blue' },
  Expired: { text: 'Expired', style: 'danger' },
  Invalid: { text: 'Invalid', style: 'danger' },
};

const LicenseItem = ({ license, handleDelete, ...props }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const handleConfirmDelete = () => {
    setShowConfirm(false);
    handleDelete(license);
  };

  return (
    <Container modifiers={['fluid', 'bottomGutters_2']}>
      <Row modifiers={['spaceBetween', 'middle', 'alignTextTop']}>
        <Column modifiers={['col_6']}>
          <Text modifiers={['block']}>
            State:{' '}
            <Text modifiers={['primary']}>
              {STATES_LIST_BY_CODE[license.stateCode]}
            </Text>
          </Text>
        </Column>
        <Column modifiers={['col_6']}>
          <Text modifiers={['block']}>
            Status:{' '}
            <Text modifiers={[LICENSE_STATUSES[license.status]?.style]}>
              {LICENSE_STATUSES[license.status].text}
            </Text>
          </Text>
        </Column>
      </Row>
      <Row modifiers={['spaceBetween', 'middle']}>
        <Column modifiers={['col']}>
          <Text modifiers={['block']}>
            License Number:{' '}
            <Text modifiers={['primary']}>{license.licenseNumber}</Text>
          </Text>
        </Column>
      </Row>
      <Row modifiers={['spaceBetween', 'middle']}>
        <Column modifiers={['col']}>
          <Text modifiers={['block']}>
            Expired at:{' '}
            <Text modifiers={['primary']}>
              {displayTime(license.expiredAt, 'MM/DD/yyyy')}
            </Text>
          </Text>
        </Column>
        <Column>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icTrash}
            width={20}
            height={20}
            onClick={() => setShowConfirm(true)}
          />
        </Column>
      </Row>
      <HR modifiers={['primary', 'height_3']} />
      {showConfirm && (
        <Popover
          open
          footer
          onClose={() => setShowConfirm(false)}
          onConfirm={handleConfirmDelete}
          title="Are you sure want to delete the license?"
        >
          <Text modifiers={['block']}>
            State:{' '}
            <Text modifiers={['blue']}>
              {STATES_LIST_BY_CODE[license.stateCode]}
            </Text>
          </Text>
          <Text modifiers={['block']}>
            Expiry Date:{' '}
            <Text modifiers={['blue']}>
              {displayTime(license.expiredAt, 'MM/DD/yyyy')}
            </Text>
          </Text>
          <Text modifiers={['block']}>
            Status:{' '}
            <Text modifiers={[LICENSE_STATUSES[license.status]?.style]}>
              {license.status}
            </Text>
          </Text>
        </Popover>
      )}
    </Container>
  );
};

export default LicenseItem;
