import { useState, useMemo, useEffect } from 'react';

import { PROGRESS_ITEMS } from 'utils/constants';
import MeasureDetail from './MeasureDetail';
import ListItem, { getItemContent } from './ListItem';
import { filterCareplanSchedulesByItemTime } from 'utils/careplan';
import { toLocalTime } from 'utils/time';

const Therapeutics = ({ responses, careplans, backHandler }) => {
  const [selectedMeasure, updateSelectedMeasure] = useState();
  const responseData = useMemo(() => {
    const data = {
      medication: {
        icon: PROGRESS_ITEMS['medication'].icon,
        title: 'Medication',
        positiveTitle: 'Taken',
        readings: 0,
        positivePct: 'N/A',
        responses: [],
      },
      activity: {
        icon: PROGRESS_ITEMS['activity'].icon,
        title: 'Activity',
        positiveTitle: 'Completed',
        readings: 0,
        positivePct: 'N/A',
        responses: [],
      },
      wellness: {
        icon: PROGRESS_ITEMS['wellness'].icon,
        title: 'Wellness',
        positiveTitle: 'Positive',
        readings: 0,
        positivePct: 'N/A',
        responses: [],
      },
      diet: {
        icon: PROGRESS_ITEMS['diet'].icon,
        title: 'Diet',
        positiveTitle: 'Positive',
        readings: 0,
        positivePct: 'N/A',
        responses: [],
      },
    };
    // responses;
    const totals = {},
      positives = {};
    responses?.forEach((dailyResponse) => {
      const answereds = {};
      const careplan = careplans[dailyResponse.careplan];
      if (!careplan) {
        return;
      }
      const cpContent = filterCareplanSchedulesByItemTime(
        careplan,
        ['medication', 'activity', 'wellness', 'diet'],
        toLocalTime(dailyResponse.date)
      );
      dailyResponse.responses?.forEach((response) => {
        const type = response.measure;
        if (type === 'vital') {
          return;
        }
        if (response.isPositive === true || response.isPositive === false) {
          answereds[type] = true;
        }
        if (!totals[type]) {
          totals[type] = 1;
        } else {
          totals[type]++;
        }
        if (response.isPositive === true) {
          if (!positives[type]) {
            positives[type] = 1;
          } else {
            positives[type]++;
          }
        }
        const responseContent = {
          ...response,
          content: getItemContent(cpContent[type], response),
        };
        data[type].responses.push(responseContent);
      });
      Object.keys(totals).forEach((type) => {
        if (answereds[type]) {
          data[type].readings++;
        }
      });
    });

    Object.keys(totals).forEach((type) => {
      if (totals[type] > 0) {
        data[type].positivePct = Math.round(
          ((positives[type] || 0) / totals[type]) * 100
        );
      }
    });

    return data;
  }, [responses]);

  useEffect(() => {
    if (selectedMeasure) {
      backHandler.current = () => updateSelectedMeasure(null);
    }
  }, [selectedMeasure]);

  return selectedMeasure ? (
    <MeasureDetail data={selectedMeasure} />
  ) : (
    <>
      {Object.values(responseData).map((data) => (
        <ListItem
          key={data.title}
          icon={data.icon}
          title={data.title}
          positiveTitle={data.positiveTitle}
          readings={data.readings}
          positivePct={data.positivePct}
          handleSelect={() => updateSelectedMeasure(data)}
        />
      ))}
    </>
  );
};

export default Therapeutics;
