import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

import { Container, Button } from '@gaz/gaz-components.public';
import { TextArea } from 'common';
import { buildStyledComponent, px2rem } from 'style';

const Component = React.forwardRef(
  ({ name, value, clearValue, handleChange, ...props }, innerRef) => {
    const handleChangeValue = (event) => {
      handleChange(event.target.value);
    };
    return (
      <Container modifiers={['fluid']} {...props}>
        <Field
          modifiers={['noteItem']}
          name={name}
          value={value}
          component={TextArea}
          onChange={handleChangeValue}
          innerRef={innerRef}
        />
        {/*<Button
          modifiers={['outlineDanger', 'small', 'transparent']}
          onClick={() => {
            innerRef?.current?.focus();
            clearValue();
          }}
        >
          Clear All
        </Button>*/}
      </Container>
    );
  }
);

const modifierConfig = {};

const styles = ({ theme }) => `
  textarea {
    height: ${px2rem(150)};
    font-size: ${theme.dimensions.fontSizeMedium};
  }
  button {
    position: absolute;
    right: ${theme.dimensions.padding_1};
    bottom: ${theme.dimensions.padding_2};
  }

`;

export const TextInput = buildStyledComponent(
  'TextInput',
  styled(Component),
  styles,
  {
    modifierConfig,
  }
);

export default TextInput;
