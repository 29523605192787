import React, { useContext } from 'react';
import styled from 'styled-components';
import { buildStyledComponent } from 'style';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import {
  Container,
  Row,
  Column,
  // FormPinInput,
  Text,
  Button,
  A,
} from '@gaz/gaz-components.public';

import { FormPinInput } from 'common';
import assets from 'assets';
import { maskString, MASK_TYPE } from 'utils/string';
import { AuthContext } from 'contexts/auth';
import { VERIFY_CODE, REQUEST_CODE } from 'graphql/mutations';
import schema from 'utils/schema';
import urls from 'routes/urls';

const codeSchema = Yup.object().shape({
  code: schema.verificationCode,
});

const initialValues = {
  code: '',
};

const formStyles = () => `
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const StyledForm = buildStyledComponent(
  'Forms_VerificationCode',
  styled(Form),
  formStyles
);

export default function VerificationCode({ history, location }) {
  const { updateAuth } = useContext(AuthContext);
  const [verifyCode] = useMutation(VERIFY_CODE);
  const [requestCode] = useMutation(REQUEST_CODE);

  const { fullName, email, phone } = location.state || {};

  const handleClickResend = async () => {
    await requestCode({
      variables: {
        fullName,
        email,
        phone,
      },
    });
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await verifyCode({
        variables: {
          code: values.code,
          email,
        },
        update: async (cache, { data: { verifyCode } }) => {
          await updateAuth(verifyCode.user, verifyCode.token);
          history.push(urls.ONBOARD);
        },
      });
    } catch (error) {
      resetForm();
    }
  };

  return (
    <Container modifiers={['flexBox', 'fullHeight', 'backgroundWhite']}>
      <Container>
        <Row>
          <Column modifiers={['col_2']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icBack}
              onClick={() => history.goBack()}
            />
          </Column>
        </Row>
      </Container>
      <Row modifiers={['withGutters']}>
        <Column modifiers={['col_12']}>
          <img
            src={assets.images.imgBrandName}
            alt=""
            height={35}
            width="100%"
            style={{ objectFit: 'contain' }}
          />
        </Column>
        <Column modifiers={['col_12', 'flexBox', 'center']}>
          <Text modifiers={['h2', 'bold', 'topPadding']}>Verification</Text>
        </Column>
      </Row>

      <Formik
        initialValues={initialValues}
        validationSchema={codeSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <StyledForm>
            <Row>
              <Text modifiers={['primary', 'large']}>
                Enter your verification code
              </Text>
            </Row>
            <Row>
              <Text modifiers={['topPadding']}>
                Please enter the verification code we sent to your email address{' '}
                {maskString(email, MASK_TYPE.EMAIL)} and mobile number{' '}
                {maskString(phone, MASK_TYPE.PHONE)}.
              </Text>
            </Row>
            <Row modifiers={['withGutters']}>
              <Column modifiers={['col_12', 'fluid']}>
                <Field
                  name="code"
                  fields={5}
                  fitWidth
                  component={FormPinInput}
                />
              </Column>
            </Row>
            <Row modifiers={['withGutters', 'center']}>
              <Text modifiers={['block']}>
                If you haven’t received code in 5 mins, please click on{' '}
                <A
                  style={{ textDecoration: 'underline' }}
                  onClick={handleClickResend}
                >
                  RESEND
                </A>
              </Text>
            </Row>
            <Row
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
              modifiers={['withGutters', 'center']}
            >
              <Button
                type="submit"
                modifiers={['widthMedium', 'primary', !isValid && 'disabled']}
              >
                Continue
              </Button>
            </Row>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
}
