import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

const trackpubsToTracks = (trackMap) =>
  Array.from(trackMap.values())
    .map((publication) => publication.track)
    .filter((track) => track !== null);

const Participant = ({ participant, muted, videoMuted, ...props }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [audioMuted, setAudioMuted] = useState(muted === true);
  const [hideVideo, setHideVideo] = useState(videoMuted === true);

  const videoRef = useRef();
  const audioRef = useRef();

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => [track, ...videoTracks]);
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => [track, ...audioTracks]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    const trackEnabled = (track) => {
      if (track.kind === 'video') {
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
      } else {
        setAudioTracks(trackpubsToTracks(participant.audioTracks));
      }
    };

    const trackDisabled = (track) => {
      if (track.kind === 'video') {
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
      } else {
        setAudioTracks(trackpubsToTracks(participant.audioTracks));
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.on('trackEnabled', trackEnabled);
    participant.on('trackDisabled', trackDisabled);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      if (videoTrack.isEnabled || videoTrack.isTrackEnabled) {
        videoTrack.attach(videoRef.current);
        setHideVideo(false);
      } else {
        videoTrack.detach();
        setHideVideo(true);
      }
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      if (audioTrack.isEnabled || audioTrack.isTrackEnabled) {
        audioTrack.attach(audioRef.current);
        setAudioMuted(false);
      } else {
        audioTrack.detach();
        setAudioMuted(true);
      }
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <Container modifiers={['fullHeight', 'fluid']} {...props}>
      <Container modifiers={['fluid', 'fullHeight', hideVideo && 'noDisplay']}>
        <video ref={videoRef} autoPlay={true} />
      </Container>
      <audio ref={audioRef} autoPlay={true} muted={audioMuted} />
    </Container>
  );
};

const styles = ({ ...props }) => `
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  background-image: url(${props.user?.photo?.url});
`;

export default buildStyledComponent('Participant', styled(Participant), styles);
