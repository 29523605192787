import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1E88E5;
  border-radius: 8px 8px 0px 0px;
  padding: ${px2rem(8)};
  box-shadow: 0px 1px 2px rgba(46, 41, 78, 0.02), 0px 4px 8px rgba(46, 41, 78, 0.08);
`;

export default buildStyledComponent('Tabs_Header', styled.div, styles);
