import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import { buildStyledComponent, px2rem } from 'style';
import assets from 'assets';

import {
  ScrollView,
  Container,
  Row,
  Column,
  HR,
  VR,
  Text,
  Button,
} from '@gaz/gaz-components.public';

const MeasureResultButton = buildStyledComponent(
  'MeasureResultButton',
  styled(({ title, value, icon, ...props }) => (
    <Container modifiers={['fluid']} {...props}>
      <Row modifiers={['spaceBetween', 'middle']}>
        <img src={icon} />
        <Text
          modifiers={value >= 75 ? ['success', 'bold'] : ['danger', 'bold']}
        >{`${value}%`}</Text>
      </Row>
      <Row modifiers={'center'}>
        <Text modifiers={['primary', 'small']}>{title}</Text>
      </Row>
    </Container>
  )),
  ({ theme }) => `
    box-shadow: ${px2rem(1)} ${px2rem(4)} ${px2rem(4)} ${theme.colors.darkGrey};
    border-radius: ${theme.dimensions.borderRadius_1};

    >:first-child {
      margin-bottom: ${theme.dimensions.padding_1};

      img {
        height: ${px2rem(30)};
        margin-left: ${px2rem(5)};
      }

      >:nth-child(2) {
        flex: 1 0 auto;
        text-align: center;
      }
    }

    margin: ${theme.dimensions.padding_1} 0;
  `
);

const ResultsSection = ({ results }) => {
  const [activeSelected, setActiveSelected] = useState(true);

  const showMeasureDetail = (measureType) => {};

  return (
    <Container modifiers={['fluid']}>
      <Row modifiers={['spaceAround']}>
        <Column modifiers={['col_4']}>
          <MeasureResultButton
            title="Vitals"
            value={75}
            icon={assets.icons.iconHeart}
            onClick={() => showMeasureDetail('vitals')}
          />
        </Column>
        <Column modifiers={['col_4']}>
          <MeasureResultButton
            title="Medications"
            value={50}
            icon={assets.icons.iconAlert}
            onClick={() => showMeasureDetail('medications')}
          />
        </Column>
        <Column modifiers={['col_4']}>
          <MeasureResultButton
            title="Activity"
            value={75}
            icon={assets.icons.iconAlert}
            onClick={() => showMeasureDetail('activity')}
          />
        </Column>
      </Row>
      <Row modifiers={['spaceAround']}>
        <Column modifiers={['col_4']}>
          <MeasureResultButton
            title="Wellness"
            value={75}
            icon={assets.icons.iconAlert}
            onClick={() => showMeasureDetail('wellness')}
          />
        </Column>
        <Column modifiers={['col_4']}>
          <MeasureResultButton
            title="Diet"
            value={50}
            icon={assets.icons.iconAlert}
            onClick={() => showMeasureDetail('diet')}
          />
        </Column>
        <Column modifiers={['col_4']}>
          <MeasureResultButton
            title="Schedule"
            value={50}
            icon={assets.icons.iconAlert}
            onClick={() => showMeasureDetail('schedule')}
          />
        </Column>
      </Row>
    </Container>
  );
};

export default ResultsSection;
