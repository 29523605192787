import React, { useContext } from 'react';

import { BackButton } from '@gaz/gaz-components.public';

import { PatientHomeContext } from './Context';

export default () => {
  const { goBack } = useContext(PatientHomeContext);

  return <BackButton onClick={goBack} />;
};
