import React from 'react';
import { useFab } from 'contexts/fab';
import { useNavMenu } from 'contexts/navMenu';

import { Button } from '@gaz/gaz-components.public';
import Header from './Header';
import Footer from './Footer';
import NavMenu from './NavMenu';
import Wrapper from './Wrapper';
import Content from './Content';
import SideMenu from './SideMenu';
import FabWrapper from './FabWrapper';

export const MainLayout = ({ children }) => {
  const fab = useFab();
  const navMenu = useNavMenu();
  return (
    <Wrapper>
      {children}
      {!navMenu.hidden && <NavMenu />}
      {!fab.hidden && (
        <FabWrapper>
          <Button
            image={fab.icon}
            style={{ width: 48, height: 48 }}
            modifiers={['icon', 'primary', 'round']}
            onClick={fab.action}
          />
        </FabWrapper>
      )}
    </Wrapper>
  );
};

MainLayout.Header = Header;
MainLayout.Content = Content;
MainLayout.SideMenu = SideMenu;
MainLayout.Footer = Footer;
