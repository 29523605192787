import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Container, BackButton } from '@gaz/gaz-components.public';
import { MainLayout } from 'layouts';
import {
  FETCH_INDIVIDUAL_INVITES,
  FETCH_ADMIN_GROUP_INVITES,
  FETCH_ADMIN_PRACTICE_INVITES,
  FETCH_ALL_INVITES,
} from 'graphql/queries';
import { ACCEPT_OR_DECLINE_INVITE, CANCEL_INVITE } from 'graphql/mutations';
import { client } from 'graphql/client';

import { InvitationCard, Tabs } from 'common';
import { loadingVar } from 'graphql/cache';

const { Header, Content } = MainLayout;

const getInvitations = (allInvites, received, category) => {
  const invitations = allInvites?.find(
    (i) => i.received === received && i.category === category
  );

  if (invitations) {
    return invitations;
  }

  return {
    received,
    category,
    invites: [],
    total: 0,
  };
};

export default function Invitations({ history }) {
  const [topTab, setTopTab] = useState(0);
  const [subTab, setSubTab] = useState(0);
  const [fetchIndividualInvites] = useLazyQuery(FETCH_INDIVIDUAL_INVITES, {
    onCompleted: ({ individualInvites }) => {
      const { received, category } = individualInvites;
      const newData = allInvites?.map((i) =>
        i.received === received && i.category === category
          ? individualInvites
          : i
      );

      client.writeQuery({
        query: FETCH_ALL_INVITES,
        data: {
          allInvites: newData,
        },
      });
    },
  });
  const [
    fetchAllInvites,
    { data: { allInvites } = {}, loading: allInvitesLoading },
  ] = useLazyQuery(FETCH_ALL_INVITES);
  const [
    fetchAdminPracticeInvites,
    { data: { adminPracticeInvites } = {}, loading: adminPracticesLoading },
  ] = useLazyQuery(FETCH_ADMIN_PRACTICE_INVITES);
  const [
    fetchAdminGroupInvites,
    { data: { adminGroupInvites } = {}, loading: adminGroupsLoading },
  ] = useLazyQuery(FETCH_ADMIN_GROUP_INVITES);
  const [acceptOrDeclineInvite] = useMutation(ACCEPT_OR_DECLINE_INVITE);
  const [cancelInvite] = useMutation(CANCEL_INVITE);

  const loading =
    allInvitesLoading || adminPracticesLoading || adminGroupsLoading;
  loadingVar(loading);

  const received = topTab === 0 ? true : false;
  const category =
    subTab === 0 ? 'providers' : subTab === 1 ? 'patients' : 'groups';

  useEffect(() => {
    fetchAllInvites();
    fetchAdminPracticeInvites();
    fetchAdminGroupInvites();
  }, [fetchAllInvites, fetchAdminPracticeInvites, fetchAdminGroupInvites]);

  useEffect(() => {
    fetchIndividualInvites({
      variables: {
        received,
        category,
      },
    });
  }, [received, category, fetchIndividualInvites]);

  const handleTabChange = (tab) => (newTab) => {
    if (tab === 'sub') {
      setSubTab(newTab);
    }
    if (tab === 'top') {
      setTopTab(newTab);
    }
  };

  const handleAcceptOrDecline = (id, accepting) => {
    acceptOrDeclineInvite({
      variables: {
        id,
        accepting,
      },
      update: (cache, { data }) => {
        if (data.acceptOrDeclineInvite) {
          const newData = allInvites.map((i) => {
            if (i.received === received && i.category === category) {
              const remaingInvites = i.invites.filter((ii) => ii._id !== id);

              return {
                __typename: 'IndividualInvites',
                received,
                category,
                invites: remaingInvites,
                total: i.total - 1,
              };
            }
            return i;
          });

          cache.writeQuery({
            query: FETCH_ALL_INVITES,
            data: { allInvites: newData },
          });
        }
      },
    });
  };

  const handleCancel = (id) => {
    cancelInvite({
      variables: {
        id,
      },
      update: (cache, { data }) => {
        if (data.cancelInvite) {
          const newData = allInvites.map((i) => {
            if (i.received === received && i.category === category) {
              const remaingInvites = i.invites.filter((ii) => ii._id !== id);

              return {
                __typename: 'IndividualInvites',
                received,
                category,
                invites: remaingInvites,
                total: i.total - 1,
              };
            }
            return i;
          });

          cache.writeQuery({
            query: FETCH_ALL_INVITES,
            data: {
              allInvites: newData,
            },
          });
        }
      },
    });
  };

  const handleClickGroup = (isPractice, groupId) => {
    const type = isPractice ? 'practice' : 'group';
    history.push(`/groups/${groupId}?type=${type}`);
  };

  const invitations = getInvitations(allInvites, received, category);
  const adminPracticesTotalReceived = adminPracticeInvites?.reduce(
    (acc, cur) => acc + cur.totalReceived,
    0
  );
  const adminPracticesTotalSent = adminPracticeInvites?.reduce(
    (acc, cur) => acc + cur.totalSent,
    0
  );
  const adminGroupsTotalReceived = adminGroupInvites?.reduce(
    (acc, cur) => acc + cur.totalReceived,
    0
  );
  const adminGroupsTotalSent = adminGroupInvites?.reduce(
    (acc, cur) => acc + cur.totalSent,
    0
  );
  let receivedTotal =
    allInvites?.reduce((acc, cur) => {
      if (cur.received) {
        return acc + cur.total;
      }
      return acc;
    }, 0) +
    adminPracticesTotalReceived +
    adminGroupsTotalReceived;
  let sentTotal =
    allInvites?.reduce((acc, cur) => {
      if (!cur.received) {
        return acc + cur.total;
      }
      return acc;
    }, 0) +
    adminPracticesTotalSent +
    adminGroupsTotalSent;

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
        <Header.Center>Invitations</Header.Center>
      </Header>
      <Content>
        <Tabs current={topTab} onChange={handleTabChange('top')}>
          <Tabs.Tab title="Received" notificationCount={receivedTotal} />
          <Tabs.Tab title="Sent" notificationCount={sentTotal} />
        </Tabs>
        <Container modifiers={['fluid']}>
          <Tabs current={subTab} onChange={handleTabChange('sub')}>
            <Tabs.Tab
              title="Providers"
              notificationCount={
                getInvitations(allInvites, received, 'providers').total
              }
              modifiers={['smallPadding', 'noTab']}
            />
            <Tabs.Tab
              title="Patients"
              notificationCount={
                getInvitations(allInvites, received, 'patients').total
              }
              modifiers={['smallPadding', 'noTab']}
            />
            <Tabs.Tab
              title="Groups"
              notificationCount={
                getInvitations(allInvites, received, 'groups').total +
                (received
                  ? adminPracticesTotalReceived + adminGroupsTotalReceived
                  : adminPracticesTotalSent + adminGroupsTotalSent)
              }
              modifiers={['smallPadding']}
            />
          </Tabs>
        </Container>
        <Container>
          {category === 'groups' &&
            adminPracticeInvites.map((invite) => (
              <InvitationCard
                key={invite.practice._id}
                invite={invite}
                isAdminGroup
                received={received}
                onRowClick={() => handleClickGroup(true, invite.practice._id)}
              />
            ))}
          {category === 'groups' &&
            adminGroupInvites.map((invite) => (
              <InvitationCard
                key={invite.group._id}
                invite={invite}
                isAdminGroup
                received={received}
                onRowClick={() => handleClickGroup(false, invite.group._id)}
              />
            ))}
          {invitations?.invites.map((invite) => (
            <InvitationCard
              key={invite._id}
              received={received}
              invite={invite}
              onAcceptOrDecline={handleAcceptOrDecline}
              onCancel={handleCancel}
            />
          ))}
        </Container>
      </Content>
    </MainLayout>
  );
}
