import { Formik, Field, Form } from 'formik';

import { Container, Row, Column, Text, FormInput } from 'common';
import { CustomRow, CustomField } from '../../Frequency/Items';

const BloodPressureAlerts = ({ initialValues, formRef }) => {
  const defaultValues = initialValues || {
    value: 200,
    value2: 5,
    periodValue: 5,
  };
  return (
    <Formik initialValues={defaultValues} innerRef={formRef}>
      <Form>
        <Container modifiers={['fluid']}>
          <CustomRow title="Current Weight" hasThreeComponents>
            <CustomField name="value" type="number" />
            <Text>lbs</Text>
          </CustomRow>
          <CustomRow title="Not Gained More Than" hasThreeComponents>
            <CustomField name="value2" type="number" />
            <Text>lbs</Text>
          </CustomRow>
          <CustomRow title="Period of time" hasThreeComponents>
            <CustomField name="periodValue" type="number" />
            <Text>days</Text>
          </CustomRow>
        </Container>
      </Form>
    </Formik>
  );
};

export default BloodPressureAlerts;
