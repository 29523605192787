import {
  createContext,
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  FETCH_PATIENT_CAREPLANS,
  FETCH_NOTES,
  FETCH_DIRECT_MESSAGES,
  FETCH_EFAX_MESSAGES,
} from 'graphql/queries';

import { EVENTS, SocketContext } from 'contexts/socket';

export const CoordinateContext = createContext();

const CoordinateProvider = ({ children }) => {
  const [careplans, setCareplans] = useState(null);
  const [notes, setNotes] = useState(null);
  const [directMessages, setDirectMessages] = useState(null);
  const [eFaxMessages, setEFaxMessages] = useState(null);
  const [careplanPeriod, updateCareplanPeriod] = useState('3m');
  const [notePeriod, updateNotePeriod] = useState('3m');
  const [directMessagePeriod, updateDirectMessagePeriod] = useState('3m');
  const [eFaxPeriod, updateEFaxPeriod] = useState('3m');
  const { subscribe } = useContext(SocketContext);
  const notesListUpdateSubscription = useRef();
  const careplanListUpdateSubscription = useRef();
  const directMessageListUpdateSubscription = useRef();
  const eFaxListUpdateSubscription = useRef();

  const [queryCareplans] = useLazyQuery(FETCH_PATIENT_CAREPLANS, {
    variables: {
      period: careplanPeriod,
      status: 'shared',
    },
    onCompleted: (data) => {
      setCareplans(data.patientCareplans);
    },
    onError: () => {
      setCareplans([]);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const fetchCareplans = useCallback(() => {
    setCareplans(null);
    queryCareplans();
  }, []);

  useEffect(() => {
    if (!!careplanPeriod) {
      fetchCareplans();
      careplanListUpdateSubscription.current?.unsubscribe();
      careplanListUpdateSubscription.current = subscribe(
        EVENTS.PATIENT_CAREPLANS,
        () => {
          fetchCareplans();
        }
      );
    }
    return () => {
      careplanListUpdateSubscription.current?.unsubscribe();
    };
  }, [careplanPeriod, fetchCareplans]);

  const [queryNotes] = useLazyQuery(FETCH_NOTES, {
    variables: { period: notePeriod, status: 'shared' },
    onCompleted: (data) => {
      setNotes(data.notes);
    },
    onError: () => {
      setNotes([]);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const fetchNotes = useCallback(() => {
    setNotes(null);
    queryNotes();
  }, []);

  useEffect(() => {
    if (!!notePeriod) {
      fetchNotes();
      notesListUpdateSubscription.current?.unsubscribe();
      notesListUpdateSubscription.current = subscribe(
        EVENTS.PATIENT_NOTES,
        () => {
          fetchNotes();
        }
      );
    }
    return () => {
      notesListUpdateSubscription.current?.unsubscribe();
    };
  }, [notePeriod, fetchNotes]);

  const [queryDirectMessages] = useLazyQuery(FETCH_DIRECT_MESSAGES, {
    variables: { period: directMessagePeriod },
    onCompleted: (data) => {
      setDirectMessages(data.directMessages);
    },
    onError: () => {
      setDirectMessages([]);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const fetchDirectMessages = useCallback(() => {
    setDirectMessages(null);
    queryDirectMessages();
  }, []);

  useEffect(() => {
    if (!!directMessagePeriod) {
      fetchDirectMessages();
      directMessageListUpdateSubscription.current?.unsubscribe();
      directMessageListUpdateSubscription.current = subscribe(
        EVENTS.DIRECT_MESSAGES,
        () => {
          fetchDirectMessages();
        }
      );
    }
    return () => {
      directMessageListUpdateSubscription.current?.unsubscribe();
    };
  }, [directMessagePeriod, fetchDirectMessages]);

  const [queryEFaxMessages] = useLazyQuery(FETCH_EFAX_MESSAGES, {
    variables: { period: eFaxPeriod },
    onCompleted: (data) => {
      setEFaxMessages(data.eFaxMessages);
    },
    onError: () => {
      setEFaxMessages([]);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const fetchEFaxMessages = useCallback(() => {
    setEFaxMessages(null);
    queryEFaxMessages();
  }, []);

  useEffect(() => {
    if (!!eFaxPeriod) {
      fetchEFaxMessages();
      eFaxListUpdateSubscription.current?.unsubscribe();
      eFaxListUpdateSubscription.current = subscribe(
        EVENTS.EFAX_MESSAGES,
        () => {
          fetchEFaxMessages();
        }
      );
    }
    return () => {
      eFaxListUpdateSubscription.current?.unsubscribe();
    };
  }, [eFaxPeriod, fetchEFaxMessages]);

  return (
    <CoordinateContext.Provider
      value={{
        careplans,
        fetchCareplans,
        careplanPeriod,
        updateCareplanPeriod,
        notes,
        fetchNotes,
        notePeriod,
        updateNotePeriod,
        directMessages,
        fetchDirectMessages,
        updateDirectMessagePeriod,
        directMessagePeriod,
        eFaxMessages,
        updateEFaxPeriod,
        eFaxPeriod,
      }}
    >
      {children}
    </CoordinateContext.Provider>
  );
};

export default CoordinateProvider;
