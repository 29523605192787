import { useState, useEffect, useRef, useCallback } from 'react';
import { displayTime } from 'utils/time';

import { Text } from '../Text';

export const TimeLabel = ({
  tickOnSeconds = false,
  format = 'hh:mm A, MM/DD/YYYY',
}) => {
  const intervalRef = useRef(null);
  const firstTimeoutRef = useRef(null);
  const [timeLabel, updateTimeLabel] = useState();

  const clearTimeouts = useCallback(() => {
    if (!!intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    if (!!firstTimeoutRef.current) {
      clearTimeout(firstTimeoutRef.current);
      firstTimeoutRef.current = null;
    }
  }, []);

  const updateLabel = useCallback(() => {
    updateTimeLabel(displayTime(new Date(), format));
  }, [format]);

  const firstTickHandler = useCallback(() => {
    firstTimeoutRef.current = null;
    updateLabel();
    intervalRef.current = setInterval(
      updateLabel,
      tickOnSeconds ? 1000 : 1000 * 60
    );
  }, [tickOnSeconds, updateLabel]);

  useEffect(() => {
    updateTimeLabel(displayTime(new Date(), format));
    return () => {
      clearTimeouts();
    };
  }, [clearTimeouts, format]);

  useEffect(() => {
    clearTimeouts();
    let firstTickDelay;
    const now = new Date().getTime();
    if (tickOnSeconds) {
      firstTickDelay = 1000 - (now % 1000);
    } else {
      firstTickDelay = 60 * 1000 - (now % (60 * 1000));
    }
    firstTimeoutRef.current = setTimeout(firstTickHandler, firstTickDelay);
  }, [tickOnSeconds, clearTimeouts, firstTickHandler]);

  return <Text modifiers={['semibold', 'white', 'subtitle']}>{timeLabel}</Text>;
};
