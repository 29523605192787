import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Row, Column, Button, Text } from '@gaz/gaz-components.public';

import assets from 'assets';
import urls from 'routes/urls';
import Card from './Card';
import { AppointmentServiceContext } from 'contexts/appointment';
import { CallServiceContext } from 'contexts/call';

const Appointments = () => {
  const history = useHistory();
  const { startingAppointments } = useContext(AppointmentServiceContext);
  const { hasOngoingCall, callRef } = useContext(CallServiceContext);

  const startingAppointment = useMemo(
    () => (startingAppointments?.length > 0 ? startingAppointments[0] : null),
    [startingAppointments]
  );

  return (
    <Card modifiers={['fluid']} onClick={() => history.push(urls.APPOINTMENTS)}>
      <Row modifiers={['fullHeight', 'middle']}>
        <Column modifiers={['col_3', 'flexBox', 'verticalContentCenter']}>
          <Card.Icon src={assets.icons.icAppointmentPurple} />
        </Column>
        <Column modifiers={['col_9']}>
          <Text modifiers={['block', 'darkGrey', 'subtitle', 'semiBold']}>
            Appointments
          </Text>
          {hasOngoingCall && callRef.current?.appointment ? (
            <Row modifiers={['end']}>
              <Button modifiers={['small', 'info']}>On Call</Button>
            </Row>
          ) : (
            startingAppointment && (
              <Row modifiers={['end']}>
                <Button
                  modifiers={[
                    'small',
                    startingAppointment.status !== 'active' && 'success',
                  ]}
                >
                  {startingAppointment.status === 'active' ? 'Join' : 'Start'}
                </Button>
              </Row>
            )
          )}
        </Column>
      </Row>
    </Card>
  );
};

export default Appointments;
