import { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import {
  Column,
  Text,
  HR,
  Button,
  FormInput,
} from '@gaz/gaz-components.public';

import { Container, Row } from 'common';
import assets from 'assets';
import { buildStyledComponent, px2rem } from 'style';
import {
  formatTitleAndName,
  formatAddress,
  maskString,
  MASK_TYPE,
} from 'utils/string';
import { displayTime } from 'utils/time';

const MedicationItem = buildStyledComponent(
  'MedicationItem',
  styled(({ medication, index }) => (
    <Row modifiers={['spaceBetween', 'middle']}>
      <Text modifiers={['medium']}>{index + 1}.</Text>
      <Column modifiers={['col', 'noPadding']}>
        <Text modifiers={['medium']}>{medication.name}</Text>
      </Column>
    </Row>
  )),
  () => `
    >:first-child {
      width: ${px2rem(25)};
    }
  `
);

const InfoItem = buildStyledComponent(
  'InfoItem',
  styled(({ label, text, ...props }) => (
    <Row modifiers={['smallGutters']} {...props}>
      <Text modifiers={['medium', 'block']}>{label}</Text>
      <Text modifiers={['primary', 'medium', 'block', 'preserveLineBreak']}>
        {text}
      </Text>
    </Row>
  )),
  ({ theme }) => `
    >:first-child {
      width: ${px2rem(70)};
    }
    >:last-child {
      flex: 1;
    }
  `
);

const validationSchema = Yup.object().shape({
  tracking: Yup.string().required('Tracking number is required.'),
});

const initialValues = {
  tracking: '',
};

export default ({ order, handleConfirm, handleClose }) => {
  const { medications, patient } = order;
  const handleSubmit = (values) => {
    handleConfirm(values.tracking);
  };

  return (
    <Container modifiers={['fluid']}>
      <Row modifiers={['smallGutters', 'center']}>
        <Text modifiers={['block', 'center', 'bold', 'large']}>
          Confirm Shipment
        </Text>
      </Row>
      <Container modifiers={['fluid', 'withGutters']}>
        {Object.values(medications).map((medication, index) => (
          <MedicationItem medication={medication} index={index} key={index} />
        ))}
      </Container>
      <HR />
      <Container modifiers={['fluid', 'withGutters']}>
        <InfoItem label="Patient:" text={formatTitleAndName(patient)} />
        <InfoItem label="Address:" text={formatAddress(patient.address)} />
        <InfoItem label="DoB:" text={displayTime(patient.dob, 'MM/DD/YYYY')} />
        <InfoItem label="SS#:" text={maskString(patient.ssn, MASK_TYPE.SSN)} />
        <InfoItem label="C:" text={patient.phones.mobile} />
        <InfoItem label="H:" text={patient.phones.home} />
        <InfoItem label="Email:" text={patient.email} />
      </Container>
      <HR />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <Container modifiers={['fluid', 'withGutters']}>
            <Text modifiers={['block', 'medium']}>Fed Ex Tracking number:</Text>
            <Field
              required
              name="tracking"
              component={FormInput}
              autoFocus={true}
            />
          </Container>
          <Row modifiers={['spaceBetween', 'middle']}>
            <Button
              image={assets.icons.icCloseGray}
              modifiers={['icon', 'transparent']}
              onClick={handleClose}
            />
            <Button modifiers={['widthSmall', 'roundCorner']} type="submit">
              Confirm
            </Button>
          </Row>
        </Form>
      </Formik>
    </Container>
  );
};
