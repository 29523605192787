import React, { useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { Row, Column, Text } from '@gaz/gaz-components.public';

import assets from 'assets';
import urls from 'routes/urls';
import { FETCH_UNREAD_MESSAGE_COUNT } from 'graphql/queries';
import { EVENTS, SocketContext } from 'contexts/socket';
import { client } from 'graphql/client';

import Card from './Card';

const Messaging = () => {
  const { subscribe } = useContext(SocketContext);
  const history = useHistory();
  const [fetchUnreadMessageCount, { data }] = useLazyQuery(
    FETCH_UNREAD_MESSAGE_COUNT,
    { fetchPolicy: 'cache-and-network' }
  );

  const handleMessageReceive = useCallback(async () => {
    client.writeQuery({
      query: FETCH_UNREAD_MESSAGE_COUNT,
      data: {
        unreadMessageCount: (data?.unreadMessageCount || 0) + 1,
      },
    });
  }, [data]);

  useEffect(() => {
    const subscription = subscribe(EVENTS.CHAT, handleMessageReceive);

    return () => {
      subscription.unsubscribe();
    };
  }, [subscribe, handleMessageReceive]);

  useEffect(() => {
    fetchUnreadMessageCount();
  }, [fetchUnreadMessageCount]);

  return (
    <Card modifiers={['fluid']} onClick={() => history.push(urls.CHAT_HISTORY)}>
      <Row modifiers={['fullHeight', 'middle']}>
        <Column modifiers={['col_3', 'flexBox', 'verticalContentCenter']}>
          <Card.Icon src={assets.icons.icMessagingOrange} />
        </Column>
        <Column modifiers={['col_9']}>
          <Text modifiers={['block', 'darkGrey', 'subtitle', 'semiBold']}>
            Messaging
          </Text>
          {data && (
            <Text modifiers={['block', 'darkGrey', 'small', 'body']}>
              {data.unreadMessageCount} New
            </Text>
          )}
        </Column>
      </Row>
    </Card>
  );
};

export default Messaging;
