import { useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { buildStyledComponent, px2rem } from 'style';
import {
  Container,
  Row,
  Column,
  Button,
  Text,
  Avatar,
} from '@gaz/gaz-components.public';
import { formatTitleAndName } from 'utils/string';
import { EVENTS } from 'contexts/socket';
import { CALL_TYPES, CallServiceContext } from 'contexts/call';
import { AuthContext } from 'contexts/auth';
import { getChatLocationDescriptor } from 'utils/chat';

const Notification = ({ notification, handleDismiss, ...props }) => {
  const messageHeader = formatTitleAndName(notification.sender);
  let messageSubHeader, messageContent;
  let actionHandler;
  let actionTitle = 'Join';
  let dismissTitle = 'Reject';
  const { startCall, hasOngoingCall } = useContext(CallServiceContext);
  const dismissHandlerSubscription = useRef();
  const history = useHistory();
  const { me } = useContext(AuthContext);

  const dismissHandler = () => {
    if (dismissHandlerSubscription.current) {
      clearTimeout(dismissHandlerSubscription.current);
      dismissHandlerSubscription.current = null;
    }
    handleDismiss();
  };

  if (notification.type === EVENTS.MEET_NOW) {
    messageSubHeader = 'would like you to join a call';
    if (!hasOngoingCall) {
      actionHandler = () => {
        startCall(
          CALL_TYPES.UNSCHEDULED,
          notification.sender,
          notification.referredPatient
        );
        if (dismissHandlerSubscription.current) {
          clearTimeout(dismissHandlerSubscription.current);
          dismissHandlerSubscription.current = null;
        }
        handleDismiss();
      };
    }
  } else if (notification.type === EVENTS.CHAT) {
    if (notification.payload.attachment) {
      messageSubHeader = 'sent you an attachment.';
    } else if (notification.payload.note) {
      messageSubHeader = 'shared a noted.';
    } else if (notification.payload.careplan) {
      messageSubHeader = 'shared a careplan.';
    } else {
      messageSubHeader = 'sent you a message.';
    }

    messageContent = notification.payload.text;

    actionTitle = 'View';
    dismissTitle = 'Close';

    actionHandler = async () => {
      const descriptor = await getChatLocationDescriptor(
        notification.payload,
        me
      );
      history.push(descriptor);
      handleDismiss();
    };
  }

  useEffect(() => {
    if (dismissHandlerSubscription.current) {
      clearTimeout(dismissHandlerSubscription.current);
      dismissHandlerSubscription.current = null;
    }
    dismissHandlerSubscription.current = setTimeout(handleDismiss, 10000);
    return () => {
      if (dismissHandlerSubscription.current) {
        clearTimeout(dismissHandlerSubscription.current);
        dismissHandlerSubscription.current = null;
      }
    };
  }, [handleDismiss]);

  return (
    <Container {...props}>
      <Row modifiers={['spaceBetween', 'middle']}>
        <Avatar
          user={notification.sender}
          width={60}
          height={60}
          modifiers="round"
        />
        <Column modifiers={['col', 'noPadding']}>
          <Text modifiers={['white', 'bold', 'block']}>{messageHeader}</Text>
          {messageSubHeader && (
            <Text modifiers={['white', 'block']}>{messageSubHeader}</Text>
          )}
        </Column>
      </Row>
      {messageContent && (
        <Row>
          <Text modifiers={['white', 'block', 'noPadding', 'oneLiner']}>
            {messageContent}
          </Text>
        </Row>
      )}
      <Row modifiers={['center']}>
        <Button
          modifiers={['danger', 'roundCorner', !dismissHandler && 'disabled']}
          onClick={dismissHandler}
          disabled={!dismissHandler}
        >
          {dismissTitle}
        </Button>
        <Button
          modifiers={['success', 'roundCorner', !actionHandler && 'disabled']}
          onClick={actionHandler}
          disabled={!actionHandler}
        >
          {actionTitle}
        </Button>
      </Row>
    </Container>
  );
};

const styles = ({ theme }) => `
  position: fixed;
  transition: all 0.5s;
  z-index: 10000;
  border-radius: ${px2rem(12)};
  border-radius: ${px2rem(12)};
  background: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(16px);
  top: ${px2rem(16)};
  left: ${px2rem(16)};
  right: ${px2rem(16)};
  width: calc(100% - ${px2rem(32)});
  padding: ${theme.dimensions.padding_2};

  span {
    margin-left: ${px2rem(20)};
  }

  > :last-child {
    margin-top: ${px2rem(10)};
    button {
      margin: 0 ${px2rem(15)};
      width: ${px2rem(100)};
    }
  }
`;

const modifierConfig = {
  dismissed: () => `
    transform: translateX(-100%);
  `,
};

export default buildStyledComponent(
  'Notification',
  styled(Notification),
  styles,
  {
    modifierConfig,
  }
);
