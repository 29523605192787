import { useEffect, useState } from 'react';

import {
  ScrollView,
  Text,
  Button,
  BackButton,
  Checkbox,
  Input,
} from '@gaz/gaz-components.public';
import { Container, Modal, Row } from 'common';
import assets from 'assets';

const AddActivity = ({ handleAdd, handleClose }) => {
  const [showAddForm, updateShowAddForm] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState();
  const [activityItems, updateActivityItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [value, updateValue] = useState('');

  const handleAddActivityItem = () => {
    updateActivityItems([...activityItems, { activity: value }]);
  };

  const handleBack = () => {
    if (showAddForm) {
      updateShowAddForm(false);
    } else {
      handleClose();
    }
  };

  const handleDone = () => {
    if (showAddForm) {
      handleAddActivityItem();
      handleBack();
    } else {
      handleAdd(selectedActivity);
      handleClose();
    }
  };

  const showForm = () => {
    updateValue('');
    updateShowAddForm(true);
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleBack} />
          <Text>{showAddForm ? 'Add New Activity' : 'Add CP Activity'}</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
          <ScrollView>
            <Container modifiers={['padding_2']}>
              {showAddForm ? (
                <Container>
                  <Row modifiers={['smallGutters']}>
                    <Text>Activity Name</Text>
                  </Row>
                  <Input
                    name="input"
                    onChange={(event) => updateValue(event.target.value)}
                    value={value}
                  />
                </Container>
              ) : activityItems.length === 0 ? (
                <Container modifiers={['section']}>
                  <Row
                    modifiers={['spaceBetween', 'middle']}
                    onClick={showForm}
                  >
                    <Text modifiers={['medium']}>Add Activity</Text>
                    <img src={assets.icons.chevronR} />
                  </Row>
                </Container>
              ) : (
                <>
                  <Row modifiers={['spaceBetween', 'middle', 'withGutters']}>
                    <Text modifiers={['medium', 'bold']}>Select Activity</Text>
                    <Button
                      modifiers={['transparent', 'noShadow']}
                      onClick={showForm}
                    >
                      <Text modifiers={['primary', 'medium']}>+ ADD NEW</Text>
                    </Button>
                  </Row>
                  {activityItems.map((activity, index) => (
                    <Row
                      modifiers={['middle', 'spaceBetween', 'withGutters']}
                      onClick={() => setSelectedActivity(activity)}
                      key={index}
                    >
                      <Text modifiers={['medium']}>{activity.activity}</Text>
                      <Checkbox
                        dark={true}
                        modifiers={['small']}
                        checked={activity === selectedActivity}
                        onChange={() => {}}
                      />
                    </Row>
                  ))}
                </>
              )}
            </Container>
          </ScrollView>
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleBack}
              />
              {!showAddForm && activityItems.length === 0 && (
                <Button
                  modifiers={['widthSmall', 'roundCorner']}
                  onClick={showForm}
                >
                  Add New
                </Button>
              )}
              <Button
                modifiers={[
                  'primary',
                  'widthSmall',
                  'roundCorner',
                  ((showAddForm && value.length === 0) ||
                    (!showAddForm && !selectedActivity)) &&
                    'disabled',
                ]}
                onClick={handleDone}
              >
                {showAddForm
                  ? 'Add'
                  : activityItems.length === 0
                  ? 'Done'
                  : 'Add Selected'}
              </Button>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AddActivity;
