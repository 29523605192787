import React from 'react';

import {
  Container,
  Row,
  Column,
  Avatar,
  Text,
} from '@gaz/gaz-components.public';
import { getFullName } from 'utils/string';
import { displayTime } from 'utils/time';

export default function OrderCard({ onClick, order }) {
  const { patient, createdAt } = order;

  return (
    <Container modifiers={['card']} onClick={() => onClick(order)}>
      <Row modifiers="middle">
        <Column modifiers={['center', 'flexBox', 'verticalCenter']}>
          <Avatar modifiers={['round']} height={50} width={50} user={patient} />
        </Column>
        <Column modifiers={['col']}>
          <Row>
            <Text>{getFullName(patient)}</Text>
          </Row>
          <Row>
            <Text modifiers={['xSmall', 'block', 'primary']}>
              {displayTime(createdAt, 'MM-DD-YYYY')}
            </Text>
          </Row>
        </Column>
      </Row>
    </Container>
  );
}
