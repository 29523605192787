import { createContext, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { saveData, removeData, loadData } from 'utils/storage';
import { CREATE_RECORD } from 'graphql/mutations';

import { AuthContext } from './auth';

export const API_TYPE = {
  TIMER: 'API_TYPE_TIMER',
  NOTE: 'API_TYPE_NOTE',
};
const STORED_API_TYPES = 'STORED_API_TYPES';

export const ApiQueueContext = createContext();

let storedApiTypes = [];

export const ApiQueueProvider = ({ children }) => {
  const { me } = useContext(AuthContext);
  const providerId = me?._id || '';

  const [createRecord] = useMutation(CREATE_RECORD, {
    onCompleted: () => {
      remove(API_TYPE.TIMER);
    },
    onError: () => {},
  });

  useEffect(() => {
    if (me?._id) {
      executeAll();
    }
  }, [me?._id]);

  const getRealType = (type) => {
    return `${providerId}:${type}`;
  };

  const save = (apiType, data) => {
    if (!apiType) {
      throw new Error('Api Type should not be empty');
    }
    const type = getRealType(apiType);

    if (!storedApiTypes.includes(type)) {
      storedApiTypes.push(type);
      saveData(STORED_API_TYPES, JSON.stringify(storedApiTypes));
    }
    saveData(type, JSON.stringify(data));
  };

  const remove = (apiType) => {
    const type = getRealType(apiType);
    removeData(type);
    storedApiTypes = storedApiTypes.filter((t) => t !== type);
    saveData(STORED_API_TYPES, JSON.stringify(storedApiTypes));
  };

  const executeCurrent = (apiType) => {
    const type = getRealType(apiType);
    execute(type);
  };

  const execute = async (type) => {
    const apiData = loadData(type);
    if (!apiData) {
      console.warn(`Api Data is missing for type: ${type}`);
      return;
    }

    const [pId, apiType] = type.split(':');

    if (providerId !== pId) {
      return;
    }
    const variables = JSON.parse(apiData);

    console.log('Processing api:', type, variables);

    if (apiType === API_TYPE.TIMER) {
      await createRecord({
        variables: {
          record: variables,
        },
      });
    } else if (apiType === API_TYPE.NOTE) {
    }
  };

  const executeAll = () => {
    const cur = loadData(STORED_API_TYPES);
    if (cur) {
      storedApiTypes = JSON.parse(cur);
    }
    storedApiTypes.forEach((type) => {
      execute(type);
    });
  };

  return (
    <ApiQueueContext.Provider
      value={{
        saveApi: save,
        executeApi: executeCurrent,
      }}
    >
      {children}
    </ApiQueueContext.Provider>
  );
};

export default ApiQueueProvider;
