import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  background-color: ${theme.colors.background};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const Wrapper = buildStyledComponent('MainLayout_Wrapper', styled.div, styles);

export default Wrapper;
