import React, { createContext, useContext, useState } from 'react';
import assets from 'assets';

const FabContext = createContext();

export function FabProvider({ children }) {
  const [hidden, setHidden] = useState(true);
  const [icon, setIcon] = useState(assets.icons.iconPlusMain);
  const [action, setAction] = useState(() => () => {});

  const showFab = () => setHidden(false);

  const hideFab = () => setHidden(true);

  const changeIcon = (icon) => setIcon(icon);

  const setFabAction = (fn) => setAction(() => fn);

  return (
    <FabContext.Provider
      value={{
        hidden,
        icon,
        action,
        showFab,
        hideFab,
        setAction: setFabAction,
        setIcon: changeIcon,
      }}
    >
      {children}
    </FabContext.Provider>
  );
}

export function useFab() {
  return useContext(FabContext);
}
