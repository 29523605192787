import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  align-items: center;
  color: ${theme.colors.text};
  display: flex;
  text-transform: inherit;
`;

export default buildStyledComponent('Checkbox__Label', styled.label, styles);
