import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  grid: ({ theme }) => `
    flex-direction: row;
  `,
  list: ({ theme }) => `
  `,
};

const styles = ({ theme }) => `
  display: flex;
  padding: ${theme.dimensions.gridPad};
  box-sizing: border-box;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;

  >div:first-child {
    font-size: ${theme.dimensions.fontSizeMedium};
    color: ${theme.colors.text};
    padding: 0;
    margin: 0 0 ${theme.dimensions.padding_1} 0;
    width: 100%;
    display: flex;
    align-items: center;

    >div:first-child { 
      flex: 1;
      padding: ${theme.dimensions.padding_1};
    }
  }
`;

const Wrapper = buildStyledComponent(
  'Search_List_Wrapper',
  styled.div,
  styles,
  { modifierConfig }
);

export default Wrapper;
