import { Text } from '../Text';

export default function Heading({ position, modifiers = [], children }) {
  return (
    <Text
      modifiers={[position, 'xLarge', 'secondaryFont', 'secondaryColor', ...modifiers]}
    >
      {children}
    </Text>
  );
}