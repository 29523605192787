import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = () => `
  text-align: left;
`;

const Left = buildStyledComponent('MainLayout_Header_Left', styled.div, styles);

export default Left;
