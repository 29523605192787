import { useState } from 'react';

import { Checkbox, Column, Button } from '@gaz/gaz-components.public';

import { Container, Row, SlidingPane, Text } from 'common';
import { frequencyTitle } from 'utils/helper';
import { VITALS } from 'utils/constants';
import assets from 'assets';

const GroupSelectionModal = ({
  isVisible,
  frequency,
  vitals,
  onConfirm,
  onClose,
}) => {
  const [selectedVitals, setSelectedVitals] = useState({});
  const frequencyTitles = frequencyTitle(frequency, true);

  const toggleSelection = (vital) => {
    selectedVitals[vital.id]
      ? delete selectedVitals[vital.id]
      : (selectedVitals[vital.id] = { id: vital.id });
    setSelectedVitals({ ...selectedVitals });
  };

  const handleConfirm = () => {
    onConfirm(Object.keys(selectedVitals));
    onClose();
  };

  return (
    <SlidingPane
      isOpen={isVisible}
      title="Group Frequency"
      onConfirm={handleConfirm}
      onClose={onClose}
      footer
    >
      <SlidingPane.Header modifiers={['hasBorder']}>
        <Text modifiers={['h2', 'bold', 'darkGrey', 'center', 'block']}>
          GROUP Medications
        </Text>
      </SlidingPane.Header>
      <SlidingPane.Content>
        <Row modifiers={['smallGutters']}>
          <Text modifiers={['medium', 'block', 'semiBold', 'secondary']}>
            Frequency Detail
          </Text>
        </Row>
        <Row modifiers={['smallGutters']}>
          <Text modifiers={['medium']}>Freq: </Text>
          <Text modifiers={['primary', 'medium']}>
            {frequencyTitles?.frequency}
          </Text>
        </Row>
        <Row modifiers={['smallGutters']}>
          <Text modifiers={['medium']}>On: </Text>
          <Text modifiers={['primary', 'medium']}>{frequencyTitles?.on}</Text>
        </Row>
        <Row modifiers={['smallGutters']}>
          <Text modifiers={['medium']}>@: </Text>
          <Text modifiers={['primary', 'medium']}>{frequencyTitles?.at}</Text>
        </Row>
        <Row modifiers={['topGutters_2', 'bottomGutters_1']}>
          <Text modifiers={['medium', 'block', 'bold', 'secondary']}>
            Select the vitals you would like to have this same frequency
          </Text>
        </Row>
        {Object.entries(vitals).map(([key, vital]) => (
          <Row key={key} onClick={() => toggleSelection(vital)}>
            <Checkbox
              dark={true}
              modifiers={['small', 'margin_1']}
              checked={!!selectedVitals[key]}
              onChange={() => {}}
            />
            <Column modifiers={['col']}>
              <Text modifiers={['block']}>{VITALS[key]}</Text>
            </Column>
          </Row>
        ))}
        <Row modifiers={['withGutters']}>
          <Text modifiers={['block', 'success', 'medium']}>
            You have selected {Object.keys(selectedVitals).length} vitals with
            the above frequency.
          </Text>
        </Row>
      </SlidingPane.Content>
      <Container modifiers={['flexBox', 'footer']}>
        <Row modifiers={['spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={onClose}
          />
          <Button
            modifiers={[
              'primary',
              'roundCorner',
              'widthMedium',
              Object.keys(selectedVitals).length === 0 && 'disabled',
            ]}
            onClick={handleConfirm}
          >
            <Text modifiers={['subtitle', 'semiBold', 'white']}>
              Group Frequency{' '}
              {Object.keys(selectedVitals).length > 0
                ? `(${Object.keys(selectedVitals).length})`
                : ''}
            </Text>
          </Button>
        </Row>
      </Container>
    </SlidingPane>
  );
};

export default GroupSelectionModal;
