import React from 'react';
import styled from 'styled-components';
import { Column as Col, Text } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

function Component({ measurementValue, ...props }) {
  return (
    <Col
      {...props}
      modifiers={[
        'col_3',
        'middle',
        'center',
        'verticalContentCenter',
        'flexBox',
      ]}
    >
      <Text modifiers={['xSmall', 'center']}>{measurementValue}</Text>
    </Col>
  );
}

const modifierConfig = {
  danger: ({ theme }) => `
    background-color: #BC0000;
    >div {
      color: ${theme.colors.white};
    }
  `,
  success: ({ theme }) => `
    background-color: ${theme.colors.success};
    >div {
      color: ${theme.colors.white};
    }
  `,
  newVital: ({ theme }) => `
    border: none;
    >div {
      color: ${theme.colors.primary};
      font-size: ${theme.dimensions.fontSize};
      font-weight: ${theme.fontWeights.bold};
    }
  `,
};

const styles = ({ theme }) => `
  background-color: #F3F3F4;
  border: 1px solid #E7E7E9;
  color: ${theme.colors.secondary};
  border-radius: 0px 6px 6px 0px;
`;

export default buildStyledComponent(
  'VitalHighlight_VitalsItem',
  styled(Component),
  styles,
  {
    modifierConfig,
  },
);
