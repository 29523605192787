import React from 'react';
import styled from 'styled-components';

import { buildStyledComponent } from 'style';

import { Container } from '@gaz/gaz-components.public';
import Header from './Header';
import Footer from './Footer';

const wrapperStyles = () => `
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
`;

const ChatLayoutWrapper = buildStyledComponent(
  'ChatLayoutWrapper',
  styled.div,
  wrapperStyles
);

const contentWrapperConfig = {
  legacy: ({ theme }) => `
    background-color: ${theme.colors.white};
  `,
};

const styles = ({ hasAttachment }) => `
  overflow-y: auto;
  position: relative;
  flex: 1;
  background-color: white;
  padding-bottom: ${hasAttachment ? 55 : 0};
  background-color: #F3F3F4;
`;

const ContentWrapper = buildStyledComponent(
  'ContentWrapper_ChatLayout',
  styled(Container),
  styles,
  { modifierConfig: contentWrapperConfig }
);

export function ChatLayout({ children }) {
  return <ChatLayoutWrapper>{children}</ChatLayoutWrapper>;
}
ChatLayout.Footer = Footer;
ChatLayout.Header = Header;
ChatLayout.ContentWrapper = ContentWrapper;
