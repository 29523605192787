import moment from 'moment';
import assets from 'assets';
import { Row, Column, Text, HR, Checkbox } from '@gaz/gaz-components.public';

import Wrapper from './Wrapper';
import { noop } from 'lodash-es';

export default function PastItems({
  item,
  selected,
  selectable,
  handleChange,
}) {
  return (
    <Wrapper>
      <Row>
        <Text modifiers={['muted', 'small', 'light']}>
          {moment().format('MM/DD/YYYY')}
        </Text>
      </Row>
      <Row onClick={selectable ? handleChange : noop} modifiers={['middle']}>
        <Column modifiers={['col_2']}>
          <img
            src={item.type === 'file' ? assets.icons.icPdf : assets.icons.icPng}
            alt=""
            width="36"
            height="36"
          />
        </Column>
        <Column modifiers={['col']}>
          <Text modifiers={['block', 'subtitle', 'darkGrey']}>
            {item.originalName || item.file?.name}
          </Text>
          <Text modifiers={['block', 'muted', 'small', 'light']}>
            Gazuntite-Record/records
          </Text>
        </Column>
        {selectable && (
          <Checkbox
            onChange={noop}
            checked={selected}
            modifiers={['radioStyle', 'widthSmall']}
          />
        )}
        <Column modifiers={['col_12']}>
          <HR modifiers={['muted']} />
        </Column>
      </Row>
    </Wrapper>
  );
}
