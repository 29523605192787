import CareplanDetail from 'pages/Careplans/CareplanDetail';
import {
  BackButton,
  Avatar,
  Row,
  Column,
  Text,
} from '@gaz/gaz-components.public';
import { Modal } from 'common';
import { getFullName } from 'utils/string';

export default ({ careplan, goBack }) => (
  <Modal open>
    <Modal.Header>
      <Row modifiers={['middle']}>
        <BackButton onClick={goBack} />
        <Avatar
          user={careplan.user}
          width={40}
          height={40}
          modifiers={['squared']}
        />
        <Column modifiers={['col_8', 'flexBox']}>
          <Text modifiers={['semiBold', 'start']}>
            {getFullName(careplan.user)}
          </Text>
        </Column>
      </Row>
    </Modal.Header>
    <Modal.Body modifiers={['noMargin', 'textLeft']}>
      <CareplanDetail careplan={careplan} goBack={goBack} />
    </Modal.Body>
  </Modal>
);
