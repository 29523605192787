import React from 'react';

import { StyledA, StyledLink } from './Wrapper';

export const A = ({ to, href, children, ...restProps }) => {
  if (to) {
    return (
      <StyledLink to={to} {...restProps}>
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledA href={href} {...restProps}>
      {children}
    </StyledA>
  );
};

export default A;
