import { useContext, useState } from 'react';
import { CreateCareplanContext } from './Context';

import CareplanHistory from '../../Records/Careplan';
import ViewCareplan from 'pages/Careplans/CareplanDetail';
import { Container } from 'common';

export default () => {
  const { setBackHandler } = useContext(CreateCareplanContext);
  const [activeCareplan, setActiveCareplan] = useState();
  const handleViewCareplan = (careplan) => {
    setActiveCareplan(careplan);
    setBackHandler(() => {
      setActiveCareplan(null);
    });
  };

  return (
    <Container modifiers={['backgroundGray', 'fullHeight', 'flexBox', 'fluid']}>
      <Container
        modifiers={[
          'fluid',
          'fullHeight',
          'flexBox',
          activeCareplan && 'noDisplay',
        ]}
      >
        <CareplanHistory handleViewCareplan={handleViewCareplan} />
      </Container>
      {activeCareplan && <ViewCareplan careplan={activeCareplan} noFooter />}
    </Container>
  );
};
