import React, { cloneElement } from 'react';

import { VR } from 'common';

import Wrapper from './Wrapper';
import Tab from './Tab';

export const Tabs = (props) => {
  const {
    onChange,
    current,
    reverseFlatTabs,
    flatTabs,
    noBorderBottom,
    multiLine,
    children,
  } = props;

  const handleTabClick = (next) => {
    if (onChange && current !== next) {
      onChange(next);
    }
  };

  return (
    <Wrapper>
      {children.map((child, index) => {
        const tabNumber = index;
        const noTab =
          flatTabs ||
          reverseFlatTabs ||
          index === children?.length - 1 ||
          child.props?.modifiers?.includes('noTab');
        const modifiers = child.props?.modifiers || [];
        if (flatTabs) {
          modifiers.push('flatTab');
        }
        if (reverseFlatTabs) {
          modifiers.push('reverseFlatTab');
        }
        if (tabNumber === current) {
          modifiers.push(reverseFlatTabs ? 'reverseActive' : 'active');
        }
        if (noBorderBottom) {
          modifiers.push('noBorderBottom');
        }
        if (multiLine) {
          modifiers.push('multiLine');
        }
        const childProps = {
          stepIndex: tabNumber,
          key: tabNumber,
          onClick: handleTabClick,
          modifiers,
          ...child.props,
        };

        return (
          <div
            style={{
              position: 'relative',
              width: childProps.hidden ? '0%' : '100%',
            }}
          >
            {cloneElement(child, childProps)}
            {!noTab && <VR modifiers={['height_95%']} />}
          </div>
        );
      })}
    </Wrapper>
  );
};

export default Tabs;

Tabs.Tab = Tab;
