import { Container, Text, Row, A } from 'common';
import { displayTime } from 'utils/time';

export default ({ handleClick, message }) => (
  <Container
    modifiers={['card', 'padding_2', 'withGutters']}
    onClick={handleClick}
  >
    <A
      to={{ pathname: message.attachment?.fileUrl }}
      modifiers={['xSmall']}
      target="_blank"
    >
      <Row modifiers={['topGutters_1', 'spaceBetween']}>
        <Text>
          From: <Text modifiers={['primary']}>{message.from}</Text>
        </Text>
      </Row>
      <Row modifiers={['topGutters_1', 'spaceBetween']}>
        <Text>
          Message Id: <Text modifiers={['primary']}>{message.messageId}</Text>
        </Text>
      </Row>
      <Row modifiers={['topGutters_1', 'spaceBetween']}>
        <Text>
          At:{' '}
          <Text modifiers={'primary'}>
            {displayTime(message.createTime, 'hh:mm a, MM/DD/yy')}
          </Text>
        </Text>
      </Row>
    </A>
  </Container>
);
