import { useState, useEffect } from 'react';

import { Row, Column, Text } from '@gaz/gaz-components.public';
import { Formik, Field, Form } from 'formik';

import { Directions, TimeSlot } from './Items';
import { Container, FormPicker } from 'common';

export const DefaultPresets = [
  {
    times: 1,
    title: 'Once a day',
    slots: ['08:00 AM'],
  },
  {
    times: 2,
    title: 'Twice a day',
    slots: ['08:00 AM', '06:00 PM'],
  },
  {
    times: 3,
    title: '3 times a day',
    slots: ['08:00 AM', '04:00 PM', '10:00 PM'],
  },
  {
    times: 4,
    title: '4 times a day',
    slots: ['08:00 AM', '12:00 PM', '06:00 PM', '10:00 PM'],
  },
  {
    times: 5,
    title: '5 times a day',
    slots: ['08:00 AM', '12:00 PM', '06:00 PM', '10:00 PM', '12:00 AM'],
  },
];

export const PresetItem = ({
  icon,
  preset,
  handleSelection,
  hasDirection,
  selected,
  formRef,
}) => {
  const [slots, setSlots] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  useEffect(() => {
    const slotTimes = {};
    preset.slots.forEach((slot, index) => {
      slotTimes[`slot${index}`] = slot;
    });
    if (hasDirection) {
      slotTimes['direction'] = preset.direction || 'none';
    }
    slotTimes['title'] = preset.title;
    setInitialValues(slotTimes);
    setSlots([...preset.slots]);
  }, [preset]);

  if (!initialValues) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} innerRef={formRef}>
      <Form>
        <Container modifiers={['card']}>
          <Row modifiers={['spaceBetween', 'middle']} onClick={handleSelection}>
            <img src={icon} />
            <Column modifiers={['col', 'noPadding']}>
              <Field type="hidden" name="title" />
              <Text modifiers={['block']}>{preset.title}</Text>
            </Column>
            {hasDirection && selected && (
              <Column>
                <Field
                  asInfo
                  noBorder
                  noEmptyOption
                  fitContent
                  name="direction"
                  title="PICK DIRECTION"
                  options={Directions}
                  component={FormPicker}
                />
              </Column>
            )}
          </Row>
          {selected && (
            <Container modifiers={['fluid']}>
              <Row>
                {slots.map((time, index) => (
                  <Column key={index}>
                    <TimeSlot name={`slot${index}`} />
                  </Column>
                ))}
              </Row>
            </Container>
          )}
        </Container>
      </Form>
    </Formik>
  );
};
