import { useEffect, useState, useContext, useCallback } from 'react';
import { noop } from 'lodash';
import moment from 'moment';
import assets from 'assets';

import { Column, Text, Button } from '@gaz/gaz-components.public';
import { LightBox } from 'common';
import { getChat } from 'graphql/utils';
import { formatTitleAndName } from 'utils/string';
import { AuthContext } from 'contexts/auth';

import ChatItemDetails from './ChatItemDetails';

const ChatItem = ({ item, handleRemoveAttachment = noop }) => {
  const [expanded, setExpanded] = useState(false);
  const [chat, setChat] = useState({});
  const { me } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      const chat = await getChat(item.chatId);
      setChat(chat);
    })();
  }, [item]);

  const memberNames = chat?.members
    ?.map((m) => formatTitleAndName(m))
    .join(', ');
  const messages = item?.messageIds?.map((mId) =>
    chat?.messages?.find((m) => m._id === mId)
  );

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const getMessageDateRange = useCallback(() => {
    if (messages?.length > 0) {
      const maxAndMin = [
        Math.min(
          ...messages.map((message) => moment(message?.createdAt).valueOf())
        ),
        Math.max(
          ...messages.map((message) => moment(message?.createdAt).valueOf())
        ),
      ];
      return [
        moment(maxAndMin[0]).format('MM/DD/YYYY'),
        moment(maxAndMin[1]).format('MM/DD/YYYY'),
      ];
    }
    return [];
  }, [messages]);

  const messagesDateRange = getMessageDateRange();

  return (
    <>
      {expanded && (
        <ChatItemDetails
          attachment={item}
          handleBackPress={() => setExpanded(false)}
        />
      )}
      <LightBox modifiers="middle">
        <Column
          style={{ justifyContent: 'space-between' }}
          modifiers={['col_10', 'flexBox', 'spaceBetween']}
          onClick={toggleExpand}
        >
          <Text
            modifiers={['primary', 'subtitle', 'light']}
          >{`${messagesDateRange[0]} to ${messagesDateRange[1]}`}</Text>
        </Column>
        <Column
          modifiers={['col_2', 'flexBox', 'end']}
          onClick={handleRemoveAttachment}
        >
          <Button
            image={assets.icons.icCloseGrey}
            modifiers={['icon', 'transparent']}
            width={16}
            height={16}
            imageWidth={16}
            imageHeight={16}
            onClick={handleRemoveAttachment}
          />
        </Column>
        <Column onClick={toggleExpand} modifiers={['col', 'noVerticalPadding']}>
          <Text
            modifiers={['inline', 'subtitle', 'darkGrey', 'semiBold', 'start']}
          >
            Participants:
            <Text modifiers={['subtitle', 'darkGrey', 'light']}>
              {memberNames}
            </Text>
          </Text>
          <Text
            modifiers={[
              'block',
              'subtitle',
              'darkGrey',
              'semiBold',
              'block',
              'start',
            ]}
          >
            Messages
          </Text>
        </Column>
      </LightBox>
    </>
  );
};

export default ChatItem;
