import React, { forwardRef, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';

import { FormPicker, FormInput, Row, Column, FormPhoneInput } from 'common';
import schema from 'utils/schema';
import { STATES, STATES_LIST } from 'utils/constants';

const contactSchema = Yup.object().shape({
  phones: schema.phones,
  email: schema.email,
  address: schema.address,
});

const initialValues = {
  address: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipcode: '',
  },
  email: '',
  phones: {
    mobile: '',
    home: '',
    work: '',
  },
};

const Contact = forwardRef((props, ref) => {
  const initialized = useRef(false);
  if (props.show) initialized.current = true;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={contactSchema}
      innerRef={ref}
      onSubmit={() => {}}
    >
      {() =>
        initialized.current && (
          <Form style={{ display: props.show ? null : 'none' }}>
            <Row>
              <Column modifiers="col_12">
                <Field
                  hasLabel
                  required
                  name="email"
                  placeholder="Email"
                  component={FormInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_6">
                <Field
                  hasLabel
                  required
                  name="phones.mobile"
                  placeholder="Mobile number"
                  component={FormPhoneInput}
                />
              </Column>
              <Column modifiers="col_6">
                <Field
                  hasLabel
                  name="phones.home"
                  placeholder="Home number"
                  component={FormPhoneInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_6">
                <Field
                  hasLabel
                  name="phones.work"
                  placeholder="Work number"
                  component={FormPhoneInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_12">
                <Field
                  hasLabel
                  name="address.addressLine1"
                  placeholder="Address"
                  component={FormInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_12">
                <Field
                  hasLabel
                  name="address.addressLine2"
                  placeholder="Address Line 2"
                  component={FormInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_6">
                <Field
                  hasLabel
                  name="address.city"
                  placeholder="City"
                  component={FormInput}
                />
              </Column>
              <Column modifiers="col_6">
                <Field
                  hasLabel
                  name="address.state"
                  placeholder="State"
                  options={STATES}
                  idField="abbreviation"
                  nameField="name"
                  component={FormPicker}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_6">
                <Field
                  hasLabel
                  name="address.zipcode"
                  placeholder="Zip"
                  component={FormInput}
                  pattern="\d*"
                  maxLength="5"
                />
              </Column>
            </Row>
          </Form>
        )
      }
    </Formik>
  );
});

export default Contact;
