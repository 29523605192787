import styled from 'styled-components';
import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  background-color: ${theme.colors.blueBrand};
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
`;

export default buildStyledComponent('Splash_Wrapper', styled.div, styles);
