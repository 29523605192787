import { useMemo } from 'react';

import { Avatar, Row, Column } from '@gaz/gaz-components.public';
import { Container, Text, HR, Badge, Ribbon } from 'common';
import { alertsTitle, getVitalTypeAttr } from 'utils/helper';
import { getFullName } from 'utils/string';
import { displayTime } from 'utils/time';
import assets from 'assets';
import { WELLNESS_ITEMS, DIET_ITEMS, PROGRESS_ITEMS } from 'utils/constants';
import { startCase } from 'lodash';

export const ListItem = ({ alert, handleClick }) => (
  <Container modifiers={['card', 'withGutters']} onClick={handleClick}>
    <Row modifiers={['middle']}>
      <Column>
        <Avatar
          user={alert.user}
          modifiers={['round']}
          height={40}
          width={40}
        />
      </Column>
      <Column modifiers={['col']}>
        <Text modifiers={['medium', 'block']}>{getFullName(alert.user)}</Text>
        <Text modifiers={['medium', 'block', 'blue']}>
          {displayTime(alert.triggerTime, 'MM-DD-YYYY')}
        </Text>
      </Column>
      {alert.unseen > 0 && (
        <Column>
          <Ribbon top="-5px" right="10px">
            <Badge>{alert.unseen}</Badge>
          </Ribbon>
        </Column>
      )}
    </Row>
  </Container>
);

export const AlertsCategoryItem = ({ category, handleClick }) => (
  <Container
    modifiers={['card', 'padding_1', 'withGutters']}
    onClick={handleClick}
  >
    <Row modifiers={['spaceBetween']}>
      <Column modifiers={['col_4', 'flexBox', 'verticalContentCenter']}>
        <img src={PROGRESS_ITEMS[category.name].icon} width={40} height={40} />
        <Text>{PROGRESS_ITEMS[category.name].title}</Text>
      </Column>
      {category.lastAlert ? (
        <Column modifiers={['col']}>
          <Text modifiers={['block']}>Last Alert</Text>
          <Text modifiers={['block', 'danger']}>
            {displayTime(category.lastAlert, 'MM/DD/YY')}
          </Text>
        </Column>
      ) : (
        <Column modifiers={['col', 'flexBox', 'verticalContentCenter']}>
          <Text modifiers={['block', 'gray']}>N/A</Text>
        </Column>
      )}
      {category.unseenCount > 0 && (
        <Column modifiers={['flexBox', 'verticalContentCenter']}>
          <Badge>{category.unseenCount}</Badge>
        </Column>
      )}
    </Row>
  </Container>
);

export const AlertsItem = ({ alert }) => {
  const titles = useMemo(() => {
    return alertsTitle(alert.alerts, alert.subType, true);
  }, [alert]);

  const icon = useMemo(() => {
    if (alert.measure === 'vital') {
      return getVitalTypeAttr(alert.subType).icon;
    } else if (alert.measure === 'medication') {
      return assets.icons.icMedication;
    } else if (alert.measure === 'activity') {
      return assets.icons.icActivity;
    } else if (alert.measure === 'wellness') {
      return WELLNESS_ITEMS[alert.subType].icon;
    } else if (alert.measure === 'diet') {
      return DIET_ITEMS[alert.subType].icon;
    }
    return assets.icons.icVital;
  }, [alert]);

  return (
    <Container modifiers={['card', 'padding_2', 'withGutters']}>
      <Row modifiers={['middle', 'spaceBetween']}>
        <img src={icon} width={40} height={40} />
        <Column modifiers={['col']}>
          <Text modifiers={[!alert.isSeen && 'bold']}>{titles}</Text>
        </Column>
      </Row>
      <HR modifiers={['gray']} />
      <Row modifiers={['topGutters_1', 'spaceBetween']}>
        <Column modifiers={['noPadding', 'col_3']}>
          <Text modifiers={['blue', 'medium']}>
            {displayTime(alert.triggerTime, 'MM/DD/YY')}
          </Text>
        </Column>
        <Column modifiers={['noPadding', 'col_3']}>
          <Text modifiers={['blue', 'medium']}>{startCase(alert.measure)}</Text>
        </Column>
        <Column modifiers={['noPadding', 'col_4']}>
          <Text modifiers={['blue', 'medium']}>
            {alert.subType ? startCase(alert.subType) : ''}
          </Text>
        </Column>
      </Row>
    </Container>
  );
};
