import styled from 'styled-components';

import { buildStyledComponent } from 'style';
import { DESKTOP_VIEWPORT_WIDTH } from 'utils/constants';

const styles = ({ theme }) => `
  background-color: ${theme.colors.background};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  @media only screen and (min-width: 769px) {
    width: ${DESKTOP_VIEWPORT_WIDTH};
  }
`;

const Wrapper = buildStyledComponent(
  'SimpleLayout_Wrapper',
  styled.div,
  styles
);

export default Wrapper;
