import { useEffect, useCallback, useContext } from 'react';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';

import {
  Container,
  Row,
  Column,
  Button,
  FormPicker,
  FormDatePicker,
  FormInput,
} from 'common';
import { Popover } from 'common';
import { Field, Formik, Form } from 'formik';
import { ADD_MEDICAL_LICENSE } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { STATES } from 'utils/constants';

const licenseSchema = Yup.object().shape({
  stateCode: Yup.string().required('State is required.').nullable(),
  licenseNumber: Yup.string().required('License number is required.'),
  expiredAt: Yup.date().required('Expiry Date is required.'),
});

const initialValues = {
  stateCode: null,
  licenseNumber: null,
  expiredAt: new Date(),
};

const AddModal = ({ handleClose }) => {
  const [addMedicalLicense, { loading }] = useMutation(ADD_MEDICAL_LICENSE, {
    onCompleted: (data) => {
      if (data.addMedicalLicense) {
        loadingVar(false);
        handleClose(data.addMedicalLicense);
      }
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const handleSubmit = async (values) => {
    await addMedicalLicense({ variables: { license: values } });
  };

  loadingVar(loading);

  return (
    <Popover
      open
      title="Add Medical License"
      onClose={() => handleClose()}
      overlayClose
    >
      <Formik
        initialValues={initialValues}
        validationSchema={licenseSchema}
        onSubmit={handleSubmit}
        key="addLicenseForm"
      >
        <Form>
          <Container modifiers={['flexBox']}>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  required
                  hasLabel
                  name="stateCode"
                  placeholder="State"
                  options={STATES}
                  idField="abbreviation"
                  nameField="name"
                  component={FormPicker}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  required
                  hasLabel
                  name="licenseNumber"
                  placeholder="License Number"
                  component={FormInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  required
                  hasLabel
                  name="expiredAt"
                  placeholder="Expiry Date"
                  minDate={new Date()}
                  component={FormDatePicker}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col_12', 'flexBox', 'center']}>
                <Button type="submit" modifiers={['primary']}>
                  Add
                </Button>
              </Column>
            </Row>
          </Container>
        </Form>
      </Formik>
    </Popover>
  );
};

export default AddModal;
