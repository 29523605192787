import React from 'react';
import { isFunction } from 'lodash';

import { Row, Column, Text } from '@gaz/gaz-components.public';
import Table from './Table';

export function SimpleTable({ data = [], columns = [{ key: '', label: '' }] }) {
  // render row if column has a render function
  const renderData = data.filter((item) => item.value.title !== 'N/A');
  const renderRow = (row, i) => {
    const renderFunction = columns[i].render;
    if (isFunction(renderFunction)) {
      return renderFunction(row);
    }
    const renderData = row[columns[i].key];
    let title,
      modifiers = ['center', 'muted'];
    if (typeof renderData === 'object') {
      title = renderData.title;
      modifiers = [...modifiers, ...renderData.modifiers];
    } else {
      title = renderData;
    }
    return <Text modifiers={modifiers}>{title}</Text>;
  };

  return (
    <Table>
      <Row>
        {columns.map((column, i) => (
          <Column
            modifiers={[
              `col_${12 / columns.length}`,
              'fluid',
              'flexBox',
              'center',
            ]}
            key={`table-column-${i}`}
          >
            <Text modifiers={['xSmall', 'muted', 'bold']}>{column.label}</Text>
          </Column>
        ))}
      </Row>
      {renderData.map((row, i) => (
        <Row key={`table-data-${i}`}>
          {columns.map((column, j) => (
            <Column
              modifiers={[
                `col_${12 / columns.length}`,
                'fluid',
                'flexBox',
                'center',
              ]}
              key={j}
            >
              {renderRow(row, j)}
            </Column>
          ))}
        </Row>
      ))}
    </Table>
  );
}
