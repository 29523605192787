import { useEffect, useCallback, useState } from 'react';

import {
  Container,
  Row,
  Column,
  Button,
  Text,
  SlidingPane,
  TextArea,
  DatePicker,
  ScrollView,
} from 'common';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import assets from 'assets';
import { formatTitleAndName } from 'utils/string';
import { displayTime } from 'utils/time';
import { loadingVar } from 'graphql/cache';
import { FETCH_AVAILABLE_TIMES } from 'graphql/queries';

const Reschedule = ({ appointment, isOpen, handleClose, handleReschedule }) => {
  const [reason, updateReason] = useState();
  const [selectedDate, setSelectedDate] = useState(
    moment(appointment.time).toDate()
  );
  const [selectedSlot, setSelectedSlot] = useState();
  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    updateReason(appointment.reason || '');
  }, [appointment]);

  const [fetchAvailableSlots] = useLazyQuery(FETCH_AVAILABLE_TIMES, {
    onCompleted: ({ availableTimes }) => {
      if (!availableTimes) {
        return;
      }
      const slots = availableTimes.map((range) => {
        const start = new Date(range.start);
        const end = new Date(range.end);
        return {
          time: start,
          end,
          text: displayTime(start, 'hh:mm a'),
        };
      });
      setTimeSlots(slots);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  useEffect(() => {
    async function fetchData() {
      setSelectedSlot(null);
      setTimeSlots([]);
      if (!isOpen || !appointment?.provider || !selectedDate) {
        return;
      }
      loadingVar(true);
      const midnightTimestamp = new Date(
        displayTime(selectedDate, 'date')
      ).getTime();
      const from = new Date(Math.max(midnightTimestamp, new Date().getTime()));
      const to = new Date(midnightTimestamp + 24 * 3600 * 1000);
      const offset = moment().utcOffset();
      await fetchAvailableSlots({
        variables: { provider: appointment.provider._id, from, to, offset },
      });
      loadingVar(false);
    }
    fetchData();
  }, [appointment, selectedDate, fetchAvailableSlots, isOpen]);

  const handleChangeDate = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const handleChangeReason = (e) => {
    updateReason(e.target.value);
  };

  const handleClickReschedule = () => {
    handleReschedule(reason, selectedSlot);
  };

  return (
    <SlidingPane
      isOpen={isOpen}
      handleClose={handleClose}
      modifiers={['fillHeight']}
    >
      <SlidingPane.Header modifiers={['hasBorder']}>
        <Text modifiers={['bold', 'primary', 'block']}>
          Reschedule Appointment with {formatTitleAndName(appointment.patient)}
        </Text>
      </SlidingPane.Header>
      <SlidingPane.Content>
        <Container
          modifiers={['flexBox', 'fullHeight', 'withGutters', 'maxHeight_80']}
        >
          <Container modifiers={['fluid', 'flexFixed']}>
            <Row>
              <Text modifiers={['block', 'h2']}>Reason</Text>
            </Row>
            <TextArea value={reason} onChange={handleChangeReason} />
          </Container>
          <Container modifiers={['flexFixed', 'fluid']}>
            <Row>
              <Text modifiers={['block', 'h2']}>Select Date and Time</Text>
            </Row>
            <DatePicker
              subModal
              handleChange={handleChangeDate}
              value={selectedDate}
              minDate={new Date()}
            >
              <Row>
                <Column modifiers={['col_4', 'noPadding']}>
                  <Text modifiers={['block', 'medium', 'center']}>Day</Text>
                </Column>
                <Column modifiers={['col_4', 'noPadding']}>
                  <Text modifiers={['block', 'medium', 'center']}>Date</Text>
                </Column>
                <Column modifiers={['col_4', 'noPadding']}>
                  <Text modifiers={['block', 'medium', 'center']}>Start</Text>
                </Column>
              </Row>
              <Row modifiers={['smallGutters', 'borderGray', 'roundCorner']}>
                <Column modifiers={['col_4']}>
                  <Text modifiers={['block', 'medium', 'center']}>
                    {displayTime(selectedDate, 'dddd')}
                  </Text>
                </Column>
                <Column modifiers={['col_4']}>
                  <Text modifiers={['block', 'medium', 'center', 'primary']}>
                    {displayTime(selectedDate, 'MM/DD/YY')}
                  </Text>
                </Column>
                <Column modifiers={['col_4']}>
                  <Text modifiers={['block', 'medium', 'center']}>
                    {selectedSlot
                      ? displayTime(selectedSlot.time, 'hh:mm a')
                      : '-- --'}
                  </Text>
                </Column>
              </Row>
            </DatePicker>
          </Container>
          <Container modifiers={['flexFixed', 'fluid']}>
            <Text modifiers={['block', 'h2']}>Available time slots</Text>
          </Container>
          <ScrollView modifiers={['noPadding']}>
            <Row>
              {timeSlots?.length > 0 ? (
                timeSlots.map((slot, index) => (
                  <Column modifiers={['col_4', 'noPadding']} key={index}>
                    <Row modifiers={['topGutters_1', 'center']}>
                      <Button
                        modifiers={[
                          slot.time === selectedSlot?.time
                            ? null
                            : 'outlinePrimary',
                          'roundCorner',
                          'small',
                        ]}
                        onClick={() => setSelectedSlot(slot)}
                        key={slot.time}
                      >
                        {slot.text}
                      </Button>
                    </Row>
                  </Column>
                ))
              ) : (
                <Text modifiers={['medium']}>No appointment available</Text>
              )}
            </Row>
          </ScrollView>
        </Container>
      </SlidingPane.Content>
      <Container modifiers={['footer', 'flexFixed']}>
        <Row modifiers={['spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={handleClose}
          />
          <Button
            modifiers={[
              'roundCorner',
              'widthSmall',
              !selectedSlot && 'disabled',
            ]}
            onClick={handleClickReschedule}
          >
            Reschedule
          </Button>
        </Row>
      </Container>
    </SlidingPane>
  );
};

export default Reschedule;
