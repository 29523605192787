import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  bgWhiteSmoke: ({ theme }) => `
    background-color: ${theme.colors.white_1};
  `,
};

const styles = ({ theme }) => `
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

const Content = buildStyledComponent('MainLayout_Content', styled.div, styles, {
  modifierConfig,
});

export default Content;
