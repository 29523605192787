import { Container, Text, Column } from '@gaz/gaz-components.public';
import styled from 'styled-components';

import { Row } from 'common';
import { buildStyledComponent } from 'style';
import { frequencyTitle, alertsTitle, medicationModsTitle } from 'utils/helper';
import MeasureItem from './MeasureItem';
import assets from 'assets';

const Medication = buildStyledComponent(
  'CareplanMedication',
  styled(({ medications, ...props }) => {
    const medicationItems = Object.entries(medications).map(
      ([id, medication]) => {
        if (id === 'alerts') {
          return null;
        }
        const detail = { Schedule: frequencyTitle(medication.frequency) };
        if (medication.mods?.mode) {
          detail.Mods = medicationModsTitle(medication.mods);
        }
        if (medication.alerts && Object.keys(medication.alerts).length > 0) {
          detail.Triggers = `>${medication.alerts.triggerValue} missed`;
          detail.Allowed = alertsTitle(medication.alerts);
          detail.Action = 'Book Appointments';
        }

        return {
          name: medication.name,
          goal: '',
          detail,
        };
      }
    );
    return (
      <Container {...props}>
        <Text modifiers={['bold']}>MEDICATION</Text>
        {medicationItems.map(
          (medication, index) =>
            medication && (
              <Container modifiers={['fluid']} key={index}>
                <Row modifiers={['middle', 'smallGutters']}>
                  <img src={assets.icons.icMedication} />
                  <Column modifiers={['col']}>
                    <Text modifiers={['block']}>{medication.name}</Text>
                  </Column>
                </Row>
                <MeasureItem details={medication.detail} />
              </Container>
            )
        )}
      </Container>
    );
  }),
  ({ theme }) => `
    >:not(:first-child) {
      margin-left: ${theme.dimensions.padding_2};
    }
  `
);

export default Medication;
