import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, ScrollView, BackButton, Button, Checkbox } from 'common';
// import { cloneDeep } from 'lodash';

import {
  ADD_PAYMENT_METHOD,
  // SET_DEFAULT_PAYMENT_METHOD,
  // REMOVE_PAYMENT_METHOD,
} from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { Row, Column, Text, Container, CreditCardInput } from 'common';
import assets from 'assets';
import { PatientHomeContext } from '../Context';

export default ({ methods, updateMethods, handleClose }) => {
  const { patient } = useContext(PatientHomeContext);
  const [addPaymentMethodApi] = useMutation(ADD_PAYMENT_METHOD);
  // const [setDefaultPaymentMethodApi] = useMutation(SET_DEFAULT_PAYMENT_METHOD);
  // const [removePaymentMethodApi] = useMutation(REMOVE_PAYMENT_METHOD);
  const [showAddModal, updateShowAddModal] = useState(false);

  const handleAddClick = () => {
    updateShowAddModal(true);
  };

  // const handleRemoveClick = () => {};

  const handleAdd = async (data) => {
    loadingVar(true);
    await addPaymentMethodApi({
      variables: {
        patient: patient._id,
        type: data.type,
        number: data.number,
        exp: data.exp,
        cvc: data.cvc,
      },
      onCompleted: ({ addPaymentMethod: newCard }) => {
        updateMethods([newCard]);
      },
    });
    updateShowAddModal(false);
    loadingVar(false);
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Payment Method</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
          <ScrollView>
            {methods?.length > 0 ? (
              <Container>
                {methods.map((method) => (
                  <Row>
                    <Column modifiers={['flexBox', 'verticalContentCenter']}>
                      <Checkbox checked={true} onChange={() => {}} />
                    </Column>
                    <Column modifiers={['col']}>
                      <Text>
                        {method.cardType.toUpperCase()} card ends with{' '}
                        {method.last4Digits}
                      </Text>
                    </Column>
                  </Row>
                ))}
              </Container>
            ) : (
              <Container>
                <Text modifiers={['large']}>
                  Please add payment method to be used on medication orders.
                </Text>
              </Container>
            )}
          </ScrollView>
          <Container modifiers={['flexFixed', 'shadow', 'backgroundWhite']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                image={assets.icons.icCloseGray}
                modifiers={['icon', 'transparent']}
                onClick={handleClose}
              />
              <Button
                modifiers={['widthMedium', 'roundCorner']}
                onClick={handleAddClick}
              >
                {methods?.length > 0 ? 'Replace' : 'Add'}
              </Button>
            </Row>
          </Container>
        </Container>
        {showAddModal && (
          <CreditCardInput
            handleAdd={handleAdd}
            handleClose={() => updateShowAddModal(false)}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
