import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import qs from 'query-string';

import { AuthContext } from 'contexts/auth';
import { uriFromPractice } from 'utils/helper';
import { loadData } from 'utils/storage';

import urls from './urls';

const AuthComp = ({ location, allowed, restricted, children }) => {
  const { me, loading } = useContext(AuthContext);

  if (loading) {
    return null;
  }

  if (!me) {
    return (
      <Redirect
        to={{
          pathname: urls.AUTH,
          state: {
            from: location,
          },
        }}
      />
    );
  }

  switch (me.status) {
    case 'new':
      if (location.pathname !== urls.ONBOARD) {
        return <Redirect to={urls.ONBOARD} />;
      }
      break;
    case 'onboarded':
      if ([urls.ONBOARD].includes(location.pathname)) {
        return <Redirect to="/" />;
      }
      break;
    default:
  }

  if (
    !window.location.href.includes('localhost') &&
    me.activeProviderPractice
  ) {
    const fulllUrl = window.location.href.replace(/(^\w+:|^)\/\//, '');
    const practiceUrl = uriFromPractice(
      me.activeProviderPractice.practice?.name
    );
    const params = qs.parse(location.search);
    params['authToken'] = loadData('token');
    if (practiceUrl?.length > 0) {
      if (
        !fulllUrl.startsWith(`${practiceUrl}.${process.env.REACT_APP_BASE_URI}`)
      ) {
        window.location.replace(
          qs.stringifyUrl({
            url: `https://${practiceUrl}.${process.env.REACT_APP_BASE_URI}${location.pathname}`,
            query: params,
          })
        );
        return null;
      }
    } else if (!fulllUrl.startsWith(process.env.REACT_APP_BASE_URI)) {
      window.location.replace(
        qs.stringifyUrl({
          url: `https://${process.env.REACT_APP_BASE_URI}${location.pathname}`,
          query: params,
        })
      );
      return null;
    }
  }

  if (me.status === 'onboarded' && !me.activeProviderPractice) {
    if (
      location.pathname === urls.REGISTER_PRACTICE ||
      location.pathname === urls.MY_PRACTICE
    ) {
      return children;
    }
    return <Redirect to={urls.MY_PRACTICE} />;
  }

  const designation = me.memberDesignation;

  if (allowed || restricted) {
    if (allowed === designation || allowed?.includes(designation)) {
      return children;
    } else if (
      !(restricted === designation || restricted?.includes(designation))
    ) {
      return children;
    }
    return <Redirect to="/" />;
  }

  return children;
};

const ProtectedRoute = ({
  component: Component,
  allowed,
  restricted,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <AuthComp
            location={props.location}
            allowed={allowed}
            restricted={restricted}
          >
            <Component {...props} />
          </AuthComp>
        );
      }}
    />
  );
};

export default ProtectedRoute;
