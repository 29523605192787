import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  FETCH_PATIENT_ACTIVE_CAREPLAN,
  FETCH_PATIENT_CAREPLANS,
} from 'graphql/queries';

import { filterCareplanSchedulesByItemTime } from 'utils/careplan';

const useCareplan = ({ patient }) => {
  const [activeCareplan, setActiveCareplan] = useState(undefined);
  const [isCPExpired, setIsCPExpired] = useState();
  const [careplans, setCareplans] = useState(null);
  const [tasks, updateTasks] = useState(null);
  const [queryActiveCareplan] = useLazyQuery(FETCH_PATIENT_ACTIVE_CAREPLAN, {
    onCompleted: (data) => {
      const careplan = data.activeCareplan?.isActive
        ? data.activeCareplan
        : null;
      setIsCPExpired(data.activeCareplan && !data.activeCareplan.isActive);
      setActiveCareplan(careplan);
      updateTasks(filterCareplanSchedulesByItemTime(careplan));
    },
    onError: (error) => {
      setIsCPExpired(null);
      setActiveCareplan(null);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const [queryCareplans] = useLazyQuery(FETCH_PATIENT_CAREPLANS, {
    onCompleted: (data) => {
      let careplans;
      if (data.patientCareplans) {
        careplans = data.patientCareplans;
      }
      setCareplans(careplans || data.patientCareplans);
    },
  });

  const fetchActiveCareplan = () => {
    if (!patient) {
      return;
    }
    queryActiveCareplan({
      variables: { patient: patient._id },
    });
  };

  const fetchCareplans = () => {
    setCareplans(null);
    queryCareplans({
      variables: { patient: patient._id },
    });
  };

  return {
    activeCareplan,
    isCPExpired,
    careplans,
    fetchActiveCareplan,
    fetchCareplans,
    tasks,
  };
};

export default useCareplan;
