import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  color: ${theme.colors.secondary};
  font-size: ${theme.dimensions.fontSize};
  text-align: center;
  padding: ${px2rem(5)} ${px2rem(10)};
  width: 100%;
  box-shadow: 0px -2px 10px rgba(46, 41, 78, 0.08);
`;

export default buildStyledComponent('Modal_Header', styled.div, styles);
