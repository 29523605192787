import { Formik, Form } from 'formik';

import { Container, Row, Text } from 'common';

import { CustomRow, CustomField } from '../Frequency/Items';

const TriggerTypes = ['Consecutive', 'Total'];

const ActivityAlerts = ({ alerts, formRef }) => {
  const initialValues = {
    triggerValue: alerts?.triggerValue || 5,
    triggerType: alerts?.triggerType || 'Consecutive',
  };

  return (
    <Container modifiers={['padding_2', 'flexBox', 'fullHeight']}>
      <Formik initialValues={initialValues} innerRef={formRef}>
        <Form>
          <Row modifiers={['smallGutters']}>
            <Text modifiers={['block', 'medium', 'secondary']}>
              Allowable missed activity before trigger alert is sent
            </Text>
          </Row>
          <CustomRow>
            <CustomField name="triggerValue" type="number" />
            <CustomField
              fieldType="picker"
              title="SELECT ORDER"
              options={TriggerTypes}
              name="triggerType"
            />
          </CustomRow>
          <Row modifiers={['smallGutters']}>
            <Text modifiers={['block', 'orange', 'medium']}>
              All parties will be alerted and prompted to book an appointment if
              patient exceeds these limits.
            </Text>
          </Row>
        </Form>
      </Formik>
    </Container>
  );
};

export default ActivityAlerts;
