import { useContext, useEffect, useState, useCallback } from 'react';
import { cloneDeep } from 'lodash';

import { Container } from '@gaz/gaz-components.public';
import { CreateCareplanContext } from '../Context';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import Main from './Main';
import AddActivity from './AddActivity';
import Instruction from './Instruction';
import PastItems from './Past';

const ActivityIndex = ({}) => {
  const { setFooterVisible, careplan, updateCareplan } = useContext(
    CreateCareplanContext
  );
  const { goBack } = useContext(PatientHomeContext);
  const [activity, updateActivity] = useState();
  const [activeAction, updateActiveAction] = useState();

  useEffect(() => {
    setFooterVisible(false);
    return () => {
      setFooterVisible(true);
    };
  }, []);

  useEffect(() => {
    if (careplan.content.activity) {
      updateActivity(cloneDeep(careplan.content.activity));
    }
  }, [careplan]);

  const handleAdd = () => {
    updateActiveAction('add');
  };

  const handleEdit = () => {
    updateActiveAction('edit');
  };

  const handleShowPrevious = () => {
    updateActiveAction('previous');
  };

  const handleSave = () => {
    careplan.content.activity = activity;
    updateCareplan({ ...careplan });
    goBack();
  };

  return (
    <Container modifiers={['fluid', 'fullHeight']}>
      <Main
        handleAdd={handleAdd}
        handleEdit={handleEdit}
        handleShowPrevious={handleShowPrevious}
        handleSave={handleSave}
        handleClose={() => goBack()}
        activity={activity}
        updateActivity={updateActivity}
      />
      {activeAction === 'add' && (
        <AddActivity
          handleAdd={updateActivity}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
      {activeAction === 'edit' && (
        <Instruction
          activity={activity}
          handleDone={updateActivity}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
      {activeAction === 'previous' && (
        <PastItems
          handleAdd={updateActivity}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
    </Container>
  );
};

export default ActivityIndex;
