import { useContext, useEffect, useState, useCallback } from 'react';
import {
  Row,
  Column,
  Text,
  Button,
  ScrollView,
} from '@gaz/gaz-components.public';

import { Container, Loader, PeriodPicker } from 'common';
import { CreateNoteContext } from '../Context';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import DiagnosisItem from './Item';
import { FETCH_NOTE_ITEM_PAST_RECORDS } from 'graphql/queries';
import { displayTime } from 'utils/time';
import { useLazyQuery } from '@apollo/client';
import assets from 'assets';

const DiagnosisHistory = ({ selectable, handleAddFromPast, handleAdd }) => {
  const { patient } = useContext(PatientHomeContext);
  const { itemsPickedPeriods, setItemsPickedPeriods, goBack } =
    useContext(CreateNoteContext);
  const [pastDiagnoses, setPastDiagnoses] = useState([]);
  const [pastDiagnosesSelection, setPastDiagnosesSelection] = useState({});
  const [fetchItemRecords] = useLazyQuery(FETCH_NOTE_ITEM_PAST_RECORDS);

  const fetchRecords = useCallback(
    (period) => {
      // setPastDiagnoses(null);
      fetchItemRecords({
        variables: {
          type: 'diagnosis',
          period,
          patient: patient._id,
        },
        onCompleted: (data) => {
          const diagnoses = [];
          data.noteItemPastRecords.forEach((record) => {
            record.content.diagnosis.forEach((diagnosis) => {
              diagnoses.push({
                value: diagnosis,
                code: diagnosis.code,
                date: displayTime(record.time, 'MM/DD/yyyy'),
              });
            });
          });
          setPastDiagnoses(diagnoses);
          setPastDiagnosesSelection({});
        },
        fetchPolicy: 'no-cache',
        initialFetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
      });
    },
    [patient]
  );

  const handleSelectPeriod = (value) => {
    setItemsPickedPeriods({ ...itemsPickedPeriods, diagnosis: value });
    fetchRecords(value);
  };

  const toggleSelectionPast = (diagnosis) => {
    if (pastDiagnosesSelection[diagnosis.code]) {
      delete pastDiagnosesSelection[diagnosis.code];
    } else {
      pastDiagnosesSelection[diagnosis.code] = diagnosis;
    }
    setPastDiagnosesSelection({ ...pastDiagnosesSelection });
  };

  const handleDone = () => {
    handleAdd(pastDiagnosesSelection);
    goBack();
  };

  useEffect(() => {
    if (!patient) {
      return;
    }
    fetchRecords(itemsPickedPeriods.diagnosis);
  }, [patient]);

  return (
    <Container modifiers={['flexBox', 'fullHeight', 'fluid']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Column>
            <Text modifiers={['bold']}>Past Diagnosis</Text>
          </Column>
          <Column>
            <PeriodPicker
              value={itemsPickedPeriods.diagnosis}
              handleSelect={handleSelectPeriod}
            />
          </Column>
        </Row>
        {!selectable && (
          <Container>
            <Button
              modifiers={['roundCorner', 'fullWidth']}
              onClick={handleAddFromPast}
            >
              + ADD FROM PAST DIAGNOSIS
            </Button>
          </Container>
        )}
      </Container>
      <ScrollView modifiers={['flexWeight_2']}>
        <Container>
          {/* {pastDiagnoses === null ? (
            <Row modifiers={['center']}>
              <Loader.Icon />
            </Row>
          ) : ( */}
          {pastDiagnoses.map((diagnosis, index) => (
            <DiagnosisItem
              value={diagnosis.value}
              key={index}
              selected={pastDiagnosesSelection[diagnosis.code]}
              selectable={selectable}
              date={diagnosis.date}
              handleChange={() => {
                toggleSelectionPast(diagnosis.value);
              }}
            />
          ))}
          {/* )} */}
        </Container>
      </ScrollView>
      {selectable && (
        <Container modifiers={['footer']}>
          <Row modifiers={['spaceBetween', 'middle']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            <Button
              modifiers={[
                'widthMedium',
                'roundCorner',
                Object.keys(pastDiagnosesSelection).length === 0 && 'disabled',
              ]}
              onClick={handleDone}
            >
              Add Selected
            </Button>
          </Row>
        </Container>
      )}
    </Container>
  );
};

export default DiagnosisHistory;
