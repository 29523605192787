import React, { useRef } from 'react';
import styled from 'styled-components';

import {
  Row,
  Column,
  ChatBubble,
  Text,
  Avatar,
} from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';

import { getFullName } from 'utils/string';
import { displayTime } from 'utils/time';

import assets from 'assets';

const playIconStyles = () => `
  position: absolute;
  width: ${px2rem(30)};
  height: ${px2rem(30)};
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

const PlayIcon = buildStyledComponent(
  'MessageItem_PlayIcon',
  styled.img,
  playIconStyles
);

export const ChatMessage = ({ meId, message, ...props }) => {
  const sender = message?.sender;
  const videoRef = useRef(null);

  const onClickMedia = () => {
    if (videoRef.current) {
      const element = videoRef.current;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      }
    }
  };

  const isMe = sender._id === meId;

  const isAttachment = Boolean(message?.attachment?.url);

  return (
    <Row modifiers={[isMe && 'reverse', isMe && 'start', 'withGutters']}>
      <Column style={{ marginTop: px2rem(10) }} modifiers={['col_2']}>
        <Avatar width={42} height={42} modifiers={['round']} user={sender} />
      </Column>
      <Column modifiers={['col_8']}>
        <Row style={{ marginBottom: px2rem(10) }} modifiers={[isMe && 'end']}>
          <Text modifiers={['inline', 'xSmall', 'muted']}>
            {`${getFullName(sender)}, ${displayTime(message.createdAt)}`}
          </Text>
        </Row>
        <Row modifiers={[isMe && 'end']}>
          <ChatBubble.Text
            modifiers={[isMe && 'isMe', isAttachment && 'attachment']}
          >
            {message.attachment?.type === 'image' && (
              <ChatBubble.Image src={message.attachment?.url} />
            )}
            {message.attachment?.type === 'video' && (
              <div style={{ position: 'relative' }} onClick={onClickMedia}>
                <PlayIcon src={assets.icons.videoPlayer} alt="" />
                <ChatBubble.Video ref={videoRef}>
                  <source src={message.attachment?.url} />
                </ChatBubble.Video>
              </div>
            )}
            {message.text}
          </ChatBubble.Text>
        </Row>
      </Column>
    </Row>
  );
};
