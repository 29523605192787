import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';

import { API_TYPE, ApiQueueContext } from 'contexts/apiQueue';
import { AuthContext } from 'contexts/auth';
import { REDUX_ACTIONS } from 'redux/constants';

import { TimeLog } from './TimeLog';

// This Call Timer is only for UI. Its duration comes from the call model.
export const TIMER_TYPE = {
  MANUAL_PSTN: 'manualAudio',
  MANUAL_VIDEO: 'manualVideo',
  CALL: 'call',
  CHAT: 'chat',
  NOTE: 'note',
  CAREPLAN: 'careplan',
  ORDER_MEDS: 'orderMeds',
  REVIEW: 'review',
};
const HOME_TIMER_TYPES = [
  TIMER_TYPE.CHAT,
  TIMER_TYPE.NOTE,
  TIMER_TYPE.CAREPLAN,
  TIMER_TYPE.ORDER_MEDS,
  TIMER_TYPE.REVIEW,
];

export const TimerContext = createContext();

let activeLogs = {
  [TIMER_TYPE.CALL]: undefined,
  [TIMER_TYPE.CHAT]: undefined,
  [TIMER_TYPE.NOTE]: undefined,
  [TIMER_TYPE.CAREPLAN]: undefined,
  [TIMER_TYPE.ORDER_MEDS]: undefined,
  [TIMER_TYPE.REVIEW]: undefined,
};
let records = [];
let activeTimerTypes = [];
let timer = null;
let patientId = 0;

const setPatientId = (id) => {
  patientId = id;
};

export const TimerProvider = ({
  homeTotal,
  homePaused,
  setTimerData,
  children,
}) => {
  const { saveApi, executeApi } = useContext(ApiQueueContext);
  const { me } = useContext(AuthContext);
  const [total, setTotal] = useState(0);
  const [startedAt, setStartedAt] = useState(0); // Unix time when a group of reporting started
  const [elapsed, setElapsed] = useState(0);

  useEffect(() => {
    const getApiInfo = () => {
      const aggregated = [...records, ...inProgressLogs()].filter(
        (record) => record.duration > 0
      );

      return {
        practice: me?.activeProviderPractice?.practice._id,
        provider: me?._id,
        patient: patientId,
        items: aggregated.map((record) => record.serialize()),
        startedAt,
        endedAt: Math.round(new Date().valueOf() / 1000),
        totalTime: total,
      };
    };

    if (total % 1 === 0 && total > 0) {
      // Store api info every 1 sec
      const apiInfo = getApiInfo();
      if (apiInfo.items.length > 0) {
        saveApi(API_TYPE.TIMER, apiInfo);
      }
    }
  }, [total]);

  useEffect(() => {
    if (!patientId) {
      return;
    }

    let shouldTotalIncrement = false;
    let shouldHomeTotalIncrement = false;

    activeTimerTypes.forEach((timerType) => {
      activeLogs[timerType].endedAt = Math.round(new Date().valueOf() / 1000);
      if (HOME_TIMER_TYPES.includes(timerType)) {
        if (!homePaused) {
          activeLogs[timerType].duration++;
          shouldHomeTotalIncrement = true;
          shouldTotalIncrement = true;
        }
      } else {
        activeLogs[timerType].duration++;
        shouldTotalIncrement = true;
      }
    });
    if (shouldTotalIncrement) {
      setTotal((total) => total + 1);
    }
    if (shouldHomeTotalIncrement) {
      setTimerData({
        homeTotal: homeTotal + 1,
      });
    }
  }, [elapsed]);

  const startTimer = () => {
    if (
      !me?.activeProviderPractice.practice.isGazuntitePractice ||
      me?.activeProviderPractice.disableTracking
    ) {
      return;
    }
    if (!timer) {
      setStartedAt(Math.round(new Date().valueOf() / 1000));
      timer = setInterval(() => {
        setElapsed((e) => e + 1);
      }, 1000);
    }
  };

  const saveLogs = () => {
    executeApi(API_TYPE.TIMER);
  };

  const inProgressLogs = () => {
    return Object.values(activeLogs).filter((v) => !!v);
  };

  const clear = () => {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
    activeLogs = {};
    activeTimerTypes = [];
    records = [];
    setTimerData({
      homeTotal: 0,
      homePaused: true,
    });
    setTotal(0);
    setStartedAt(0);
  };

  const refreshHome = () => {
    HOME_TIMER_TYPES.forEach((type) => {
      if (activeLogs[type]) {
        activeLogs[type].refresh();
      }
    });
    setTimerData({
      homeTotal: 0,
      homePaused: false,
    });
  };

  const addTimer = (timerType, entityId) => {
    if (
      !me?.activeProviderPractice.practice.isGazuntitePractice ||
      me?.activeProviderPractice.disableTracking
    ) {
      return;
    }
    if (!activeTimerTypes.includes(timerType)) {
      activeTimerTypes.push(timerType);
      activeLogs[timerType] = new TimeLog(timerType, entityId);
    }
    startTimer();
    if (HOME_TIMER_TYPES.includes(timerType)) {
      setTimerData({
        homePaused: false,
      });

      // end all previous home timers
      activeTimerTypes.forEach((type) => {
        if (type !== timerType && HOME_TIMER_TYPES.includes(type)) {
          completeTimer(type);
        }
      });
    }

    return activeLogs[timerType];
  };

  const removeTimer = (timerType) => {
    activeTimerTypes = activeTimerTypes.filter((value) => value !== timerType);
    activeLogs[timerType] = undefined;

    if (activeTimerTypes.every((type) => !HOME_TIMER_TYPES.includes(type))) {
      setTimerData({
        homeTotal: 0,
        homePaused: true,
      });
    }

    if (activeTimerTypes.length === 0 && records.length > 0) {
      saveLogs();
      clear();
    }
    console.log(`timer ${timerType} completed`);
  };

  const completeTimer = (timerType, save = true) => {
    if (
      !me?.activeProviderPractice.practice.isGazuntitePractice ||
      me?.activeProviderPractice.disableTracking
    ) {
      return;
    }
    const log = activeLogs[timerType];
    if (!log) {
      return;
    }
    log.endedAt = Math.round(new Date().valueOf() / 1000);

    if (!save) {
      log.deleted = true;
    }

    records.push(log);
    removeTimer(timerType);
  };

  const value = useMemo(() => {
    return {
      addTimer,
      completeTimer,
      refreshHome,
      setPatientId,
    };
  }, [me, saveApi, executeApi]);

  return (
    <TimerContext.Provider value={value}>{children}</TimerContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  homeTotal: state.timer.homeTotal,
  homePaused: state.timer.homePaused,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setTimerData: (payload) =>
      dispatch({ type: REDUX_ACTIONS.TIMER_SET, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimerProvider);
