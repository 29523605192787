import { Text } from '../Text';

export default function SubHeading({ position, modifiers = [], children }) {
  return (
    <Text
      modifiers={[
        position,
        'light',
        'secondaryFont',
        ...modifiers
      ]}
    >
      {children}
    </Text>
  );
}