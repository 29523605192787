import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  containedHeight: () => `
    height: auto;
  `,
  fullWidth: () => `
    width: 100%;
  `,
};

const styles = ({ theme }) => `
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: ${theme.dimensions.lightBorder};
  color: ${theme.colors.primary};
  font-size: ${theme.dimensions.fontSizeXLarge};
  padding: ${theme.dimensions.padding_1};
  box-shadow: ${theme.dimensions.boxShadow_4};
  width: ${px2rem(311)};
  height: ${px2rem(101)};
  border-radius: ${px2rem(20)};
  background: ${theme.colors.white};
  margin-top: ${px2rem(10)};
  margin-bottom: ${px2rem(10)};
  font-family: ${theme.fonts.secondary} !important;
`;

export const CardButton = buildStyledComponent(
  'CardButton',
  styled.button,
  styles,
  { modifierConfig }
);
