import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  fixedGroup: () => `
    background-color: #D2D2D2;
  `,
};

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  border-radius: ${px2rem(12)};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  padding: ${px2rem(6)};
  margin: ${px2rem(6)} 0;
`;

export default buildStyledComponent(
  'Invitations_InvitationCard',
  styled.div,
  styles,
  { modifierConfig }
);
