import styled from 'styled-components';
import { buildStyledComponent } from 'style';

const fileInput = () => `
  display: none;
`;

const AttachWrapper = buildStyledComponent(
  'AddAttachment_FileInput',
  styled.input,
  fileInput
);

export default AttachWrapper;
