import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import assets from 'assets';

const modifierConfig = {
  fullWidth: () => ({
    styles: `
      border-radius: 1rem;
      width: 100%;
    `,
  }),
};

const styles = ({ theme }) => `
  height: ${px2rem(44)};
  padding: ${px2rem(4)} ${px2rem(4)} ${px2rem(4)} ${px2rem(36)};
  background-color: ${theme.colors.white};
  background-image: url(${assets.icons.icSearch});
  background-repeat: no-repeat;
  background-position: ${px2rem(15)} center;
  background-size: ${px2rem(20)} auto;
  border: 1px solid #F3F3F4;
  border-radius: ${px2rem(124)};
  position: relative;

  >input {
    width: 100%;
    padding: ${px2rem(6)} ${px2rem(28)} ${px2rem(6)} ${px2rem(10)};
    background-color: ${theme.colors.transparent};
    border: none;
    font-size: ${theme.dimensions.fontSize};
    color: ${theme.colors.textSecondary};
  }

  >input:focus {
    outline: none;
  }

  >div {
    position: absolute;
    top: 0;
    right: 0;
    width: ${px2rem(37)};
    height: ${px2rem(37)};
    text-align: center;
    padding: ${theme.dimensions.padding_1};

    img {
      width: ${px2rem(20)};
      height: ${px2rem(20)};
    }
  }
`;

const Wrapper = buildStyledComponent('SearchBar_Wrapper', styled.div, styles, {
  modifierConfig,
});

export default Wrapper;
