import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import {
  Text,
  Avatar,
  HR,
  Container,
  Row,
  Column,
  Tabs,
} from '@gaz/gaz-components.public';
import { InvitationCard } from 'common';
import assets from 'assets';
import { ACCEPT_OR_DECLINE_INVITE, CANCEL_INVITE } from 'graphql/mutations';
import { formatTitleAndName } from 'utils/string';

export default function Members({ data, refresh }) {
  const [tab, setTab] = useState(0);
  const admins = data.members?.filter((member) => member.isAdmin);
  const members = data.members?.filter((member) => !member.isAdmin);

  const [acceptOrDeclineInvite] = useMutation(ACCEPT_OR_DECLINE_INVITE);
  const [cancelInvite] = useMutation(CANCEL_INVITE);

  const handleTabChange = (newTab) => {
    setTab(newTab);
  };

  const handleAcceptOrDecline = (id, accepting) => {
    acceptOrDeclineInvite({
      variables: {
        id,
        accepting,
      },
      update: (cache, { data }) => {
        if (data.acceptOrDeclineInvite) {
          refresh();
        }
      },
    });
  };

  const handleCancel = (id) => {
    cancelInvite({
      variables: {
        id,
      },
      update: (cache, { data }) => {
        if (data.cancelInvite) {
          refresh();
        }
      },
    });
  };

  return (
    <Container>
      <Row>
        <Text modifiers={['bold']}>Administrators</Text>
      </Row>
      {admins?.map((admin, i) => (
        <Row key={admin.user._id} modifiers={['withGutters']}>
          <Column modifiers={['col_2']}>
            <Avatar
              user={admin.user}
              modifiers={['round']}
              width={48}
              height={48}
            />
          </Column>
          <Column modifiers={['col_9']}>
            <Text>{formatTitleAndName(admin.user)}</Text>
          </Column>
          {i < admins.length - 1 && (
            <Column modifiers={['col_12']}>
              <HR modifiers={['colored']} />
            </Column>
          )}
        </Row>
      ))}
      {data.isAdmin ? (
        <Tabs current={tab} onChange={handleTabChange}>
          <Tabs.Tab
            modifiers={['small']}
            title="Members"
            notificationCount={members.length}
          />
          <Tabs.Tab
            modifiers={['small']}
            title="Invitations"
            notificationCount={data.invites.length}
          />
          <Tabs.Tab
            modifiers={['small']}
            title="Requests"
            notificationCount={data.requests.length}
          />
        </Tabs>
      ) : (
        <Row>
          <Text modifiers={['bold']}>Members</Text>
        </Row>
      )}
      {tab === 0 &&
        members?.map((member, i) => (
          <Row key={member.user._id} modifiers={['withGutters']}>
            <Column modifiers={['col_2']}>
              <Avatar
                user={member.user}
                modifiers={['round']}
                width={48}
                height={48}
              />
            </Column>
            <Column modifiers={['col_9']}>
              <Text>{formatTitleAndName(member.user)}</Text>
            </Column>
            {i < members.length - 1 && (
              <Column modifiers={['col_12']}>
                <HR modifiers={['colored']} />
              </Column>
            )}
          </Row>
        ))}
      {tab === 1 &&
        data.invites?.map((invite) => (
          <InvitationCard
            key={invite._id}
            invite={invite}
            onCancel={handleCancel}
          />
        ))}
      {tab === 2 &&
        data.requests?.map((invite) => (
          <InvitationCard
            key={invite._id}
            invite={invite}
            received
            onAcceptOrDecline={handleAcceptOrDecline}
            onCancel={handleCancel}
          />
        ))}
    </Container>
  );
}
