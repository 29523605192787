import { useState, useCallback, useMemo } from 'react';
import {
  Row,
  Column,
  ScrollView,
  Text,
  Button,
} from '@gaz/gaz-components.public';

import { Container, Tabs, Popover } from 'common';
import Item from './Item';
import Instruction from './Instruction';
import assets from 'assets';
import { displayTime } from 'utils/time';

const Main = ({
  items,
  updateItems,
  handleAdd,
  handleShowPrevious,
  handleSave,
  handleClose,
}) => {
  const [deletingItem, setDeletingItem] = useState();
  const [editingItem, setEditingItem] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onRemove = useCallback((item) => {
    setShowDeleteModal(true);
    setDeletingItem(item);
  }, []);

  const handleRemoveAppointment = useCallback(
    (user, index) => {
      if (!items[user._id]) {
        return;
      }
      items[user._id].appointments.splice(index, 1);
      updateItems({ ...items });
    },
    [items]
  );

  const onConfirmDelete = useCallback(() => {
    delete items[deletingItem.user._id];
    setShowDeleteModal(false);
    updateItems({ ...items });
  }, [items, deletingItem]);

  const onEditItem = useCallback((item) => {
    setEditingItem(item);
  }, []);

  const handleSaveInstruction = (item, appointment, alerts) => {
    if (appointment.time) {
      if (!item.appointments) {
        item.appointments = [];
      }
      const existingAppointment = item.appointments.find(
        (elem) =>
          displayTime(appointment.time, 'MM/DD/YY hh:mm A') ===
          displayTime(elem.time, 'MM/DD/YY hh:mm A')
      );
      if (!existingAppointment) {
        item.appointments.push(appointment);
        item.appointments.sort((a, b) => a.time.getTime() - b.time.getTime());
      }
    }
    item.alerts = alerts;
    updateItems({ ...items });
  };

  const handleGroupAlerts = (item, groupedItems, alerts) => {
    item.alerts = alerts;
    groupedItems.forEach((itemId) => {
      items[itemId].alerts = alerts;
    });
    updateItems({ ...items });
  };

  const [activeTab, setActiveTab] = useState(0);
  const selectTab = (tab) => {
    if (tab === 1) {
      handleShowPrevious();
    }
  };

  const hasIncompleteItem = useMemo(() => {
    if (!items) {
      return true;
    }
    for (let item of Object.values(items)) {
      if (
        (!item.appointments || item.appointments.length === 0) &&
        (!item.alerts || Object.keys(item.alerts).length === 0)
      ) {
        return true;
      }
    }
    return false;
  }, [items]);

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Column modifiers={['col']}>
            <Text modifiers={['bold', 'block']}>Care Team</Text>
            <Text modifiers={['muted', 'medium', 'block']}>
              Create care team care plan (CP)
            </Text>
          </Column>
          <Button
            modifiers={['success', 'roundCorner', 'widthXSmall', 'noShadow']}
            onClick={handleAdd}
          >
            + ADD
          </Button>
        </Row>
      </Container>
      <Container
        modifiers={[
          'flexBox',
          'flexOne',
          'fullHeight',
          'backgroundWhite',
          'fluid',
        ]}
      >
        <Container modifiers={['flexFixed', 'topGutters_1']}>
          <Tabs current={activeTab} onChange={selectTab} noSeperator>
            <Tabs.Tab
              title="Recently added"
              modifiers={['xSmallPadding', 'noTab']}
            />
            <Tabs.Tab
              title="Previous Care Team"
              modifiers={['xSmallPadding']}
            />
          </Tabs>
        </Container>
        <ScrollView>
          {items && Object.values(items).length > 0 ? (
            Object.values(items).map((item) => (
              <Item
                item={item}
                key={item.id}
                onRemove={() => {
                  onRemove(item);
                }}
                onEdit={() => {
                  onEditItem(item);
                }}
                handleRemoveSubItem={handleRemoveAppointment}
              />
            ))
          ) : (
            <Row modifiers={['fullHeight', 'middle', 'center']}>
              <Container>
                <img src={assets.icons.icNoCareteam} />
                <Row modifiers={['withGutters']}>
                  <Text>
                    There are no care plan care team members. Please select from
                    previous care teams or press add to create a new care team.
                  </Text>
                </Row>
              </Container>
            </Row>
          )}
        </ScrollView>
      </Container>
      <Container modifiers={['flexFixed', 'footer', 'backgroundWhite']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Button
            image={assets.icons.icCloseGray}
            modifiers={['icon', 'transparent']}
            onClick={handleClose}
          />
          <Button
            modifiers={[
              hasIncompleteItem && 'disabled',
              'widthMedium',
              'roundCorner',
            ]}
            disabled={hasIncompleteItem}
            onClick={handleSave}
          >
            Save
          </Button>
        </Row>
      </Container>
      <Popover
        open={showDeleteModal}
        onConfirm={onConfirmDelete}
        onClose={() => setShowDeleteModal(false)}
        footer
      >
        <Text modifiers={['block', 'center']}>
          Are you sure want to remove this member?
        </Text>
      </Popover>
      {editingItem && (
        <Instruction
          item={editingItem}
          items={items}
          handleClose={() => setEditingItem(null)}
          handleDone={handleSaveInstruction}
          handleGrouping={handleGroupAlerts}
        />
      )}
    </Container>
  );
};

export default Main;
