import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  color: ${theme.colors.text};
  font-size: ${theme.dimensions.fontSizePageTitle};
  padding: 0px;
  margin: 0px;
  letter-spacing: ${px2rem(1)};
  text-transform: uppercase;
  line-height: ${px2rem(42)};
`;

const Title = buildStyledComponent(
  'MainLayout_Header_Title',
  styled.h1,
  styles
);

export default Title;
