import { useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useSwipeable } from 'react-swipeable';

import { Container, SimpleTable, LineChart, ScrollView } from 'common';
import { filterPeriodResponses } from 'utils/careplan';
import { displayTime, toLocalTime } from 'utils/time';
import { WELLNESS_ITEMS, DIET_ITEMS } from 'utils/constants';
import { getQuestionValueString, questionTypeValues } from 'utils/helper';
import SwitchSection from '../SwitchSection';
import Item from './Item';

const COLUMNS = [
  {
    key: 'day',
    label: 'Day',
  },
  { key: 'value', label: 'Value' },
  {
    key: 'time',
    label: 'Time',
  },
];

const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export default ({ type, itemType, finalResponse, responses }) => {
  const [selectedDate, updateSelectedDate] = useState(new Date());
  const [period, updatePeriod] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const graphKeys = useMemo(
    () => [WELLNESS_ITEMS[itemType]?.title || DIET_ITEMS[itemType]?.title],
    [itemType]
  );

  const periodData = useMemo(() => {
    return filterPeriodResponses(responses, period, selectedDate);
  }, [responses, period, selectedDate]);

  const tableData = useMemo(() => {
    const datas = [];
    periodData
      .sort((a, b) => {
        if (new Date(a.time) > new Date(b.time)) {
          return -1;
        }
        return 1;
      })
      .forEach((response) => {
        let valueString;
        if (
          response.response.value === undefined ||
          response.response.value === null
        ) {
          valueString = 'N/A';
        } else {
          valueString = getQuestionValueString(
            itemType,
            response.response.value
          );
        }
        datas.push({
          day: displayTime(
            response.addedTime
              ? response.addedTime
              : toLocalTime(response.time),
            'MMM D'
          ),
          timeObject: response.addedTime
            ? response.addedTime
            : toLocalTime(response.time),
          value:
            response.isPositive === true
              ? {
                  title: valueString,
                  modifiers: ['bold', 'success'],
                  value: response.response.value,
                }
              : response.isPositive === false
              ? {
                  title: valueString,
                  modifiers: ['bold', 'danger'],
                  value: response.response.value,
                }
              : {
                  title: valueString,
                  modifiers: ['bold', 'secondaryColor'],
                  value: response.response.value,
                },
          time: {
            title: displayTime(
              response.addedTime
                ? response.addedTime
                : toLocalTime(response.time),
              'hh:mm A'
            ),
            fullLabel: displayTime(
              response.addedTime
                ? response.addedTime
                : toLocalTime(response.time),
              'MM/DD/YY @hh:mm A'
            ),
            modifiers: ['secondaryColor'],
          },
        });
      });
    return datas;
  }, [periodData]);

  const graphData = useMemo(() => {
    const responseData = cloneDeep(
      tableData.filter((data) => data.value.title !== 'N/A')
    ).reverse();
    let datas = {};
    switch (period) {
      case 0:
        responseData.forEach((response) => {
          const record = {
            label: response.time.fullLabel,
            value: response.value.value,
          };
          datas[record.label] = {
            label: record.label,
            data: [record],
          };
        });
        break;
      case 1:
        if (responseData.length === 0) {
          break;
        }
        WEEKDAYS.forEach((day) => {
          datas[day] = {
            label: day,
            data: [],
          };
        });
        responseData.forEach((response) => {
          const key = displayTime(response.timeObject, 'ddd');
          datas[key].data.push({
            label: response.time.fullLabel,
            value: response.value.value,
          });
        });
        break;
      case 2:
        if (responseData.length === 0) {
          break;
        }
        const monthString = displayTime(selectedDate, 'MM');
        datas[0] = { label: `${monthString}/01`, data: [] };
        datas[1] = {
          label: `${monthString}/07`,
          data: [],
        };
        datas[2] = {
          label: `${monthString}/14`,
          data: [],
        };
        datas[3] = {
          label: `${monthString}/21`,
          data: [],
        };
        datas[4] = {
          label: `${monthString}/28`,
          data: [],
        };
        responseData.forEach((response) => {
          const date = moment(response.timeObject).date();
          const key = Math.floor(date / 7);
          const value =
            typeof response.value.value === 'string'
              ? response.value.value.split('/')
              : [response.value.value];
          const payload = {
            label: response.time.fullLabel,
            value: value[0],
          };
          if (graphKeys[1]) {
            payload.value2 = value[1];
          }
          datas[key].data.push(payload);
        });
        break;
      case 3:
        if (responseData.length === 0) {
          break;
        }
        MONTHS.forEach((month) => {
          datas[month] = {
            label: month[0],
            data: [],
          };
        });
        responseData.forEach((response) => {
          const key = displayTime(response.timeObject, 'MMM');
          datas[key].data.push({
            label: response.time.fullLabel,
            value: response.value.value,
          });
        });
        break;
    }
    return {
      labels: graphKeys,
      fixedValues: questionTypeValues(itemType),
      data: Object.values(datas),
    };
  }, [tableData, period]);

  const handleSwipe = (direction) => {
    const newDate = moment(selectedDate);
    const today = moment();
    switch (period) {
      case 0:
        newDate.add(direction, 'd');
        if (newDate.isAfter(today)) {
          return;
        }
        break;
      case 1:
        newDate.add(direction, 'w');
        if (newDate.isAfter(today) && newDate.weeks() > today.weeks()) {
          return;
        }
        break;
      case 2:
        newDate.add(direction, 'M');
        if (newDate.isAfter(today) && newDate.month() > today.month()) {
          return;
        }
        break;
      case 3:
        newDate.add(direction, 'y');
        if (newDate.isAfter(today) && newDate.year() > today.year()) {
          return;
        }
        break;
    }
    updateSelectedDate(newDate.toDate());
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      handleSwipe(1);
    },
    onSwipedRight: () => {
      handleSwipe(-1);
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
      <Container modifiers={['flexFixed']}>
        <Item
          handleSelectItem={() => {}}
          measurement={{
            time: !finalResponse
              ? null
              : finalResponse.addedTime
              ? finalResponse.addedTime
              : toLocalTime(finalResponse.time),
            value: finalResponse?.response.value,
            success: finalResponse?.isPositive === true,
            danger: finalResponse?.isPositive === false,
            muted:
              finalResponse?.isPositive === undefined ||
              finalResponse?.isPositive === null,
          }}
          type={type}
          itemType={itemType}
        />
      </Container>
      <Container
        modifiers={['fluid', 'flexBox', 'fullHeight', 'backgroundWhite']}
      >
        {showTable ? (
          <>
            <Container modifiers={['flexFixed', 'fluid']}>
              <SwitchSection
                date={selectedDate}
                updateDate={updateSelectedDate}
                period={period}
                updatePeriod={updatePeriod}
                showTable={showTable}
                setShowTable={setShowTable}
              />
            </Container>
            <Container modifiers={['flexOne', 'fullHeight', 'fluid']}>
              <Container modifiers={['padding_2', 'fullHeight']}>
                <SimpleTable columns={COLUMNS} data={tableData} />
              </Container>
            </Container>
          </>
        ) : (
          <ScrollView>
            <Container modifiers={['flexFixed', 'fluid']}>
              <SwitchSection
                date={selectedDate}
                updateDate={updateSelectedDate}
                period={period}
                updatePeriod={updatePeriod}
                showTable={showTable}
                setShowTable={setShowTable}
              />
            </Container>
            <Container modifiers={['flexOne', 'fluid']} {...swipeHandlers}>
              <LineChart data={graphData} />
            </Container>
          </ScrollView>
        )}
      </Container>
    </Container>
  );
};
