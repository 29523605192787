import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  Row,
  Column,
  Button,
  Text,
  ScrollView,
  Modal,
} from '@gaz/gaz-components.public';
import { cloneDeep } from 'lodash';

import { Input, Container, Switch, DatePicker } from 'common';
import { loadingVar } from 'graphql/cache';
import { SAVE_CAREPLAN_DRAFT, SIGN_CAREPLAN } from 'graphql/mutations';
import { FETCH_CAREPLAN } from 'graphql/queries';

import { PatientHomeContext } from '../../Context';
import CreateCareplanProvider, { CreateCareplanContext } from './Context';
import CareplanItem from './CareplanItem';
import MedicationIndex from './Medications';
import VitalIndex from './Vitals';
import ActivityIndex from './Activity';
import WellnessIndex from './Wellness';
import DietIndex from './Diet';
import CareTeamIndex from './CareTeam';
import ViewCareplan from 'pages/Careplans/CareplanDetail';
import History from './History';
import { displayTime, toGMT } from 'utils/time';
import assets from 'assets';

const CAREPLAN_ITEMS = {
  medication: 'Medication',
  vital: 'Vitals',
  activity: 'Activity',
  wellness: 'Wellness',
  diet: 'Diet',
  careTeam: 'Care Team',
  history: 'History / Draft',
};

const CAREPLAN_ICONS = {
  medication: assets.icons.icMedication,
  vital: assets.icons.icVital,
  activity: assets.icons.icActivity,
  wellness: assets.icons.icWellness,
  diet: assets.icons.icDiet,
  careTeam: assets.icons.icCareTeam,
  history: assets.icons.icHistory,
};

const CreateCareplanIndex = ({}) => {
  const { careplanId: draftCPId } = useParams();
  const [activeSection, setActiveSection] = useState(null);
  const { patient, goBack, fetchActiveCareplan } =
    useContext(PatientHomeContext);
  const {
    careplan,
    updateCareplan,
    setBackHandler,
    footerVisible,
    setFooterVisible,
    isEmptyCP,
    removeLocalDraft,
    fetchPastCPs,
    careplanHistory,
  } = useContext(CreateCareplanContext);
  const [showViewCP, setShowViewCP] = useState(false);
  const [patientConsent, setPatientConsent] = useState(false);
  const [duration, updateDuration] = useState(30);
  const [startDate, updateStartDate] = useState(new Date());
  const [endDate, updateEndDate] = useState(new Date());

  useEffect(() => {
    setBackHandler('');
  }, []);

  useEffect(() => {
    if (isNaN(parseInt(duration))) {
      return;
    }
    const date = new Date(startDate);
    date.setDate(date.getDate() + parseInt(duration));
    updateEndDate(date);
  }, [duration, startDate]);

  const cpObject = useRef();
  useEffect(() => {
    const content = cloneDeep(careplan.content);
    if (content.careTeam) {
      Object.values(content.careTeam).forEach((item) => {
        item.user = item.user._id;
      });
    }
    cpObject.current = {
      startDate: toGMT(startDate, true),
      duration: parseInt(duration, 10),
      content: content,
      user: careplan.user,
      _id: careplan._id,
    };
  }, [careplan]);

  const [fetchDraft] = useLazyQuery(FETCH_CAREPLAN, {
    onCompleted: ({ careplan }) => {
      loadingVar(false);
      if (!careplan || !careplan.isDraft) {
        goBack();
        return;
      }
      updateCareplan({ ...careplan });
    },
  });

  useEffect(() => {
    if (draftCPId) {
      loadingVar(true);
      fetchDraft({
        variables: {
          id: draftCPId,
        },
      });
    }
  }, [draftCPId]);

  const [saveDraft] = useMutation(SAVE_CAREPLAN_DRAFT, {
    variables: {
      careplan: cpObject.current,
    },
    onCompleted: (data) => {
      const savedCareplan = data.saveCareplanDraft;
      savedCareplan.user = patient._id;
      delete savedCareplan.signDate;
      updateCareplan(savedCareplan, false);
      removeLocalDraft();
      setShowViewCP(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const [signCP] = useMutation(SIGN_CAREPLAN, {
    variables: {
      careplan: cpObject.current,
    },
  });

  const showSection = (section) => {
    setActiveSection(section);
    setBackHandler(() => {
      setActiveSection('');
      setFooterVisible(true);
    });
    setFooterVisible(false);
  };

  const handleShowViewCP = () => {
    setBackHandler(() => {
      setShowViewCP(false);
    });
    setShowViewCP(true);
  };

  const handleDurationChange = (e) => {
    updateDuration(e.target.value);
  };

  const handleDateChange = (date) => {
    updateStartDate(date);
  };

  if (!patient) {
    return null;
  }

  const handleSaveDraft = async () => {
    loadingVar(true);
    await saveDraft();
    await fetchPastCPs();
    loadingVar(false);
    goBack();
  };

  const handleSign = async () => {
    if (!patientConsent) {
      Modal.info({
        text: 'Please check Verbal Consent in previous screen.',
      });
    } else {
      loadingVar(true);
      await signCP();
      loadingVar(false);
      removeLocalDraft();
      setBackHandler(null);
      goBack();
      fetchActiveCareplan();
    }
  };

  const handleCancel = () => {
    setBackHandler(null);
    goBack();
  };

  let contentComponent;

  switch (activeSection) {
    case CAREPLAN_ITEMS.medication:
      contentComponent = <MedicationIndex />;
      break;
    case CAREPLAN_ITEMS.vital:
      contentComponent = <VitalIndex />;
      break;
    case CAREPLAN_ITEMS.activity:
      contentComponent = <ActivityIndex />;
      break;
    case CAREPLAN_ITEMS.wellness:
      contentComponent = <WellnessIndex />;
      break;
    case CAREPLAN_ITEMS.diet:
      contentComponent = <DietIndex />;
      break;
    case CAREPLAN_ITEMS.careTeam:
      contentComponent = <CareTeamIndex />;
      break;
    case CAREPLAN_ITEMS.history:
      contentComponent = <History />;
      break;
  }

  const handleFocusInput = () => {
    updateDuration('');
  };

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      {showViewCP && (
        <ViewCareplan careplan={careplan} noFooter noPatientInfo />
      )}
      <Container
        modifiers={[
          'fluid',
          'flexBox',
          'fullHeight',
          showViewCP && 'noDisplay',
        ]}
      >
        <Container
          modifiers={['flexBox', 'fullHeight', contentComponent && 'noDisplay']}
        >
          <Container modifiers={['flexFixed']}>
            <Text modifiers={['block', 'bold']}>Interactive Care Plan</Text>
            <Container modifiers="section">
              <Row modifiers={['spaceBetween', 'middle']}>
                <Text>Verbal Consent</Text>
                <Switch active={patientConsent} onChange={setPatientConsent} />
              </Row>
              <Row modifiers={['withGutters']}>
                <Column modifiers={['col_4', 'noPadding']}>
                  <Text>Duration</Text>
                </Column>
                <Column modifiers={['col_8', 'noPadding']}>
                  <Row modifiers={['spaceBetween']}>
                    <Column modifiers={['col_5', 'noPadding']}>
                      <Text>Start</Text>
                    </Column>
                    <Column modifiers={['col_5', 'noPadding']}>
                      <Text>End</Text>
                    </Column>
                  </Row>
                </Column>
              </Row>
              <Row>
                <Column modifiers={['col_4', 'noPadding']}>
                  <Row modifiers={['middle']}>
                    <Input
                      modifiers={[
                        'asInfo',
                        'borderBottomOnly',
                        'textCenter',
                        'widthSmall',
                        'noPadding',
                      ]}
                      onChange={handleDurationChange}
                      value={duration}
                      pattern="[0-9]*"
                      type="number"
                      inputmode="decimal"
                      onFocus={handleFocusInput}
                    />
                    <Text modifiers={['inline', 'small', 'center']}>Days</Text>
                  </Row>
                </Column>
                <Column modifiers={['col_8', 'noPadding']}>
                  <Row modifiers={['spaceBetween', 'middle']}>
                    <Column modifiers={['col_5', 'noPadding']}>
                      <DatePicker
                        minDate={new Date()}
                        value={startDate}
                        handleChange={handleDateChange}
                        className="create-careplan-date-picker"
                      >
                        <Text modifiers={['medium', 'primary']}>
                          {displayTime(startDate, 'MM/DD/YY')}
                        </Text>
                      </DatePicker>
                    </Column>
                    <Column modifiers={['col_2', 'noPadding']}>
                      <Text modifiers={['small']}>To</Text>
                    </Column>
                    <Column modifiers={['col_5', 'noPadding']}>
                      <Text modifiers={['medium']}>
                        {displayTime(endDate, 'MM/DD/YY')}
                      </Text>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Container>
          </Container>
          <ScrollView>
            <Row modifiers={['spaceBetween']}>
              {Object.keys(CAREPLAN_ITEMS).map((key) => (
                <CareplanItem
                  key={key}
                  icon={CAREPLAN_ICONS[key]}
                  name={CAREPLAN_ITEMS[key]}
                  badge={
                    key === 'history'
                      ? careplanHistory?.length || 0
                      : careplan.content[key]
                      ? key === 'activity'
                        ? 1
                        : Object.keys(careplan.content[key]).length
                      : 0
                  }
                  onClick={() => {
                    showSection(CAREPLAN_ITEMS[key]);
                  }}
                />
              ))}
            </Row>
          </ScrollView>
        </Container>
        {contentComponent}
      </Container>
      {footerVisible && (
        <Container modifiers={['flexFixed', 'footer', 'backgroundWhite']}>
          {!showViewCP ? (
            <Row modifiers={['spaceBetween']}>
              <Button
                image={assets.icons.icCloseGray}
                modifiers={['icon', 'transparent']}
                onClick={handleCancel}
              />
              <Button modifiers={['roundCorner']} onClick={handleShowViewCP}>
                View Careplan
              </Button>
            </Row>
          ) : (
            <Row modifiers={['spaceBetween']}>
              <Button
                image={assets.icons.icCloseGray}
                modifiers={['icon', 'transparent']}
                onClick={() => setShowViewCP(false)}
              />
              <Button
                modifiers={[
                  'widthXSmall',
                  'roundCorner',
                  'small',
                  isEmptyCP && 'disabled',
                ]}
                onClick={handleSaveDraft}
                disabled={isEmptyCP}
              >
                Save Draft
              </Button>
              <Button
                modifiers={[
                  'widthXSmall',
                  'small',
                  'roundCorner',
                  isEmptyCP && 'disabled',
                ]}
                onClick={handleSign}
                disabled={isEmptyCP}
              >
                Sign
              </Button>
            </Row>
          )}
        </Container>
      )}
    </Container>
  );
};

const CreateCareplanWrapper = ({}) => {
  return (
    <CreateCareplanProvider>
      <CreateCareplanIndex />
    </CreateCareplanProvider>
  );
};

export default CreateCareplanWrapper;
