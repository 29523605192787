import React, { useState, useCallback, useEffect } from 'react';
import { debounce, startCase } from 'lodash';
import { useHistory } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Text,
  Container,
  Row,
  Button,
  Column,
  BackButton,
} from '@gaz/gaz-components.public';
import Invite from 'common/SelectContact/Invite';
import { Modal, SearchBar } from 'common';

import { FETCH_LEADS } from 'graphql/queries';
import { loadingVar } from 'graphql/cache';
import {
  INVITE_EXISTING_USER,
  INVITE_NEW_USER,
  REQUEST_TO_JOIN_GROUP,
} from 'graphql/mutations';
import { INVITE_ROLE_TYPES, SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import assets from 'assets';
import urls from 'routes/urls';

import Contact from './Contact';

export default function AddContact({
  contactType = INVITE_ROLE_TYPES.PROVIDER,
  showManualAdd = true,
  handleClose = () => {},
  handleSuccess = () => {},
  isModalView = true,
  placeholder = '',
  isFullWidth = false,
}) {
  const history = useHistory();

  const callback = (successful) => {
    Modal.info({
      render: () => (
        <Modal.Body>
          <Row modifiers={['middle', 'center']}>
            <Column modifiers={['col_12']}>
              <Text modifiers={['secondaryColor', 'bold']}>
                {successful
                  ? `Your request was sent successfully. They will be added to your
                network once they accept your request.`
                  : `Something went wrong. Please try again!`}
              </Text>
            </Column>
            <Column modifiers={['col_12', 'flexBox', 'center']}>
              <Button
                image={assets.icons.icCloseGray}
                modifiers={['icon', 'transparent']}
              />
            </Column>
          </Row>
        </Modal.Body>
      ),
    });

    if (successful) {
      handleClose();
    }
  };
  const [fetchLeads, { data: { leads } = {} }] = useLazyQuery(FETCH_LEADS);
  const [inviteExistingUser] = useMutation(INVITE_EXISTING_USER, {
    onCompleted: (data) => {
      const successful = data.inviteExistingUser;
      if (successful) callback(successful);
    },
    onError: () => {},
  });
  const [inviteNewUser] = useMutation(INVITE_NEW_USER, {
    onCompleted: (data) => {
      const successful = data.inviteNewUser;

      if (successful) {
        callback(successful);
        handleSuccess();
      }
    },
    onError: () => {},
  });
  // const [inviteNewUserToGroup] = useMutation(INVITE_NEW_USER_TO_GROUP, {
  //   onCompleted: (data) => {
  //     const successful = data.inviteNewUserToGroup;
  //     callback(successful);
  //   },
  // });
  const [requestToJoinGroup] = useMutation(REQUEST_TO_JOIN_GROUP, {
    onCompleted: (data) => {
      const successful = data.requestToJoinGroup;
      callback(successful);
    },
  });
  const [selectedContact, setSelectedContact] = useState({});
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = useCallback(
    debounce((query) => {
      if (query?.length) setIsSearching(true);
      else setIsSearching(false);
      fetchLeads({
        variables: {
          query,
          category: contactType,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchLeads]
  );

  useEffect(() => {
    if (contactType === INVITE_ROLE_TYPES.PRACTICE) {
      setSelectedContact({});
    }
  }, [leads]);

  const handleAddExistingContact = () => {
    if (
      contactType === INVITE_ROLE_TYPES.GROUPS ||
      contactType === INVITE_ROLE_TYPES.PRACTICE
    ) {
      requestToJoinGroup({
        variables: {
          groupId: selectedContact._id,
          isPractice: selectedContact.__typename === 'Practice',
        },
      });
    } else {
      inviteExistingUser({
        variables: {
          inviteeId: selectedContact._id,
        },
      });
    }
  };

  const handleAddNewContact = async (values) => {
    loadingVar(true);
    await inviteNewUser({
      variables: {
        ...values,
        role: contactType,
      },
    });
    loadingVar(false);
  };

  const handleSelect = (c) => {
    setSelectedContact(c);
  };

  const handleAddManually = () => {
    if (contactType === INVITE_ROLE_TYPES.GROUPS) {
      history.push(urls.CREATE_GROUP);
    } else if (contactType === INVITE_ROLE_TYPES.PRACTICE) {
      history.push(urls.REGISTER_PRACTICE);
    } else {
      setShowInviteForm(true);
    }
  };

  const renderBody = () => {
    return (
      <Container>
        <Row modifiers={['middle', 'center']}>
          <SearchBar
            onChange={handleSearch}
            placeholder={placeholder}
            isFullWidth={isFullWidth}
          />
        </Row>
        {isSearching &&
          leads?.map(({ contact }) => (
            <Contact
              key={contact._id}
              contact={contact}
              selected={selectedContact}
              handleSelect={handleSelect}
            />
          ))}
        {!isSearching && showManualAdd && (
          <Row modifiers={['withGutters', 'center', 'flexColumn']}>
            <Column modifiers={['col_12']}>
              <Text modifiers={['xSmall']}>
                Manually add {startCase(contactType)}
              </Text>
            </Column>
            <Column modifiers={['col_12']}>
              <Button
                onClick={handleAddManually}
                modifiers={[
                  'textIcon',
                  'transparent',
                  'roundCorner',
                  'fullWidth',
                ]}
              >
                <Row style={{ width: '100%' }}>
                  <Column
                    modifiers={[
                      'col_2',
                      'flexBox',
                      'verticalContentCenter',
                      'center',
                    ]}
                  >
                    <img
                      alt="add"
                      src={assets.icons.icPlus}
                      width="20"
                      height="20"
                    />
                  </Column>
                  <Column
                    modifiers={[
                      'col_6',
                      'col_offset_1',
                      'flexBox',
                      'verticalContentCenter',
                      'center',
                    ]}
                  >
                    <Text modifiers={['secondaryColor']}>
                      Add {startCase(contactType)} Manually
                    </Text>
                  </Column>
                </Row>
              </Button>
            </Column>
          </Row>
        )}
      </Container>
    );
  };

  const renderModalFooter = () => {
    return (
      <Row>
        <Column modifiers={['col_6']}>
          <Button
            image={assets.icons.icCloseGray}
            modifiers={['icon', 'transparent']}
            onClick={handleClose}
          />
        </Column>
        <Column modifiers={['col_6', 'flexBox', 'center']}>
          <Button
            modifiers={['primary', 'widthSmall']}
            onClick={handleAddExistingContact}
          >
            Add
          </Button>
        </Column>
      </Row>
    );
  };

  const renderFooter = () => {
    return (
      <Row>
        <Column modifiers={['flexBox', 'end', 'col']}>
          <Button
            modifiers={[
              'primary',
              'widthSmall',
              Object.keys(selectedContact).length === 0 && 'disabled',
            ]}
            onClick={handleAddExistingContact}
          >
            Add
          </Button>
        </Column>
      </Row>
    );
  };

  return (
    <>
      {isModalView ? (
        <Modal open>
          {showInviteForm && (
            <Invite
              handleAdd={handleAddNewContact}
              handleClose={() => setShowInviteForm(false)}
            />
          )}
          <Modal.Header>
            <Row>
              <Column modifiers={['col_2']}>
                <BackButton />
              </Column>
              <Column
                modifiers={[
                  'col_9',
                  'center',
                  'flexBox',
                  'verticalContentCenter',
                ]}
              >
                <Text modifiers={['secondaryColor', 'bold']}>
                  {`Add ${contactType}`}
                </Text>
              </Column>
            </Row>
          </Modal.Header>
          <Modal.Body>{renderBody()}</Modal.Body>
          <Modal.Footer>{renderModalFooter()}</Modal.Footer>
        </Modal>
      ) : (
        <>
          {renderBody()}
          {renderFooter()}
        </>
      )}
    </>
  );
}
