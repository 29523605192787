import { createRef, useMemo } from 'react';
import { Formik, Form } from 'formik';

import { Container, Row, Text } from 'common';
import { CustomRow, CustomField } from './Frequency/Items';

const TriggerTypes = ['Consecutive', 'Total'];

const Alerts = ({ alerts, getRef, type = 'vital' }) => {
  const initialValues = {
    triggerValue: alerts?.triggerValue || 5,
    triggerType: alerts?.triggerType || 'Consecutive',
  };
  const formRef = createRef();
  const typeText = useMemo(
    () =>
      type === 'vital' ? 'vitals' : type === 'careTeam' ? 'appointments' : type,
    [type]
  );

  getRef.current = () => {
    return formRef.current.values;
  };
  return (
    <Container modifiers={['padding_2', 'fullHeight', 'flexBox']}>
      <Formik initialValues={initialValues} innerRef={formRef}>
        <Form>
          <Row modifiers={['smallGutters']}>
            <Text modifiers={['block', 'medium', 'secondary']}>
              {type === 'wellness' || type === 'diet'
                ? `Allowable number of negative responses to ${typeText} questions.`
                : `Allowable missed ${typeText} before trigger alert is sent`}
            </Text>
          </Row>
          <CustomRow>
            <CustomField name="triggerValue" type="number" />
            <CustomField
              fieldType="picker"
              title="SELECT ORDER"
              options={TriggerTypes}
              name="triggerType"
            />
          </CustomRow>
          {type !== 'careTeam' && (
            <Row modifiers={['smallGutters']}>
              <Text modifiers={['block', 'orange', 'medium']}>
                All parties will be alerted and prompted to book an appointment
                if patient exceeds these limits.
              </Text>
            </Row>
          )}
        </Form>
      </Formik>
    </Container>
  );
};

export default Alerts;
