import React from 'react';
import styled from 'styled-components';
import { Ribbon, Badge } from 'common';

import { buildStyledComponent, px2rem } from 'style';

const Comp = (props) => {
  const {
    title,
    children,
    notificationCount,
    onClick,
    stepIndex,
    ...otherProps
  } = props;

  const handleClick = () => {
    onClick(stepIndex);
  };

  return (
    <div {...otherProps} onClick={handleClick}>
      {title ? <span style={{ position: 'relative' }}>{title}</span> : children}
      {notificationCount > 0 && (
        <Ribbon top="-15px" right="-15px">
          <Badge modifiers={['small']}>{notificationCount}</Badge>
        </Ribbon>
      )}
    </div>
  );
};

const modifierConfig = {
  active: ({ theme }) => `
    color: ${theme.colors.textPrimary};
    border-bottom: ${theme.dimensions.borderWidth_1} solid ${theme.colors.primary};
    font-weight: ${theme.fontWeights.semiBold};
  `,
  noTab: () => `
    border-right: none;
  `,
  noBorderBottom: () => `
    border-bottom: none !important;
  `,
  smallPadding: () => `
    padding: 10px;
  `,
  small: () => `
    padding: 10px;
    background-color: transparent;
  `,
  flatTab: ({ theme }) => `
    padding: ${px2rem(5)};
    background: ${theme.colors.transparent};
    white-space: nowrap;
  `,
  reverseFlatTab: ({ theme }) => `
    padding: ${px2rem(5)};
    background: ${theme.colors.transparent};
    white-space: nowrap;
    border-bottom: none;
    margin-right: ${px2rem(10)};
  `,
  reverseActive: ({ theme }) => `
    color: ${theme.colors.primary};
    border-bottom: ${theme.dimensions.borderWidth_1} solid ${theme.colors.primary};
    font-weight: ${theme.fontWeights.semiBold};
  `,
  plain: () => `
    border-bottom: none;
  `,
  h2: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeMedium};
    font-weight: ${theme.fontWeights.medium};
  `,
  xSmallPadding: () => `
    padding: ${px2rem(5)};
  `,
  multiLine: () => `
    white-space: unset !important;
  `,
  noDisplay: () => `
    display: none;
  `,
};

const styles = ({ theme }) => `
  color: ${theme.colors.text};
  font-size: ${theme.dimensions.fontSizeMedium};
  text-align: center;
  padding: 20px;
  border-bottom: ${theme.dimensions.borderWidth_1} solid ${theme.colors.grey};
  flex: 1;
  background-color: white;
  height: 100%;
  & > div {
    height: 100%;
    align-items: end;
  }
`;

export const Tab = buildStyledComponent('Tabs_Tab', styled(Comp), styles, {
  modifierConfig,
});

export default Tab;
