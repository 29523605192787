import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = () => `
  margin-top: ${px2rem(20)};
  padding: ${px2rem(10)};
  flex: 0 0;
`;

export default buildStyledComponent('Popover_Footer', styled.div, styles);
