import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { Container, Row, Text, Button, Column, HR, ScrollView } from 'common';

import { AuthContext } from 'contexts/auth';
import { ProviderContext } from 'contexts/provider';
import { client } from 'graphql/client';
import urls from 'routes/urls';
import assets from 'assets';

import { Overlay } from './Overlay';
import { Wrapper } from './Wrapper';
import MenuItem from './MenuItem';
import { OPTIONS } from './constants';

export const SideMenu = ({ isOpen, closeSideMenu }) => {
  const history = useHistory();
  const { me, updateAuth } = useContext(AuthContext);
  const { schedulerSet, callMasking } = useContext(ProviderContext);

  const logOut = async () => {
    await updateAuth();
    history.replace(urls.AUTH);
    client.resetStore();
  };

  return (
    <>
      <Overlay onClick={closeSideMenu} modifiers={[isOpen && 'shown']} />
      <Wrapper modifiers={[isOpen && 'shown']}>
        <Container modifiers={['fluid']}>
          <Row modifiers={['middle', 'spaceBetween']}>
            <Column>
              <Text modifiers={['subtitle', 'semiBold']}>Menu</Text>
            </Column>
            <Column>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={closeSideMenu}
              />
            </Column>
          </Row>
          <HR modifiers={['colored']} />
          <ScrollView modifiers={['noPadding']}>
            {OPTIONS.map(({ name, icon, route }, i) => (
              <Row
                key={`menu-key-${name}-${i}`}
                onClick={() => {
                  history.push(route);
                }}
              >
                <MenuItem
                  icon={icon}
                  label={typeof name === 'function' ? name(me) : name}
                  ribbon={
                    (name === 'Schedule' && !schedulerSet) ||
                    (name === 'Call Masking' && !callMasking)
                  }
                />
              </Row>
            ))}
          </ScrollView>
          <Row modifiers={['center', 'withGutters']}>
            <Button
              modifiers={[
                'primary',
                'roundCorner',
                'widthMedium',
                'normalFontSize',
                'noShadow',
              ]}
              onClick={logOut}
            >
              Sign Out
            </Button>
          </Row>
        </Container>
      </Wrapper>
    </>
  );
};

export default SideMenu;
