import styled from 'styled-components';
import { px2rem } from 'style';

import { buildStyledComponent } from 'style';

const Value = styled.div``;
const Label = styled.div``;

const Segment = ({ label, value, active, ...inputProps }) => {
  return (
    <div {...inputProps}>
      <Value className={active ? 'active' : ''}>{value}</Value>
      <Label>{label}</Label>
    </div>
  );
};

const styles = ({ theme }) => `
  margin: 0 ${px2rem(10)};
  flex-basis: 0;
  flex-grow: 1;

  ${Value} {
    font-size: ${theme.dimensions.fontSize};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.text};
    min-height: ${px2rem(28)};
    width: 100%;
    text-align: center;
    padding: ${px2rem(4)} 0;
    border-bottom: ${px2rem(2)} solid ${theme.colors.grey};
    
    &.active {
      border-bottom: ${px2rem(2)} solid ${theme.colors.primary};
    }
  }

  ${Label} {
    font-size: ${theme.dimensions.fontSizeXSmall};
    color: ${theme.colors.text};
    text-transform: uppercase;
    text-align: center;
    padding: ${px2rem(4)} 0;
  }
`;

const StyledSegment = buildStyledComponent(
  'BirthdayPicker_Segment',
  styled(Segment),
  styles
);

export default StyledSegment;
