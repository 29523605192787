import React from 'react';
import styled from 'styled-components';
import { buildStyledComponent } from 'style';
import { noop } from 'lodash';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  Row,
  Column,
  Button,
  Avatar,
  FormInput,
  FormPhoneInput,
} from '@gaz/gaz-components.public';
import { FormFileInput } from 'common';

import assets from 'assets';
import schema from 'utils/schema';

const userSchema = Yup.object().shape({
  firstName: schema.firstName,
  middleName: schema.middleName,
  lastName: schema.lastName,
  email: schema.email,
  phone: schema.phone,
  photo: schema.file,
});

const formStyles = () => `
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledForm = buildStyledComponent(
  'Forms_Profile',
  styled(Form),
  formStyles
);

export default function Profile({
  initialValues,
  updateFormData,
  goNext = noop,
}) {
  const handleSubmit = (values) => {
    updateFormData(values);
    goNext();
  };

  return (
    <Container modifiers={['fluid']}>
      <Formik
        initialValues={initialValues}
        validationSchema={userSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <StyledForm>
            <Row modifiers="middle">
              <Column modifiers="col">
                <Field
                  required
                  name="photo"
                  width={90}
                  height={90}
                  accept="image/*"
                  modifiers={['round', 'flexColumn']}
                  addText="Add Photo"
                  editText="Edit"
                  component={FormFileInput}
                  s3={{ folder: 'providers' }}
                >
                  <Avatar
                    modifiers={['round']}
                    width={90}
                    height={90}
                    image={assets.images.emptyAvatar}
                  />
                </Field>
              </Column>
              <Column modifiers={['col_8']}>
                <Row>
                  <Column modifiers={['col_12', 'fluid']}>
                    <Field
                      name="firstName"
                      modifiers={['squared']}
                      hasLabel
                      label="First Name"
                      placeholder="Enter First Name"
                      required
                      largeLabel
                      component={FormInput}
                    />
                  </Column>
                  <Column modifiers={['col_12', 'fluid']}>
                    <Field
                      name="middleName"
                      modifiers={['squared']}
                      hasLabel
                      label="Middle Name"
                      placeholder="Enter Middle Name"
                      largeLabel
                      component={FormInput}
                    />
                  </Column>
                  <Column modifiers={['col_12', 'fluid']}>
                    <Field
                      name="lastName"
                      modifiers={['squared']}
                      hasLabel
                      label="Last Name"
                      placeholder="Enter Last Name"
                      required
                      largeLabel
                      component={FormInput}
                    />
                  </Column>
                </Row>
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  name="email"
                  hasLabel
                  label="Email"
                  placeholder="Enter email"
                  required
                  largeLabel
                  modifiers={['squared']}
                  component={FormInput}
                />
              </Column>
              <Column modifiers={['col_12']}>
                <Field
                  name="phone"
                  hasLabel
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  required
                  largeLabel
                  modifiers={['squared']}
                  component={FormPhoneInput}
                />
              </Column>
            </Row>
            <Row modifiers={['withGutters', 'center']}>
              <Button
                type="submit"
                modifiers={['primary', 'widthMedium', 'roundCorner']}
              >
                Continue
              </Button>
            </Row>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
}
