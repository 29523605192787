import React from 'react';
import styled from 'styled-components';
import { buildStyledComponent } from 'style';

const overlayStyles = () => `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 100vw;
  min-height: 100vh;
  background: rgba(59, 76, 106, 0.6);
  z-index: 999;
  backdrop-filter: blur(10px);
  margin-left: -15px;
`;

const Overlay = buildStyledComponent(
  'SelectContact_Overlay',
  styled.div,
  overlayStyles,
);

const styles = ({ theme }) => `
  width: 80%;
  height: 100%;
  z-index: 1000;
  background-color: ${theme.colors.white};
  border-radius: 12px;
  height: fit-content;
  width: fit-content;
  padding: 15px;

  display: flex;
  flex-direction: column;
  margin: auto;
`;

const Wrapper = buildStyledComponent('Modal_Wrapper', styled.div, styles);

export function ContainedModal({ open, modifiers, children }) {
  if (!open) {
    return null;
  }
  return (
    <div
      style={{
        display: 'flex',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 15,
        right: 15,
        backgroundColor: 'transparent',
      }}
    >
      <Overlay />
      <Wrapper modifiers={modifiers}>{children}</Wrapper>
    </div>
  );
}
