import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { startCase } from 'lodash';
import {
  Container,
  Row,
  Column,
  Text,
  Tabs,
  Switch,
} from '@gaz/gaz-components.public';

import { LineChart, SimpleTable } from 'common';
import { px2rem } from 'style';

import { CreateNoteContext } from '../Context';

const mockData = [
  {
    period: 'Mon',
    heartRate: 65,
  },
  {
    period: 'Wed',
    heartRate: 70,
  },
  {
    period: 'Fri',
    heartRate: 60,
  },
  {
    period: 'Sun',
    heartRate: 43,
  },
];

const mockTableData = [
  {
    day: 'bla',
    value: 'bla',
    time: 'bla',
  },
];

const columns = Object.keys(mockTableData[0]).map((key) => ({
  key,
  label: startCase(key),
}));

export default function VitalDetails({ vitalType, goBack }) {
  const [showTable, setShowtable] = useState(false);
  const [currentPeriod, setCurrentPeriod] = useState(0);

  const handleStepChange = (nextStep) => setCurrentPeriod(nextStep);

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <Container style={{ background: '#F3F3F4' }} modifiers={['flexFixed']}>
        <Row modifiers={['middle']}>
          <Column modifiers={['col_9']}>
            <Text modifiers={['bold']}>{startCase(vitalType)}</Text>
            <Text modifiers={['block', 'muted']}>Aggregated in range: 65%</Text>
          </Column>
        </Row>
      </Container>
      <Row>
        <Column modifiers={['col_12']}>
          <Tabs current={currentPeriod} onChange={handleStepChange}>
            <Tabs.Tab title="day" />
            <Tabs.Tab title="week" />
            <Tabs.Tab title="month" />
            <Tabs.Tab title="year" />
          </Tabs>
        </Column>
        <Column modifiers={['col_8']}>
          <Row>
            <Text modifiers={['inline', 'large', 'danger']}>49</Text>
            <Text
              style={{ display: 'inline-flex', alignItems: 'center' }}
              modifiers={['xSmall', 'danger', 'noPadding']}
            >
              bpm
            </Text>
          </Row>
          <Row style={{ marginTop: px2rem(2) }}>
            <Text modifiers={['xSmall', 'secondaryColor']}>
              {moment().format('MM/DD/YYYY, hh:mm a')}
            </Text>
          </Row>
        </Column>
        <Column modifiers={['flexBox', 'verticalContentCenter', 'col_4']}>
          <Switch
            active={showTable}
            onChange={setShowtable}
            leftText="Chart"
            rightText="Table"
          />
        </Column>
      </Row>
      <Row style={{ minHeight: px2rem(250), paddingTop: px2rem(25) }}>
        {showTable ? (
          <SimpleTable columns={columns} data={mockTableData} />
        ) : (
          <LineChart data={mockData} dataKeys={['heartRate']} />
        )}
      </Row>
    </Container>
  );
}
