import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  width: 100%;
  flex: 1;
  overflow-y: auto;
  text-align: center;
  margin-top: ${px2rem(15)};
  margin-bottom: ${px2rem(15)};
  font-size: ${theme.dimensions.fontSizeSmall};
`;

const modifierConfig = {
  noMargin: () => `
    margin-top: 0;
    margin-bottom: 0;
  `,
  textLeft: () => `
    text-align: left;
  `,
  centering: () => `
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  flex: () => `
    display: flex;
  `,
  inlineBlock: () => `
    display: inline-block;
  `,
};

export default buildStyledComponent('Modal_Body', styled.div, styles, {
  modifierConfig,
});
