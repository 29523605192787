import { useState } from 'react';

import Wrapper from './Wrapper';
import { Container, Row } from '../Grid';
import { Button } from '../Button';
import { Text } from '../Text';
import assets from 'assets';
import { formatPhoneNumber } from 'utils/string';

export const CallPad = ({ handleCall }) => {
  const [number, updateNumber] = useState('');

  const handleClickNumber = (n) => {
    if (number.length >= 10) {
      return;
    }
    updateNumber(number + n.toString());
  };
  const handleDeleteNumber = () => {
    updateNumber(number.slice(0, -1));
  };
  const handleCallNumber = () => {
    handleCall(number);
  };

  return (
    <Wrapper>
      <Text modifiers={['block']}>
        {number.length > 0 ? formatPhoneNumber(number) : '\u00a0'}
      </Text>
      <Container>
        <Row modifiers={['center', 'smallGutters']}>
          <Button onClick={() => handleClickNumber(1)}>1</Button>
          <Button onClick={() => handleClickNumber(2)}>2</Button>
          <Button onClick={() => handleClickNumber(3)}>3</Button>
        </Row>
        <Row modifiers={['center', 'smallGutters']}>
          <Button onClick={() => handleClickNumber(4)}>4</Button>
          <Button onClick={() => handleClickNumber(5)}>5</Button>
          <Button onClick={() => handleClickNumber(6)}>6</Button>
        </Row>
        <Row modifiers={['center', 'smallGutters']}>
          <Button onClick={() => handleClickNumber(7)}>7</Button>
          <Button onClick={() => handleClickNumber(8)}>8</Button>
          <Button onClick={() => handleClickNumber(9)}>9</Button>
        </Row>
        <Row modifiers={['center', 'smallGutters']}>
          <Button>*</Button>
          <Button onClick={() => handleClickNumber(0)}>0</Button>
          <Button>#</Button>
        </Row>
        <Row modifiers={['center', 'smallGutters']}>
          <Text />
          <Button onClick={handleCallNumber} modifiers={['success']}>
            <img src={assets.icons.icPhoneWhite} />
          </Button>
          {number.length > 0 ? (
            <Button onClick={handleDeleteNumber}>
              <img src={assets.icons.icPadBackspace} />
            </Button>
          ) : (
            <Text />
          )}
        </Row>
      </Container>
    </Wrapper>
  );
};
