import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import { Button, Text } from '@gaz/gaz-components.public';
import assets from 'assets';
import { DESKTOP_VIEWPORT_WIDTH } from 'utils/constants';

const Mask = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  display: none;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.6);
`;

const Body = styled.div`
  @media only screen and (min-width: 769px) {
    width: ${DESKTOP_VIEWPORT_WIDTH};
    margin: 0 auto;
    background: white;
  }
`;

const Modal = styled.div(
  ({ theme, subModal }) => `
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  background-color: ${theme.colors.grey};
  transform: translate(0, 100%);
  backface-visibility: hidden;
  transition: transform 0.3s;
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;

  @media only screen and (min-width: 769px) {
    width: ${DESKTOP_VIEWPORT_WIDTH};
    margin: auto;
    left: ${subModal ? '0' : `calc((100vw - ${DESKTOP_VIEWPORT_WIDTH}) / 2)`};
  }

  &.picker-modal-toggle {
    transform: translate(0, 0);

    @media only screen and (min-width: 769px) {
      background: transparent;
    }
  }

  header {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: ${px2rem(45)};
    box-sizing: border-box;
    background: ${theme.colors.white};

    > * {
      flex: 1 1;
      color: ${theme.colors.text};
      font-weight: ${theme.fontWeights.bold};
      padding: ${px2rem(15)} ${px2rem(20)};
      border-bottom: solid ${px2rem(1)} ${theme.colors.gray6};
      width: 100%;
      height: 100%;
    }
  }

  footer {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${theme.dimensions.padding_1};
    background: ${theme.colors.white};
    box-shadow: ${theme.dimensions.footerBoxShadow};
  }

  .rmc-picker-item {
    color: ${theme.colors.text};
  }
  `
);

const Container = (props) => {
  const {
    title,
    show,
    onToggle,
    noMask,
    noSeparator,
    handleDone,
    children,
    subModal,
    ...otherProps
  } = props;

  const maskStyle = {
    display: show ? 'block' : 'none',
  };
  const modalClass = show ? 'picker-modal-toggle' : '';

  return (
    <div {...otherProps} className="picker-modal-container">
      {!noMask && <Mask onClick={onToggle} style={maskStyle}></Mask>}
      <Modal className={modalClass} subModal={subModal}>
        <Body>
          <header>
            {title && <Text modifiers={['medium']}>{title}</Text>}
          </header>
          <div>{children}</div>
          <footer>
            <Button
              modifiers={['transparent', 'icon']}
              image={assets.icons.icCloseGrey}
              imageWidth={26}
              imageHeight={26}
              onClick={onToggle}
            />
            <Button
              onClick={handleDone}
              modifiers={['primary', 'roundCorner', 'widthSmall']}
            >
              Done
            </Button>
          </footer>
        </Body>
      </Modal>
    </div>
  );
};

const modifierConfig = {
  v2: ({ theme }) => `
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background-color: ${theme.colors.white};
    transform: translate(0, 100%);
    backface-visibility: hidden;
    transition: transform 0.3s;

    @media only screen and (min-width: 769px) {
      width: ${DESKTOP_VIEWPORT_WIDTH};
      margin: auto;
      left: calc((100vw - ${DESKTOP_VIEWPORT_WIDTH}) / 2);
    }

    &.picker-modal-toggle {
      transform: translate(0, 0);
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    header {
      display: flex;
      align-items: center;
      width: 100%;
      height: ${px2rem(45)};
      padding: 0 ${theme.dimensions.padding_2};
      ${Button} {
        margin-left: auto;
        margin-right: 40px;
      }

      .title {
        color: ${theme.colors.darkGrey};
        font-weight: ${theme.fontWeights.bold};
        font-size: ${theme.dimensions.fontSizeNormal};
      }
    }

    .rmc-picker-indicator {
      border: none;
      &::after {
        content: '';
        width: 40px;
        height: 1px;
        position: absolute;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 0;
        background-color: ${theme.colors.primary};
      }
      &::before {
        content: '';
        width: 40px;
        height: 1px;
        position: absolute;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 1px;
        background-color: ${theme.colors.primary};
      }
    }

    .rmc-picker-item {
      color: ${theme.colors.text};
      background-color: ${theme.colors.white};
    }
    .rmc-picker-item.rmc-picker-item-selected {
      border: none;
    }
  `,
};

const styles = ({ theme }) => `
`;

export default buildStyledComponent(
  'FormPicker_Modal',
  styled(Container),
  styles,
  {
    modifierConfig,
  }
);
