import styled from 'styled-components';
import { Row } from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';

const styles = () => `
  background: #F2F7FF;
  border: 1px solid #D8D8F6;
  border-radius: ${px2rem(8)};
`;

export const LightBox = buildStyledComponent('LightBox', styled(Row), styles);

export default LightBox;
