import React from 'react';
import styled from 'styled-components';
import { Container, Row, Column } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

const Component = ({ children, ...props }) => (
  <Container {...props}>
    <Row>
      <Column modifiers={['col_12']}>{children}</Column>
    </Row>
  </Container>
);

const styles = ({ theme }) => `
  background-color: ${theme.colors.headerBackground};
`;

export default buildStyledComponent(
  'MainLayout_Header_Bottom',
  styled(Component),
  styles,
);
