import React, { useEffect, useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';

import assets from 'assets';
import { MainLayout } from 'layouts';
import {
  Container,
  Row,
  Column,
  BackButton,
  Tabs,
  SearchBar,
  Text,
  Button,
  HR,
  Modal,
} from '@gaz/gaz-components.public';
import { ContentCard } from 'common';
import AddContact from 'compositions/AddContact';
import { useFab } from 'contexts/fab';
import { useNavMenu } from 'contexts/navMenu';
import { FETCH_CONTACTS } from 'graphql/queries';
import { loadingVar } from 'graphql/cache';
import { INVITE_ROLE_TYPES, SEARCH_DEBOUNCE_TIME } from 'utils/constants';

import Contact from './Contact';

const Contacts = ({ history }) => {
  const [tab, setTab] = useState(0);
  const [query, setQuery] = useState('');
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [displaySelectContactModal, setDisplaySelectContactModal] =
    useState(false);
  const [contactType, setContactType] = useState();
  const [fetchContacts, { data: { contacts } = {}, loading }] =
    useLazyQuery(FETCH_CONTACTS);
  const navMenu = useNavMenu();
  const fab = useFab();

  loadingVar(loading);

  const getCategoryByTab = () => {
    switch (tab) {
      case 0:
        return 'providers';
      case 1:
        return 'patients';
      case 2:
        return 'groups';
      default:
        return '';
    }
  };

  const handleSearch = useCallback(
    debounce((query) => {
      fetchContacts({
        variables: {
          query,
          category: getCategoryByTab(tab),
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchContacts, tab]
  );

  useEffect(() => {
    handleSearch();
    navMenu.hideMenu();
    fab.setIcon(assets.icons.icAddUser);
    fab.setAction(() => setDisplayAddModal(true));
    return () => {
      fab.hideFab();
    };
  }, [handleSearch]);

  useEffect(() => {
    fab.showFab();
  }, [fab]);

  const handleTabChange = (newTab) => {
    setTab(newTab);
  };

  const handleQueryChange = (value) => {
    setQuery(value);
    handleSearch(value);
  };

  const handleSelectContactType = (contactType) => () => {
    setDisplayAddModal(false);
    setDisplaySelectContactModal(true);
    setContactType(contactType);
  };

  const handleAddContactClose = () => {
    setDisplaySelectContactModal(false);
    setContactType();
  };

  const handleReloadContacts = () => {
    if (tab === 1) {
      // patients
      fetchContacts({
        variables: {
          query,
          category: getCategoryByTab(tab),
        },
      });
    }
  };

  return (
    <MainLayout>
      <Modal
        onClose={() => setDisplayAddModal(false)}
        contained
        open={displayAddModal}
      >
        <Container>
          <Row modifiers={['middle']}>
            <Column modifiers={['col_10', 'col_offset_1']}>
              <Text modifiers={['center']}>
                Select the type of contact you would like to add
              </Text>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col_10', 'col_offset_1']}>
              <Button
                modifiers={['roundCorner', 'fullWidth', 'outlinePrimary']}
                onClick={handleSelectContactType(INVITE_ROLE_TYPES.PROVIDER)}
              >
                Provider
              </Button>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col_10', 'col_offset_1']}>
              <Button
                modifiers={['roundCorner', 'fullWidth', 'outlinePrimary']}
                onClick={handleSelectContactType(INVITE_ROLE_TYPES.PATIENT)}
              >
                Patient
              </Button>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col_10', 'col_offset_1']}>
              <Button
                modifiers={['roundCorner', 'fullWidth', 'outlinePrimary']}
                onClick={handleSelectContactType(INVITE_ROLE_TYPES.GROUPS)}
              >
                Group
              </Button>
            </Column>
          </Row>
        </Container>
      </Modal>
      <MainLayout.Header>
        <MainLayout.Header.Left>
          <BackButton />
        </MainLayout.Header.Left>
        <MainLayout.Header.Center>
          <Text>Contacts</Text>
        </MainLayout.Header.Center>
      </MainLayout.Header>
      <Tabs current={tab} onChange={handleTabChange}>
        <Tabs.Tab title="Providers" />
        <Tabs.Tab title="Patients" />
        <Tabs.Tab title="Groups" />
      </Tabs>
      <Container modifiers={['withGutters']}>
        <SearchBar onChange={handleQueryChange} placeholder="Search" />
      </Container>
      <MainLayout.Content>
        {displaySelectContactModal && (
          <AddContact
            handleClose={handleAddContactClose}
            handleSuccess={handleReloadContacts}
            contactType={contactType}
          />
        )}
        <Container>
          <ContentCard>
            {contacts?.map((contact, index) => (
              <Contact
                key={contact._id}
                contact={contact}
                isLast={index === contacts?.length - 1}
              />
            ))}
          </ContentCard>
        </Container>
      </MainLayout.Content>
    </MainLayout>
  );
};

export default Contacts;
