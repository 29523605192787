import styled, { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => css`
  display: none;

  & + label::before {
    background-color: ${theme.colors.white};
    border-radius: 50%;
    border: 1px solid ${theme.colors.primary};
    cursor: pointer;
    display: block;
    content: '';
    height: ${px2rem(25)};
    transition: all 200ms ease;
    width: ${px2rem(25)};
  }

  & + label {
    color: ${theme.colors.text};

    &:hover {
      color: ${theme.colors.text};
    }
  }

  &:checked + label::before {
    background-color: ${theme.colors.primary};
  }

  &:checked + label {
    color: ${theme.colors.text};
  }

  &:hover + label::before {
    border: 1px solid ${theme.colors.primary};
  }
`;

const modifierConfig = {
  small: ({ theme }) => `
    & + label::before {
      width: ${px2rem(16)};
      height: ${px2rem(16)};
    }
  `,
  margin_1: () => `
    & + label::before {
      margin-top: ${px2rem(12)};
    }
  `,
  squared: () => `
    & + label::before {
      border-radius: 6px;
    }
  `,
  widthSmall: () => `
    & + label::before {
      width: ${px2rem(24)};
      height: ${px2rem(24)};
    }
  `,
  radioStyle: ({ theme }) => `
    & + label::before {
      border: 1px solid #D2D2D2;
      border-radius: 50%;
      transition: all 200ms ease;
    }
    &:checked + label::before {
      background-color: white;
    }
    &:checked + label {
      position: relative;
    }
    & + label::after {

      transition: all 200ms ease;
    }
    &:checked + label::after {
      content: '';
      position: absolute;
      display: flex;
      margin: auto;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: ${theme.colors.primary};
      border-radius: 50%;
      width: ${px2rem(16)};
      height: ${px2rem(16)};
    }
  `,
};

const Input = buildStyledComponent(
  'Checkbox__Input',
  styled.input.attrs({ type: 'checkbox' }),
  styles,
  { modifierConfig },
);

export default Input;
