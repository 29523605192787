import { useState } from 'react';
import styled from 'styled-components';

import { Container, Row, Text } from 'common';
import { buildStyledComponent, px2rem } from 'style';
import assets from 'assets';

const Mods = {
  full: [
    '1',
    'Full Pill',
    assets.icons.icFullPillSelected,
    assets.icons.icFullPill,
  ],
  threeQuarter: [
    '3/4',
    'Three Quarter Pill',
    assets.icons.icThreeQuarterPillSelected,
    assets.icons.icThreeQuarterPill,
  ],
  half: [
    '1/2',
    'Half Pill',
    assets.icons.icHalfPillSelected,
    assets.icons.icHalfPill,
  ],
  oneQuarter: [
    '1/4',
    'One Quarter Pill',
    assets.icons.icOneQuarterPillSelected,
    assets.icons.icOneQuarterPill,
  ],
};

const ModItem = buildStyledComponent(
  'MedicationMod',
  styled(({ mod, selected, ...props }) => (
    <Container {...props}>
      <Row modifiers={['spaceBetween']}>
        <Text modifiers={['block', 'bold']}>{Mods[mod][0]}</Text>
        <img src={selected ? Mods[mod][2] : Mods[mod][3]} />
      </Row>
      <Text modifiers={['block']}>{Mods[mod][1]}</Text>
    </Container>
  )),
  ({ theme }) => `
    border: solid ${px2rem(1)} #D2D2D2;
    border-radius: ${px2rem(10)};
    width: calc(50% - ${theme.dimensions.padding_1});
    margin-bottom: ${theme.dimensions.padding_2};
    padding: ${theme.dimensions.padding_2};

    span {
      font-size: ${theme.dimensions.fontSizeMedium};
    }

    span:last-child {
      margin-top: ${px2rem(20)};
    }

    :nth-child(odd) {
      margin-right: ${theme.dimensions.padding_1};
    }
    :nth-child(even) {
      margin-left: ${theme.dimensions.padding_1}:
    }
  `,
  {
    modifierConfig: {
      selected: ({ theme }) => `
        background-color: ${theme.colors.primary};
        span {
          color: ${theme.colors.white};
        }
      `,
    },
  }
);

const MedicationMods = ({ mods, getRef }) => {
  const [selectedMode, setSelectedMode] = useState(mods?.mode);
  getRef.current = () => {
    return { mode: selectedMode };
  };

  return (
    <Container modifiers={['fullHeight', 'flexBox']}>
      <Row modifiers={['smallGutters']}>
        <Text modifiers={['block', 'secondary']}>Modifications</Text>
      </Row>
      <Row>
        {Object.keys(Mods).map((key) => (
          <ModItem
            modifiers={[key === selectedMode && 'selected']}
            selected={key === selectedMode}
            onClick={() => setSelectedMode(key)}
            key={key}
            mod={key}
          />
        ))}
      </Row>
      <Row modifiers={['withGutters']}>
        <Text modifiers={['block', 'secondary']}>
          Please select the medication modification.
        </Text>
      </Row>
    </Container>
  );
};

export default MedicationMods;
