import styled from 'styled-components';
import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
`;

export default buildStyledComponent(
  'Summary_Wrapper',
  styled(Container),
  styles,
);
