import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { MainLayout } from 'layouts';
import { Avatar, Container, Row, Text, SearchBar } from 'common';
import { Tabs, DatePicker } from 'common';
import {
  FETCH_PROVIDER_REPORT,
  FETCH_MONTHLY_BILLABLE_SUMMARY,
  FETCH_BILLABLE_READING_PATIENTS,
} from 'graphql/queries';
import { loadingVar, reportPeriodVar } from 'graphql/cache';
import { AuthContext } from 'contexts/auth';
import { displayTime } from 'utils/time';
import List from './itemList';
import ReportTab from './ReportTab';

const { Content } = MainLayout;

export default function Reports() {
  const { me } = useContext(AuthContext);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [selectedDate, updateSelectedDate] = useState(reportPeriodVar());
  const [tab, setTab] = useState(0);
  const [physiologics, setPhysiologics] = useState([]);
  const [therapeutics, setTherapeutics] = useState([]);

  const [fetchSummary, { data: { monthlyProviderBillableSummary } = {} }] =
    useLazyQuery(FETCH_MONTHLY_BILLABLE_SUMMARY, {
      variables: {
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1,
      },
      fetchPolicy: 'no-cache',
      initialFetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    });

  const [fetchProviderReport, { data: { providerReport } = {}, loading }] =
    useLazyQuery(FETCH_PROVIDER_REPORT, {
      variables: {
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1,
      },
      fetchPolicy: 'no-cache',
      initialFetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    });

  const [
    fetchBillableReadingPatients,
    { data: { billableReadingPatients } = {} },
  ] = useLazyQuery(FETCH_BILLABLE_READING_PATIENTS, {
    variables: {
      year: selectedDate.getFullYear(),
      month: selectedDate.getMonth() + 1,
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  loadingVar(loading);

  useEffect(() => {
    fetchProviderReport();
    fetchSummary();
    fetchBillableReadingPatients();
  }, [selectedDate]);

  useEffect(() => {
    const phys = [],
      thera = [];
    if (billableReadingPatients?.length > 0) {
      billableReadingPatients.forEach((patientReading) => {
        if (patientReading.physiologic > 0) {
          phys.push({
            reading: patientReading.physiologic,
            billable: patientReading.physBillable,
            patient: patientReading.patient,
          });
        }
        if (patientReading.therapeutic > 0) {
          thera.push({
            reading: patientReading.therapeutic,
            billable: patientReading.theraBillable,
            patient: patientReading.patient,
          });
        }
      });
    }
    setPhysiologics(phys);
    setTherapeutics(thera);
  }, [billableReadingPatients]);

  const handleChangeDate = useCallback((date) => {
    reportPeriodVar(date);
    updateSelectedDate(date);
  }, []);

  return (
    <MainLayout>
      <MainLayout.SideMenu
        closeSideMenu={() => setSideMenuOpen(false)}
        isOpen={sideMenuOpen}
      />
      <MainLayout.Header>
        <MainLayout.Header.Left>
          <MainLayout.Header.MenuButton
            openSideMenu={() => setSideMenuOpen(true)}
          />
        </MainLayout.Header.Left>
        <MainLayout.Header.Center>
          <Text modifiers={['subtitle', 'semiBold']}>Reports</Text>
        </MainLayout.Header.Center>
        <MainLayout.Header.Right>
          <Avatar width={36} height={36} modifiers="round" user={me} />
        </MainLayout.Header.Right>
      </MainLayout.Header>

      <Container modifiers={['backgroundWhite']}>
        <Row modifiers={['center', 'withSmallGutters']}>
          <DatePicker
            hideDates
            handleChange={handleChangeDate}
            value={selectedDate}
            maxDate={new Date()}
          >
            <Text modifiers={['primary']}>
              Period: {displayTime(selectedDate, 'MMM YYYY')}
            </Text>
          </DatePicker>
        </Row>
        <Tabs flatTabs multiLine current={tab} onChange={setTab}>
          <ReportTab
            title="Provider Time"
            subTitle={monthlyProviderBillableSummary?.time}
          />
          <ReportTab
            title="Therapeutic Readings"
            subTitle={monthlyProviderBillableSummary?.therapeutic || 0}
          />
          <ReportTab
            title="Physiologic Readings"
            subTitle={monthlyProviderBillableSummary?.physiologic || 0}
          />
        </Tabs>
      </Container>

      <Container>
        <SearchBar placeholder="Search" />
      </Container>

      <Content>
        <List hidden={tab !== 0} data={providerReport?.items} />
        <List hidden={tab !== 1} data={physiologics} />
        <List hidden={tab !== 2} data={therapeutics} />
      </Content>
    </MainLayout>
  );
}
