import { useState, useRef, useEffect } from 'react';
import { cloneDeep } from 'lodash';

import { Row, Text, Button, BackButton } from '@gaz/gaz-components.public';
import { Container, Modal, Tabs, Popover } from 'common';
import Alerts from './Alerts';
import Frequency from '../Frequency';
import GroupFrequencyModal from './GroupFrequencyModal';
import assets from 'assets';
import { VITALS } from 'utils/constants';

const Instruction = ({
  vital,
  vitals,
  handleDone,
  handleClose,
  handleGrouping,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [frequency, updateFrequency] = useState(
    cloneDeep(vital?.frequency || {})
  );
  const [alerts, updateAlerts] = useState(cloneDeep(vital?.alerts || {}));
  const [otherVitals, setOtherVitals] = useState([]);
  const [showGroupingModal, setShowGroupingModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const frequencyRef = useRef();
  const alertsRef = useRef();

  useEffect(() => {
    const others = {};
    Object.keys(vitals).forEach((item) => {
      if (item !== vital.id) {
        others[item] = { id: item };
      }
    });
    setOtherVitals(others);
  }, []);

  const handleDoneClick = () => {
    if (activeTab === 0 && Object.keys(alerts).length === 0) {
      setShowConfirmModal(true);
      return;
    }
    handleSave();
  };

  const handleSave = () => {
    let frequencyEdit, alertsEdit;
    if (activeTab === 0) {
      updateFrequency(frequencyRef.current());
      frequencyEdit = frequencyRef.current();
      alertsEdit = alerts;
    } else {
      updateAlerts(alertsRef.current());
      frequencyEdit = frequency;
      alertsEdit = alertsRef.current();
    }

    handleDone(vital, frequencyEdit, alertsEdit);
    handleClose();
  };

  const onConfirmContinue = () => {
    setShowConfirmModal(false);
    handleSave();
  };

  const handleGroupClick = () => {
    const newFrequency = frequencyRef.current();
    updateFrequency(newFrequency);
    setShowGroupingModal(true);
  };

  const handleFinishGrouping = (selectedVitals) => {
    handleGrouping(vital, selectedVitals, frequency);
  };

  const changeTab = (tab) => {
    setActiveTab(tab);
    if (tab === 1) {
      updateFrequency(frequencyRef.current());
    } else {
      updateAlerts(alertsRef.current());
    }
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Vital Instructions</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container
          modifiers={['flexBox', 'fluid', 'fullHeight', 'backgroundGray']}
        >
          <Container modifiers={['flexFixed', 'padding_2']}>
            <Text modifiers={['medium']}>{VITALS[vital.id]}</Text>
          </Container>
          <Container
            modifiers={['flexOne', 'fullHeight', 'flexBox', 'backgroundWhite']}
          >
            <Tabs current={activeTab} onChange={changeTab}>
              <Tabs.Tab
                title="Frequency"
                modifiers={['xSmallPadding', 'noTab']}
              />
              <Tabs.Tab title="Alerts" modifiers={['xSmallPadding']} />
            </Tabs>
            <Container modifiers={['fluid', 'fullHeight', 'withGutters']}>
              {activeTab === 0 ? (
                <Frequency
                  frequency={frequency}
                  getRef={frequencyRef}
                  icon={assets.icons.icVital}
                />
              ) : (
                <Alerts
                  vital={{ id: vital.id, alerts: alerts }}
                  getRef={alertsRef}
                />
              )}
            </Container>
          </Container>
          <Container modifiers={['flexFixed', 'footer', 'backgroundWhite']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleClose}
              />
              {activeTab === 0 && Object.keys(otherVitals).length > 0 && (
                <Button modifiers={['roundCorner']} onClick={handleGroupClick}>
                  Group Frequency
                </Button>
              )}
              <Button
                modifiers={['primary', 'widthSmall', 'roundCorner']}
                onClick={handleDoneClick}
              >
                Done
              </Button>
            </Row>
          </Container>
          <GroupFrequencyModal
            isVisible={showGroupingModal}
            frequency={frequency}
            vitals={otherVitals}
            onConfirm={handleFinishGrouping}
            onClose={() => setShowGroupingModal(false)}
          />
        </Container>
        <Popover
          open={showConfirmModal}
          onConfirm={onConfirmContinue}
          onClose={() => setShowConfirmModal(false)}
          footer
        >
          <Text modifiers={['bold', 'center']}>No alert added</Text>
          <Text modifiers={['medium', 'center']}>Do you wish to continue?</Text>
        </Popover>
      </Modal.Body>
    </Modal>
  );
};

export default Instruction;
