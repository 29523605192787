import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  position: fixed;
  top: ${px2rem(121)}; 
  left: 0;
  width: 100vw;
  color: ${theme.colors.text};
  border-top: ${px2rem(1)} solid ${theme.colors.grey};
  border-bottom: ${px2rem(1)} solid ${theme.colors.grey};
  background-color: ${theme.colors.white};
  z-index: 10;

  >.heading {
    font-size: ${theme.dimensions.fontSizeSmall};
    font-weight: ${theme.fontWeights.bold};
    border-bottom: ${px2rem(1)} solid ${theme.colors.grey};

    >div {
      padding: ${px2rem(15)} 0;
    }
  }

  >.Row .column {
    font-size: ${theme.dimensions.fontSize};
    max-height: calc(100vh - 295px); // todo: better idea?
    overflow-x: scroll;

    >div {
      padding: ${px2rem(5)} 0;
      box-sizing: border-box;
      border: ${px2rem(1)} solid ${theme.colors.transparent};

      &.active {
        border: ${px2rem(1)} solid ${theme.colors.primary};
        border-radius: ${theme.dimensions.borderRadius_1};
      }
    }

    &:not(:last-child) {
      border-right: ${px2rem(1)} solid ${theme.colors.grey};
    }
  }

  >.Row:not(:last-child) {
    border-bottom: ${px2rem(1)} solid ${theme.colors.grey};
  }
`;

const Dropdown = buildStyledComponent(
  'BirthdayPicker_Dropdown',
  styled.div,
  styles
);

export default Dropdown;
