import { useEffect, useCallback, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';

import {
  Avatar,
  Container,
  Row,
  Column,
  Button,
  SearchBar,
  ScrollView,
  Text,
} from '@gaz/gaz-components.public';
import { Popover } from 'common';
import { FETCH_CONTACTS } from 'graphql/queries';
import { formatTitleAndName } from 'utils/string';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import { AuthContext } from 'contexts/auth';

const ProviderSelect = ({ handleSelect, handleClose, showMe }) => {
  const [fetchContacts, { data: { contacts } = {} }] =
    useLazyQuery(FETCH_CONTACTS);
  const { me } = useContext(AuthContext);

  const handleSearch = useCallback(
    debounce((query) => {
      fetchContacts({
        variables: {
          query,
          category: 'providers',
          page: 0,
          pageSize: 100,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchContacts]
  );

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const handleQueryChange = (value) => {
    handleSearch(value);
  };

  const handleSelectProvider = useCallback((contact) => {
    handleSelect(contact);
    handleClose();
  }, []);

  return (
    <Popover open maxModal>
      <Container modifiers={['flexBox', 'fullHeight']}>
        <Text modifiers={['center', 'medium', 'bold']}>Select Provider</Text>
        {showMe && (
          <Row
            modifiers={['middle']}
            key={me._id}
            onClick={() => handleSelectProvider(me)}
          >
            <Column>
              <Avatar width={50} height={50} modifiers={['round']} user={me} />
            </Column>
            <Column modifiers="col">
              <Text modifiers={['block', 'start']}>
                {formatTitleAndName(me)} (me)
              </Text>
            </Column>
          </Row>
        )}
        <SearchBar
          onChange={handleQueryChange}
          autoFocus
          placeholder="Search providers"
        />
        <ScrollView>
          {contacts?.map((contact) => (
            <Row
              modifiers="middle"
              key={contact._id}
              style={{ borderBottom: '1px solid gray' }}
              onClick={() => handleSelectProvider(contact)}
            >
              <Column>
                <Avatar
                  width={50}
                  height={50}
                  modifiers={['round']}
                  user={contact}
                />
              </Column>
              <Column modifiers="col">
                <Text modifiers={['block', 'start']}>
                  {formatTitleAndName(contact)}
                </Text>
              </Column>
            </Row>
          ))}
          {(!contacts || contacts.length === 0) && (
            <Text modifiers={['medium', 'center']}>No result</Text>
          )}
        </ScrollView>
        <Container modifiers={['flexFixed']}>
          <Row modifiers={['center']}>
            <Button onClick={handleClose}>Close</Button>
          </Row>
        </Container>
      </Container>
    </Popover>
  );
};

export default ProviderSelect;
