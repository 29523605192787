import { createContext } from 'react';

import socket, { SOCKET_EVENTS } from 'socket';

export const SocketContext = createContext();

export const EVENTS = SOCKET_EVENTS;

export const SocketProvider = ({ children }) => {
  const closeSocket = () => {
    socket.disconnect();
  };

  const openSocket = (token) => {
    socket.connect(token);
  };

  const subscribe = (eventType, listener) => {
    return socket.subscribe(eventType, listener);
  };

  const sendData = (type, data) => {
    socket.send(type, data);
  };

  return (
    <SocketContext.Provider
      value={{
        closeSocket,
        openSocket,
        subscribe,
        sendData,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
