import { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import { Container } from '@gaz/gaz-components.public';
import { CreateCareplanContext } from '../Context';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import Main from './Main';
import PastItems from './Past';
import AddItems from './Add';
import { WELLNESS_ITEMS } from 'utils/constants';

const Index = ({}) => {
  const { setFooterVisible, careplan, updateCareplan } = useContext(
    CreateCareplanContext
  );
  const { goBack } = useContext(PatientHomeContext);
  const [items, updateItems] = useState(() => {
    const objects = {};
    Object.keys(WELLNESS_ITEMS).forEach((item) => {
      objects[item] = {
        id: item,
        active: false,
        frequency: null,
        alerts: null,
      };
    });
    return objects;
  });
  const [activeAction, updateActiveAction] = useState();

  useEffect(() => {
    setFooterVisible(false);
    return () => {
      setFooterVisible(true);
    };
  }, []);

  useEffect(() => {
    if (careplan.content.wellness) {
      Object.entries(careplan.content.wellness).forEach(([key, item]) => {
        items[key].active = true;
        items[key].frequency = cloneDeep(item.frequency);
        items[key].alerts = cloneDeep(item.alerts);
      });
      updateItems({ ...items });
    }
  }, [careplan]);

  const handleAddItems = (newItems) => {
    Object.entries(newItems).forEach(([key, item]) => {
      if (items[key].active && !item.frequency) {
        return;
      }
      items[key].active = true;
      items[key].frequency = item.frequency;
      items[key].alerts = item.alerts;
    });
    updateItems({ ...items });
  };

  const handleAdd = () => {
    updateActiveAction('add');
  };
  const handleShowPrevious = () => {
    updateActiveAction('previous');
  };

  const handleSave = () => {
    const activeItems = {};
    Object.keys(items).forEach((itemId) => {
      if (!items[itemId].active) {
        return;
      }
      activeItems[itemId] = {
        frequency: items[itemId].frequency,
        alerts: items[itemId].alerts,
      };
    });
    careplan.content.wellness = activeItems;
    updateCareplan({ ...careplan });
    goBack();
  };

  return (
    <Container modifiers={['fluid', 'fullHeight']}>
      <Main
        items={items}
        handleAdd={handleAdd}
        handleShowPrevious={handleShowPrevious}
        handleSave={handleSave}
        handleClose={() => goBack()}
        updateItems={updateItems}
      />
      {activeAction === 'add' && (
        <AddItems
          items={items}
          handleAdd={handleAddItems}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
      {activeAction === 'previous' && (
        <PastItems
          handleAdd={handleAddItems}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
    </Container>
  );
};

export default Index;
