import { Formik, Field, Form } from 'formik';

import { Container, Row, Text } from 'common';
import { CustomRow, CustomField } from '../../Frequency/Items';

const TriggerTypes = ['Consecutive', 'Total'];

const TemperatureAlerts = ({ initialValues, formRef }) => {
  const defaultValues = initialValues || {
    value: 99,
    triggerType: 'Consecutive',
    triggerValue: 5,
  };
  return (
    <Formik initialValues={defaultValues} innerRef={formRef}>
      <Form>
        <Container modifiers={['fluid']}>
          <CustomRow title="Not Greater Than" hasThreeComponents>
            <CustomField name="value" type="number" />
            <Text>{'\u2109'} (Degrees)</Text>
          </CustomRow>
          <Row modifiers={['smallGutters']}>
            <Text modifiers={['block', 'medium', 'secondary']}>
              Allowed out of range measurements before notifications are sent
            </Text>
          </Row>
          <CustomRow>
            <CustomField name="triggerValue" type="number" />
            <CustomField
              fieldType="picker"
              title="SELECT ORDER"
              options={TriggerTypes}
              name="triggerType"
            />
          </CustomRow>
          <Row modifiers={['smallGutters']}>
            <Text modifiers={['block', 'orange', 'medium']}>
              All parties will be alerted and prompted to book an appointment if
              patient exceeds these limits.
            </Text>
          </Row>
        </Container>
      </Form>
    </Formik>
  );
};

export default TemperatureAlerts;
