import { get } from 'lodash';
import React from 'react';

import FileInput from 'common/FileInput';

import Label from './FormLabel';
import Error from './FormError';

export const FormFileInput = (props) => {
  const {
    field: { name, onBlur, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    hasLabel,
    dark,
    placeholder,
    required,
    children,
    round,
    bordered,
    contained,
    s3,
    noMargin,
    isCard = false,
    ...inputProps
  } = props;

  const hasError = get(errors, name) && get(touched, name);
  const valid = !get(errors, name) && value;

  return (
    <>
      {hasLabel && (
        <Label modifiers={[dark && 'dark']}>
          {placeholder} {required && <span>*</span>}
        </Label>
      )}
      <FileInput
        modifiers={[
          bordered && 'bordered',
          round && 'round',
          dark && 'dark',
          hasError && 'error',
          valid && 'valid',
          noMargin && 'noMargin',
        ]}
        value={value}
        contained={contained}
        s3={s3}
        onChange={(newValue) => setFieldValue(name, newValue)}
        onBlur={() => {
          setFieldTouched(name);
          onBlur(name);
        }}
        isCard={isCard}
        {...inputProps}
      >
        {children}
      </FileInput>
      {hasError && <Error>{get(errors, name)}</Error>}
    </>
  );
};

export default FormFileInput;
