import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = () => `
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: right;
`;

const Right = buildStyledComponent(
  'MainLayout_Header_Right',
  styled.div,
  styles
);

export default Right;
