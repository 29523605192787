import React from 'react';

import { A, Button, Column, Container, Row, Text } from 'common';
import { AuthLayoutV2 } from 'layouts';

const { Title, Content } = AuthLayoutV2;

export const ErrorPage = () => {
  const goBack = () => {
    window.location.reload(false);
  };

  return (
    <AuthLayoutV2>
      <Title>
        <Column modifiers={['col_12']}>
          <A>
            <Text modifiers={['h2', 'white', 'start', 'block']}>
              Gazuntite Provider
            </Text>
          </A>
        </Column>
      </Title>
      <Content>
        <Container
          modifiers={['flexBox', 'fullHeight']}
          style={{ paddingTop: '40%' }}
        >
          <Row modifiers={['withGutters', 'center']}>
            <Text mo>Something went wrong. Please try again!</Text>
          </Row>
          <Row>
            <Column modifiers={['col_12', 'flexBox', 'center']}>
              <Button onClick={goBack}>Go Back</Button>
            </Column>
          </Row>
        </Container>
      </Content>
    </AuthLayoutV2>
  );
};

export default ErrorPage;
