import { useContext } from 'react';
import { useHistory } from 'react-router';

import { MainLayout } from 'layouts';
import { BackButton, Text, Row, Column } from '@gaz/gaz-components.public';
import { Container, Avatar } from 'common';
import List from './List';

import { AuthContext } from 'contexts/auth';
import { formatTitleAndName } from 'utils/string';

const { Header, Content } = MainLayout;

export default () => {
  const history = useHistory();
  const { me } = useContext(AuthContext);

  const goBack = () => {
    history.replace('/');
  };

  if (!me) {
    return null;
  }

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton onClick={goBack} />
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle']}>
            <Avatar user={me} width={40} height={40} modifiers={['squared']} />
            <Column modifiers={['col_8', 'flexBox']}>
              <Text modifiers={['semiBold', 'start']}>
                {formatTitleAndName(me)}
              </Text>
            </Column>
          </Row>
        </Header.Center>
      </Header>
      <Content>
        <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
          <List />
        </Container>
      </Content>
    </MainLayout>
  );
};
