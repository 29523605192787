import React, { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { useMutation, useLazyQuery } from '@apollo/client';
import assets from 'assets';
import {
  Container,
  Row,
  Button,
  Text,
  ScrollView,
} from '@gaz/gaz-components.public';
import { Modal } from 'common';
import { buildStyledComponent } from 'style';

import { loadingVar } from 'graphql/cache';
import { SAVE_NOTE_DRAFT, SIGN_NOTE } from 'graphql/mutations';
import { FETCH_NOTE } from 'graphql/queries';

import { PatientHomeContext } from '../../Context';
import CreateNoteProvider, { CreateNoteContext } from './Context';
import Diagnosis from './Diagnosis';
import Attachments from './Attachments';
import NoteItem, { NOTE_ITEMS } from './NoteItem';
import Footer from './Footer';
import ViewNote from 'pages/Notes/ViewNote';
import SimpleNote from './SimpleNote';
import Objective from './Objective';
import { AuthContext } from 'contexts/auth';

const PageWrapper = buildStyledComponent(
  'CreateNote_PageWrapper',
  styled(Container)
  // { paddingBottom: px2rem(40) },
);

const CreateNoteIndex = ({ location }) => {
  const { noteId: draftNoteId, fromNote: fromNoteId } = useParams();
  const history = useHistory();
  const [loading, updateLoading] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const { me } = useContext(AuthContext);
  const { patient, goBack } = useContext(PatientHomeContext);
  const {
    note,
    updateNote,
    setBackHandler,
    footerVisible,
    setFooterVisible,
    isEmptyNote,
    removeLocalDraft,
  } = useContext(CreateNoteContext);
  const [showViewNote, setShowViewNote] = useState(false);

  const [fetchNote] = useLazyQuery(FETCH_NOTE);

  const [saveDraft] = useMutation(SAVE_NOTE_DRAFT, {
    onCompleted: (data) => {
      loadingVar(false);
      const savedNote = data.saveNoteDraft;
      savedNote.user = patient._id;
      savedNote.content = note.content;
      updateNote(savedNote, false);
      removeLocalDraft();
      goBack();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const [signNote] = useMutation(SIGN_NOTE, {
    onCompleted: (data) => {
      loadingVar(false);
      removeLocalDraft();
      setBackHandler('', true);
      goBack();
    },
  });

  const showSection = (section) => {
    setActiveSection(section);
    if (section) {
      setBackHandler(() => {
        setActiveSection('');
        setFooterVisible(true);
      });
    }
    setFooterVisible(false);
  };

  const itemActiveStatus = (item) => {
    if (!note.content[item]) {
      return false;
    }
    if (typeof note.content[item] === 'string') {
      return note.content[item].trim().length > 0;
    }
    return Object.keys(note.content[item]).length > 0;
  };

  const handleViewNote = () => {
    setShowViewNote(true);
    setBackHandler(() => {
      setShowViewNote(false);
    });
  };

  useEffect(() => {
    const attachments = location.state?.attachments;
    updateNote({
      ...note,
      content: {
        ...note.content,
        attachments,
      },
    });
    if (attachments) {
      setBackHandler(() => history.goBack());
    }
  }, [location.state?.attachments]);

  useEffect(() => {
    setBackHandler('');
  }, []);

  useEffect(() => {
    if (draftNoteId) {
      loadingVar(true);
      updateLoading(true);
      fetchNote({
        variables: {
          id: draftNoteId,
        },
        onCompleted: ({ note }) => {
          if (!note || !note.isDraft) {
            loadingVar(false);
            updateLoading(false);
            goBack();
            return;
          }
          let attachedNoteId;
          if (note.content.attachments) {
            for (let attachment of note.content.attachments) {
              if (attachment.category === 'note') {
                attachedNoteId = attachment.note;
                break;
              }
            }
          }
          if (attachedNoteId) {
            fetchNote({
              variables: {
                id: attachedNoteId,
              },
              onCompleted: ({ note: noteAttachment }) => {
                loadingVar(false);
                updateLoading(false);
                const noteClone = cloneDeep(note);
                if (noteAttachment) {
                  for (let attachment of noteClone.content.attachments) {
                    if (attachment.category === 'note') {
                      attachment.noteContent = noteAttachment;
                      break;
                    }
                  }
                }
                updateNote({
                  ...noteClone,
                });
              },
            });
          } else {
            loadingVar(false);
            updateLoading(false);
            updateNote({ ...note });
          }
        },
      });
    }
  }, [draftNoteId]);

  useEffect(() => {
    if (fromNoteId) {
      loadingVar(true);
      fetchNote({
        variables: {
          id: fromNoteId,
        },
        onCompleted: ({ note: noteAttachment }) => {
          loadingVar(false);
          if (noteAttachment) {
            note.content.attachments = [
              {
                category: 'note',
                note: noteAttachment._id,
                noteContent: noteAttachment,
              },
            ];
            updateNote({
              ...note,
            });
          }
        },
      });
    }
  }, [fromNoteId]);

  if (!patient) {
    return null;
  }

  const handleSaveDraft = () => {
    loadingVar(true);
    const saveContent = cloneDeep(note.content);
    if (saveContent.attachments) {
      saveContent.attachments.forEach((attachment) => {
        if (attachment.category === 'note') {
          delete attachment.noteContent;
        }
      });
    }
    saveDraft({
      variables: {
        note: {
          _id: note._id,
          content: saveContent,
          user: note.user,
        },
      },
    });
  };

  const handleSignNote = () => {
    loadingVar(true);
    const saveContent = cloneDeep(note.content);
    if (saveContent.attachments) {
      saveContent.attachments.forEach((attachment) => {
        if (attachment.category === 'note') {
          delete attachment.noteContent;
        }
      });
    }
    signNote({
      variables: {
        note: {
          _id: note._id,
          content: saveContent,
          user: note.user,
        },
      },
    });
  };

  const handleClickSign = () => {
    Modal.confirm({
      text: 'Are you sure you want to sign the note?',
      title: 'Sign Note',
      onOk: () => {
        handleSignNote();
      },
    });
  };

  const handleClear = () => {
    updateNote(
      {
        ...note,
        content: {
          subjective: null,
          objective: null,
          plan: null,
          assessment: null,
          attachments: null,
          diagnosis: null,
        },
      },
      true
    );
  };

  let contentComponent;

  switch (activeSection) {
    case NOTE_ITEMS.subjective:
      contentComponent = <SimpleNote noteType="subjective" />;
      break;
    case NOTE_ITEMS.objective:
      contentComponent = <Objective />;
      break;
    case NOTE_ITEMS.assessment:
      contentComponent = <SimpleNote noteType="assessment" />;
      break;
    case NOTE_ITEMS.plan:
      contentComponent = <SimpleNote noteType="plan" />;
      break;
    case NOTE_ITEMS.diagnosis:
      contentComponent = <Diagnosis />;
      break;
    case NOTE_ITEMS.attachments:
      contentComponent = <Attachments />;
      break;
    default:
  }

  return (
    <PageWrapper modifiers={['fluid', 'flexBox', 'fullHeight']}>
      {showViewNote && (
        <ViewNote note={{ ...note, user: patient, creator: me }} />
      )}
      <Container
        modifiers={[
          'fluid',
          'flexBox',
          'flexOne',
          showViewNote && 'noDisplay',
          'fullHeight',
        ]}
        style={{ backgroundColor: 'white' }}
      >
        {contentComponent || (
          <>
            <Container
              style={{ backgroundColor: '#F3F3F4' }}
              modifiers={['padding_1', 'flexFixed']}
            >
              <Text modifiers={['block', 'start', 'bold']}>Create Note</Text>
              <Text modifiers={['medium', 'muted', 'block']}>
                Choose the type of note you want to add
              </Text>
            </Container>
            <ScrollView style={{ backgroundColor: 'white' }}>
              <Container>
                {Object.keys(NOTE_ITEMS).map((key) => (
                  <NoteItem
                    key={key}
                    itemKey={key}
                    note={note}
                    onClick={() => {
                      showSection(NOTE_ITEMS[key]);
                    }}
                    active={itemActiveStatus(key)}
                  />
                ))}
              </Container>
            </ScrollView>
          </>
        )}
      </Container>
      {footerVisible && (
        <Footer modifiers={['flexFixed', 'shadow']}>
          <Row modifiers={['spaceBetween']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            {showViewNote ? (
              <>
                <Button
                  modifiers={['widthXSmall', 'roundCorner', 'success']}
                  onClick={handleSaveDraft}
                >
                  <Text modifiers={['subtitle', 'semiBold', 'white']}>
                    Save as Draft
                  </Text>
                </Button>
                <Button
                  modifiers={[
                    'widthXSmall',
                    'roundCorner',
                    'primary',
                    'roundCorner',
                  ]}
                  onClick={handleClickSign}
                >
                  <Text modifiers={['subtitle', 'semiBold', 'white']}>
                    Sign
                  </Text>
                </Button>
              </>
            ) : (
              <>
                {/*<Button
                  modifiers={[
                    'widthSmall',
                    isEmptyNote ? 'disabled' : 'primary',
                    'roundCorner',
                    'widthSmall',
                  ]}
                  onClick={handleClear}
                  disabled={isEmptyNote}
                >
                  <Text modifiers={['subtitle', 'semiBold', 'white']}>
                    Clear
                  </Text>
                </Button>*/}
                <Button
                  modifiers={[
                    'widthSmall',
                    isEmptyNote ? 'disabled' : 'primary',
                    'roundCorner',
                    'widthSmall',
                  ]}
                  onClick={handleViewNote}
                  disabled={isEmptyNote}
                >
                  <Text modifiers={['subtitle', 'semiBold', 'white']}>
                    View Note
                  </Text>
                </Button>
              </>
            )}
          </Row>
        </Footer>
      )}
    </PageWrapper>
  );
};

const CreateNoteWrapper = ({ location }) => {
  return (
    <CreateNoteProvider>
      <CreateNoteIndex location={location} />
    </CreateNoteProvider>
  );
};

export default CreateNoteWrapper;
