import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import {
  Container,
  ScrollView,
  Row,
  Column,
  Button,
  Widget,
} from '@gaz/gaz-components.public';

import assets from 'assets';
import urls from 'routes/urls';

import { PatientHomeContext } from '../Context';
import ActiveCareplanSection from '../Careplans/Dashboard/ActiveCareplanSection';
import CareplanDetail from 'pages/Careplans/CareplanDetail';

import { Section } from './Section';
import ScheduleTile from './ScheduleTile';

const Dashboard = () => {
  const { patient, goPage, activeCareplan, setBackHandler } =
    useContext(PatientHomeContext);
  const [activeCPExpanded, setActiveCPExpanded] = useState(false);
  const [showCP, setShowCP] = useState(false);
  const history = useHistory();

  const handleViewPlan = () => {
    setShowCP(true);
    setBackHandler(() => {
      setShowCP(false);
      return false;
    });
  };

  const goCareplan = () => {
    goPage('careplans');
  };
  const goCreateNote = () => {
    goPage('notes/create');
  };
  const goCreateCP = () => {
    goPage('careplans/create');
  };
  const goRecords = () => {
    goPage('records');
  };
  const goReports = () => {
    history.push(`${urls.REPORTS}/${patient._id}`);
  };
  const goAlerts = () => {
    goPage('alerts');
  };
  const goChat = () => {
    history.push({
      pathname: urls.CHAT,
      search: qs.stringify({
        memberIds: [patient._id],
      }),
    });
  };
  const goCalls = () => {
    goPage('calls');
  };
  const goOrderMeds = () => {
    goPage('order-medications');
  };
  const goSchedule = () => {
    goPage('schedule');
  };

  const expandActiveCareplanSection = (expanded) => {
    setActiveCPExpanded(expanded);
  };

  return (
    <ScrollView modifiers={['noPadding']}>
      <Container
        modifiers={[
          'fluid',
          activeCPExpanded && 'fullHeight',
          showCP && 'noDisplay',
        ]}
      >
        <Section
          noTitle
          modifiers={['primary', activeCPExpanded && 'fullHeight']}
        >
          <ActiveCareplanSection
            expandSection={expandActiveCareplanSection}
            handleViewPlan={handleViewPlan}
          />
        </Section>
        {/* <Section
          title="Active Care Plan"
          whiteTitle
          largeTitle
          modifiers={['primary', activeCPExpanded && 'fullHeight']}
        >
          <ActiveCareplanSection
            expandSection={expandActiveCareplanSection}
            handleViewPlan={handleViewPlan}
          />
        </Section> */}
      </Container>
      {showCP ? (
        <Container modifiers={['fluid', 'fullHeight']}>
          <CareplanDetail careplan={activeCareplan} />
        </Container>
      ) : (
        !activeCPExpanded && (
          <>
            <Section title="Document">
              <Row modifiers={['spaceAround', 'withGutters']}>
                <Widget
                  icon={assets.icons.iconNote}
                  title="Create Note"
                  onClick={goCreateNote}
                />
                <Widget
                  icon={assets.icons.iconHeart}
                  onClick={goCreateCP}
                  title="Create CP"
                />
                <Widget
                  icon={assets.icons.iconMeds}
                  title="Order Meds"
                  onClick={goOrderMeds}
                />
              </Row>
            </Section>
            <Section title="Records">
              <Row modifiers={['spaceAround', 'withGutters']}>
                <Widget
                  icon={assets.icons.iconRecord}
                  title="Records"
                  onClick={goRecords}
                />
                <Widget
                  icon={assets.icons.iconReport}
                  title="Reports"
                  onClick={goReports}
                />
                <Widget
                  icon={assets.icons.iconAlert}
                  title="Alerts"
                  onClick={goAlerts}
                />
              </Row>
            </Section>
            <Section title="Contact" modifiers={['overflowShow']}>
              <Row modifiers={['spaceAround', 'withGutters', 'bottom']}>
                <Column modifiers={['col_4', 'noPadding']}>
                  <Widget
                    icon={assets.icons.iconCall}
                    title="Call"
                    onClick={goCalls}
                  />
                </Column>
                <Column modifiers={['col_4', 'noPadding']}>
                  <Widget
                    icon={assets.icons.iconMessage}
                    title="Message"
                    onClick={goChat}
                  />
                </Column>
                <Column modifiers={['col_4', 'noPadding']}>
                  <ScheduleTile handleClick={goSchedule} />
                </Column>
              </Row>
            </Section>
          </>
        )
      )}
    </ScrollView>
  );
};

export default Dashboard;
