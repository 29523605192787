import React, { useEffect } from 'react';
import moment from 'moment';
import { Row, Column, VR, Text } from '@gaz/gaz-components.public';
import { useLazyQuery } from '@apollo/client';

import { FETCH_MONTHLY_PATIENT_STATUS } from 'graphql/queries';

import Card from './Card';
import { secondsToDuration } from 'utils/time';

export default function Summary() {
  const now = moment.utc();
  const year = now.get('year');
  const month = now.get('month') + 1;
  const [fetchMonthlyPatientStatus, { data }] = useLazyQuery(
    FETCH_MONTHLY_PATIENT_STATUS
  );
  const status = data?.monthlyPatientStatus;

  useEffect(() => {
    fetchMonthlyPatientStatus({
      variables: { year, month },
    });
  }, [fetchMonthlyPatientStatus, year, month]);

  return (
    <>
      <Row modifiers={['spaceBetween']}>
        <Text modifiers={['h3', 'block']}>Monthly Status</Text>
        <Text modifiers={['body', 'block']}>{moment().format('MMM YYYY')}</Text>
      </Row>
      <Card>
        <Row modifiers={['spaceBetween']}>
          <Column modifiers={['noVerticalPadding', 'col_4']}>
            <Text modifiers={['small', 'body', 'white', 'block']}>
              Total Patients
            </Text>
            <Text modifiers={['h3', 'white']}>
              {status ? status.totalPatients : '-'}
            </Text>
            <VR modifiers={['height_95%']} />
          </Column>
          <Column modifiers={['noVerticalPadding', 'col_4']}>
            <Text modifiers={['small', 'body', 'white', 'block']}>
              Total Hours
            </Text>
            <Text modifiers={['h3', 'white']}>
              {status ? secondsToDuration(status.totalSeconds) : '-'}
            </Text>
          </Column>
          <Column modifiers={['noVerticalPadding', 'col_4', 'center']}>
            <VR modifiers={['height_95%', 'left']} />
            <Text modifiers={['small', 'body', 'white', 'block']}>Average</Text>
            <Text modifiers={['h3', 'white']}>
              {status ? secondsToDuration(status.averageSeconds) : '-'}
            </Text>
          </Column>
        </Row>
      </Card>
    </>
  );
}
