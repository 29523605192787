import { IconButton, Column } from 'common';
import NavData from './NavData';

const Home = ({ selected, handleClick }) => {
  const nav = NavData.home;
  const blueTextColor = '#039BE5';
  return (
    <Column>
      <IconButton
        title={nav.title}
        textColor={selected === nav.title && blueTextColor}
        icon={selected ? nav.selectedIcon : nav.icon}
        size={40}
        onClick={handleClick}
      />
    </Column>
  );
};

export default Home;
