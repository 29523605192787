import styled from 'styled-components';
import { buildStyledComponent } from 'style';
import { DESKTOP_VIEWPORT_WIDTH } from 'utils/constants';

const modifierConfig = {
  small: () => `
    max-width: 75%;
  `,
};

const styles = () => `
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 9999;

  @media only screen and (min-width: 769px) {
    max-width: ${DESKTOP_VIEWPORT_WIDTH};
    margin: 0 auto;
  }
`;

export default buildStyledComponent('Modal_Wrapper', styled.div, styles, {
  modifierConfig,
});
