import styled from 'styled-components';
import { Row, Column, Text } from '@gaz/gaz-components.public';

import { Container } from 'common';

import { buildStyledComponent, px2rem } from 'style';

export default buildStyledComponent(
  'CareplanItem',
  styled(({ icon, name, badge, ...props }) => (
    <Container modifiers={['section']} {...props}>
      <Row modifiers={['spaceBetween', 'middle']}>
        <img src={icon} />
        <Column modifiers={['col', 'noPadding']}>
          <Text modifiers={['bold', 'block', 'small']}>{name}</Text>
          <Text modifiers={['small']}>{badge}</Text>
        </Column>
      </Row>
    </Container>
  )),
  ({ theme }) => `
    width: 48%;
    margin: ${theme.dimensions.padding_1} 0;
    padding: ${theme.dimensions.padding_1};

    img {
      width: ${px2rem(40)};
      height: ${px2rem(40)};
    }
  `
);
