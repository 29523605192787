import { useMemo } from 'react';
import styled from 'styled-components';
import { Formik, Field, Form } from 'formik';

import {
  Container,
  Row,
  Button,
  Text,
  Switch,
  WeekdaysPicker,
  TimePicker,
  FormPicker,
  HR,
} from 'common';
import assets from 'assets';
import {
  buildStyledComponent,
  px2rem,
} from '@gaz/gaz-components.styled-builder';

const TimeRangePicker = buildStyledComponent(
  'TimeRangePicker',
  styled(({ from, to, handleUpdateTime, handleRemove, ...props }) => {
    const handleChangeFrom = (value) => {
      handleUpdateTime(value.toUpperCase(), to);
    };
    const handleChangeTo = (value) => {
      handleUpdateTime(from, value.toUpperCase());
    };
    return (
      <Row modifiers={['center']} {...props}>
        <Text>From:</Text>
        <TimePicker handleChange={handleChangeFrom} value={from}>
          <Text modifiers={['primary']}>{from}</Text>
        </TimePicker>
        <Text>To:</Text>
        <TimePicker handleChange={handleChangeTo} value={to}>
          <Text modifiers={['primary']}>{to}</Text>
        </TimePicker>
        {handleRemove && (
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={handleRemove}
            width={20}
            height={20}
          />
        )}
      </Row>
    );
  }),
  ({ theme }) => `
    margin: ${px2rem(10)} 0;
    span, input {
      font-size: ${theme.dimensions.fontSizeMedium} !important;
      vertical-align: text-top;
    }
    position: relative;

    >:first-child {
      width: 15%;
      text-align: right;
    }
    >:nth-child(even) {
      width: ${px2rem(80)};
    }
    >:nth-child(5) {
      position: absolute;
      right: 0;
    }
  `
);

const DURATION_MINS = ['5', '10', '15', '20', '25', '30'];

const Component = ({ schedule, handleUpdate, occupiedDays, ...props }) => {
  const initialValues = useMemo(() => {
    return {
      duration: schedule.duration?.toString() || '5',
    };
  }, [schedule]);
  const availableDays = useMemo(() => {
    const allDays = [...Array(7).keys()];
    return allDays.filter((day) => !occupiedDays.includes(day));
  }, [occupiedDays]);

  const handleToggleBreakOn = () => {
    schedule.breakOn = !schedule.breakOn;
    if (schedule.breakOn && schedule.breaks.length === 0) {
      schedule.breaks.push({
        from: '12:00 PM',
        to: '2:00 PM',
      });
    }
    handleUpdate(false);
  };

  const handleSelectDay = (day, selected) => {
    if (selected) {
      schedule.days.push(day);
      schedule.days.sort();
    } else {
      schedule.days = schedule.days.filter((elem) => elem !== day);
    }
    handleUpdate(true);
  };

  const handleUpdateOperationTime = (from, to) => {
    schedule.from = from;
    schedule.to = to;
    handleUpdate(false);
  };

  const handleUpdateBreakTime = (index) => (from, to) => {
    schedule.breaks[index].from = from;
    schedule.breaks[index].to = to;
    handleUpdate(false);
  };

  const handleAddBreak = () => {
    schedule.breaks.push({
      from: '12:00 PM',
      to: '2:00 PM',
    });
    handleUpdate(false);
  };

  const handleRemoveBreak = (index) => {
    schedule.breaks.splice(index, 1);
    if (schedule.breaks.length === 0) {
      schedule.breaks.push({
        from: '12:00 PM',
        to: '2:00 PM',
      });
    }
    handleUpdate(false);
  };

  const handleDurationChange = (value) => {
    schedule.duration = parseInt(value, 10);
    handleUpdate(false);
  };

  return (
    <Container modifiers={['fluid']} {...props}>
      <Container modifiers={['topGutters_2', 'fluid']}>
        <Text modifiers={['block', 'bold', 'medium']}>
          Select Days of Operation
        </Text>
        <Container modifiers={['withGutters']}>
          <WeekdaysPicker
            handleSelect={handleSelectDay}
            selectedDays={schedule.days}
            availableDays={availableDays}
          />
        </Container>
      </Container>
      <Container modifiers={['topGutters_2', 'fluid']}>
        <Text modifiers={['block', 'bold', 'medium']}>
          Select Hours of Operation
        </Text>
        <TimeRangePicker
          from={schedule.from}
          to={schedule.to}
          handleUpdateTime={handleUpdateOperationTime}
        />
      </Container>
      <Row modifiers={['withGutters', 'spaceBetween']}>
        <Text modifiers={['block', 'bold', 'medium']}>Select Break Period</Text>
        <Switch active={schedule.breakOn} onChange={handleToggleBreakOn} />
      </Row>
      {schedule.breakOn && (
        <>
          {schedule.breaks.map((breakTime, index) => (
            <TimeRangePicker
              key={index}
              from={breakTime.from}
              to={breakTime.to}
              handleUpdateTime={handleUpdateBreakTime(index)}
              handleRemove={() => handleRemoveBreak(index)}
            />
          ))}
          <Row modifiers={['smallGutters', 'end']}>
            <Button
              modifiers={['roundCorner', 'widthSmall']}
              onClick={handleAddBreak}
            >
              Add Break
            </Button>
          </Row>
        </>
      )}
      <Formik initialValues={initialValues}>
        <Form>
          <Row>
            <Text modifiers={['bold', 'medium']}>Appointment Duration </Text>
            <Field
              asInfo
              noPadding
              noEmptyOption
              noBorder
              fitContent
              options={DURATION_MINS}
              handleChange={handleDurationChange}
              component={FormPicker}
              name="duration"
            />
            <Text modifiers={['bold', 'medium']}>Minutes</Text>
          </Row>
        </Form>
      </Formik>
      <HR modifiers={['primary', 'height_3']} />
    </Container>
  );
};

export default buildStyledComponent(
  'ScheduleComponent',
  styled(Component),
  ({ theme }) => `
    > form > div {
      align-items: baseline;
      margin-top: ${theme.dimensions.padding_2};
      margin-bottom: ${theme.dimensions.padding_1};

      input {
        font-size: ${px2rem(20)};
        color: ${theme.colors.primary};
      }
    }
  `
);
