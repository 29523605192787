import styled from 'styled-components';

import { buildStyledComponent } from 'style';
import { DESKTOP_VIEWPORT_WIDTH } from 'utils/constants';

const modifierConfig = {
  shortLived: () => `
    z-index: 100;
  `,
};

const styles = ({ theme }) => `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 100vw;
  min-height: 100vh;
  background: rgba(59, 76, 106, 0.6);
  z-index: 89;
  backdrop-filter: blur(10px);

  @media only screen and (min-width: 769px) {
    max-width: ${DESKTOP_VIEWPORT_WIDTH};
    min-width: ${DESKTOP_VIEWPORT_WIDTH};
    margin: auto;
  }
`;

export default buildStyledComponent('Overlay', styled.div, styles, {
  modifierConfig,
});
