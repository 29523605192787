import styled from 'styled-components';
import { buildStyledComponent } from 'style';

const styles = () => `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px 5px 5px;
  background: white;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  margin-bottom: 5px;
`;

export default buildStyledComponent(
  'Participants_ReferredPatient',
  styled.div,
  styles
);
