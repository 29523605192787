import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  color: ${theme.colors.black};
  font-size: ${theme.dimensions.fontSizeMedium};
`;

export default buildStyledComponent('Dashboard_Card_Title', styled.div, styles);
