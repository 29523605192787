import styled from 'styled-components';
import { Row, Column, Checkbox, Button } from '@gaz/gaz-components.public';
import { Text } from 'common';
import { buildStyledComponent, px2rem } from 'style';
import { formatPhoneNumber } from 'utils/string';

const styles = ({ theme }) => `
  span {
    margin: 0;
  }
  > :last-child {
    width: ${px2rem(80)};
    text-align: right;

    button {
      margin-left: auto;
    }
  }
`;

const Component = ({
  type,
  number,
  name,
  selected,
  verified,
  practice,
  handleVerify,
  handleSelect,
  ...props
}) => {
  const onVerify = (event) => {
    event.stopPropagation();
    handleVerify();
  };
  return (
    <Row modifiers={['spaceBetween']} onClick={handleSelect} {...props}>
      <Checkbox
        dark={true}
        modifiers={['small', 'margin_1']}
        checked={selected}
        onChange={() => {}}
      />
      <Column modifiers={['col']}>
        <Row>
          <Text modifiers={['medium', 'block']}>
            Call from{' '}
            <Text modifiers={['bold']}>
              {type === 'work' ? practice : name}
            </Text>
          </Text>
        </Row>
        {type !== 'work' && (
          <Row>
            <Text modifiers={['small', 'block']}>
              (Personal {type === 'mobile' ? 'Cell' : 'Home'})
            </Text>
          </Row>
        )}
        <Row>
          <Text modifiers={['medium', 'block']}>
            {type === 'work' ? 'Practice' : type === 'mobile' ? 'Cell' : 'Home'}{' '}
            phone: <Text modifiers={['bold']}>{formatPhoneNumber(number)}</Text>
          </Text>
        </Row>
      </Column>
      <Column>
        {verified ? (
          <Text modifiers={['primary', 'medium']}>Verified</Text>
        ) : (
          <Button modifiers={['small']} onClick={onVerify}>
            Verify
          </Button>
        )}
      </Column>
    </Row>
  );
};

export default buildStyledComponent(
  'PhoneNumberItem_Wrapper',
  styled(Component),
  styles
);
