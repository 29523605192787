import React from 'react';
import ReactSelect from 'react-select';
import { px2rem } from 'style';
import { withTheme } from 'styled-components';

const customStyles = {
  control: (provided, { selectProps: { hasError, light, customTheme } }) => ({
    ...provided,
    borderColor: hasError
      ? customTheme.colors.danger
      : light
      ? '#F3F3F4'
      : '#F3F3F4',
  }),
  option: (provided, { selectProps: { light } }) => ({
    ...provided,
    background: light ? '#F3F3F4' : provided.background,
    borderRadius: light && px2rem(124),
  }),
  menu: (provided, { selectProps: { light } }) => ({
    ...provided,
    padding: light && 5,
  }),
};

export const Select = ({ hasError, light, theme, disabled, ...rest }) => {
  return (
    <ReactSelect
      hasError={hasError}
      light={light}
      customTheme={theme}
      isDisabled={disabled}
      styles={customStyles}
      {...rest}
    />
  );
};

export default withTheme(Select);
