import { createRef, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { Container } from '@gaz/gaz-components.public';

import { FormDatePicker } from '../Form/FormDatePicker';

export const DatePicker = ({
  handleChange,
  children,
  noFormWrap,
  name = 'date',
  value,
  format = 'MM/DD/YY',
  hideDates,
  subModal,
  minDate,
  maxDate,
}) => {
  const dateFieldRef = createRef();

  const handleChangeDate = (values) => {
    handleChange(values.date);
  };

  const handleComponentClick = useCallback(
    (event) => {
      if (dateFieldRef.current) {
        dateFieldRef.current.click();
      }
    },
    [dateFieldRef]
  );

  const fieldComponent = (
    <Field
      type="hidden"
      name={name}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat={format}
      valueAsObject
      submitOnClose
      hideDates={hideDates}
      title="Select date"
      component={FormDatePicker}
      innerRef={dateFieldRef}
      subModal={subModal}
    />
  );

  let wrapper;

  if (noFormWrap) {
    wrapper = fieldComponent;
  } else {
    wrapper = (
      <Formik initialValues={{ date: value }} onSubmit={handleChangeDate}>
        {() => <Form>{fieldComponent}</Form>}
      </Formik>
    );
  }

  return (
    <Container modifiers={['fluid']}>
      <Container modifiers={['fluid']} onClick={handleComponentClick}>
        {children}
      </Container>
      {wrapper}
    </Container>
  );
};
