import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'style';
import { Container } from 'common';

const Wrapper = buildStyledComponent(
  'CallssWrapper',
  styled(Container),
  ({ theme }) => `
    height: 100%;
    padding: 0;

    > :first-child {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding: ${theme.dimensions.padding_2} 5%;

      >* {
        margin-top: ${px2rem(25)};
      }

      >:nth-child(2) {
        >:last-child >:last-child >button >div{
          width: 100%;
        }
      }
    }
  `
);

export default Wrapper;
