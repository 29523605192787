import { Container } from '@gaz/gaz-components.public';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import { Modal } from 'common';

export const ProfileRow = buildStyledComponent(
  'BookingProfile',
  styled(Container),
  ({ theme }) => `
    border: solid ${px2rem(1)} ${theme.colors.white_1};
    padding: ${theme.dimensions.padding_1};
  `
);

const Wrapper = buildStyledComponent(
  'BookingWrapper',
  styled(Container),
  ({ theme }) => `
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0;

    >:first-child {
      box-shadow: 0 -2px 10px rgba(46, 41, 78, 0.08);
    }
  `
);

const WrapperModal = ({ children }) => (
  <Modal open>
    <Wrapper>{children}</Wrapper>
  </Modal>
);

export default WrapperModal;
