import React, { useState } from 'react';
import { noop, startCase } from 'lodash';

import {
  // Modal,
  // SearchBar,
  Container,
  Row,
  Column,
  Button,
  Ribbon,
  Avatar,
  Text,
  ListView,
} from '@gaz/gaz-components.public';
import { Modal, SearchBar } from 'common';

import { INVITE_ROLE_TYPES, MODAL_PRIORITIES } from 'utils/constants';
import assets from 'assets';
import { getFullName } from 'utils/string';

import Invite from './Invite';

export function SelectContact({
  open,
  showManualAdd,
  users,
  onSearch,
  handleClose,
  handleDone = noop,
  contactType = INVITE_ROLE_TYPES.PROVIDER,
  priority = MODAL_PRIORITIES.NORMAL,
  action = 'invite',
}) {
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [query, setQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [showInviteForm, setShowInviteForm] = useState(false);

  const handleSearch = (query) => {
    if (query?.length) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    onSearch(query);
    setQuery(query);
  };

  const handleClickItem = (record) => {
    if (selectedParticipants.find((selected) => selected._id === record._id)) {
      setSelectedParticipants((alreadySelected) => {
        const newState = alreadySelected.filter(
          (selected) => selected._id !== record._id
        );
        return newState;
      });
    } else {
      setSelectedParticipants((alreadySelected) => [
        ...alreadySelected,
        record,
      ]);
    }
  };

  const handleAdd = () => {
    handleClose();
    handleDone(selectedParticipants);
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <>
      <Modal
        open={open}
        modifiers={[priority === MODAL_PRIORITIES.HIGH && 'superPriority']}
      >
        {showInviteForm && (
          <Invite handleClose={() => setShowInviteForm(false)} />
        )}
        <Modal.Header>
          <Row>
            <Column modifiers={['col_2']}>
              {/* <BackButton onClick={handleClose} /> */}
            </Column>
            <Column
              modifiers={[
                'col_8',
                'flexBox',
                'verticalContentCenter',
                'center',
              ]}
            >
              <Text modifiers={['bold']}>
                {`${startCase(action)} ${startCase(contactType)}`}
              </Text>
            </Column>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Container modifiers={['fluid']}>
            <Row modifiers={['withGutters', 'center']}>
              <SearchBar value={query} onChange={handleSearch} />
            </Row>
            {(!isSearching || !users?.length) && showManualAdd && (
              <Row modifiers={['withGutters', 'center', 'flexColumn']}>
                <Column modifiers={['col_12']}>
                  <Text modifiers={['xSmall']}>
                    {action === 'invite'
                      ? 'Send invite to join Gazuntite'
                      : `Manually add ${startCase(contactType)}`}
                  </Text>
                </Column>
                <Column modifiers={['col_12']}>
                  <Button
                    onClick={() => setShowInviteForm(true)}
                    modifiers={[
                      'textIcon',
                      'transparent',
                      'roundCorner',
                      'fullWidth',
                    ]}
                  >
                    <Row style={{ width: '100%' }}>
                      <Column
                        modifiers={[
                          'col_2',
                          'flexBox',
                          'verticalContentCenter',
                          'center',
                        ]}
                      >
                        <img
                          alt="add"
                          src={assets.icons.icPlus}
                          width="20"
                          height="20"
                        />
                      </Column>
                      <Column
                        modifiers={[
                          'col_6',
                          'col_offset_1',
                          'flexBox',
                          'verticalContentCenter',
                          'center',
                        ]}
                      >
                        <Text modifiers={['secondaryColor']}>
                          {action === 'invite'
                            ? 'Invite'
                            : `Add ${startCase(contactType)} Manually`}
                        </Text>
                      </Column>
                    </Row>
                  </Button>
                </Column>
              </Row>
            )}

            <Row modifiers={['withGutters']}>
              {selectedParticipants.map((participant, i) => (
                <Column
                  key={`selected-participants-${i}`}
                  modifiers={['col_6', 'flexBox']}
                >
                  <Avatar
                    modifiers={['round']}
                    width={40}
                    height={40}
                    user={participant}
                  />
                  <Ribbon top={-15} right={-15}>
                    <Button
                      onClick={() => handleClickItem(participant)}
                      image={assets.icons.close}
                      modifiers={['icon', 'noPadding', 'transparent']}
                    />
                  </Ribbon>
                  <Text modifiers={['xxSmall', 'light', 'muted']}>
                    {getFullName(participant)}
                  </Text>
                </Column>
              ))}
            </Row>
            {isSearching && (
              <Row modifiers={['withGutters', 'center']}>
                <Column modifiers={['col_12']}>
                  <ListView
                    selectedItems={selectedParticipants}
                    records={users}
                    onClickItem={handleClickItem}
                  />
                </Column>
              </Row>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Column modifiers={['col_6']}>
              <Button
                image={assets.icons.icCloseGray}
                modifiers={['icon', 'transparent']}
                onClick={handleCancel}
              />
            </Column>
            <Column modifiers={['col_6', 'flexBox', 'center']}>
              <Button modifiers={['primary', 'widthSmall']} onClick={handleAdd}>
                Add
              </Button>
            </Column>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}
