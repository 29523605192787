import React, { useContext, useState } from 'react';
import moment from 'moment';

import {
  Avatar,
  Row,
  Column,
  Container,
  Text,
} from '@gaz/gaz-components.public';
import { AuthContext } from 'contexts/auth';
import { MainLayout } from 'layouts';

import Call from './Call';
import Messaging from './Messaging';
import Contacts from './Contacts';
import Appointments from './Appointments';
import Summary from './Summary';
import Alerts from './Alerts';
import CarePlan from './CarePlan';
import Coordinate from './Coordinate';
import Education from './Education';
import Note from './Notes';
import Prescription from './Prescription';
import PatientSearch from './PatientSearch';

const providerComponents = [
  <Note />,
  <Coordinate />,
  <Alerts />,
  <Education />,
  <Prescription />,
  <CarePlan />,
  <Call />,
  <Contacts />,
  <Messaging />,
  <Appointments />,
];

const pharmacistComponents = [
  <Note />,
  <Coordinate />,
  <Alerts />,
  <Education />,
  <Call />,
  <Contacts />,
  <Messaging />,
  <Appointments />,
];

const Dashboard = () => {
  const { me } = useContext(AuthContext);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const components =
    me.memberDesignation === 'pharmacist'
      ? pharmacistComponents
      : providerComponents;

  return (
    <MainLayout>
      <MainLayout.SideMenu
        closeSideMenu={() => setSideMenuOpen(false)}
        isOpen={sideMenuOpen}
      />
      <MainLayout.Header>
        <MainLayout.Header.Left>
          <MainLayout.Header.MenuButton
            openSideMenu={() => setSideMenuOpen(true)}
          />
        </MainLayout.Header.Left>
        <MainLayout.Header.Center>
          <Text modifiers={['subtitle', 'semiBold']}>
            {moment().format('dddd, MM/DD/YYYY')}
          </Text>
        </MainLayout.Header.Center>
        <MainLayout.Header.Right>
          <Avatar width={36} height={36} modifiers="round" user={me} />
        </MainLayout.Header.Right>
      </MainLayout.Header>
      <MainLayout.Content
        modifiers={['bgWhiteSmoke']}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Summary />
        <Container>
          <PatientSearch />
        </Container>
        <Container modifiers={['flexOne', 'flexBox', 'padding_2']}>
          {[0, 1, 2, 3, 4].map((row) => (
            <Row key={row} style={{ flex: 1 }}>
              <Column modifiers="col" style={{ padding: '0.4rem 0.5rem' }}>
                {components[row * 2]}
              </Column>
              <Column modifiers="col" style={{ padding: '0.4rem 0.5rem' }}>
                {components[row * 2 + 1]}
              </Column>
            </Row>
          ))}
        </Container>
      </MainLayout.Content>
    </MainLayout>
  );
};

export default Dashboard;
