import styled from 'styled-components';
import { buildStyledComponent } from '@gaz/gaz-components.styled-builder';

const modifierConfig = {
  left: () => `
    left: -5px;
  `,
  right: () => `
    right: -5px;
  `,
  bottom: () => `
    bottom: -5px
  `,
  top: () => `
    top: -5px;
  `,
  small: () => `
    min-width: 5px;
    min-height: 5px;
  `,
  medium: () => `
    min-width: 10px;
    min-height: 10px;
  `,
  large: () => `
    min-width: 20px;
    min-height: 20px;
  `,
};

const styles = ({ top, left, right, bottom }) => `
  width: fit-content;
  height: fit-content;
  position: absolute;
  background-color: transparent;
  top: ${top};
  left: ${left};
  right: ${right};
  bottom: ${bottom};
`;

export const Ribbon = buildStyledComponent('Ribbon', styled.div, styles, {
  modifierConfig,
});
