import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { Avatar, Column, Row, Text } from '@gaz/gaz-components.public';
import { CardButton, Badge } from 'common';
import { chatTitle, getFullName } from 'utils/string';
import { displayTime } from 'utils/time';
import { AuthContext } from 'contexts/auth';
import { getChatLocationDescriptor } from 'utils/chat';
import assets from 'assets';

export default function ChatHistoryItem({ message }) {
  const history = useHistory();
  const { me } = useContext(AuthContext);
  const { chat, text, updatedAt } = message;
  const otherMembers = chat.members.filter((m) => m._id !== me._id);
  const avatarProps = {};

  if (chat.members.length >= 5) {
    avatarProps.image = assets.icons.userGroup5;
  } else if (chat.members.length >= 3) {
    avatarProps.image = assets.icons.userGroup3;
  } else {
    avatarProps.user = otherMembers[0];
  }

  const handleClick = async () => {
    const descriptor = await getChatLocationDescriptor(message, me);
    history.push(descriptor);
  };

  return (
    <CardButton
      modifiers={['containedHeight', 'fullWidth']}
      style={{ textAlign: 'left' }}
      onClick={handleClick}
    >
      <Row modifiers="">
        <Column modifiers={['col_2', 'noPadding']}>
          <Avatar
            width={50}
            height={50}
            modifiers={['round']}
            {...avatarProps}
          />
        </Column>
        <Column modifiers={['col_4', 'noPadding']}>
          <Text modifiers={['block', 'medium']}>
            {chatTitle(chat.members, me)}
          </Text>
        </Column>
        <Column modifiers={['col_4', 'noPadding']}>
          {chat.referredPatient && (
            <div>
              <Text modifiers={['block', 'info', 'medium']}>Reference</Text>
              <Text modifiers={['block', 'small']}>
                {getFullName(chat.referredPatient)}
              </Text>
            </div>
          )}
        </Column>
        <Column modifiers={['col', 'noPadding']}>
          <Text modifiers={['block', 'small']}>
            {displayTime(updatedAt, 'MM/DD/YY h:mm A')}
          </Text>
        </Column>
      </Row>
      <Row modifiers="middle">
        <Column modifiers={['col_offset_2', 'col', 'noPadding']}>
          <Text modifiers={['block', 'dark']}>{text}</Text>
        </Column>
        <Column
          modifiers={[
            'col_2',
            'noPadding',
            'flexBox',
            'center',
            'verticalContentCenter',
          ]}
        >
          {chat.unreadCount > 0 && <Badge modifiers>{chat.unreadCount}</Badge>}
        </Column>
      </Row>
    </CardButton>
  );
}
