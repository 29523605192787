import styled from 'styled-components';

import { Row, Column, Button, Text } from '@gaz/gaz-components.public';
import { Formik, Form, Field } from 'formik';
import { buildStyledComponent, px2rem } from 'style';

import { FormInput } from 'common';

import assets from 'assets';

const InputRow = buildStyledComponent(
  'MedicationQuantityRow',
  styled(({ children, ...props }) => (
    <Row modifiers={['spaceBetween', 'withGutters', 'middle']} {...props}>
      {children}
    </Row>
  )),
  ({ theme }) => `
    background: ${theme.colors.white};
    border-radius: ${theme.dimensions.borderRadius_1};
    border: 1px solid #E7E7E9;
    overflow: hidden;

    div div > span {
      font-size: ${theme.dimensions.fontSize};
      color: ${theme.colors.primary};
      font-weight: ${theme.fontWeights.normal};
    }

    input {
      width: 100%;
      border: none;
      color: ${theme.colors.primary};
    }

    >* {
      padding: ${px2rem(10)} ${px2rem(8)} !important;
    }
    > :last-child {
      border-left: solid ${px2rem(1)} #E7E7E9;
      width: 80%;
      text-align: center;
    }
    >:first-child {
      width: 20%;
    }
  `
);

const Quantity = ({ medication, quantity, updateQuantity }) => {
  const initialValues = {
    quantity,
  };

  return (
    <Formik initialValues={initialValues}>
      <Form>
        <InputRow>
          <Field
            name="quantity"
            asInfo
            noPadding
            widthSmall
            hasBorderBottom
            onChange={(e) => {
              updateQuantity(e.target.value);
            }}
            value={quantity}
            component={FormInput}
          />
          <Column modifiers={['noPadding']}>
            <Text modifiers={['block', 'medium']}>
              Please enter quantity for this order.
            </Text>
            <Text modifiers={['block', 'primary']}>{medication.name}</Text>
          </Column>
        </InputRow>
      </Form>
    </Formik>
  );
};

export default Quantity;
