import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {};

const styles = ({ theme }) => `
  height: ${px2rem(20)};
  width: ${px2rem(20)};
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  line-height: ${px2rem(20)};
  text-align: center;
  white-space: nowrap;
  border-radius: 50%;
  box-shadow: 0 0 0 2px ${theme.colors.white_1};
  font-size: ${px2rem(12)};
  padding: 0 ${px2rem(5)};
  display: inline-block;
  position: absolute;
  right: ${px2rem(-8)};
  top: ${px2rem(-8)};
`;

export default buildStyledComponent('Badge', styled.span, styles, {
  modifierConfig,
});
