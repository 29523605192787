import { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';

import { loadingVar } from 'graphql/cache';
import ViewNote from 'pages/Notes/ViewNote';

import { MainLayout } from 'layouts';
import {
  BackButton,
  Avatar,
  Row,
  Column,
  Text,
} from '@gaz/gaz-components.public';
import { FETCH_NOTE } from 'graphql/queries';
import { READ_PATIENT_NOTE } from 'graphql/mutations';
import { AuthContext } from 'contexts/auth';
import { getFullName } from 'utils/string';
import urls from 'routes/urls';

const { Header, Content } = MainLayout;

export default function NoteDetails() {
  const { me } = useContext(AuthContext);
  const history = useHistory();
  const { id } = useParams();
  const [readNote] = useMutation(READ_PATIENT_NOTE, {
    variables: { note: id },
  });
  const { data: { note } = {}, loading } = useQuery(FETCH_NOTE, {
    variables: { id },
  });

  useEffect(() => {
    if (note && !note.isDraft && !note.isSeen) {
      readNote();
    }
  }, [note]);

  const goBack = () => history.replace(urls.NOTES);

  loadingVar(loading);

  if (loading || !me) {
    return null;
  }

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton onClick={goBack} />
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle']}>
            <Avatar user={me} width={40} height={40} modifiers={['squared']} />
            <Column modifiers={['col_8', 'flexBox']}>
              <Text modifiers={['semiBold', 'start']}>{getFullName(me)}</Text>
            </Column>
          </Row>
        </Header.Center>
      </Header>
      <Content>
        <ViewNote note={note} goBack={goBack} />
      </Content>
    </MainLayout>
  );
}
