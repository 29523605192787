import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  height: ${px2rem(30)};
  border-radius: ${px2rem(15)};
  display: inline-block;
  background-color: #F3F3F4;
  box-shadow: rgb(223 223 223) 0 0 0 0 inset;
  position: relative;
  overflow: hidden;
  width: 100%;

  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 ${px2rem(17)};
    font-size: ${theme.dimensions.fontSizeXSmall};
    
    color: #3B4C6A;

    >span:first-child {
      margin-right: ${px2rem(25)};
      z-index: 2;
      font-weight: bold;
      text-align: center;
    }
    >span:last-child {
      opacity: 60%;
      z-index: 2;
    }
  }

  >span {
    background-color: ${theme.colors.white};
    transition: all 200ms ease;
    width: 50%;
    height ${px2rem(26)};
    border-radius: 18px;
    box-shadow: 0px 2px 6px #3B4C6A29;
    display: block;
    position: absolute;
    left: ${px2rem(3)};
    top: ${px2rem(2)};
    z-index: 1;
  }
`;

const modifierConfig = {
  active: ({ theme }) => `
    >div {
      >span:first-child {
        opacity: 60%;
      }
      >span:last-child {
        opacity: 100%;
        font-weight: bold;
      }
    }
    >span {
      margin-left: calc(100% - 50% - 5px);
    }
  `,
};

const Wrapper = buildStyledComponent('Slider_Wrapper', styled.span, styles, {
  modifierConfig,
});

export default Wrapper;
