import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  display: flex;  
  align-items: center;
`;

const Wrapper = buildStyledComponent(
  'BirthdayPicker_Wrapper',
  styled.div,
  styles
);

export default Wrapper;
