import React from 'react';
import { noop } from 'lodash';

import Wrapper from './Wrapper';
import Overlay from './Overlay';
import Content from './Content';
import Header from './Header';

export const SlidingPane = ({
  isOpen,
  handleClose = noop,
  children,
  modifiers = [],
  ...props
}) => {
  return (
    <>
      <Overlay modifiers={[!isOpen && 'hidden']} onClick={handleClose} />
      <Wrapper modifiers={[isOpen && 'open', ...modifiers]}>{children}</Wrapper>
    </>
  );
};

SlidingPane.Content = Content;
SlidingPane.Header = Header;
