import { useEffect, useState } from 'react';
import { Row, Button, Text, ScrollView } from '@gaz/gaz-components.public';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Container, Modal } from 'common';
import { buildStyledComponent } from 'style';
import InfoSection from './InfoSection';
import Medication from './Medication';
import Vital from './Vital';
import Activity from './Activity';
import Question from './Question';
import CareTeam from './CareTeam';
import ProviderSelect from './ProviderSelect';
import { formatTitleAndName } from 'utils/string';
import { SHARE_CAREPLAN, READ_PATIENT_CAREPLAN } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import assets from 'assets';

const CareplanDetail = buildStyledComponent(
  'CareplanDetail',
  styled(({ careplan, goBack, noFooter, noPatientInfo, ...props }) => {
    const history = useHistory();
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [shareCareplan] = useMutation(SHARE_CAREPLAN);
    const [readCareplan] = useMutation(READ_PATIENT_CAREPLAN);
    const handleSelectProvider = async (provider) => {
      loadingVar(true);
      await shareCareplan({
        variables: {
          provider: provider._id,
          careplan: careplan._id,
        },
        onCompleted: () => {
          Modal.info({
            render: () => (
              <Text>
                Careplan shared with{' '}
                <Text modifiers={['primary']}>
                  {formatTitleAndName(provider)}
                </Text>
              </Text>
            ),
          });
        },
      });
      loadingVar(false);
    };

    const goEdit = () => {
      history.replace(
        `/patients/${careplan.user._id}/careplans/create/${careplan._id}`
      );
    };

    useEffect(() => {
      if (careplan?._id && !careplan.isDraft && !careplan.isSeen) {
        readCareplan({ variables: { careplan: careplan._id } });
      }
    }, [careplan]);

    return (
      <Container
        modifiers={['fluid', 'flexBox', 'fullHeight', 'backgroundWhite']}
        {...props}
      >
        {!noPatientInfo && (
          <Row>
            <InfoSection careplan={careplan} />
          </Row>
        )}
        <ScrollView>
          <Container>
            <Text modifiers={['block', 'medium', 'bold', 'center']}>
              Care Plan
            </Text>
            {/* <CareplanSection type="Appointments" detail={{}} /> */}
            {careplan.content.medication && (
              <Medication medications={careplan.content.medication} />
            )}
            {careplan.content.vital && (
              <Vital vitals={careplan.content.vital} />
            )}
            {careplan.content.activity && (
              <Activity activity={careplan.content.activity} />
            )}
            {careplan.content.wellness && (
              <Question type="WELLNESS" questions={careplan.content.wellness} />
            )}
            {careplan.content.diet && (
              <Question type="DIET" questions={careplan.content.diet} />
            )}
            {careplan.content.careTeam && (
              <CareTeam members={careplan.content.careTeam} />
            )}
          </Container>
        </ScrollView>
        {careplan.signDate && goBack && (
          <Container modifiers={['footer', 'flexFixed']}>
            <Row modifiers={['spaceBetween']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={goBack}
              />
              <Button
                modifiers={['roundCorner', 'widthSmall']}
                onClick={() => setShowSearchModal(true)}
              >
                Share
              </Button>
            </Row>
          </Container>
        )}
        {!careplan.signDate && goBack && (
          <Container modifiers={['footer', 'flexFixed']}>
            <Row modifiers={['spaceBetween']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={goBack}
              />
              <Button
                modifiers={['roundCorner', 'widthSmall']}
                onClick={goEdit}
              >
                Edit
              </Button>
            </Row>
          </Container>
        )}
        {showSearchModal && (
          <Container modifiers={[!showSearchModal && 'noDisplay']}>
            <ProviderSelect
              handleClose={() => setShowSearchModal(false)}
              handleSelect={handleSelectProvider}
            />
          </Container>
        )}
      </Container>
    );
  }),
  ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeSmall};
  `
);

export default CareplanDetail;
