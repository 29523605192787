import Wrapper from './Wrapper';
import Wrapper2 from './Wrapper2';

export const Switch = ({
  onChange,
  active,
  leftText,
  rightText,
  styleV2,
  ...inputProps
}) => {
  const handleClick = () => {
    onChange(!active);
  };

  return styleV2 ? (
    <Wrapper2
      {...inputProps}
      onClick={handleClick}
      modifiers={[active && 'active']}
    >
      <div>
        <span>{leftText || 'Yes'}</span>
        <span>{rightText || 'No'}</span>
      </div>
      <span></span>
    </Wrapper2>
  ) : (
    <Wrapper
      {...inputProps}
      onClick={handleClick}
      modifiers={[active && 'active']}
    >
      <div>
        <span>{leftText || 'Yes'}</span>
        <span>{rightText || 'No'}</span>
      </div>
      <span></span>
    </Wrapper>
  );
};

export default Switch;
