import styled from 'styled-components';

import { Row } from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';

export const FOOTER_HEIGHT = px2rem(60);

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  max-height: ${FOOTER_HEIGHT};
`;

export default buildStyledComponent('MainLayout_Footer', styled(Row), styles);
