import React from 'react';

import {
  Container,
  Row,
  Column,
  Avatar,
  Text,
} from '@gaz/gaz-components.public';
import assets from 'assets';

import { getFullName } from 'utils/string';
import { formatLastSeen, secondsToDuration } from 'utils/time';

export default function PatientCard({
  onClick,
  record: { subTotal, lastSeen, patient, reading, billable },
}) {
  return (
    <Container modifiers={['card']} onClick={() => onClick(patient)}>
      <Container modifiers={['fluid']}>
        <Row modifiers="middle">
          <Column modifiers={['center', 'flexBox', 'verticalCenter']}>
            <Avatar
              modifiers={['round']}
              height={50}
              width={50}
              user={patient}
            />
          </Column>
          <Column modifiers={['col']}>
            <Row>
              <Text modifiers={['darkGrey']}>{getFullName(patient)}</Text>
            </Row>
            {lastSeen && (
              <Row>
                <Text modifiers={['xSmall', 'darkGrey']}>
                  Last Seen: {formatLastSeen(lastSeen, true)}
                </Text>
              </Row>
            )}
          </Column>
          <Column
            modifiers={['col_3', 'fluid', 'flexBox', 'verticalContentCenter']}
          >
            {subTotal ? (
              <>
                <img
                  src={
                    subTotal < 1200
                      ? assets.icons.clockDanger
                      : assets.icons.clockNormal
                  }
                  alt=""
                  width="18"
                  height="18"
                />

                <Text
                  modifiers={[
                    'xSmall',
                    'topPadding',
                    'block',
                    subTotal < 1200 ? 'danger' : 'darkGrey',
                  ]}
                >
                  {secondsToDuration(subTotal)}
                </Text>
              </>
            ) : (
              <Text modifiers={['block', billable ? 'darkGrey' : 'danger']}>
                {reading}
              </Text>
            )}
          </Column>
        </Row>
      </Container>
    </Container>
  );
}
