import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  noPadding: () => `
    padding-top: 0;
  `,
};

const styles = () => `
  padding-left: ${px2rem(20)};
  padding-right: ${px2rem(20)};
  flex: 1;
  overflow: hidden;
`;

export default buildStyledComponent('Content_SlidingPane', styled.div, styles, {
  modifierConfig,
});
