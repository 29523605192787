import styled from 'styled-components';
import { Column, Text } from '@gaz/gaz-components.public';

import { Container, Row, HR } from 'common';
import { displayTime } from 'utils/time';
import assets from 'assets';
import { buildStyledComponent, px2rem } from 'style';
import { formatTitleAndName } from 'utils/string';

const StatusLabel = buildStyledComponent(
  'OrderStatusLabel',
  styled(({ status, ...props }) => (
    <Text {...props} modifiers={['medium']}>
      {status === 'placed' || status === 'sent'
        ? 'Ordered'
        : status === 'shipped'
        ? 'Shipped'
        : 'Received'}
    </Text>
  )),
  ({ theme, status }) => `
      width: ${px2rem(90)};
      padding: ${px2rem(5)};
      text-align: center;
      color: ${theme.colors.white};
      border-radius: ${px2rem(6)};
      background-color: ${
        status === 'placed' || status === 'sent'
          ? theme.colors.blue
          : status === 'shipped'
          ? theme.colors.purple
          : theme.colors.success
      };
  `
);

const Item = buildStyledComponent(
  'MedicationOrderItem',
  styled(({ handleClick, order, ...props }) => (
    <Container modifiers={['card']} onClick={handleClick} {...props}>
      <Row modifiers={['middle', 'spaceBetween']}>
        <img src={assets.icons.icMedicationItem} />
        <Column modifiers={['col']}>
          {formatTitleAndName(order.provider)}
        </Column>
        <StatusLabel status={order.status} />
      </Row>
      <HR modifiers={['gray']} />
      <Row modifiers={['topGutters_1']}>
        <Text>
          Patient Name:{' '}
          <Text modifiers={['primary']}>
            {formatTitleAndName(order.patient)}
          </Text>
        </Text>
      </Row>
      <Row modifiers={['smallGutters']}>
        <Text>
          Pharmacy: <Text modifiers={['primary']}>Rightway Meds</Text>
        </Text>
      </Row>
      <Row modifiers={['middle', 'spaceBetween']}>
        <Text>On {displayTime(order.createdAt, 'MM/DD/yy @hh:mm a')}</Text>
      </Row>
    </Container>
  )),
  ({ theme }) => `
    margin: ${theme.dimensions.padding_2} 0;
    padding: ${theme.dimensions.padding_2} ${theme.dimensions.padding_1};
    > :last-child {
      img {
        width: ${px2rem(18)};
        height: ${px2rem(18)};
      }
    }
  `
);

export default Item;
