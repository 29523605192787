import React, { useState, useEffect } from 'react';
import { Container, Row, Column } from '@gaz/gaz-components.public';
import MediaPreview from '../AddAttachment/MediaPreview';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

export const SliderFilePreview = ({ item, attachments }) => {
  const [attachmentFiles, setAttachmentFiles] = useState([]);

  const moveAttachmentOrder = (toIndex) => {
    const arr = attachments.filter(
      (s) => s.type !== 'pdf' && s.category !== 'note'
    );
    const index = arr.findIndex((s) => item.originalName === s.originalName);

    const element = arr[index];
    arr.splice(index, 1);
    arr.splice(toIndex, 0, element);

    return arr;
  };

  useEffect(() => {
    const updatedValue = moveAttachmentOrder(0);
    setAttachmentFiles(updatedValue);
  }, [attachments]);

  const renderView = (file, index) => {
    const isVideo = file.type?.includes('video');

    const mediaPreviewProps = {
      src: file.url,
    };

    if (isVideo) {
      mediaPreviewProps.controls = true;
    }

    return (
      <div key={index}>
        <MediaPreview {...mediaPreviewProps} isVideo={isVideo} />
      </div>
    );
  };

  return (
    <Container>
      <Row>
        <Column modifiers={['col_12', 'flexBox', 'verticalContentCenter']}>
          {attachmentFiles.length === 1 ? (
            renderView(attachmentFiles[0], 0)
          ) : (
            <AwesomeSlider>
              {attachmentFiles.map((file, i) => {
                return renderView(file, i);
              })}
            </AwesomeSlider>
          )}
        </Column>
      </Row>
    </Container>
  );
};

export default SliderFilePreview;
