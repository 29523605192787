import { useMemo } from 'react';
import { Text, Column, Switch } from '@gaz/gaz-components.public';
import moment from 'moment';

import { Container, Row, Tabs, DatePicker } from 'common';
import { displayTime, getStartDate } from 'utils/time';
import assets from 'assets';

export default ({
  date,
  updateDate,
  period,
  updatePeriod,
  showTable,
  setShowTable,
}) => {
  const periodString = useMemo(() => {
    let startDate, endDate;
    const today = new Date();
    switch (period) {
      case 0:
        return displayTime(date, 'MMM DD yyyy');
      case 1:
        startDate = getStartDate('w', date);
        endDate = moment(startDate).add(6, 'days').toDate();
        break;
      case 2:
        startDate = getStartDate('m', date);
        endDate = moment(startDate).add(1, 'month').subtract(1, 'day').toDate();
        break;
      case 3:
        startDate = getStartDate('y', date);
        endDate = moment(startDate).add(1, 'year').subtract(1, 'day').toDate();
        break;
    }
    if (endDate.getTime() > today.getTime()) {
      endDate = today;
    }
    return `${displayTime(startDate, 'MMM DD')} - ${displayTime(
      endDate,
      'MMM DD yyyy'
    )}`;
  }, [date, period]);

  return (
    <Container modifiers={['fluid']}>
      <Container>
        <Row modifiers={['smallGutters']}>
          <Column modifiers={['col_8']}>
            <DatePicker
              handleChange={updateDate}
              value={date}
              maxDate={new Date()}
            >
              <Row modifiers={['middle']}>
                <Text modifiers={['medium', 'bold', 'secondaryColor', 'start']}>
                  {periodString}
                </Text>
                <img src={assets.icons.icChevronDownGray} />
              </Row>
            </DatePicker>
          </Column>
          <Column modifiers={['flexBox', 'verticalContentCenter', 'col_4']}>
            <Switch
              active={showTable}
              onChange={setShowTable}
              leftText="Graph"
              rightText="Table"
            />
          </Column>
        </Row>
      </Container>
      <Tabs current={period} onChange={updatePeriod} flatTabs>
        <Tabs.Tab title="Day" />
        <Tabs.Tab title="Week" />
        <Tabs.Tab title="Month" />
        <Tabs.Tab title="Year" />
      </Tabs>
    </Container>
  );
};
