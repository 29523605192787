import { useContext } from 'react';
import styled from 'styled-components';

import { CallServiceContext } from 'contexts/call';
import { AuthContext } from 'contexts/auth';
import Participant from './Participant';
import TimeElapsed from './TimeElapsed';
import { Container, Text, Avatar } from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';
import { formatTitleAndName } from 'utils/string';

const Content = ({ ...props }) => {
  const { muted, videoMuted, room, participants, callParticipants } =
    useContext(CallServiceContext);
  const { me } = useContext(AuthContext);
  const callee = participants[0];

  return (
    <Container modifiers={['fullHeight', 'fluid']} {...props}>
      <Container modifiers={['fullHeight', 'fluid']}>
        <Container modifiers={['fluid', 'flexBox']}>
          <Avatar
            modifiers={['round']}
            user={callee}
            width={200}
            height={200}
          />
          <Text modifiers={['block', 'center', 'white']}>
            {formatTitleAndName(callee)}
          </Text>
        </Container>
        {callParticipants?.length > 0 && (
          <Participant participant={callParticipants[0]} />
        )}
      </Container>
      <Container modifiers={['fluid']}>
        <Container modifiers={['fluid']}>
          <Avatar modifiers={['squared']} user={me} />
          {room && (
            <Participant
              participant={room.localParticipant}
              muted={muted}
              videoMuted={videoMuted}
            />
          )}
        </Container>
        <TimeElapsed modifiers={['block', 'center', 'white', 'medium']} />
      </Container>
    </Container>
  );
};

const styles = ({ theme }) => `
  background: ${theme.colors.primary};

  > :first-child {
    z-index: 40;
    display: flex;
    align-items: center;
    justify-content: center;

    > :first-child {
      width: 100%;
      justify-content: center;

      > :first-child {
        font-size: ${px2rem(60)};
        object-fit: contain;
        border: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: ${px2rem(10)};
      }
    }

    > :last-child:not(:first-child) {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 50;
    }
  }

  > :last-child {
    position: absolute;
    top: 5%;
    right: 5%;
    width: ${px2rem(80)};
    height: ${px2rem(110)};
    z-index: 60;
    border-radius: ${px2rem(6)};
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(${px2rem(6)});

    > :first-child {
      position: relative;
      height: ${px2rem(90)};
      width: 100%;

      > :first-child {
        position: absolute;
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius: ${px2rem(6)};
        border: solid ${px2rem(1)} #F3F3F4;
        font-size: ${px2rem(30)};
      }

      video {
        z-index: 100;
        border-radius: ${px2rem(6)};
        border: solid ${px2rem(1)} #F3F3F4;
      }
    }

    > :last-child {
      margin: 0;
    }
  }
`;

export default buildStyledComponent('CallContent', styled(Content), styles);
