import React from 'react';

import { Button, Row } from '@gaz/gaz-components.public';

import Wrapper from './Wrapper';
import Content from './Content';
import Header from './Header';
import Footer from './Footer';

export const Popover = ({
  open,
  title,
  children,
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  onClose,
  onConfirm,
  overlayClose,
  footer,
  maxModal,
  superPriority,
}) => {
  return open ? (
    <Wrapper
      onClick={(e) => {
        if (overlayClose && onClose) {
          e.stopPropagation();
          onClose();
        }
      }}
      modifiers={[superPriority && 'superPriority']}
    >
      <Content
        modifiers={[maxModal && 'maxModal']}
        onClick={(e) => e.stopPropagation()}
      >
        {title && <Header>{title}</Header>}
        {children}
        {footer && (
          <Footer>
            <Row modifiers={['spaceAround']}>
              {onConfirm && (
                <>
                  <Button modifiers={['primary']} onClick={onConfirm}>
                    {okLabel}
                  </Button>
                  <Button modifiers={['outlineInfo']} onClick={onClose}>
                    {cancelLabel}
                  </Button>
                </>
              )}
              {!onConfirm && (
                <Button modifiers={['primary']} onClick={onClose}>
                  {okLabel}
                </Button>
              )}
            </Row>
          </Footer>
        )}
      </Content>
    </Wrapper>
  ) : null;
};

export default Popover;
