import React, { useContext, useRef } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from 'contexts/auth';
import { useMutation } from '@apollo/client';

import {
  BackButton,
  Text,
  Container,
  Row,
  Column as Col,
  Button,
  Avatar,
} from '@gaz/gaz-components.public';
import { MainLayout } from 'layouts';
import assets from 'assets';
import { CREATE_GROUP } from 'graphql/mutations';

// import { GROUP_TYPES } from 'utils/constants';

import Form from './Form';

const { Header, Content, Footer } = MainLayout;

export default function CreateGroup() {
  const { me } = useContext(AuthContext);
  const history = useHistory();
  const formRef = useRef();
  const [createGroup] = useMutation(CREATE_GROUP, {
    onCompleted: (data) => {
      history.replace(`/groups/${data.createGroup._id}?type=group`);
    },
    onError: () => {},
  });

  // const getGroupType = (cbRoute) => {
  //   switch (cbRoute) {
  //     case '/care-team':
  //       return GROUP_TYPES.CARE_TEAM;
  //     case '/':
  //     default:
  //       return GROUP_TYPES.CONTACTS;
  //   }
  // };

  // const groupType = getGroupType(callbackRoute);

  const handleDone = async () => {
    await formRef.current.submitForm();

    if (formRef.current.isValid) {
      createGroup({
        variables: {
          group: formRef.current.values,
        },
      });
    }
  };

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
        <Header.Center>
          <Text>Create Group</Text>
        </Header.Center>
      </Header>
      <Content>
        <Container>
          <Form ref={formRef} />
        </Container>

        <Container>
          <Row>
            <Col modifiers={['col_12']}>
              <Text modifiers={['secondaryColor']}>Members</Text>
            </Col>
            <Col modifiers={['col_2']}>
              <Avatar user={me} width={40} height={40} modifiers={['round']} />
            </Col>
            <Col modifiers={['col_6']}>
              <Text>{`${me.firstName} ${me.lastName} (You)`}</Text>
            </Col>
            <Col modifiers={['col_4']}>
              <Text modifiers={['info']}>Admin</Text>
            </Col>
          </Row>
        </Container>
      </Content>
      <Footer>
        <Col modifiers={['col']}>
          <Button
            onClick={() => history.goBack()}
            image={assets.icons.close}
            modifiers={['icon', 'transparent']}
          />
        </Col>
        <Col modifiers={['col', 'flexBox', 'end']}>
          <Button onClick={handleDone} modifiers={['primary']}>
            Done
          </Button>
        </Col>
      </Footer>
    </MainLayout>
  );
}
