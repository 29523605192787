import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { offsetLimitPagination } from '@apollo/client/utilities';
import _ from 'lodash';

import { loadData } from 'utils/storage';

import { errorVar, loadingVar, splashLoadingVar } from './cache';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API,
});

const errorLink = onError(({ networkError, graphQLErrors, response }) => {
  let errMessage = '';
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`);

      if (message !== 'Invalid Auth!') {
        errMessage += `${message} `;
      }
    });

    if (errMessage) {
      errorVar({ message: errMessage });
    }
  }

  if (_.get(response, 'errors.0.message') === 'Invalid Auth!') {
    response.errors = null;
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    errorVar({
      message: 'Please check your internet connection.',
      status: 404,
    });
  }
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) =>
      key === '__typename' ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const getToken = async () => {
  const token = loadData('token');

  return token;
};

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        loading: {
          read() {
            return loadingVar();
          },
        },
        splashLoading: {
          read() {
            return splashLoadingVar();
          },
        },
        error: {
          read() {
            return errorVar();
          },
        },
        recentChats: offsetLimitPagination(['query']),
      },
    },
    //   User: {
    //     keyFields: ['email'],
    //   },
  },
});

// Initialize Apollo Client
export const client = new ApolloClient({
  link: ApolloLink.from([cleanTypeName, errorLink, authLink, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  },
});
