import styled from 'styled-components';
import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  border-radius: 40px 40px 0px 0px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
`;

export default buildStyledComponent(
  'AuthLayoutV2_Content',
  styled(Container),
  styles
);
