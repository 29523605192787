import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = () => `
  position: absolute;
  left: 7px;
  top: 5px;
`;

export default buildStyledComponent('CheckBox_CheckMark', styled.img, styles);
