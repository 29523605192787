import React from 'react';
import { Container, Row, Text, AttachmentFileItem } from 'common';

import ChatItem from './ChatItem';
import NoteItem from './NoteItem';

const Attachment = ({ attachments }) => (
  <Container modifiers={['fluid', 'withGutters']}>
    <Row modifiers={['smallGutters']}>
      <Text modifiers={['block', 'semiBold', 'secondary']}>Attachments</Text>
    </Row>
    {attachments.map((attachment, index) => {
      const Comp =
        attachment?.category === 'chat'
          ? ChatItem
          : attachment?.category === 'note'
          ? NoteItem
          : AttachmentFileItem;
      return <Comp key={index} item={attachment} attachments={attachments} />;
    })}
  </Container>
);

export default Attachment;
