import React, { useEffect, useState } from 'react';

import Wrapper from './Wrapper';
import assets from 'assets';

export const SearchBar = ({
  autoFocus,
  placeholder,
  value,
  onChange,
  isFullWidth,
  ...inputProps
}) => {
  const [showButton, setShowButton] = useState(false);
  const [query, setQuery] = useState(value || '');
  const ref = React.createRef();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setQuery(value);
    onChange(value);

    if (value.length > 0) {
      setShowButton(true);
    }
  };

  const handleDelete = () => {
    setQuery('');
    onChange('');
    setShowButton(false);
    ref.current.focus();
  };

  useEffect(() => {
    if (onChange) {
      onChange(query);
    }
  }, [query]);

  return (
    <Wrapper {...inputProps} modifiers={isFullWidth && 'fullWidth'}>
      <input
        type="text"
        value={query}
        onChange={handleChange}
        autoFocus={autoFocus}
        placeholder={placeholder}
        ref={ref}
      />
      {showButton && (
        <div onClick={handleDelete}>
          <img src={assets.icons.close2} alt="delete" />
        </div>
      )}
    </Wrapper>
  );
};

export default SearchBar;
