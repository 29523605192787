import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { FormInput } from 'common';
import Modal from './Modal';
import Input from './Input';
import Error from '../FormError';
import { Column, Row } from 'common/Grid';

export const FormDirectDateInput = (props) => {
  const {
    options,
    label,
    noEmptyOption,
    minDate,
    maxDate,
    handleChange: customChangeHandler,
    dateFormat,
    selectedDropdownIcon,
    hasDay = true,
    submitOnClose,
    valueAsObject,
    ...inputProps
  } = props;

  const {
    field: { value, name, onChange },
    form: { touched, setFieldValue, submitForm },
    placeholder,
    title,
  } = inputProps;

  const [showModal, setShowModal] = useState(false);
  const [hasYearError, setHasYearError] = useState(false);
  const [hasMonthError, setHasMonthError] = useState(false);
  const [hasDayError, setHasDayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    onSubmit(moment(new Date()).format('MM/DD/Y'));
  }, []);

  const onSubmit = (date) => {
    onChange(name)(date);

    if (customChangeHandler) {
      customChangeHandler(date);
    }

    if (submitOnClose) {
      setFieldValue(name, convertDate(valueAsObject));
      submitForm();
    }
  };

  const convertDate = (asObject = false, date) => {
    let d = moment();

    if (date) {
      d = moment(date, 'MM/DD/Y');
    } else {
      if (day && month && year) {
        d = moment(`${month}/${day}/${year}`, 'MM/DD/Y');
      }

      if (!hasDay && month && year) {
        d = moment(`${month}/${year}`, 'MM/Y');
      }
    }

    if (asObject) {
      return d.toDate();
    }

    return d.format(dateFormat || 'MM/DD/Y');
  };

  const handleToggle = () => {
    setShowModal(!showModal);
  };

  const onFilterDate = (selectedDate) => {
    if (
      minDate &&
      moment(selectedDate, dateFormat || 'MM/DD/Y').isBefore(moment(minDate))
    ) {
      setErrorMessage(
        `The date should be later than ${moment(minDate).format(
          dateFormat || 'MM/DD/Y'
        )}`
      );

      return false;
    }

    if (
      maxDate &&
      moment(selectedDate, dateFormat || 'MM/DD/Y').isAfter(moment(maxDate))
    ) {
      setErrorMessage(
        `The date should be earlier than ${moment(maxDate).format(
          dateFormat || 'MM/DD/Y'
        )}.`
      );

      return false;
    }

    return true;
  };

  const handleDone = () => {
    if (!month) setHasMonthError(true);
    if (hasDay && !day) setHasDayError(true);
    if (!year) setHasYearError(true);
    if (!month || (hasDay && !day) || !year) {
      setErrorMessage('Oops! Try a valid date.');
      return;
    }

    const updatedDate = convertDate();

    if (updatedDate === 'Invalid date') {
      setErrorMessage('Oops! Try a valid date.');
      return;
    }

    if (!minDate) {
      const diffYears = moment(updatedDate, dateFormat || 'MM/DD/Y').diff(
        moment(new Date()),
        'year'
      );

      if (Math.abs(diffYears) > 99) {
        setErrorMessage('Oops! Try a valid year.');
        return;
      }
    }

    if (!onFilterDate(updatedDate)) return;

    onSubmit(updatedDate);
    setShowModal(false);
  };

  const onChangeDate = (res, type) => {
    setErrorMessage('');

    switch (type) {
      case 'month':
        setMonth(res.target.value);
        break;

      case 'day':
        setDay(res.target.value);
        break;

      default:
        setYear(res.target.value);
        break;
    }
  };

  const filterNumOnly = (id, type) => {
    const element = document.getElementById(id);
    const regex = /[^0-9\-]/gi;

    if (element) {
      element.value = element.value.replace(regex, '');
    }
  };

  return (
    <div>
      <FormInput
        {...inputProps}
        field={{
          ...inputProps.field,
          value: convertDate(false, value) || '',
        }}
        onClick={(event) => {
          setShowModal(true);
        }}
        selectedDropdownIcon={selectedDropdownIcon}
        readOnly
        htmlFor="input-id"
      />

      <Modal
        modifiers={['v2']}
        title={title || placeholder}
        show={showModal ? 'yes' : undefined}
        onToggle={handleToggle}
        handleDone={handleDone}
      >
        <Row modifiers={['horizontalSmallPadding']}>
          <Column modifiers={hasDay ? 'col_4' : 'col_6'}>
            <Input
              modifiers={[hasMonthError && 'hasError', 'mediumHeight']}
              placeholder="MM"
              type="text"
              maxLength="2"
              id="direct-date-input-month"
              onChange={(val) => onChangeDate(val, 'month')}
              oninput={filterNumOnly('direct-date-input-month', 'month')}
            />
          </Column>

          {hasDay && (
            <Column modifiers="col_4">
              <Input
                modifiers={[hasDayError && 'hasError', 'mediumHeight']}
                placeholder="DD"
                type="text"
                maxLength="2"
                id="direct-date-input-day"
                onChange={(val) => onChangeDate(val, 'day')}
                oninput={filterNumOnly('direct-date-input-day', 'day')}
              />
            </Column>
          )}

          <Column modifiers={hasDay ? 'col_4' : 'col_6'}>
            <Input
              modifiers={[hasYearError && 'hasError', 'mediumHeight']}
              placeholder="YYYY"
              type="text"
              maxLength="4"
              id="direct-date-input-year"
              onChange={(val) => onChangeDate(val, 'year')}
              oninput={filterNumOnly('direct-date-input-year', 'year')}
            />
          </Column>

          {errorMessage.length > 0 && (
            <Column modifiers={['col_12', 'noPadding']}>
              <Error>{errorMessage}</Error>
            </Column>
          )}
        </Row>
      </Modal>
    </div>
  );
};

export default FormDirectDateInput;
