import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

import { FOOTER_HEIGHT } from './Footer/Wrapper';

const styles = ({ theme }) => `
  position: absolute;
  bottom: ${FOOTER_HEIGHT};
  right: ${px2rem(25)};
  z-index: ${theme.dimensions.zLevel50};
`;

export default buildStyledComponent(
  'MainLayout_FabWrapper',
  styled.div,
  styles
);
