import React from 'react';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const imageStyles = () => `
  width: 100%;
  height: ${px2rem(16)};
  border-radius: 50%;
  object-fit: contain;
`;

const Image = buildStyledComponent(
  'SideMenu_MenuIcon',
  styled.img,
  imageStyles,
);

const wrapperStyles = ({ theme }) => `
  background: #D2D2D2;
  border-radius: 50%;
  width: ${px2rem(30)};
  height: ${px2rem(30)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(59, 76, 106, 0.1);
`;

const Wrapper = buildStyledComponent(
  'SideMenu_Icon_Wrapper',
  styled.div,
  wrapperStyles,
);

const MenuIcon = ({ src, alt }) => (
  <Wrapper>
    <Image src={src} alt={alt} />
  </Wrapper>
);

export default MenuIcon;
