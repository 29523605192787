import icons from './icons';
import images from './images';
import fonts from './fonts';
import lotties from './lotties';

const assets = {
  icons,
  images,
  fonts,
  lotties,
};

export default assets;
