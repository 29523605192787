import React from 'react';
import { noop } from 'lodash';

import assets from 'assets';

import Wrapper from './Wrapper';
import CheckMark from './CheckMark';

export function Checkbox({
  checked,
  dark,
  disabled = false,
  hasError,
  id,
  label,
  onChange = noop,
  readOnly = false,
  hasCheckMark,
  isGroup = false,
  type = 'checkbox',
  ...rest
}) {
  return (
    <Wrapper
      modifiers={[
        disabled && 'disabled',
        readOnly && 'readOnly',
        dark && 'dark',
        hasError && 'hasError',
      ]}
      onClick={() => onChange(!checked)}
    >
      {hasCheckMark && checked && (
        <CheckMark
          width="12"
          height="16"
          alt=""
          src={assets.icons.icCheckMark}
        />
      )}
      <Wrapper.Input
        {...rest}
        checked={isGroup ? id === checked : checked}
        type={type}
        id={id}
        onChange={noop}
      />
      <Wrapper.Label htmlFor={id}>
        {label && <Wrapper.Text>{label}</Wrapper.Text>}
      </Wrapper.Label>
    </Wrapper>
  );
}

export default Checkbox;
