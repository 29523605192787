import React, { useContext, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router';
import { Container, Row } from '@gaz/gaz-components.public';

import { Modal } from 'common';
import { AuthContext } from 'contexts/auth';
import { REGISTER_PROVIDER } from 'graphql/mutations';
import { client } from 'graphql/client';
import { MEMBER_DESIGNATIONS_LIST } from 'utils/constants';

import Profile from '../Forms/Profile';
import Security from '../Forms/Security';
import ProfessionalInfo from '../Forms/ProfessionalInfo';

export default function Content({ step, goNext }) {
  const history = useHistory();
  const location = useLocation();
  const { me, updateAuth } = useContext(AuthContext);
  const [registerProvider] = useMutation(REGISTER_PROVIDER, {
    onCompleted: async (data) => {
      const {
        registerProvider: { user: newUser },
      } = data;

      await updateAuth(newUser);
      history.replace(location.state?.from || '/');
    },
    onError: (error) => {
      let message = error?.message || 'Unknown error!';
      if (message.startsWith('Error: ')) {
        message = message.substring(6);
      }
      Modal.info({ text: message });
    },
  });
  const formData = useRef({
    // step 0
    firstName: me.firstName,
    lastName: me.lastName,
    middleName: me.middleName || '',
    email: me.email,
    phone: me.phones?.mobile,
    photo: null,
    // step 1
    password: '',
    passwordConfirmation: '',
    pin: '',
    pinConfirmation: '',
    // step 2
    npi: '',
    memberDesignation: null,
    termsAgreed: false,
  });

  const handleSubmit = async (values) => {
    updateFormData(values);

    const {
      passwordConfirmation,
      pinConfirmation,
      termsAgreed,
      memberDesignation,
      npi,
      phone,
      ...profileValues
    } = formData.current;
    const timezoneOffset = -new Date().getTimezoneOffset();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    await client.clearStore();
    registerProvider({
      variables: {
        provider: {
          memberDesignation: memberDesignation.value,
          npi,
          user: {
            ...profileValues,
            phones: {
              mobile: phone,
            },
          },
        },
        timezoneOffset,
        timezone,
      },
    });
  };

  const updateFormData = (values) => {
    Object.assign(formData.current, values);
  };

  return (
    <Container>
      <Row modifiers={['withGutters']}>
        {step === 0 && (
          <Profile
            initialValues={formData.current}
            updateFormData={updateFormData}
            goNext={goNext}
          />
        )}
        {step === 1 && (
          <Security
            initialValues={formData.current}
            updateFormData={updateFormData}
            goNext={goNext}
          />
        )}
        {step === 2 && (
          <ProfessionalInfo
            initialValues={formData.current}
            memberDesignationsList={MEMBER_DESIGNATIONS_LIST}
            onSubmit={handleSubmit}
          />
        )}
      </Row>
    </Container>
  );
}
