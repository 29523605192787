import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = () => `
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
  background-color: #ffffffc0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default buildStyledComponent('Loader', styled.div, styles);
