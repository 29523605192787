import React from 'react';
import { Text } from 'common';

import Wrapper from './Wrapper';

export const IconButton = ({
  icon,
  title,
  theme = 'dark',
  size = 25,
  background = '',
  outline,
  roundOutline,
  disabled,
  textColor,
  onClick,
  ...inputProps
}) => {
  const handleClick = (e) => {
    if (onClick && !disabled) {
      onClick(e);
    }
  };

  return (
    <Wrapper
      modifiers={[
        theme,
        background && `${background}Background`,
        outline && 'outline',
        roundOutline && 'roundOutline',
      ]}
      onClick={handleClick}
      {...inputProps}
    >
      <img
        src={icon}
        alt={title}
        width={size}
        height={size}
        style={{ paddingBottom: 5, opacity: disabled ? 0.3 : 1 }}
      />
      <Text modifiers={['small', 'body']} style={{ color: textColor }}>
        {title}
      </Text>
    </Wrapper>
  );
};

export default IconButton;
