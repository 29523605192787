import React, { useState } from 'react';

import { Checkbox } from 'common';
import FormInput from '../FormInput';
import Modal from './Modal';
import { Column, Row } from 'common/Grid';
import Label from '../FormLabel';

export const FormRadioCheckbox = (props) => {
  const {
    options,
    label,
    noEmptyOption,
    handleChange: customChangeHandler,
    ...inputProps
  } = props;
  const items = options.map((item) => {
    return {
      id: item,
      label: item,
    };
  });

  const {
    field: { value, name, onChange },
    placeholder,
    title,
  } = inputProps;

  const [showModal, setShowModal] = useState(false);
  const [selectedValue, updateSelectedValue] = useState(value);
  const originalItem = items.find((item) => item.id === value);

  const handleToggle = () => {
    if (showModal === true) {
      updateSelectedValue(value);
    }

    setShowModal(!showModal);
  };

  const handleDone = () => {
    onChange(name)(selectedValue);

    if (customChangeHandler) {
      customChangeHandler(selectedValue);
    }

    setShowModal(false);
  };

  const onChangeValue = (id) => {
    if (showModal) {
      updateSelectedValue(id);
    }
  };

  return (
    <div>
      <FormInput
        {...inputProps}
        field={{
          ...inputProps.field,
          value: originalItem?.label || '',
        }}
        onClick={() => setShowModal(true)}
        readOnly
      ></FormInput>

      <Modal
        modifiers={['v2']}
        title={title || placeholder}
        show={showModal ? 'yes' : undefined}
        onToggle={handleToggle}
        handleDone={handleDone}
      >
        <Row modifiers={['horizontalSmallPadding']}>
          {items.map(({ id, label }, index) => (
            <Column modifiers={['col_6']} key={index}>
              <Checkbox
                label={<Label>{label}</Label>}
                checked={selectedValue}
                id={id}
                onChange={() => onChangeValue(id)}
                modifiers={['small']}
                isGroup
                {...inputProps}
              />
            </Column>
          ))}
        </Row>
      </Modal>
    </div>
  );
};

export default FormRadioCheckbox;
