import styled from 'styled-components';
import { px2rem } from 'style';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  light: ({ theme }) => ({
    styles: `
      >h6 {
        color: ${theme.colors.white};
      }
    `,
  }),
  dark: ({ theme }) => ({
    styles: `
      >h6 {
        color: ${theme.colors.text};
      }
    `,
  }),
  greyBackground: ({ theme }) => ({
    styles: `
      background-color: ${theme.colors.grey};
      border-radius: ${px2rem(5)};
    `,
  }),
  primaryBackground: ({ theme }) => ({
    styles: `
      background-color: ${theme.colors.primary};
      border-radius: ${px2rem(5)};
    `,
  }),
  outline: ({ theme, ...props }) => ({
    styles: `
      background-color: ${theme.colors.transparent};
      border-radius: ${px2rem(5)};
      border: ${theme.dimensions.borderWidth_1} solid ${
      props.borderColor || theme.colors.grey
    };
    `,
  }),
  roundOutline: ({ theme, ...props }) => ({
    styles: `
      background-color: ${theme.colors.transparent};
      border-radius: 12px;
      border: ${theme.dimensions.lightBorder};
      box-shadow: 0px 12px 30px #00000012;
      padding: 15px;
    `,
  }),
  flat: ({ theme }) => `
    background-color: ${theme.colors.transparent};
    border: none;
    box-shadow: 0;
  `,
};

const styles = ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >h6 {
    padding: 0;
    margin: 2px 0px 0px 0px;
    font-size: ${theme.dimensions.fontSizeIconBtn};
    color: ${theme.colors.text};  
    white-space: nowrap;
  }
`;

const Wrapper = buildStyledComponent('IconButton_Wrapper', styled.div, styles, {
  modifierConfig,
});

export default Wrapper;
