import { Column, Avatar } from '@gaz/gaz-components.public';

import { Container, Text, Row, HR } from 'common';
import { displayTime } from 'utils/time';
import { formatTitleAndName } from 'utils/string';

export default ({ handleClick, note }) => (
  <Container
    modifiers={['card', 'padding_2', 'withGutters']}
    onClick={handleClick}
  >
    <Row modifiers={['middle', 'spaceBetween']}>
      <Avatar
        modifiers={['round']}
        user={note.creator.user}
        width={50}
        height={50}
      />
      <Column modifiers={['col']}>
        <Row>
          <Text modifiers={[!note.isSeen && !note.isDraft && 'bold', 'block']}>
            {formatTitleAndName(note.creator.user)}
          </Text>
        </Row>
        <Row>
          <Text modifiers={['block', !note.isSeen && !note.isDraft && 'bold']}>
            {displayTime(note.signDate, 'hh:mm a, MM/DD/yy')}
          </Text>
        </Row>
      </Column>
    </Row>
    <HR modifiers={['gray']} />
    <Row modifiers={['topGutters_1', 'spaceBetween']}>
      <Text modifiers={[!note.isSeen && !note.isDraft && 'bold']}>
        Reference:{' '}
        <Text modifiers={['primary']}>{formatTitleAndName(note.user)}</Text>
      </Text>
      <Text>{note._id.substr(-7)}</Text>
    </Row>
  </Container>
);
