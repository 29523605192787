import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import debounce from 'lodash/debounce';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';

import {
  SearchBar,
  Container,
  Row,
  Column,
  Button,
  HR,
  Avatar,
} from '@gaz/gaz-components.public';
import {
  CustomSlidingPane,
  FormIconInput,
  FormBirthdayPicker,
  Text,
} from 'common';

import urls from 'routes/urls';
import assets from 'assets';
import { FETCH_PATIENTS, FETCH_RECENT_PATIENTS } from 'graphql/queries';
import { SAVE_RECENT_PATIENTS } from 'graphql/mutations';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import { getFullName } from 'utils/string';

export default function PatientSearch() {
  const [openSearch, setOpenSearch] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [dob, setDob] = useState('');
  const history = useHistory();
  const [fetchPatients, { data: { patients } = {} }] =
    useLazyQuery(FETCH_PATIENTS);
  const showResult = keyword || dob;
  const [saveRecentPatients] = useMutation(SAVE_RECENT_PATIENTS);
  const { data: { recentPatients } = { recentPatients: [] } } = useQuery(
    FETCH_RECENT_PATIENTS
  );

  const updateRecentPatients = (data) => {
    saveRecentPatients({
      variables: { recentPatients: data },
      update: (cache, { data }) => {
        cache.writeQuery({
          query: FETCH_RECENT_PATIENTS,
          data: {
            recentPatients: data.saveRecentPatients,
          },
        });
      },
    });
  };

  const handleSearch = useCallback(
    debounce((query, dob) => {
      const birthday = dob
        ? moment(dob, 'MMMM DD, YYYY').format('MM/DD/Y')
        : '';

      fetchPatients({
        variables: {
          query,
          birthday,
          inPractice: 'yes',
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchPatients]
  );

  const onKeywordChange =
    () =>
    ({ target: { value } }) => {
      setKeyword(value);
      handleSearch(value, dob);
    };

  const handleDobChange = () => (value) => {
    setDob(value);
    handleSearch(keyword, value);
  };

  const clearRecentHistory = () => {
    updateRecentPatients([]);
  };

  const addToRecentSearches = (patient) => {
    const newRecent = recentPatients.map((s) => s._id).concat(patient._id);
    updateRecentPatients(newRecent);
  };

  const goToPatientChart = (patient) => {
    if (showResult) {
      addToRecentSearches(patient);
    }
    history.push(`${urls.PATIENTS}/${patient._id}`);
  };

  const getPatients = () => {
    const result = showResult ? patients : recentPatients;

    return result || [];
  };

  const closePane = () => {
    setOpenSearch(false);
    setKeyword('');
    setDob('');
  };

  return (
    <div>
      <SearchBar
        onClick={() => setOpenSearch(true)}
        placeholder={'Search Patients'}
        autoFocus={false}
      />
      <CustomSlidingPane
        closeIcon={<div></div>}
        modifiers={['noPadding', 'noHeader']}
        isOpen={openSearch}
        from="bottom"
        overlayClassName="sliding-pane-overlay"
        width="100%"
        onRequestClose={closePane}
      >
        <Container>
          <Row>
            <Column modifiers={['col_12']}>
              <FormIconInput
                name="keyword"
                placeholder="Search Patients by name"
                iconWidth={20}
                iconHeight={20}
                modifiers={['round', 'darkPlaceholder', 'textNormal']}
                round
                icon={assets.icons.icSearch}
                field={{
                  onChange: onKeywordChange,
                  value: keyword,
                  onBlur: () => {},
                }}
                form={{
                  touched: keyword.length > 0,
                  setFieldTouched: () => {},
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col_12']}>
              <FormBirthdayPicker
                name="dob"
                placeholder="Search by DOB"
                iconWidth={20}
                iconHeight={20}
                modifiers={['round', 'darkPlaceholder', 'textNormal']}
                variant="contained"
                valueAsTitle
                displayFormat="MMMM DD, YYYY"
                noBorder
                round
                noOverlay
                icon={assets.icons.icDayCalendar}
                pickerModifiers={['v2']}
                field={{
                  name: 'dob',
                  onChange: handleDobChange,
                  onBlur: () => {},
                }}
                form={{
                  touched: dob.length > 0,
                  setFieldTouched: () => {},
                }}
                flex
              />
            </Column>
          </Row>

          <Row modifiers={['spaceBetween', 'withGutters', 'middle']}>
            <Text modifiers={['darkGrey', 'semiBold', 'mediumWeight']}>
              {showResult ? 'Search Results' : 'Recent Search'}
            </Text>
            {!showResult && (
              <Button onClick={clearRecentHistory} modifiers={['link']}>
                <Text modifiers={['primary', 'small']}>Clear History</Text>
              </Button>
            )}
          </Row>
          <HR modifiers={['colored']} />

          {getPatients().map((patient) => (
            <Row key={patient._id} onClick={() => goToPatientChart(patient)}>
              <Column
                modifiers={[
                  'col_2',
                  'flexBox',
                  'verticalContentCenter',
                  'center',
                ]}
              >
                <Avatar
                  alt=""
                  user={patient}
                  width={40}
                  height={40}
                  modifiers={['round']}
                />
              </Column>
              <Column modifiers={['flexBox', 'verticalContentCenter']}>
                <Text modifiers={['darkGrey', 'h3', 'center', 'normalWeight']}>
                  {getFullName(patient)}
                </Text>
              </Column>
            </Row>
          ))}
        </Container>
      </CustomSlidingPane>
    </div>
  );
}
