import { useState } from 'react';
import assets from 'assets';
import { Row, Column, Text, Button } from '@gaz/gaz-components.public';
import { Popover } from 'common';
import { formatTitleAndName } from 'utils/string';
import { displayTime } from 'utils/time';
import ViewNote from 'pages/Notes/ViewNote';

export const NoteItem = ({ item, handleRemoveAttachment }) => {
  const [showDetail, setShowDetail] = useState(false);
  return (
    <>
      <Row modifiers={['spaceBetween']} onClick={() => setShowDetail(true)}>
        <Column modifiers={['noPadding']}>
          <img src={assets.icons.icNoteItem} alt="" width="36" height="36" />
        </Column>

        <Column modifiers={['col', 'noPadding']}>
          <Text modifiers={['block', 'subtitle', 'darkGrey']}>
            {item.noteContent && formatTitleAndName(item.noteContent.creator)}
          </Text>
          <Text modifiers={['block', 'muted', 'small', 'light']}>
            {item.noteContent &&
              displayTime(item.noteContent.signDate, 'MM/DD/YYYY hh:mm a')}
          </Text>
        </Column>

        {handleRemoveAttachment && (
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            width={30}
            height={30}
            onClick={handleRemoveAttachment}
          />
        )}
      </Row>
      {item.noteContent && showDetail && (
        <Popover
          open={showDetail}
          overlayClose
          onClose={() => setShowDetail(false)}
        >
          <ViewNote note={item.noteContent} />
        </Popover>
      )}
    </>
  );
};

export default NoteItem;
