import { useContext, useState, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Container,
  Row,
  Column,
  ScrollView,
  Text,
  IconButton,
} from '@gaz/gaz-components.public';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import debounce from 'lodash/debounce';

import { FormDatePicker } from 'common';
import AppointmentItem from './AppointmentItem';
import assets from 'assets';
import { displayTime } from 'utils/time';
import { FETCH_APPOINTMENTS } from 'graphql/queries';
import { CANCEL_APPOINTMENT } from 'graphql/mutations';
import { EVENTS, SocketContext } from 'contexts/socket';
import { AuthContext } from 'contexts/auth';
import { loadingVar } from 'graphql/cache';

export default function MainList({
  bookTime,
  selectedDate,
  updateSelectedDate,
  patient,
}) {
  const history = useHistory();
  const { subscribe } = useContext(SocketContext);
  const { me } = useContext(AuthContext);
  const appointmentUpdateSubscription = useRef();
  const appointmentsListUpdateSubscription = useRef();
  const [appointments, updateAppointments] = useState();
  const [loading, setLoading] = useState(false);
  const [fetchAppointments] = useLazyQuery(FETCH_APPOINTMENTS, {
    onCompleted: (data) => {
      if (data.appointments) {
        updateAppointments([...data.appointments]);
      } else {
        updateAppointments([]);
      }
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const [cancelAppointment] = useMutation(CANCEL_APPOINTMENT);

  const updateDate = (values) => {
    updateSelectedDate(values.date);
  };
  const goPreviousDay = (setFieldValue) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() - 1);
    updateSelectedDate(newDate);
    setFieldValue('date', newDate);
  };
  const goNextDay = (setFieldValue) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() + 1);
    updateSelectedDate(newDate);
    setFieldValue('date', newDate);
  };

  const loadAppointments = useCallback(
    (date) => {
      const appointmentDate = date || selectedDate;
      const from = moment(
        displayTime(appointmentDate, 'MM/DD/YYYY'),
        'MM/DD/YYYY'
      );
      const to = from.clone();
      to.add(1, 'day');
      const params = { from: from.toDate(), to: to.toDate() };
      if (!!patient) {
        params.patient = patient._id;
      }
      setLoading(true);
      fetchAppointments({
        variables: params,
      });
    },
    [fetchAppointments, selectedDate]
  );

  const debounceLoad = debounce(() => {
    loadAppointments();
  }, 1000);

  useEffect(() => {
    appointmentUpdateSubscription.current?.unsubscribe();
    appointmentUpdateSubscription.current = subscribe(
      EVENTS.APPOINTMENT_UPDATE,
      (payload) => {
        const { appointment: appointmentId, payload: updateData } = payload;
        const checkAppointment = appointments?.find(
          (item) => item._id === appointmentId
        );
        if (checkAppointment) {
          const newAppointments = appointments?.map((appointment) => {
            if (appointment._id === appointmentId) {
              if (updateData.joined === me._id) {
                if (!appointment.joined) {
                  appointment.joined = [];
                }
                appointment.joined.push(me._id);
              }
              return { ...appointment, status: updateData.status };
            }
            return appointment;
          });
          updateAppointments([...newAppointments]);
        }
      }
    );

    return () => {
      appointmentUpdateSubscription.current?.unsubscribe();
    };
  }, [appointments]);

  useEffect(() => {
    debounceLoad();
  }, [selectedDate, bookTime]);

  useEffect(() => {
    loadAppointments();
  }, []);

  useEffect(() => {
    appointmentsListUpdateSubscription.current?.unsubscribe();
    appointmentsListUpdateSubscription.current = subscribe(
      EVENTS.APPOINTMENTS,
      () => {
        loadAppointments();
      }
    );
  }, [loadAppointments]);

  const handleCancel = async (appointment) => {
    loadingVar(true);
    await cancelAppointment({
      variables: { appointment: appointment._id },
    });
    loadingVar(false);
    const appointmentDate = selectedDate;
    const from = moment(
      displayTime(appointmentDate, 'MM/DD/YYYY'),
      'MM/DD/YYYY'
    );
    const to = from.clone();
    to.add(1, 'day');
    setLoading(true);
    fetchAppointments({
      variables: { from: from.toDate(), to: to.toDate() },
    });
  };

  const handleGoToChart = (appointment) => {
    history.push(`/patients/${appointment.patient._id}`);
  };

  const handleDoneReschedule = useCallback(() => {
    loadAppointments();
  }, [loadAppointments]);

  return (
    <Container modifiers={['flexBox', 'fullHeight', 'fluid']}>
      <Container>
        <Formik initialValues={{ date: new Date() }} onSubmit={updateDate}>
          {({ setFieldValue }) => (
            <Form>
              <Row modifiers={['center']}>
                <IconButton
                  icon={assets.icons.chevronR}
                  onClick={() => goPreviousDay(setFieldValue)}
                  style={{ transform: 'scaleX(-1)' }}
                />
                <Column modifiers={['col_6', 'noPadding']}>
                  <Field
                    asGrayInfo
                    noBorder
                    noPadding
                    transparent
                    name="date"
                    value={displayTime(selectedDate, 'M/DD/YY')}
                    dateFormat="M/DD/YY"
                    valueAsObject
                    submitOnClose
                    title="Select date"
                    component={FormDatePicker}
                    style={{ backgroundColor: 'transparent' }}
                  />
                  <Text modifiers={['center', 'medium', 'primary']}>
                    {displayTime(selectedDate, 'dddd')}
                  </Text>
                </Column>
                <IconButton
                  icon={assets.icons.chevronR}
                  onClick={() => goNextDay(setFieldValue)}
                />
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
      <ScrollView>
        {appointments?.length > 0 ? (
          appointments.map((appointment) => (
            <AppointmentItem
              key={appointment._id}
              appointment={appointment}
              handleDoneReschedule={handleDoneReschedule}
              handleCancelAppointment={handleCancel}
              handleGoToChart={handleGoToChart}
            />
          ))
        ) : (
          <Text modifiers={['center', 'large']}>No appoinments</Text>
        )}
      </ScrollView>
    </Container>
  );
}
