import { useState, createRef, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';

import {
  Container,
  Row,
  TimePicker,
  Button,
  Text,
  WeekdaysPicker,
} from 'common';
import { Directions, CustomRow, CustomField, WeeklyTimeItem } from './Items';
import { displayTime, hourToDate } from 'utils/time';

const FrequencyTypes = ['Daily', 'Weekly'];
const WeeksOptions = Array(10)
  .fill()
  .map((_, index) => {
    return {
      id: `${index + 1}`,
      name: `${index + 1}`,
    };
  });
const DirectionOptions = [...Directions];
DirectionOptions[0] = { id: 'none', shortName: 'SELECT', name: 'NONE' };

const CustomFrequency = ({ value, innerRef }) => {
  const initialValues = {
    frequency: value?.frequency || 'Weekly',
    everyWeeks: value?.everyWeeks || '1',
    weeklyTimes: value?.weeklyTimes || [],
    weeklyDirection: value?.weeklyDirection || 'none',
    weekDays: value?.weekDays || [],
    everyDays: value?.everyDays || 1,
    startDate: value?.startDate || new Date(),
    dailyTimes: value?.dailyTimes || [],
    dailyDirection: value?.dailyDirection || 'none',
  };
  const [frequencyType, setFrequencyType] = useState('Weekly');
  const [selectedWeekDays, setSelectedWeekdays] = useState([]);
  const [selectedTimes, updateSelectedTimes] = useState({});
  const addTimePickerRef = createRef();
  const [timePickerValue, setTimePickerValue] = useState();

  const toggleWeekdaySelection = (day, selected) => {
    let updatedDays = [...selectedWeekDays];
    if (selected) {
      updatedDays.push(day);
      updatedDays.sort();
    } else {
      updatedDays = selectedWeekDays.filter((elem) => elem !== day);
    }
    setSelectedWeekdays([...updatedDays]);
    innerRef.current.setFieldValue('weekDays', updatedDays);
  };

  useEffect(() => {
    setFrequencyType(value?.frequency || 'Weekly');
    if (value?.weekDays?.length > 0) {
      setSelectedWeekdays([...value.weekDays]);
    } else {
      setSelectedWeekdays([]);
    }
    if (value?.frequency === 'Weekly' && value?.weeklyTimes?.length > 0) {
      const times = {};
      value.weeklyTimes.forEach((time) => (times[time] = true));
      setSelectedTimes(times, value?.frequency || 'Weekly');
    } else if (value?.frequency === 'Daily' && value?.dailyTimes?.length > 0) {
      const times = {};
      value.dailyTimes.forEach((time) => (times[time] = true));
      setSelectedTimes(times, value?.frequency || 'Weekly');
    } else {
      setSelectedTimes({}, value?.frequency || 'Weekly');
    }
  }, [value]);

  const onClickAddTime = useCallback(() => {
    setTimePickerValue([true]);
  });
  const handleAddTime = useCallback(
    (time) => {
      if (!!timePickerValue && timePickerValue[0] !== true) {
        delete selectedTimes[timePickerValue[0]];
      }
      selectedTimes[time] = true;
      setSelectedTimes({ ...selectedTimes });
      setTimePickerValue(null);
    },
    [selectedTimes, timePickerValue]
  );
  useEffect(() => {
    if (!addTimePickerRef || !timePickerValue) {
      return;
    }
    addTimePickerRef.current.click();
  }, [addTimePickerRef, timePickerValue]);

  const setSelectedTimes = useCallback(
    (times, frequency) => {
      if (!value) {
        return;
      }
      if (!times || Object.keys(times).length === 0) {
        innerRef?.current?.setFieldValue('weeklyTimes', []);
        innerRef?.current?.setFieldValue('dailyTimes', []);
        updateSelectedTimes([]);
        return;
      }
      const hourObjects = Object.keys(times).map((time) =>
        hourToDate(time).toDate()
      );
      hourObjects.sort();
      const newTimes = {};
      hourObjects.forEach(
        (time) => (newTimes[displayTime(time, 'hh:mm A')] = true)
      );
      if (
        frequency === 'Weekly' ||
        (!frequency && frequencyType === 'Weekly')
      ) {
        innerRef?.current?.setFieldValue('weeklyTimes', Object.keys(newTimes));
        innerRef?.current?.setFieldValue('dailyTimes', []);
      } else {
        innerRef?.current?.setFieldValue('dailyTimes', Object.keys(newTimes));
        innerRef?.current?.setFieldValue('weeklyTimes', []);
      }
      updateSelectedTimes(newTimes);
    },
    [value, innerRef, frequencyType]
  );

  const changeFrequencyType = useCallback(
    (type) => {
      setFrequencyType(type);
      if (type === 'Weekly') {
        innerRef?.current?.setFieldValue(
          'weeklyTimes',
          Object.keys(selectedTimes)
        );
        innerRef?.current?.setFieldValue('dailyTimes', []);
      } else {
        innerRef?.current?.setFieldValue(
          'dailyTimes',
          Object.keys(selectedTimes)
        );
        innerRef?.current?.setFieldValue('weeklyTimes', []);
      }
    },
    [innerRef, selectedTimes]
  );

  const handleRemoveTime = useCallback(
    (time) => {
      delete selectedTimes[time];
      setSelectedTimes({ ...selectedTimes });
    },
    [selectedTimes]
  );

  const handleClickTime = useCallback((time) => {
    setTimePickerValue([time]);
  }, []);

  if (!value) {
    return null;
  }

  return (
    <>
      <Formik initialValues={initialValues} innerRef={innerRef}>
        <Form>
          <CustomRow title="Frequency">
            <CustomField
              fieldType="picker"
              placeholder="SELECT FREQUENCY"
              name="frequency"
              options={FrequencyTypes}
              handleChange={(value) => {
                changeFrequencyType(value);
              }}
            />
          </CustomRow>
          {frequencyType === 'Weekly' ? (
            <Container modifiers={['fluid']}>
              <CustomRow title="Every" hasThreeComponents>
                <CustomField
                  fieldType="picker"
                  name="everyWeeks"
                  options={WeeksOptions}
                />
                <Text modifiers={['blue']}>Week(s)</Text>
              </CustomRow>
              <WeekdaysPicker
                handleSelect={toggleWeekdaySelection}
                selectedDays={selectedWeekDays}
                twoDayStyle
              />
            </Container>
          ) : (
            <Container modifiers={['fluid']}>
              <CustomRow title="Every" hasThreeComponents>
                <CustomField name="everyDays" />
                <Text modifiers={['blue']}>Day(s)</Text>
              </CustomRow>
              <CustomRow title="Start Date">
                <CustomField fieldType="datePicker" name="startDate" />
              </CustomRow>
            </Container>
          )}
          <CustomRow title="Time(s)" noBorder>
            <Button
              modifiers={['roundCorner', 'medium', 'outlinePrimary']}
              onClick={onClickAddTime}
            >
              + ADD TIME
            </Button>
          </CustomRow>
          {Object.values(selectedTimes).length > 0 && (
            <Row modifiers={['withGutters']}>
              {Object.keys(selectedTimes).map((time) => (
                <WeeklyTimeItem
                  key={time}
                  value={time}
                  handleRemove={handleRemoveTime}
                  handleClick={handleClickTime}
                />
              ))}
            </Row>
          )}

          {frequencyType === 'Weekly' ? (
            <CustomRow title="Direction">
              <CustomField
                fieldType="picker"
                name="weeklyDirection"
                options={DirectionOptions}
              />
            </CustomRow>
          ) : (
            <CustomRow title="Direction">
              <CustomField
                fieldType="picker"
                name="dailyDirection"
                options={DirectionOptions}
              />
            </CustomRow>
          )}
        </Form>
      </Formik>
      <TimePicker handleChange={handleAddTime} value="08:00 AM">
        <Container modifiers={['noDisplay']} ref={addTimePickerRef}></Container>
      </TimePicker>
    </>
  );
};

export default CustomFrequency;
