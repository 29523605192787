import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';

import {
  Container,
  Row,
  Column,
  FormPhoneInput,
  FormInput,
  // FormCheckbox,
  A,
  Button,
} from '@gaz/gaz-components.public';
import { FormCheckbox } from 'common';
import { REQUEST_CODE } from 'graphql/mutations';
import schema from 'utils/schema';
import urls from 'routes/urls';

const verifySchema = Yup.object().shape({
  firstName: schema.firstName,
  lastName: schema.lastName,
  middleName: schema.middleName,
  email: schema.email,
  phone: schema.phone,
  termsAgreed: Yup.bool().isTrue('Please accept the Terms and Conditions.'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  phone: '',
  termsAgreed: false,
};

export default function SignUp({ history }) {
  const [requestCode] = useMutation(REQUEST_CODE);

  const handleSubmit = async ({ termsAgreed, ...values }) => {
    try {
      values.firstName = values.firstName.trim();
      values.middleName = values.middleName?.trim() || '';
      values.lastName = values.lastName.trim();
      const variables = {
        fullName: `${values.firstName} ${values.lastName}`,
        email: values.email,
        phone: values.phone,
      };
      await requestCode({
        variables,
      });

      history.push({
        pathname: urls.VERIFY_CODE,
        state: variables,
      });
    } catch (error) {}
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={verifySchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  name="firstName"
                  hasLabel
                  label="First name"
                  placeholder="Enter first name"
                  largeLabel
                  modifiers={['squared']}
                  component={FormInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  name="middleName"
                  hasLabel
                  label="Middle name"
                  placeholder="Enter middle name"
                  largeLabel
                  modifiers={['squared']}
                  component={FormInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  name="lastName"
                  hasLabel
                  label="Last name"
                  placeholder="Enter last name"
                  largeLabel
                  modifiers={['squared']}
                  component={FormInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  name="email"
                  hasLabel
                  label="Email"
                  type="email"
                  placeholder="Enter email"
                  largeLabel
                  modifiers={['squared']}
                  component={FormInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  name="phone"
                  hasLabel
                  label="Phone Number"
                  placeholder="Enter phone number"
                  largeLabel
                  modifiers={['squared']}
                  component={FormPhoneInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  name="termsAgreed"
                  label={
                    <>
                      Agree to our <A to={urls.TERMS}>Terms & Conditions</A>
                    </>
                  }
                  type="checkbox"
                  modifiers={['squared']}
                  hasCheckMark
                  component={FormCheckbox}
                />
              </Column>
            </Row>
            <Row modifiers={['withGutters', 'center']}>
              <Button
                type="submit"
                modifiers={[
                  'widthMedium',
                  'primary',
                  'noShadow',
                  'roundCorner',
                  (!dirty || !isValid) && 'disabled',
                ]}
              >
                Sign Up
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
