const urls = {
  AUTH: '/auth',
  VERIFY_CODE: '/verify-code',
  ONBOARD: '/onboard',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  DASHBOARD: '/',
  PATIENTS: '/patients',
  ADD_PATIENT: '/add-patient',
  REGISTER_PRACTICE: '/register-practice',
  ADD_PATIENT_MANUAL: '/add-patient-manual',
  TERMS: '/terms',
  SECURITY_PRIVACY_STATEMENT: '/security-privacy-statement',
  PATIENT_HOME: '/patients/:id',
  CALL_HISTORY: '/calls',
  CHAT: '/chat',
  CHAT_HISTORY: '/chat-history',
  CONTACTS: '/contacts',
  CONTACT: '/contacts/:id',
  PRIVACY_POLICY: '/privacy-policy',
  HELP_CENTER: '/help-center',
  CALL_MASKING: '/call-masking',
  BILLING: '/billing',
  SCHEDULE: '/schedule',
  MY_PROFILE: '/my-profile',
  MY_PRACTICE: '/my-practice',
  MEDICAL_LICENSES: '/medical-licenses',
  REPORTS: '/reports',
  PATIENT_REPORT: '/reports/:id',
  APPOINTMENTS: '/appointments',
  INVITATIONS: '/invitations',
  GROUP: '/groups/:id',
  CREATE_GROUP: '/create-group',
  PHARMACY_ORDERS: '/pharmacy/orders',
  E_PRESCRIBE: '/e-prescribe',
  SECURITY: '/security',
  COORDINATE: '/coordinate',
  NOTES: '/notes',
  NOTE: '/notes/:id',
  CAREPLANS: '/care-plans',
  CAREPLAN: '/care-plans/:id',
  ALERTS: '/alerts',
  PATIENT_ALERTS: '/alerts/:id',
};

export default urls;
