import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';
import ImageViewer from 'react-simple-image-viewer';

import { Row, Avatar, Column } from '@gaz/gaz-components.public';
import { ChatBubble, Text, Checkbox, PDFViewer } from 'common';
import { buildStyledComponent, px2rem } from 'style';
import { AuthContext } from 'contexts/auth';
import { displayTime } from 'utils/time';
import NoteDetail from './NoteDetail';
import CareplanDetail from './CareplanDetail';

import assets from 'assets';
import { getFullName } from 'utils/string';

const playIconStyles = () => `
  position: absolute;
  width: ${px2rem(30)};
  height: ${px2rem(30)};
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
`;

const PlayIcon = buildStyledComponent(
  'MessageItem_PlayIcon',
  styled.img,
  playIconStyles
);

const MessageItem = React.forwardRef(
  ({ message, isSelecting, isSelected, onClickSelect }, ref) => {
    const { me } = useContext(AuthContext);
    const sender = message?.sender;
    const videoRef = useRef(null);
    const imageRef = useRef(null);
    const [imageViewerIsOpen, setImageViewerIsOpen] = useState(false);
    const [pdfviewerIsOpen, setPDFViewerIsOpen] = useState(false);
    const [showNote, setShowNote] = useState();
    const [showCareplan, setShowCareplan] = useState();

    const onClickMedia = () => {
      if (videoRef.current) {
        const element = videoRef.current;
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.webkitEnterFullScreen) {
          element.webkitEnterFullScreen();
        }
      }
    };

    const isMe = sender._id === me._id;

    return (
      <Row
        onClick={
          isSelecting ? () => onClickSelect(message._id)(!isSelected) : noop
        }
      >
        <Column
          modifiers={[
            'fluid',
            'noVerticalPadding',
            isSelecting ? 'col_10' : 'col_12',
          ]}
        >
          <Row
            ref={ref}
            modifiers={[isMe && 'reverse', isMe && 'start', 'withGutters']}
          >
            <Column
              style={{ marginTop: px2rem(10) }}
              modifiers={[
                'col_1',
                'fluid',
                'flexBox',
                'verticalEnd',
                !isMe && 'end',
                'noVerticalPadding',
              ]}
            >
              <Avatar
                width={28}
                height={28}
                modifiers={['round']}
                user={sender}
              />
            </Column>
            <Column modifiers={['col_10']}>
              {/* <Row
                style={{ marginBottom: px2rem(10) }}
                modifiers={[isMe && 'end']}
              ></Row> */}
              <Row modifiers={[isMe && 'end']}>
                <ChatBubble.Text isMe={isMe} modifiers={[isMe && 'isMe']}>
                  {message.attachment?.type === 'image' && !imageViewerIsOpen && (
                    <div
                      style={{ position: 'relative' }}
                      onClick={() => setImageViewerIsOpen(true)}
                    >
                      <ChatBubble.Image
                        src={message.attachment?.url}
                        ref={imageRef}
                      />
                    </div>
                  )}
                  {message.attachment?.type === 'image' &&
                    imageViewerIsOpen && (
                      <ImageViewer
                        src={[message.attachment?.url]}
                        currentIndex={0}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={() => setImageViewerIsOpen(false)}
                      />
                    )}

                  {message.attachment?.type === 'video' && (
                    <div
                      style={{ position: 'relative' }}
                      onClick={onClickMedia}
                    >
                      <PlayIcon src={assets.icons.videoPlayer} alt="" />
                      <ChatBubble.Video ref={videoRef}>
                        <source
                          src={message.attachment?.url.concat('#t=0.001')}
                        />
                      </ChatBubble.Video>
                    </div>
                  )}
                  {message.attachment?.type === 'pdf' && pdfviewerIsOpen && (
                    <PDFViewer
                      url={message.attachment?.url}
                      setPDFViewerIsOpen={setPDFViewerIsOpen}
                    />
                  )}

                  {message.attachment?.type === 'pdf' && !pdfviewerIsOpen && (
                    <div
                      style={{ position: 'relative' }}
                      onClick={() => setPDFViewerIsOpen(true)}
                    >
                      <ChatBubble.PDF url={message.attachment?.url} />
                    </div>
                  )}
                  {message.note && (
                    <div
                      style={{
                        padding: '5px 10px',
                        justifyContent: 'space-between',
                        backgroundColor: isMe ? 'white' : '#42A5F5',
                      }}
                      onClick={() => setShowNote(true)}
                    >
                      <Row modifiers={['middle', 'end']}>
                        <img
                          alt=""
                          src={assets.icons.icNoteBlue}
                          width="17"
                          height="21"
                        />
                        <Text modifiers={['subtitle', 'semiBold', 'darkGrey']}>
                          Note:
                        </Text>
                        <Text modifiers={['end', 'subtitle', 'light']}>
                          {getFullName(message.note.user)}
                        </Text>
                      </Row>
                    </div>
                  )}

                  {message.careplan && (
                    <div
                      style={{
                        padding: '5px 10px',
                        justifyContent: 'space-between',
                        backgroundColor: isMe ? 'white' : '#42A5F5',
                      }}
                      onClick={() => setShowCareplan(true)}
                    >
                      <Row modifiers={['middle', 'end']}>
                        <img
                          alt=""
                          src={assets.icons.icCarePlanGreen}
                          width="17"
                          height="21"
                        />
                        <Text modifiers={['subtitle', 'semiBold', 'darkGrey']}>
                          Careplan:
                        </Text>
                        <Text modifiers={['end', 'subtitle', 'light']}>
                          {getFullName(message.careplan.user)}
                        </Text>
                      </Row>
                    </div>
                  )}

                  <div style={{ whiteSpace: 'pre-line' }}>{message.text}</div>

                  <Text
                    modifiers={[
                      'block',
                      'xSmall',
                      'muted',
                      'topPadding',
                      isMe ? 'white' : 'primary',
                      'light',
                      'right',
                    ]}
                  >
                    {`${displayTime(message.createdAt)}`}
                  </Text>
                </ChatBubble.Text>
              </Row>
            </Column>
          </Row>
        </Column>

        {isSelecting && (
          <Column
            modifiers={[
              'verticalContentCenter',
              'flexBox',
              'fluid',
              'center',
              'noVerticalPadding',
              'col',
            ]}
          >
            <Checkbox
              checked={isSelected}
              onChange={noop}
              modifiers={['radioStyle', 'widthSmall']}
            />
          </Column>
        )}

        {message.note && showNote && (
          <NoteDetail note={message.note} goBack={() => setShowNote(false)} />
        )}
        {message.careplan && showCareplan && (
          <CareplanDetail
            careplan={message.careplan}
            goBack={() => setShowCareplan(false)}
          />
        )}
      </Row>
    );
  }
);

export default MessageItem;
