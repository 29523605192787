import styled from 'styled-components';
import { Row, Column } from '../Grid';
import { Text } from '../Text';
import {
  buildStyledComponent,
  px2rem,
} from '@gaz/gaz-components.styled-builder';

const ItemComponent = ({ item, ...props }) => (
  <Row {...props}>
    <Column />
    <Column modifiers={['col']}>
      <Text modifiers={['block', 'semiBold']}>{item.time}</Text>
      {item.items?.map((medication, index) => (
        <Text key={index} modifiers={['block']}>
          {medication}
        </Text>
      ))}
    </Column>
  </Row>
);

const styles = ({ theme }) => `
  text-align: left;

  ::before {
    content: '';
    width: ${px2rem(3)};
    background-color: ${theme.colors.gray7};
    position: absolute;
    left: ${px2rem(10)};
    height: 100%;
  }

  >:first-child {
    width: ${px2rem(16)};
    height: ${px2rem(16)};
    margin-right: ${px2rem(15)};
    margin-top: ${px2rem(13)};
    background-color: ${theme.colors.white};
    border: solid ${px2rem(3)} ${theme.colors.gray7};
    border-radius: ${px2rem(11)};
  }

  >:last-child {
    padding: ${theme.dimensions.padding_1};
    margin-bottom: ${px2rem(20)};
    border-top-right-radius: ${px2rem(8)};
    border-bottom-right-radius: ${px2rem(8)};

    >:first-child {
      margin-bottom: ${theme.dimensions.padding_1};
    }
    >:not(:first-child) {
      display: block;
      &::before {
        content: '•';
        margin-left: ${px2rem(5)};
        margin-right: ${px2rem(5)};
      }
    }
  }

  &:first-child::before {
    top: ${px2rem(15)};
    height: calc(100% - ${px2rem(15)});
  }

  &:last-child >:last-child {
    margin-bottom: 0;
  }
`;

const modifierConfig = {
  success: ({ theme }) => `
    >:last-child {
      background-color: ${theme.colors.success_3};
      border-left: solid ${px2rem(3)} ${theme.colors.success_2};

      >:first-child {
        color: ${theme.colors.success_2};
      }
    }
  `,
  danger: ({ theme }) => `
    >:last-child {
      background-color: ${theme.colors.danger_3};
      border-left: solid ${px2rem(3)} ${theme.colors.danger};

      >:first-child {
        color: ${theme.colors.danger};
      }
    }
  `,
  gray: ({ theme }) => `
    >:last-child {
      background-color: ${theme.colors.gray8};
      border-left: solid ${px2rem(3)} ${theme.colors.gray7};

      >:first-child {
        color: ${theme.colors.textSecondary};
      }
    }
  `,
};

const Item = buildStyledComponent(
  'DetailedListItem',
  styled(ItemComponent),
  styles,
  { modifierConfig }
);

const ItemWrapper = ({ item }) => (
  <Item
    item={item}
    modifiers={[
      item.value.title === 'YES'
        ? 'success'
        : item.value.title === 'NO'
        ? 'danger'
        : 'gray',
    ]}
  />
);

export default ItemWrapper;
