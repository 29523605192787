import { connect } from 'react-redux';
// import { Timer as GazTimer } from '@gaz/gaz-components.public';

import { REDUX_ACTIONS } from 'redux/constants';
import { secondsToDuration } from 'utils/time';

import Wrapper from './Wrapper';

export const Timer = ({ homePaused, homeTotal, setTimerData }) => {
  const handleToggle = () => {
    setTimerData({
      homePaused: !homePaused,
    });
  };

  return (
    <Wrapper onClick={handleToggle}>
      {secondsToDuration(homeTotal, true)}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  homeTotal: state.timer.homeTotal,
  homePaused: state.timer.homePaused,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setTimerData: (payload) =>
      dispatch({ type: REDUX_ACTIONS.TIMER_SET, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
