import styled from 'styled-components';

import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  padding-left: ${theme.dimensions.padding_2};
  padding-right: ${theme.dimensions.padding_2};
`;

export default buildStyledComponent(
  'MainLayout_NavMenu',
  styled(Container),
  styles
);
