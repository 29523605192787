import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'style';

const styles = () => `
  background: #F3F3F4;
  padding: ${px2rem(5)} ${px2rem(8)};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default buildStyledComponent(
  'Medications_IconWrapper',
  styled.div,
  styles,
);
