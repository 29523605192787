import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ADD_CAREPLAN_RESPONSE } from 'graphql/mutations';
import { FETCH_CAREPLAN_RESPONSE } from 'graphql/queries';
import { toGMT } from 'utils/time';

const useResponse = ({ patient }) => {
  const [responses, updateResponses] = useState([]);
  const [callAddResponseApi] = useMutation(ADD_CAREPLAN_RESPONSE, {
    onCompleted: (data) => {
      if (data.addResponse?.responses) {
        updateResponses(data.addResponse.responses);
      }
    },
    onError: () => {},
  });
  const [loadResponseApi] = useLazyQuery(FETCH_CAREPLAN_RESPONSE, {
    onCompleted: (data) => {
      updateResponses(data.todayResponse?.responses);
    },
  });

  const addResponse = async (type, time, response) => {
    const responseData = {
      measure: type,
      time: toGMT(time),
      response,
    };
    await callAddResponseApi({
      variables: {
        patient: patient._id,
        date: toGMT(new Date(), true),
        response: responseData,
      },
    });
  };

  const fetchResponses = async () => {
    await loadResponseApi({
      variables: {
        patient: patient._id,
        date: toGMT(new Date(), true),
      },
    });
  };

  return { responses, addResponse, fetchResponses };
};

export default useResponse;
