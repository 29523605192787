import React from 'react';

import { Container, Row, Column, Text, Ribbon, Badge } from 'common';
import MenuIcon from './MenuIcon';

export default function MenuItem({ icon, label, ribbon }) {
  return (
    <Container>
      <Row>
        <Column modifiers={['col_2']}>
          <MenuIcon src={icon} alt="" />
          {ribbon && (
            <Ribbon top="7px" right="10px">
              <Badge modifiers={['tiny']} />
            </Ribbon>
          )}
        </Column>
        <Column modifiers={['col_9', 'flexBox', 'verticalCenter']}>
          <Text modifiers={['secondaryColor', 'secondaryFont', 'subtitle']}>
            {label}
          </Text>
        </Column>
      </Row>
    </Container>
  );
}
