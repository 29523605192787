import React, { useState, useEffect, useContext } from 'react';
import { Container, Text } from '@gaz/gaz-components.public';
import styled from 'styled-components';

import { PatientHomeContext } from '../../Context';
import { buildStyledComponent } from 'style';
import ActiveCareplanSection from './ActiveCareplanSection';
import CareplanListSection from './CareplanListSection';
import ResultsSection from './ResultsSection';
import CareplanDetail from 'pages/Careplans/CareplanDetail';

const SectionHeader = buildStyledComponent(
  'SectionHeader',
  styled(Container),
  ({ theme }) => `
    background: ${theme.colors.grey};
    text-align: center;
    padding: ${theme.dimensions.padding_1};
  `
);

const Dashboard = ({ showCareplan, inactive }) => {
  const { fetchCareplans, patient, activeCareplan, setBackHandler } =
    useContext(PatientHomeContext);
  const [activeCPExpanded, setActiveCPExpanded] = useState(false);
  const [showCP, setShowCP] = useState(false);

  useEffect(() => {
    if (!!patient) {
      fetchCareplans();
    }
  }, [patient]);

  const expandActiveCareplanSection = (expanded) => {
    setActiveCPExpanded(expanded);
  };

  const handleViewPlan = () => {
    setShowCP(true);
    setBackHandler(() => {
      setShowCP(false);
      return false;
    });
  };

  return (
    <Container modifiers={['fluid', 'fullHeight', inactive && 'noDisplay']}>
      <Container modifiers={['fluid', showCP && 'noDisplay']}>
        <SectionHeader>
          <Text modifiers={['bold']}>Active Care Plan</Text>
        </SectionHeader>
        <Container>
          <ActiveCareplanSection
            expandSection={expandActiveCareplanSection}
            handleViewPlan={handleViewPlan}
          />
        </Container>
      </Container>
      {showCP ? (
        <CareplanDetail careplan={activeCareplan} />
      ) : (
        !activeCPExpanded && (
          <>
            <Container modifiers={['fluid']}>
              <SectionHeader>
                <Text modifiers={['bold']}>Care Plans</Text>
              </SectionHeader>
              <Container>
                <CareplanListSection showCareplan={showCareplan} />
              </Container>
            </Container>
            <Container modifiers={['fluid']}>
              <SectionHeader>
                <Text modifiers={['bold']}>Care Plan Results</Text>
              </SectionHeader>
              <Container>
                <ResultsSection results={[]} />
              </Container>
            </Container>
          </>
        )
      )}
    </Container>
  );
};

export default Dashboard;
