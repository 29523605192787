import { connect } from 'react-redux';

import { Text } from 'common';
import { secondsToDuration } from 'utils/time';

const TimeElapsed = ({ timeElapsed, modifiers }) => (
  <Text modifiers={modifiers}>{secondsToDuration(timeElapsed, true)}</Text>
);

const mapStateToProps = (state) => ({
  timeElapsed: state.callTimer.time,
});

export default connect(mapStateToProps)(TimeElapsed);
