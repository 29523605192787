import React, { useRef, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';
import assets from 'assets';

import { Row, Button, Text } from '@gaz/gaz-components.public';
import TextInput from './TextInput';
import { SlidingPane, Container } from 'common';

const NoteSimpleInputForm = ({
  value,
  handleSave,
  handleCancel,
  isVisible,
  children,
}) => {
  const formRef = useRef(null);
  const initialValues = {
    input: value,
  };
  const inputRef = useRef(null);

  const handleSubmit = (values) => {
    handleSave(values.input);
  };

  const handleClickDone = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  useEffect(() => {
    if (isVisible) {
      if (inputRef.current) {
        // waits for sliding animation to finish
        setTimeout(() => {
          inputRef.current.focus();
        }, 300);
      }
    }
  }, [isVisible]);

  return (
    <SlidingPane isOpen={isVisible} modifiers={['unsetHeight']}>
      <SlidingPane.Header modifiers={['noJustify']}>
        {children}
      </SlidingPane.Header>
      <SlidingPane.Content>
        <Container modifiers={['flexBox', 'fluid']}>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <TextInput
                  name="input"
                  handleChange={(value) => setFieldValue('input', value)}
                  clearValue={() => {
                    setFieldValue('input', '');
                  }}
                  value={values.input || ''}
                  ref={inputRef}
                />
              </Form>
            )}
          </Formik>
        </Container>
      </SlidingPane.Content>
      <Container modifiers={['flexBox', 'footer']}>
        <Row modifiers={['spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={handleCancel}
          />
          <Button
            modifiers={['widthXSmall', 'primary', 'roundCorner']}
            onClick={handleClickDone}
          >
            <Text modifiers={['subtitle', 'semiBold', 'white']}>Done</Text>
          </Button>
        </Row>
      </Container>
    </SlidingPane>
  );
};

export default NoteSimpleInputForm;
