import assets from 'assets';
import urls from 'routes/urls';

export default {
  home: {
    icon: assets.icons.icHome,
    selectedIcon: assets.icons.icHomeBlue,
    title: 'Home',
    url: urls.DASHBOARD,
  },
  invitations: {
    icon: assets.icons.icInvitation,
    selectedIcon: assets.icons.icInvitationBlue,
    title: 'Invitations',
    url: urls.INVITATIONS,
  },
  reports: {
    icon: assets.icons.icReports,
    selectedIcon: assets.icons.icReportsBlue,
    title: 'Reports',
    url: urls.REPORTS,
  },
  patient: {
    icon: assets.icons.icAdd,
    selectedIcon: assets.icons.icAddBlue,
    title: 'Add Patient',
    url: urls.ADD_PATIENT,
  },
};
