import {
  useContext,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/client';

import { MainLayout } from 'layouts';
import {
  BackButton,
  Avatar,
  Row,
  Column,
  Text,
  ScrollView,
} from '@gaz/gaz-components.public';
import { Container, PeriodPicker, Tabs } from 'common';
import Item from './Item';
import { FETCH_NOTES } from 'graphql/queries';
import { EVENTS, SocketContext } from 'contexts/socket';
import { AuthContext } from 'contexts/auth';
import { getFullName } from 'utils/string';

const { Header, Content } = MainLayout;

export default () => {
  const history = useHistory();
  const { me } = useContext(AuthContext);
  const [tab, setTab] = useState(0);
  const [notes, setNotes] = useState(null);
  const [period, setPeriod] = useState('3m');
  const { push, location } = useHistory();
  const notesListUpdateSubscription = useRef();
  const { subscribe } = useContext(SocketContext);
  const [queryNotes] = useLazyQuery(FETCH_NOTES, {
    variables: { period },
    onCompleted: (data) => {
      setNotes(data.notes);
    },
    onError: () => {
      setNotes([]);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const fetchNotes = useCallback(() => {
    setNotes(null);
    queryNotes();
  }, []);

  useEffect(() => {
    if (!!period) {
      fetchNotes();
      notesListUpdateSubscription.current?.unsubscribe();
      notesListUpdateSubscription.current = subscribe(
        EVENTS.PATIENT_NOTES,
        () => {
          fetchNotes();
        }
      );
    }
    return () => {
      notesListUpdateSubscription.current?.unsubscribe();
    };
  }, [period, fetchNotes]);

  const signedNotes = useMemo(() => {
    if (notes?.length > 0) {
      return notes.filter((note) => !note.isDraft);
    }
    return [];
  }, [notes]);

  const draftNotes = useMemo(() => {
    if (notes?.length > 0) {
      return notes.filter((note) => note.isDraft);
    }
    return [];
  }, [notes]);

  const showingNotes = tab === 0 ? signedNotes : draftNotes;

  const handleSelectNote = (note) => push(`${location.pathname}/${note._id}`);

  const goBack = () => {
    history.replace('/');
  };

  if (!me) {
    return null;
  }

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton onClick={goBack} />
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle']}>
            <Avatar user={me} width={40} height={40} modifiers={['squared']} />
            <Column modifiers={['col_8', 'flexBox']}>
              <Text modifiers={['semiBold', 'start']}>{getFullName(me)}</Text>
            </Column>
          </Row>
        </Header.Center>
      </Header>
      <Content>
        <Container
          modifiers={['fluid', 'fullHeight', 'flexBox', 'backgroundWhite']}
        >
          <Container modifiers={['backgroundGray']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Text modifiers={['medium', 'bold']}>Notes List</Text>
              <Column>
                <PeriodPicker value={period} handleSelect={setPeriod} />
              </Column>
            </Row>
            <Tabs current={tab} onChange={setTab} reverseFlatTabs>
              <Tabs.Tab
                title={`Signed Notes${
                  signedNotes.length > 0 ? ` (${signedNotes.length})` : ''
                }`}
              />
              <Tabs.Tab
                title={`Draft${
                  draftNotes.length > 0 ? ` (${draftNotes.length})` : ''
                }`}
              />
            </Tabs>
          </Container>
          <ScrollView>
            {showingNotes.length > 0 ? (
              <Container>
                {showingNotes.map((note, index) => (
                  <Item
                    key={index}
                    note={note}
                    handleClick={() => handleSelectNote(note)}
                  />
                ))}
              </Container>
            ) : (
              <Text>No notes found.</Text>
            )}
          </ScrollView>
        </Container>
      </Content>
    </MainLayout>
  );
};
