import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import { A, Column, Row, Tabs, Text } from 'common';
import { AuthLayoutV2 } from 'layouts';
import urls from 'routes/urls';
import { AuthContext } from 'contexts/auth';

import Login from './Login';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

const { Title, Content } = AuthLayoutV2;

// here we can set up routes like recover password
const PATHS = ['/login', '/sign-up', '/forgot-password', '/reset-password'];

export default function Auth({ history }) {
  const [currentTab, setCurrentTab] = useState();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { me } = useContext(AuthContext);

  useEffect(() => {
    for (let subPath = 0; subPath < PATHS.length; subPath++) {
      if (pathname.startsWith(`${path}${PATHS[subPath]}`)) {
        setCurrentTab(subPath);
        return;
      }
    }
    setCurrentTab(0);
  }, [pathname]);

  const getPathByTab = useCallback(() => {
    if (currentTab >= 0) {
      return `${path}${PATHS[currentTab]}`;
    }
  }, [currentTab, path]);

  useEffect(() => {
    if (currentTab >= 0 && currentTab < 2) {
      const route = getPathByTab();
      history.push(route);
    }
  }, [currentTab]);

  useEffect(() => {
    localStorage.removeItem('_expiredTime');
  }, []);

  if (me) {
    return <Redirect to={urls.DASHBOARD} />;
  }

  return (
    currentTab >= 0 && (
      <AuthLayoutV2>
        <Title>
          <Column modifiers={['col_12']}>
            <A to={urls.DASHBOARD}>
              <Text modifiers={['h2', 'white', 'start', 'block']}>
                Welcome to
                <br />
                Gazuntite Provider
              </Text>
            </A>
          </Column>
        </Title>
        <Content>
          {currentTab <= 1 && (
            <Row modifiers={['middle', 'center', 'withGutters']}>
              <Tabs
                current={currentTab}
                onChange={(newTab) => setCurrentTab(newTab)}
              >
                <Tabs.Tab
                  modifiers={['noTab', 'plain', 'h2', 'small']}
                  title="Login"
                />
                <Tabs.Tab
                  modifiers={['noTab', 'plain', 'h2', 'small']}
                  title="Register"
                />
              </Tabs>
            </Row>
          )}
          <Switch>
            <Route exact path={`${path}/login`} component={Login} />
            <Route exact path={`${path}/sign-up`} component={SignUp} />
            <Route
              exact
              path={`${path}/forgot-password`}
              component={ForgotPassword}
            />
            <Route
              exact
              path={`${path}/reset-password`}
              component={ResetPassword}
            />
            <Redirect to={urls.DASHBOARD} />
          </Switch>
        </Content>
      </AuthLayoutV2>
    )
  );
}
