import styled from 'styled-components';
import { buildStyledComponent } from 'style';

export default buildStyledComponent(
  'AddAttachment_PDFWrapper',
  styled.div,
  ({ theme }) => `
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  canvas {
    width: 159px !important;
    height: 220px !important;
    border: 1px solid ${theme.colors.primary};
    border-radius: 10px;
  }
`,
);
