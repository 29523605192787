import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  show: () => `
    transform: translateY(0%);
    transition: all 0.2s;
  `,
};

const styles = ({ theme }) => `
  transform: translateY(100%);
  transition: transform 0.2s;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.dimensions.boxShadow_4};
  padding-top: ${px2rem(10)};
  padding-bottom: ${px2rem(10)};
  padding-left: ${px2rem(10)};
  padding-right: ${px2rem(10)};
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;

export default buildStyledComponent(
  'PatientReport_SharePane',
  styled.div,
  styles,
  { modifierConfig }
);
