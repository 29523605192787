import { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import {
  ScrollView,
  Row,
  Column,
  Text,
  Button,
} from '@gaz/gaz-components.public';
import assets from 'assets';
import { Container, Input, TimeLabel, Modal } from 'common';
import Tabs from '../Tabs';
import Vital from './Vital';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { checkTaskDue, hourToDate, toLocalTime, displayTime } from 'utils/time';
import { getVitalTypeAttr } from 'utils/helper';
import { loadingVar } from 'graphql/cache';

export default () => {
  const [tab, setTab] = useState(null);
  const { tasks, addResponse, responses, goBack } =
    useContext(PatientHomeContext);
  const hours = tasks ? Object.keys(tasks.vital) : [];
  const [inputs, updateInputs] = useState({});
  const isTaskDue =
    tab !== null && hours.length > 0 ? checkTaskDue(hours[tab]) : null;
  const vitals =
    tab !== null && tasks?.vital && hours.length > 0
      ? tasks.vital[hours[tab]]
      : [];
  const [alerts, updateAlerts] = useState([]);
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    if (!tasks || tab !== null) {
      return;
    }
    setTab(0);
  }, [tasks]);

  const addAnswer = () => {
    if (!tasks) {
      return;
    }
    checkAlerts();
  };

  const checkAlerts = () => {
    const newAlerts = [];
    if (
      parseInt(inputs?.heartRate, 10) > 224 ||
      parseInt(inputs?.heartRate, 10) < 40
    ) {
      newAlerts.push(`You entered ${inputs.heartRate} BPM.`);
    }
    if (
      parseInt(inputs?.bloodPressure) >= 190 ||
      parseInt(inputs?.bloodPressure2) <= 100
    ) {
      newAlerts.push(
        `You entered ${inputs.bloodPressure} mmHg and ${inputs.bloodPressure2} mmHg.`
      );
    }
    if (parseInt(inputs?.weight) >= 450) {
      newAlerts.push(`You entered ${inputs.weight} lbs.`);
    }
    if (parseInt(inputs?.glucose) > 300 || parseInt(inputs?.glucose) < 50) {
      newAlerts.push(`You entered ${inputs.glucose} mg/dL.`);
    }
    if (
      parseInt(inputs?.respiratory) >= 27 ||
      parseInt(inputs?.respiratory) <= 5
    ) {
      newAlerts.push(`You entered ${inputs.respiratory} breaths/min.`);
    }
    if (parseInt(inputs?.bloodOxygen) < 90) {
      newAlerts.push(`You entered ${inputs.bloodOxygen}%.`);
    }
    if (parseInt(inputs?.temperature) >= 104) {
      newAlerts.push(`You entered ${inputs.temperature}\u2109.`);
    }
    updateAlerts(newAlerts);
    if (newAlerts.length === 0) {
      addInput();
    }
  };

  const addInput = async () => {
    loadingVar(true);
    await addResponse('vital', hourToDate(hours[tab]), inputs);
    loadingVar(false);
    goBack();
  };

  useEffect(() => {
    if (!tasks || !responses || vitals.length === 0 || tab === null) {
      updateInputs({});
      return;
    }
    const hour = new Date(hourToDate(hours[tab])).getTime() / 1000;
    const inputs = {};
    let hasResponse = false;
    vitals.forEach((vital) => {
      const vitalResponse = responses.find(
        (response) =>
          response.measure === 'vital' &&
          toLocalTime(response.time).unix() === hour &&
          response.response.type === vital.id
      );
      if (!vitalResponse) {
        return;
      }
      inputs[vitalResponse.response.type] = vitalResponse.response.value;
      if (!!vitalResponse.response.value) {
        hasResponse = true;
      }
      if (vitalResponse.response.type === 'bloodPressure') {
        inputs['bloodPressure2'] = vitalResponse.response.value2;
      }
    });
    updateInputs({ ...inputs });
    if (isTaskDue && !hasResponse) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [responses, tab, tasks]);

  useEffect(() => {
    if (alerts.length === 0) {
      return;
    }
    Modal.confirm({
      render: () => (
        <>
          <Text modifiers={['h2', 'center']}>Confirm Data?</Text>
          <Row modifiers={['withGutters']}>
            <Text modifiers={['medium', 'center', 'block']}>
              {alerts[0]} Is this correct?
            </Text>
          </Row>
        </>
      ),
      onOk: () => {
        const newAlerts = alerts.slice(1);
        updateAlerts(newAlerts);
        if (newAlerts.length === 0) {
          addInput();
        }
      },
      onCancel: () => {
        updateAlerts([]);
      },
    });
  }, [alerts]);

  const updateInputValue = (key, value) => {
    inputs[key] = value;
    updateInputs({ ...inputs });
  };

  const handleEdit = () => {
    setCanEdit(true);
  };

  return (
    <Container modifiers={['flexBox', 'fullHeight', 'fluid']}>
      <Container modifiers={['flexOne', 'fluid', 'fullHeight', 'flexBox']}>
        <Container modifiers={['flexFixed']}>
          <Tabs>
            <Tabs.Header>
              <Text modifiers={['white', 'subtitle']}>Vitals</Text>
              <Text modifiers={['primary', 'subtitle', 'semiBold']}>
                {isTaskDue === false ? (
                  <Text modifiers={['danger', 'subtitle', 'semiBold']}>
                    Not Due - {hours[tab]}, {moment().format('MM/DD/YYYY')}
                  </Text>
                ) : (
                  <TimeLabel />
                )}
              </Text>
            </Tabs.Header>
            <Row>
              {hours.map((hour, index) => (
                <Tabs.Tab
                  key={index}
                  onClick={() => setTab(index)}
                  modifiers={[tab === index && 'active']}
                >
                  {hour}
                </Tabs.Tab>
              ))}
            </Row>
          </Tabs>
        </Container>
        <Container modifiers={['flexFixed']}>
          <Text modifiers={['darkGrey', 'subtitle']}>
            Please use monitoring device for manual or auto input
          </Text>
        </Container>
        <ScrollView>
          {vitals.map((vital, index) => {
            const attributes = getVitalTypeAttr(vital.id);
            return (
              <Column modifiers={['col_12']} key={index}>
                <Row>
                  <Vital.Item
                    modifiers={['col_6', 'flexBox', 'verticalContentCenter']}
                  >
                    <img
                      src={attributes.icon}
                      alt=""
                      width="20"
                      height="20"
                      style={{
                        margin: 3,
                      }}
                    />
                    <Text modifiers={['darkGrey', 'subtitle', 'inline']}>
                      {attributes.title}
                    </Text>
                  </Vital.Item>
                  <Vital.Value modifiers={['col_6']}>
                    {vital.id === 'bloodPressure' ? (
                      isTaskDue && canEdit ? (
                        <Row modifiers={['middle', 'center']}>
                          <Column modifiers={['noPadding', 'col_1']}>
                            <Text modifiers={['subtitle', 'primary']}>S</Text>
                          </Column>
                          <Column modifiers={['noPadding', 'col_5']}>
                            <Input
                              type="number"
                              inputmode="decimal"
                              value={inputs['bloodPressure'] || ''}
                              modifiesr={['noBorder', 'noPadding']}
                              onChange={(e) =>
                                updateInputValue(
                                  'bloodPressure',
                                  e.target.value
                                )
                              }
                            />
                          </Column>
                          <Column modifiers={['noPadding', 'col_1']}>
                            <Text modifiers={['subtitle', 'primary']}>D</Text>
                          </Column>
                          <Column modifiers={['noPadding', 'col_5']}>
                            <Input
                              type="number"
                              inputmode="decimal"
                              value={inputs['bloodPressure2'] || ''}
                              modifiesr={['noBorder']}
                              onChange={(e) =>
                                updateInputValue(
                                  'bloodPressure2',
                                  e.target.value
                                )
                              }
                            />
                          </Column>
                        </Row>
                      ) : (
                        <Row modifiers={['middle', 'center']}>
                          <Column modifiers={['noPadding', 'col_1']}>
                            <Text modifiers={['subtitle', 'darkGrey']}>S</Text>
                          </Column>
                          <Column modifiers={['noPadding', 'col_5']}>
                            <Text modifiers={['subtitle', 'darkGrey']}>
                              {!canEdit && inputs['bloodPressure']
                                ? inputs['bloodPressure']
                                : 0}
                            </Text>
                          </Column>
                          <Column modifiers={['noPadding', 'col_1']}>
                            <Text modifiers={['subtitle', 'darkGrey']}>D</Text>
                          </Column>
                          <Column modifiers={['noPadding', 'col_5']}>
                            <Text modifiers={['subtitle', 'darkGrey']}>
                              {!canEdit && inputs['bloodPressure2']
                                ? inputs['bloodPressure2']
                                : 0}
                            </Text>
                          </Column>
                        </Row>
                      )
                    ) : isTaskDue && canEdit ? (
                      <Input
                        type="number"
                        inputmode="decimal"
                        value={inputs[vital.id] || ''}
                        modifiesr={['noBorder']}
                        onChange={(e) =>
                          updateInputValue(vital.id, e.target.value)
                        }
                      />
                    ) : (
                      <Text modifiers={['subtitle', 'darkGrey']}>
                        {!canEdit && inputs[vital.id] ? inputs[vital.id] : 0}
                      </Text>
                    )}
                  </Vital.Value>
                </Row>
              </Column>
            );
          })}
        </ScrollView>
      </Container>
      <Container modifiers={['footer', 'flexFixed', 'fluid']}>
        <Row modifiers={['spaceBetween']}>
          <Column modifiers={['col_6', 'flexBox', 'verticalContentCenter']}>
            <Button
              image={assets.icons.icCloseGrey}
              width={22}
              height={22}
              modifiers={['icon', 'transparent']}
              onClick={() => goBack()}
            />
          </Column>
          <Column modifiers={['col_5', 'col_offset_1', 'flexBox', 'center']}>
            <Button
              modifiers={[
                !isTaskDue && 'disabled',
                'widthSmall',
                'roundCorner',
              ]}
              onClick={isTaskDue && (canEdit ? addAnswer : handleEdit)}
            >
              <Text modifiers={['subtitle', 'white', 'semiBold']}>
                {!isTaskDue || canEdit ? 'Done' : 'Edit'}
              </Text>
            </Button>
          </Column>
        </Row>
      </Container>
    </Container>
  );
};
