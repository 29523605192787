import { client } from './client';
import fragments from './fragments';
import { FETCH_CHAT, FETCH_USER } from './queries';

export const getUser = async (id) => {
  const user = client.readFragment({
    id: `User:${id}`,
    fragment: fragments.userFragment,
    fragmentName: 'UserFragment',
  });

  if (user) {
    return user;
  }

  const { data } = await client.query({
    query: FETCH_USER,
    variables: {
      id,
    },
  });

  return data.user;
};

export const getChat = async (id) => {
  const chat = client.readFragment({
    id: `Chat:${id}`,
    fragment: fragments.chatFragment,
    fragmentName: 'ChatFragment',
  });

  if (chat) {
    return chat;
  }

  const { data } = await client.query({
    query: FETCH_CHAT,
    variables: {
      id,
    },
  });

  return data.chat;
};
