import { useContext, useState, useRef } from 'react';
import assets from 'assets';
import { loadingVar } from 'graphql/cache';
import axios from 'axios';
import { useMutation } from '@apollo/client';

import { Row, Column, Button, Text } from '@gaz/gaz-components.public';

import { Container, AddAttachment } from 'common';
import { CreateNoteContext } from '../Context';
import { PatientHomeContext } from '../../../Context';
import { SIGN_S3 } from 'graphql/mutations';

import History from './History';
import AddedItems from './AddedItems';

const NoteAttachments = () => {
  const { note, updateNote, goBack, setBackHandler } =
    useContext(CreateNoteContext);
  const { patient } = useContext(PatientHomeContext);
  const [attachments, updateAttachments] = useState(() => {
    if (!note.content?.attachments) {
      return [];
    }
    return [...note.content.attachments];
  });
  const attachmentRef = useRef(null);
  const [selectFromPast, setSelectFromPast] = useState(false);
  const [signS3] = useMutation(SIGN_S3);

  const handleAddAttachment = async ({ file, type: fileType }) => {
    loadingVar(true);
    const {
      data: {
        signS3: { url, signedRequest },
      },
    } = await signS3({
      variables: {
        folder: `notes-${patient._id}`,
        fileType,
      },
    });
    let attachment;
    try {
      await axios.put(signedRequest, file, {
        headers: {
          'Content-Type': fileType,
        },
      });
      attachment = {
        category: 'file',
        url,
        originalName: file.name,
      };
      if (fileType.includes('video')) {
        attachment.type = 'video';
      } else if (fileType.includes('image')) {
        attachment.type = 'image';
      } else if (fileType === 'application/pdf') {
        attachment.type = 'pdf';
      } else {
        attachment.type = fileType.split('/')[1];
      }
      updateAttachments([...attachments, attachment]);
    } catch (error) {
      // errorVar(error);
    } finally {
      loadingVar(false);
      URL.revokeObjectURL(file);
    }
  };

  const handleAddAttachments = (attachments) => {};

  const handleAddFromPast = () => {
    setSelectFromPast(true);
    setBackHandler(() => setSelectFromPast(false));
  };

  const handleClickAdd = () => {
    attachmentRef.current?.openInput();
  };

  const handleDone = () => {
    const content = note.content || {};
    content.attachments = attachments;
    updateNote({ ...note, content });
    goBack();
  };

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <Container style={{ background: '#F3F3F4' }} modfiers={['flexFixed']}>
        <Row modifiers={['middle', 'spaceBetween']}>
          <Column modifiers={['noPadding']}>
            <Text modifiers={['bold', 'block']}>Add Attachments</Text>
            <Text modifiers={['muted', 'block', 'medium']}>
              Add documents to note
            </Text>
          </Column>
          <Column modifiers={['noPadding']}>
            <Button
              onClick={handleClickAdd}
              modifiers={['success', 'roundCorner']}
            >
              <Text modifiers={['subtitle', 'white', 'bold']}>+ ADD</Text>
            </Button>
          </Column>
        </Row>
      </Container>
      {!selectFromPast && attachments.length > 0 && (
        <Container modifiers={['flexFixed', 'padding_2']}>
          <AddedItems
            attachments={attachments}
            updateAttachments={updateAttachments}
          />
        </Container>
      )}
      <Container modifiers={['flexOne', 'fluid']}>
        <History
          selectable={selectFromPast}
          handleAddFromPast={handleAddFromPast}
          handleAdd={handleAddAttachments}
        />
      </Container>
      {!selectFromPast && (
        <Container modifiers={['footer']}>
          <Row modifiers={['spaceBetween', 'middle']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            <Button
              modifiers={['widthSmall', 'roundCorner']}
              onClick={handleDone}
            >
              Done
            </Button>
          </Row>
        </Container>
      )}
      <AddAttachment
        ref={attachmentRef}
        onAddAttachment={handleAddAttachment}
      />
    </Container>
  );
};

export default NoteAttachments;
