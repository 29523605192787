import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  color: ${theme.colors.primary};
  font-size: ${theme.dimensions.fontSizeLarge};
  text-align: center;
  padding: ${px2rem(10)};
  flex: 0 0;
`;

export default buildStyledComponent('Popover_Header', styled.div, styles);
