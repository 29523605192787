import React, { useContext } from 'react';

import { AuthContext } from 'contexts/auth';
import Wrapper from './Wrapper';
import Provider from './Provider';
import Pharmacist from './Pharmacist';

export default function Summary() {
  const { me } = useContext(AuthContext);

  return (
    <Wrapper>
      {me.memberDesignation === 'pharmacist' ? <Pharmacist /> : <Provider />}
    </Wrapper>
  );
}
