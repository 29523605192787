import styled from 'styled-components';
import { Column, Text } from '@gaz/gaz-components.public';

import { Container, Row, HR } from 'common';
import { displayTime } from 'utils/time';
import assets from 'assets';
import { buildStyledComponent, px2rem } from 'style';
import { formatTitleAndName } from 'utils/string';

const Item = buildStyledComponent(
  'NoteItem',
  styled(({ handleClick, note, ...props }) => (
    <Container modifiers={['card']} onClick={handleClick} {...props}>
      <Row modifiers={['middle', 'spaceBetween']}>
        <img src={assets.icons.icNoteItem} />
        <Column modifiers={['col']}>
          <Text
            modifiers={[note?._id && !note?.isDraft && !note?.isSeen && 'bold']}
          >
            {formatTitleAndName(note.creator.user)}
          </Text>
        </Column>
        <Text>{note._id.substr(-7)}</Text>
      </Row>
      <HR modifiers={['gray']} />
      <Row modifiers={['smallGutters']}>
        <Text
          modifiers={[note?._id && !note?.isDraft && !note?.isSeen && 'bold']}
        >
          Patient Name:{' '}
          <Text modifiers={['primary']}>{formatTitleAndName(note.user)}</Text>
        </Text>
      </Row>
      <Row modifiers={['middle', 'spaceBetween']}>
        <Text
          modifiers={[note?._id && !note?.isDraft && !note?.isSeen && 'bold']}
        >
          {displayTime(
            note.isDraft ? note.updatedAt : note.signDate,
            'hh:mm a, MM/DD/yy'
          )}
        </Text>
        <img src={assets.icons.icMore} />
      </Row>
    </Container>
  )),
  ({ theme }) => `
    margin: ${theme.dimensions.padding_2} 0;
    padding: ${theme.dimensions.padding_2} ${theme.dimensions.padding_1};
    > :last-child {
      img {
        width: ${px2rem(18)};
        height: ${px2rem(18)};
      }
    }
  `
);

export default Item;
