import { Container } from '@gaz/gaz-components.public';
import SimpleItem from './SimpleItem';
import Diagnosis from './Diagnosis';
import Objective from './Objective';
import Attachment from './Attachment';

const NoteDetail = ({ note }) => (
  <Container modifiers={['fluid']}>
    {note.content?.subjective && (
      <SimpleItem title="Subjective" value={note.content.subjective} />
    )}
    {note.content?.objective && <Objective vitals={note.content.objective} />}
    {note.content?.assessment && (
      <SimpleItem title="Assessment" value={note.content.assessment} />
    )}
    {note.content?.plan && (
      <SimpleItem title="Plan Note" value={note.content.plan} />
    )}
    {note.content?.diagnosis && (
      <Diagnosis diagnosis={note.content.diagnosis} />
    )}
    {note.content?.attachments?.length > 0 && (
      <Attachment attachments={note.content.attachments} />
    )}
  </Container>
);

export default NoteDetail;
