import React from 'react';
import { Text } from '@gaz/gaz-components.public';
import assets from 'assets';

import Wrapper from './Wrapper';

export default function Splash() {
  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
        }}
      >
        <img
          alt=""
          style={{
            marginBottom: 10,
            objectFit: 'contain',
            width: 200,
            height: 187.33,
          }}
          src={assets.images.imgBigLogo}
        />
        <img
          alt=""
          style={{
            marginBottom: 10,
            objectFit: 'contain',
            width: 240,
            height: 30,
          }}
          src={assets.images.imgBrandTitle}
        />
        <Text modifiers={['h3', 'white', 'topPadding']}>
          Chronic Care Delivered
        </Text>
      </div>
    </Wrapper>
  );
}
