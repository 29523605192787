import { useContext, useEffect, useState } from 'react';

import { CallServiceContext } from 'contexts/call';
import Wrapper from './Wrapper';
import Content from './Content';
import Footer from './Footer';
import MinIndicator from './MinIndicator';

const CallIndex = () => {
  const { hasOngoingCall } = useContext(CallServiceContext);
  const [minimized, setMinimized] = useState(false);

  useEffect(() => {
    if (hasOngoingCall) {
      setMinimized(false);
    }
  }, [hasOngoingCall]);

  return hasOngoingCall ? (
    <>
      <Wrapper modifiers={[minimized && 'minimized']}>
        <Content />
        <Footer handleMinimize={() => setMinimized(true)} />
      </Wrapper>
      <MinIndicator
        handleMaximize={() => setMinimized(false)}
        minimized={minimized}
      />
    </>
  ) : null;
};

export default CallIndex;
