import React, { useEffect, useState, useCallback, useRef } from 'react';
import Picker from '../../Picker/Picker';
import MultiPicker from 'rmc-picker/lib/MultiPicker';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { debounce } from 'lodash';

import 'rmc-picker/assets/index.css';

import FormInput from '../FormInput';
import Modal from '../FormPicker/Modal';

const moment = extendMoment(Moment);
const locale = 'en';

export const FormTimePicker = (props) => {
  const {
    field: { name, onChange },
    form: { touched },
    placeholder,
    handleChange: customChangeHandler,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const defaultTime =
    typeof props.field.value === 'string'
      ? moment(props.field.value, 'hh:mm a')
      : props.field.value;
  const defaultValue = defaultTime
    ? [
        moment(defaultTime).format('h'),
        moment(defaultTime).format('mm'),
        moment(defaultTime).format('a').toUpperCase(),
      ]
    : [];
  const [value, setValue] = useState(defaultValue);
  const wheelDeltaData = useRef();

  const createHours = () => {
    return Array(12)
      .fill()
      .map((values, index) => (index === 0 ? '12' : `${index}`));
  };

  const createMinutes = () => {
    return Array(60)
      .fill()
      .map((values, index) => (index < 10 ? `0${index}` : `${index}`));
  };

  const createAs = () => ['AM', 'PM'];

  const convertTime = (time) => {
    if (time.length === 3 && time[0] && time[1] && time[2]) {
      return moment(`${time[0]}:${time[1]} ${time[2]}`, 'h:mm a').format(
        'h:mm a'
      );
    }
    return '';
  };

  const handleChange = useCallback(
    (time) => {
      if (showModal) {
        setValue(time);
      }
    },
    [showModal, name, onChange]
  );

  const handleDone = () => {
    if (showModal) {
      onChange(name)(convertTime(value));
      if (customChangeHandler) {
        customChangeHandler(convertTime(value));
      }
    }
    setShowModal(false);
  };

  const handleToggle = () => {
    setShowModal(!showModal);
    if (showModal === true) {
      setValue(defaultValue);
    }
  };

  const clearWheelDelta = useCallback(
    debounce(() => {
      if (wheelDeltaData.current) {
        wheelDeltaData.current.delta = 0;
      }
    }, 300),
    []
  );

  const handleWheel = useCallback(
    (event) => {
      const delta = -event.nativeEvent?.wheelDeltaY || 0;
      if (delta === 0) {
        return;
      }
      const picker = event.target.parentElement;
      const multiPicker = picker.parentElement;
      let section;
      let values;
      if (picker === multiPicker.children[0]) {
        section = 0;
        values = createHours();
      } else if (picker === multiPicker.children[1]) {
        section = 1;
        values = createMinutes();
      } else if (picker === multiPicker.children[2]) {
        section = 2;
        values = createAs();
      } else {
        return;
      }

      if (
        !wheelDeltaData.current ||
        wheelDeltaData.current.section !== section
      ) {
        wheelDeltaData.current = { delta: 0, section };
      }
      wheelDeltaData.current.delta = wheelDeltaData.current.delta + delta;
      let step = 0;
      if (wheelDeltaData.current.delta >= 120) {
        step = wheelDeltaData.current.delta / 120;
        wheelDeltaData.current.delta = 0;
      } else if (wheelDeltaData.current.delta <= -120) {
        step = -(-wheelDeltaData.current.delta / 120);
        wheelDeltaData.current.delta = 0;
      }

      const wheelStep = Math.max(-3, Math.min(3, Math.round(step)));
      const index = values.indexOf(value[section]);
      const newIndex = Math.min(
        values.length - 1,
        Math.max(0, wheelStep + index)
      );
      if (index !== newIndex) {
        value[section] = values[newIndex];
        setValue([...value]);
      }
      clearWheelDelta();
    },
    [value]
  );

  useEffect(() => {
    if (!touched[name] && value.length === 0 && showModal) {
      handleChange([1, '1', createHours()[0]]);
    }
  }, [touched, name, showModal, value, handleChange]);

  return (
    <div>
      <FormInput
        {...props}
        field={{ ...props.field, value: convertTime(defaultValue) }}
        onClick={() => setShowModal(true)}
        readOnly
      ></FormInput>
      <Modal
        title={placeholder}
        show={showModal ? 'yes' : undefined}
        onToggle={handleToggle}
        handleDone={handleDone}
        onPointerDown={(evt) => {
          evt.target?.setPointerCapture(evt.pointerId);
        }}
        onPointerUp={(evt) => {
          evt.target?.releasePointerCapture(evt.pointerId);
        }}
        onWheel={handleWheel}
      >
        <MultiPicker selectedValue={value} onValueChange={handleChange}>
          <Picker>
            {createHours().map((value) => {
              return (
                <Picker.Item value={value} key={value}>
                  {value}
                </Picker.Item>
              );
            })}
          </Picker>
          <Picker>
            {createMinutes().map((value) => {
              return (
                <Picker.Item value={value} key={value}>
                  {value}
                </Picker.Item>
              );
            })}
          </Picker>
          <Picker>
            {createAs().map((value) => {
              return (
                <Picker.Item value={value} key={value}>
                  {value}
                </Picker.Item>
              );
            })}
          </Picker>
        </MultiPicker>
      </Modal>
    </div>
  );
};

export default FormTimePicker;
